import moment from "moment";
import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useDispatch, useSelector } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { getTimeSpent } from "../../store/features/DashBoard";
import {
  dashboard__coordinates,
  dashboard__dataSet,
  getCurrentDaysMonth,
  isAdminAccess,
  isEmptyArray,
  isEmptyObject,
} from "../../utility/utility";

export default function TimeSpent() {
  const usersAdmin = isAdminAccess("Dashboard");
  const dispatch = useDispatch();
  const { hrsAdminSeriesData, totalAdminHrsDuration } = useSelector(
    (store) => store.dashBoard
  );
  const [dropdown, setdropdown] = useState("Weekly");
  const [isAdmin, setisAdmin] = useState(false);
  const [dataSet, setDataSet] = useState(dashboard__dataSet);

  useEffect(() => {
    setDataSet((prev) => {
      const cpy = { ...prev };
      if (dropdown === "Monthly") cpy.labels = getCurrentDaysMonth();
      else cpy.labels = [0, 1, 2, 3, 4, 5, 6];
      if (
        !isEmptyArray(hrsAdminSeriesData) &&
        !isEmptyObject(totalAdminHrsDuration)
      ) {
        cpy.datasets[0].data = hrsAdminSeriesData;
        cpy.datasets[0].label = ` ${totalAdminHrsDuration?.hours} Hrs   ${totalAdminHrsDuration?.minutes} Mins`;
      }
      return cpy;
    });
  }, [hrsAdminSeriesData, dropdown, isAdmin]);

  useEffect(() => {
    let from, to;
    if (dropdown === "Weekly") {
      from = moment().startOf("week").format("YYYY-MM-DD");
      to = moment().endOf("week").format("YYYY-MM-DD");
    } else {
      from = moment().startOf("month").format("YYYY-MM-DD");
      to = moment().endOf("month").format("YYYY-MM-DD");
    }

    const endpointfn = () => {
      let endpt;
      if (isAdmin) endpt = `?from=${from}&to=${to}&status=Admin`;
      else endpt = `?from=${from}&to=${to}`;
      return endpt;
    };

    dispatch(getTimeSpent(endpointfn()));

    return () => {
      from = "";
      to = "";
    };
  }, [isAdmin, dropdown]);

  return (
    <div className="dashboard-chart-container">
      <div className="flex align-items-center justify-content-between">
        <div className="announcement-time">Time Spent</div>
        <div id="dash-admin" className="flex align-items-center column-gap-2">
          {usersAdmin && (
            <div className="flex align-items-center">
              <InputSwitch
                className="dash-toggle"
                checked={isAdmin}
                onChange={(e) => setisAdmin(e.value)}
                name="dash-admin"
              />
              <span className="ml-2">Admin View</span>
            </div>
          )}
        </div>
        <div className="flex align-items-center">
          <span>Show:</span>
          <Dropdown
            name="timespend-chart"
            value={dropdown}
            options={["Weekly", "Monthly"]}
            onChange={(e) => setdropdown(e.value)}
            className="drop-chart mx-1"
          />
        </div>
      </div>
      <div className="w-full flex justify-content-center">
        <Chart
          className="line-chart-height"
          type="line"
          data={dataSet}
          options={dashboard__coordinates}
        />
      </div>
    </div>
  );
}

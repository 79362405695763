let DEV = {
  PROJECT: {
    FETCH: "http://localhost:3005/projects",
    CREATE: "http://localhost:3005/create",
    DELETE: "http://localhost:3005/deactivate",
    ACTIVATE: "http://localhost:3005/activate",
    UPDATE: "http://localhost:3005/project",
    FETCH_BY_ID: "http://localhost:3005/project",
    RECENTS: "http://localhost:3005/recents",
    SPRINT_LIST: `http://localhost:3005/report/sprint/list`,
    SPRINT_TASK_COUNT: `http://localhost:3005/task/task/sprit/list`,
    SPRINT_REPORT: `http://localhost:3005/task/sprint/task`,
    PROJECT_ROADMAP: `http://localhost:3005/project/roadmap`,
    PROJECT_ASSIGNED_LIST: "http://localhost:3005/projects/list",
    // Project Assignments
    ASSIGNMENT: "http://localhost:3005/assignment",
    ASSIGN_REASSIGN: "http://localhost:3005/assignment?member=",
    ASSIGN: "http://localhost:3005/assignment/assigned?member=",
    PROJECTASSIGNEDMEMBERS: "http://localhost:3005/assignment/assigned/",

    //Files
    FOLDER_CREATE: "http://localhost:3005/add-file",
    FOLDER_LIST: "http://localhost:3005/get-files",
    FILE_UPLOAD: "http://localhost:3005/upload-files",
    FOLDER_DELETE: "http://localhost:3005/delete-file",
    FOLDER_LIST_BY_ID: "http://localhost:3005/get-file",
    FOLDER_FILE_DOCSURL_DELETE: "http://localhost:3005/delete-docsfile",
  },
  HR: {
    SOCKET: "http://localhost:3015/",
    SERIES: "http://localhost:3015/attendence/count",
  },
  CLIENT: {
    FETCH: "http://localhost:3003/clients",
    CREATE: "http://localhost:3003/client",
  },

  SLACK: {
    CHANNEL_LIST: "http://localhost:3011/activity/listchannel",
    REMOVE_SLACK: "http://localhost:3005/projects/channel",
  },

  TASK: {
    FETCH: "http://localhost:3005/task/task",
    FETCH_TASK_BY_SPRINT: "http://localhost:3005/task/",
    START_TIMER: "http://localhost:3005/task/task/start",
    STOP_TIMER: "http://localhost:3005/task/task/stop",
    DELETE_MULTIPLE: "http://localhost:3005/task/multiple",
    KANBAN: "http://localhost:3005/task/kanban",
    IMPORT: "http://localhost:3005/task/import",
    UPDATE_MULTIPLE: "http://localhost:3005/task/update/multiple",
    STATUS_COUNT: `http://localhost:3005/task/status/count`,
  },
  MEMBER: {
    FETCH_DETAIL: "http://localhost:3002/member",
    FETCH: "http://localhost:3002/members/active",
    LIST: "http://localhost:3002/members-list",
    INVITE: "http://localhost:3002/invite",
    EDIT: "http://localhost:3002/member",
    DELETE: "http://localhost:3002/deactivate",
    ACTIVATE: "http://localhost:3002/activate",
    ROLES: "http://localhost:3002/role",
    MEMBER_LIST: "http://localhost:3002/org/members/",
    GETALLROLES: "http://localhost:3002/role/getOrganizationRoles",
    GET_ORGANIZATIONS: "http://localhost:3002/?/organization",
    USER: "http://localhost:3002/user",
    CANCEL_SUBCRIPTION: "http://localhost:3002/cancelsubscription",
    BILLING_DETAILS: "http://localhost:3002/getsubscription",
    UPGRADE_SUBCRIPTION: "http://localhost:3002/upgradesubscription",
    TRANSACTION_HISTORY: "http://localhost:3002/subscriptionhistory",
  },
  PAYSLIP: {
    GET_DASHBOARD: "http://localhost:3015/payslip/dashboard",
    GET: "http://localhost:3015/payslip/bindData",
    EXPORT: "http://localhost:3015/payslip/export",
    GENERATE_PAYSLIP: "http://localhost:3015/payslip/",
  },
  PAYROLL: {
    GET: "http://localhost:3015/salary/employee/list",
    UPDATE: "http://localhost:3015/salary/",
  },
  ATTENDANCE:{
    GET: "http://localhost:3015/attendance",
  },
  SUB_TASK: {
    CREATE: "http://localhost:3005/task/subTask",
  },
  BUG_TASK: {
    CREATE: "http://localhost:3005/task/bugTask",
  },
  SPRINT: {
    FETCH: "http://localhost:3005/task/sprint",
    FETCH_ACTIVE: "http://localhost:3005/task/sprint/active",
    START: "http://localhost:3005/task/sprint/start",
    COMPLETE: "http://localhost:3005/task/sprint/complete",
  },
  CLOUD: {
    UPLOAD: "http://localhost:3007/profilepic",
  },
  TIMESHEET: {
    CREATE: "http://localhost:3011/activity",
    DELETE: "http://localhost:3011/activity/delete",
    WORKING_HOURS: "http://localhost:3011/workinghrs",
    DELETE_BY_TASK: "http://localhost:3011/activity/timelogs",
    EXPORT: "http://localhost:3011/export",
    REJECT: "http://localhost:3011/activity/status",
    SERIES: "http://localhost:3011/activity/countlist",
    DELETE_MULTIPLE: "http://localhost:3011/activity/multiple",
  },
  WIKI: {
    WORKSPACE: "http://localhost:3004/workspace",
    WORKSPACE_SHARE: "http://localhost:3004/workspace-share",
    CREATE_PAGE: "http://localhost:3004/page",
    PAGE: "http://localhost:3004/page/content",
    PAGE_SHARE: "http://localhost:3004/page-share",
    PARENT_PAGE: "http://localhost:3004/page/parent",
    SUB: "http://localhost:3004/page/sub",
    EXPORT: "http://localhost:3004/page/export",
  },
  HOLIDAY: {
    CREATE: "http://localhost:3015/holiday",
    DELETE: "http://localhost:3015/holiday/delete",
    PUBLISH: "http://localhost:3015/holiday/publish",
  },
  ASSET: {
    CREATE_ASSET: "http://localhost:3015/asset",
    DELETE_ASSET: "http://localhost:3015/asset",
    REQUEST_ASSET: "http://localhost:3015/asset/request",
    APPROVE_ASSET: "http://localhost:3015/asset/approve",
    COUNT: "http://localhost:3015/asset/listcount",
    STATUS_COUNT: "http://localhost:3015/asset/statuscount",
    ASSET_CATEGORY: "http://localhost:3015/asset/category",
    DELETE_ASSET_CATEGORY: "http://localhost:3015/asset/delete-category",
    ASSET_CATEGORY_DEPARTMENT: "http://localhost:3015/asset/department",
    MAINTENANCE_HISTORY: "http://localhost:3015/asset/history",
    MOVE_TO_MAINTENANCE: "http://localhost:3015/asset/maintenance",
  },
  LEAVE_REQUEST: {
    CREATE: "http://localhost:3015/",
    CREATE_LEAVE_POLICY: "http://localhost:3015/leave_policy",
    DELETE_LEAVE: "http://localhost:3015/delete",
    STATUS_UPDATE: "http://localhost:3015/status",
    GET_LEAVE_COUNT: "http://localhost:3015/stats",
    GET_WHO_ON_LEAVE: "http://localhost:3015/leaveStat",
    CHANGE_STATUS: "http://localhost:3015/status",
    LEAVE_HISTORY: "http://localhost:3015/history",
    EXPORT: "http://localhost:3015/export",
    GETLEAVEPOLICIESFORMEMBER: "http://localhost:3015/leave_policy/member",
  },
  PERMISSION_REQUEST: {
    CREATE: "http://localhost:3015/permission_request",
    CREATE_PERMISSION_POLICY: "http://localhost:3015/permission_policy",
    DELETE_PERMISSION: "http://localhost:3015/permission_request/delete",
    STATUS_UPDATE: "http://localhost:3015/permission_request/status",
    GET_TAKEN_REMAINING_STATUS:
      "http://localhost:3015/permission_request/timeStats",
  },
  WFH_REQUEST: {
    CREATE: "http://localhost:3015/wfh_request",
    CREATE_WFH_POLICY: "http://localhost:3015/wfh_policy",
    DELETE_WFH: "http://localhost:3015/wfh_request/delete",
    STATUS_UPDATE: "http://localhost:3015/wfh_request/status",
    GET_TAKEN_REMAINING_STATUS: "http://localhost:3015/wfh_request/timeStats",
  },
  RECOGNITION: {
    CREATE: "http://localhost:3015/recognition",
  },
  ANNOUNCEMENT: {
    CREATE: "http://localhost:3015/announcement",
    COMMENT: "http://localhost:3015/announcement_comment",
  },
  BIRTHDAY: {
    GET: "http://localhost:3002/birthday",
    STATUS: "http://localhost:3002/birthday/upcoming",
    TODAY: "http://localhost:3002/birthday/today",
  },
  AUTHENTICATION: {
    SIGNIN: "http://localhost:3002/login",
    SIGNUP: "http://localhost:3002/admin/signup",
    PLAN_TYPE: "http://localhost:3002/admin/payment",
    SIGNUPWITHGOOGLE: "http://localhost:3002/admin/signup/google",
    ORG_SIGNUP: "http://localhost:3002/organization/signup",
    SIGNUP_VERIFY: "http://localhost:3002/otp/verify",
    SIGNUP_RESEND: "http://localhost:3002/otp/resend",
    FORGOT_PASSWORD: "http://localhost:3002/password/forgot",
    CHANGE_PASSWORD: "http://localhost:3002/password/change",
    USER_VERIFY: "http://localhost:3002/user/verify",
    OTP_VERIFY: "http://localhost:3002/member/otp/verify",
    MEMBER_RESEND: "http://localhost:3002/member/otp/resend",
  },

  SCRATCHPAD: {
    CREATE: "http://localhost:3010/scratchpad/",
    COLOR: "http://localhost:3010/colors/",
    SETBACKGROUND_COLOR: "http://localhost:3010/scratchpad/background/color",
    SETBACKGROUND_IMAGE:
      "http://localhost:3010/scratchpad/:scratchpadid/background/image",
    PIN_SCRATCHPAD: "http://localhost:3010/scratchpad/pin",
  },
  DOCUMENT: {
    CREATE: "http://localhost:3007/doc/",
  },
  DEPARTMENT: {
    FETCH: "http://localhost:3002/department/",
  },
  DESIGNATION: {
    FETCH: "http://localhost:3002/designation/",
  },
  RELEASE: {
    CREATE: "http://localhost:3005/release",
    FETCH: "http://localhost:3005/release",
    FETCH_TASK: "http://localhost:3005/release/task",
    TASK_ADD: "http://localhost:3005/release/taskAdd",
    DELETE: "http://localhost:3005/release/delete",
    TASK_DELETE: "http://localhost:3005/release/taskDelete",
    EXPORT: "http://localhost:3005/release/export",
  },
  DASHBOARD: {
    ONGOING_TASK: "http://localhost:3005/dashboard",
    PROJECT_LIST: "http://localhost:3005/dashboard",
    FETCH_LEAVE: "http://localhost:3015/dashboard",
    FETCH_HOLIDAY: "http://localhost:3015/dashboard",
    LEAVE_POLICY: "http://localhost:3015/history",
    TIMESHEET: "http://localhost:3011/totalhrs",
    FETCH_DESIGNATION: "http://localhost:3002/members-list",
    GET_ASSIGNED_PROJECT: "http://localhost:3005/dashboard/assignedProjects",
    GET_UPCOMING_SPRINT: "http://localhost:3005/dashboard/upcomingSprints",
    GET_PROJECT_RELEASE: " http://localhost:3005/dashboard/release",
    GET_EMPLOYEE_LEAVE: "http://localhost:3015/dashboard/leaveStatus",
    GET_ACTIVE_TASKS: "http://localhost:3005/task/current/tasks",
  },
  NOTIFICATION: {
    TODAY: "http://localhost:3002/notifications/today",
    TODAY_ANNOUNCEMENT:
      "http://localhost:3002/notifications/today-announcement",
  },
  PROJECT_SETTINGS: {
    FETCH_TASK: "http://localhost:3005/task/allTasks",
    UPDATE_TASK: "http://localhost:3005/task/status/update",
    UPDATE_BOARD_SETTING: "http://localhost:3005/board/settings",
    UPDATE_BACKLOG_TYPE: "http://localhost:3005/backlog/type",
    UPDATE_BOARD_TYPE: "http://localhost:3005/board/type",
    UPDATE_CAP_COUNT: "http://localhost:3005/capCount",
  },
  SETTINGS: {
    CREATE_ROLE: "http://localhost:3002/role/createRole",
    GET_ROLE: "http://localhost:3002/role/getOrganizationRoles",
    UPDATE_ROLE: "http://localhost:3002/role/updateRole",
    DELETE_ROLE: "http://localhost:3002/role/deleteRole",
    BIRTHDAY_CCEMAIL: "http://localhost:3002/birthdaymail",
  },
};

let QA = {
  PROJECT: {
    FETCH: `${process.env.REACT_APP_QA}/project/projects`,
    CREATE: `${process.env.REACT_APP_QA}/project/create`,
    DELETE: `${process.env.REACT_APP_QA}/project/deactivate`,
    ACTIVATE: `${process.env.REACT_APP_QA}/project/activate`,
    UPDATE: `${process.env.REACT_APP_QA}/project/project`,
    FETCH_BY_ID: `${process.env.REACT_APP_QA}/project/project`,
    RECENTS: `${process.env.REACT_APP_QA}/project/recents`,
    SPRINT_LIST: `${process.env.REACT_APP_QA}/project/report/sprint/list`,
    SPRINT_TASK_COUNT: `${process.env.REACT_APP_QA}/project/task/task/sprit/list`,
    SPRINT_REPORT: `${process.env.REACT_APP_QA}/project/task/sprint/task`,
    PROJECT_ROADMAP: `${process.env.REACT_APP_QA}/project/project/roadmap`,
    PROJECT_ASSIGNED_LIST: `${process.env.REACT_APP_QA}/project/projects/list`,
    // Project Assignments
    ASSIGNMENT: `${process.env.REACT_APP_QA}/project/assignment`,
    ASSIGN_REASSIGN: `${process.env.REACT_APP_QA}/project/assignment?member=`,
    ASSIGN: `${process.env.REACT_APP_QA}/project/assignment/assigned?member=`,
    PROJECTASSIGNEDMEMBERS: `${process.env.REACT_APP_QA}/project/assignment/assigned/`,
  },
  // CLIENT: {
  //   FETCH: `${process.env.REACT_APP_CLIENT_SERVICE}/client/clients`,
  //   CREATE: `${process.env.REACT_APP_CLIENT_SERVICE}/client/client`,
  // },
  TASK: {
    FETCH: `${process.env.REACT_APP_QA}/project/task/task`,
    FETCH_TASK_BY_SPRINT: `${process.env.REACT_APP_QA}/project/task/`,
    START_TIMER: `${process.env.REACT_APP_QA}/project/task/task/start`,
    STOP_TIMER: `${process.env.REACT_APP_QA}/project/task/task/stop`,
    KANBAN: `${process.env.REACT_APP_QA}/project/task/kanban`,
    IMPORT: `${process.env.REACT_APP_QA}/project/task/import`,
    UPDATE_MULTIPLE: `${process.env.REACT_APP_QA}/project/task/update/multiple`,
    STATUS_COUNT: `${process.env.REACT_APP_QA}/project/task/status/count`,
  },
  MEMBER: {
    // FETCH: '/member/members',
    FETCH: `${process.env.REACT_APP_QA}/member/members/active`,
    LIST: `${process.env.REACT_APP_QA}/member/members-list`,
    INVITE: `${process.env.REACT_APP_QA}/member/invite`,
    EDIT: `${process.env.REACT_APP_QA}/member/member`,
    DELETE: `${process.env.REACT_APP_QA}/member/deactivate`,
    ACTIVATE: `${process.env.REACT_APP_QA}/member/activate`,
    ROLES: `${process.env.REACT_APP_QA}/member/role`,
    MEMBER_LIST: `${process.env.REACT_APP_QA}/member/org/members/`,
    GETALLROLES: `${process.env.REACT_APP_QA}/member/role/getOrganizationRoles`,
    GET_ORGANIZATIONS: `${process.env.REACT_APP_QA}/member/?/organization`,
    USER: `${process.env.REACT_APP_QA}/member/user/`,
  },
  DEPARTMENT: {
    FETCH: `${process.env.REACT_APP_QA}/member/department/`,
  },
  HR: {
    SOCKET: `${process.env.REACT_APP_WEB_SOCKET}`,
    SERIES: `${process.env.REACT_APP_QA}/humanResource/attendence/count`,
  },
  DESIGNATION: {
    FETCH: `${process.env.REACT_APP_QA}/member/designation/`,
  },
  SUB_TASK: {
    CREATE: `${process.env.REACT_APP_QA}/project/task/subTask`,
  },
  BUG_TASK: {
    CREATE: `${process.env.REACT_APP_QA}/project/task/bugTask`,
  },
  SPRINT: {
    FETCH: `${process.env.REACT_APP_QA}/project/task/sprint`,
    FETCH_ACTIVE: `${process.env.REACT_APP_QA}/project/task/sprint/active`,
    START: `${process.env.REACT_APP_QA}/project/task/sprint/start`,
    COMPLETE: `${process.env.REACT_APP_QA}/project/task/sprint/complete`,
  },
  CLOUD: {
    UPLOAD: `${process.env.REACT_APP_QA}/image/profilepic`,
  },
  TIMESHEET: {
    CREATE: `${process.env.REACT_APP_QA}/dailyActivity/activity`,
    DELETE: `${process.env.REACT_APP_QA}/dailyActivity/activity/delete`,
    WORKING_HOURS: `${process.env.REACT_APP_QA}/dailyActivity/workinghrs`,
    DELETE_BY_TASK: `${process.env.REACT_APP_QA}/dailyActivity/activity/timelogs`,
    EXPORT: `${process.env.REACT_APP_QA}/dailyActivity/export`,
    REJECT: `${process.env.REACT_APP_QA}/dailyActivity/activity/status`,
    SERIES: `${process.env.REACT_APP_QA}/dailyActivity/activity/countlist`,
  },
  WIKI: {
    WORKSPACE: `${process.env.REACT_APP_QA}/wiki/workspace`,
    WORKSPACE_SHARE: `${process.env.REACT_APP_QA}/wiki/workspace-share`,
    PAGE: `${process.env.REACT_APP_QA}/wiki/page/content`,
    PAGE_SHARE: `${process.env.REACT_APP_QA}/wiki/page-share`,
    CREATE_PAGE: `${process.env.REACT_APP_QA}/wiki/page`,
    PARENT_PAGE: `${process.env.REACT_APP_QA}/wiki/page/parent`,
    SUB: `${process.env.REACT_APP_QA}/wiki/page/sub`,
    EXPORT: `${process.env.REACT_APP_QA}/wiki/page/export`,
  },
  HOLIDAY: {
    CREATE: `${process.env.REACT_APP_QA}/humanResource/holiday`,
    DELETE: `${process.env.REACT_APP_QA}/humanResource/holiday/delete`,
    PUBLISH: `${process.env.REACT_APP_QA}/humanResource/holiday/publish`,
  },
  ASSET: {
    CREATE_ASSET: `${process.env.REACT_APP_QA}/humanResource/asset`,
    DELETE_ASSET: `${process.env.REACT_APP_QA}/humanResource/asset`,
    REQUEST_ASSET: `${process.env.REACT_APP_QA}/humanResource/asset/request`,
    APPROVE_ASSET: `${process.env.REACT_APP_QA}/humanResource/asset/approve`,
    COUNT: `${process.env.REACT_APP_QA}/humanResource/asset/listcount`,
    STATUS_COUNT: `${process.env.REACT_APP_QA}/humanResource/asset/statuscount`,
    ASSET_CATEGORY: `${process.env.REACT_APP_QA}/humanResource/asset/category`,
    ASSET_CATEGORY_DEPARTMENT: `${process.env.REACT_APP_QA}/humanResource/asset/department`,
  },
  LEAVE_REQUEST: {
    CREATE: `${process.env.REACT_APP_QA}/humanResource/`,
    CREATE_LEAVE_POLICY: `${process.env.REACT_APP_QA}/humanResource/leave_policy`,
    DELETE_LEAVE: `${process.env.REACT_APP_QA}/humanResource/delete`,
    STATUS_UPDATE: `${process.env.REACT_APP_QA}/humanResource/status`,
    GET_LEAVE_COUNT: `${process.env.REACT_APP_QA}/humanResource/stats`,
    GET_WHO_ON_LEAVE: `${process.env.REACT_APP_QA}/humanResource/leaveStat`,
    CHANGE_STATUS: `${process.env.REACT_APP_QA}/humanResource/status`,
    LEAVE_HISTORY: `${process.env.REACT_APP_QA}/humanResource/history`,
    EXPORT: `${process.env.REACT_APP_QA}/humanResource/export`,
    GETLEAVEPOLICIESFORMEMBER: `${process.env.REACT_APP_QA}/humanResource/leave_policy/member`,
  },
  // PERMISSION_REQUEST: {
  //   CREATE: `${process.env.REACT_APP_LEAVE_REQUEST_SERVICE}/permission_request`,
  //   CREATE_PERMISSION_POLICY: `${process.env.REACT_APP_LEAVE_REQUEST_SERVICE}/permission_policy`,
  //   DELETE_PERMISSION: `${process.env.REACT_APP_LEAVE_REQUEST_SERVICE}/permission_request/delete`,
  //   STATUS_UPDATE: `${process.env.REACT_APP_LEAVE_REQUEST_SERVICE}/permission_request/status`,
  //   GET_TAKEN_REMAINING_STATUS: `${process.env.REACT_APP_LEAVE_REQUEST_SERVICE}/permission_request/timeStats`,
  // },
  // WFH_REQUEST: {
  //   CREATE: `${process.env.REACT_APP_HR_SERVICE}/wfh_request`,
  //   CREATE_PERMISSION_POLICY: `${process.env.REACT_APP_HR_SERVICE}/wfh_policy`,
  //   DELETE_PERMISSION: `${process.env.REACT_APP_HR_SERVICE}/wfh_request/delete`,
  //   STATUS_UPDATE: `${process.env.REACT_APP_HR_SERVICE}/wfh_request/status`,
  //   GET_TAKEN_REMAINING_STATUS: `${process.env.REACT_APP_HR_SERVICE}/wfh_request/timeStats`,
  // },
  RECOGNITION: {
    CREATE: `${process.env.REACT_APP_QA}/humanResource/recognition/`,
  },
  PAYSLIP: {
    GET: `${process.env.REACT_APP_QA}/humanResource/payslip/bindData`,
    GET_DASHBOARD: `${process.env.REACT_APP_QA}/humanResource/payslip/dashboard`,
    EXPORT: `${process.env.REACT_APP_QA}/humanResource/payslip/export`,
    GENERATE_PAYSLIP: `${process.env.REACT_APP_QA}/humanResource/payslip/`,
  },
  PAYROLL: {
    GET: `${process.env.REACT_APP_QA}/humanResource/salary/employee/list`,
    UPDATE: `${process.env.REACT_APP_QA}/humanResource/salary/`,
  },
  ATTENDANCE:{
    GET: `${process.env.REACT_APP_QA}/humanResource/attendance`,
  },
  ANNOUNCEMENT: {
    CREATE: `${process.env.REACT_APP_QA}/humanResource/announcement`,
    COMMENT: `${process.env.REACT_APP_QA}/humanResource/announcement_comment`,
  },
  BIRTHDAY: {
    GET: `${process.env.REACT_APP_QA}/member/birthday`,
    STATUS: `${process.env.REACT_APP_QA}/member/birthday/upcoming`,
    TODAY: `${process.env.REACT_APP_QA}/member/birthday/today`,
  },
  AUTHENTICATION: {
    SIGNIN: `${process.env.REACT_APP_QA}/member/login`,
    SIGNUP: `${process.env.REACT_APP_QA}/member/admin/signup`,
    PLAN_TYPE: `${process.env.REACT_APP_QA}/member/admin/payment`,
    SIGNUPWITHGOOGLE: `${process.env.REACT_APP_QA}/member/admin/signup/google`,
    ORG_SIGNUP: `${process.env.REACT_APP_QA}/member/organization/signup`,
    SIGNUP_VERIFY: `${process.env.REACT_APP_QA}/member/otp/verify`,
    SIGNUP_RESEND: `${process.env.REACT_APP_QA}/member/otp/resend`,
    FORGOT_PASSWORD: `${process.env.REACT_APP_QA}/member/password/forgot`,
    CHANGE_PASSWORD: `${process.env.REACT_APP_QA}/member/password/change`,
    USER_VERIFY: `${process.env.REACT_APP_QA}/member/user/verify`,
    OTP_VERIFY: `${process.env.REACT_APP_QA}/member/member/otp/verify`,
    MEMBER_RESEND: `${process.env.REACT_APP_QA}/member/member/otp/resend`,
  },
  SCRATCHPAD: {
    CREATE: `${process.env.REACT_APP_QA}/scratchpad/scratchpad/`,
    COLOR: `${process.env.REACT_APP_QA}/scratchpad/colors/`,
    SETBACKGROUND_COLOR: `${process.env.REACT_APP_QA}/scratchpad/scratchpad/background/color`,
    SETBACKGROUND_IMAGE: `${process.env.REACT_APP_QA}/scratchpad/scratchpad/:scratchpadid/background/image`,
    PIN_SCRATCHPAD: `${process.env.REACT_APP_QA}/scratchpad/scratchpad/pin`,
  },
  RELEASE: {
    CREATE: `${process.env.REACT_APP_QA}/project/release`,
    FETCH: `${process.env.REACT_APP_QA}/project/release`,
    FETCH_TASK: `${process.env.REACT_APP_QA}/project/release/task`,
    TASK_ADD: `${process.env.REACT_APP_QA}/project/release/taskAdd`,
    DELETE: `${process.env.REACT_APP_QA}/project/release/delete`,
    TASK_DELETE: `${process.env.REACT_APP_QA}/project/release/taskDelete`,
    EXPORT: `${process.env.REACT_APP_QA}/project/release/export`,
  },
  DASHBOARD: {
    ONGOING_TASK: `${process.env.REACT_APP_QA}/project/dashboard`,
    PROJECT_LIST: `${process.env.REACT_APP_QA}/project/dashboard`,
    FETCH_LEAVE: `${process.env.REACT_APP_QA}/humanResource/dashboard`,
    FETCH_HOLIDAY: `${process.env.REACT_APP_QA}/humanResource/dashboard`,
    LEAVE_POLICY: `${process.env.REACT_APP_QA}/humanResource/history`,
    TIMESHEET: `${process.env.REACT_APP_QA}/dailyActivity/totalhrs`,
    FETCH_DESIGNATION: `${process.env.REACT_APP_QA}/member/members-list`,
    GET_ASSIGNED_PROJECT: `${process.env.REACT_APP_QA}/project/dashboard/assignedProjects`,
    GET_UPCOMING_SPRINT: `${process.env.REACT_APP_QA}/project/dashboard/upcomingSprints`,
    GET_PROJECT_RELEASE: `${process.env.REACT_APP_QA}/project/dashboard/release`,
    GET_EMPLOYEE_LEAVE: `${process.env.REACT_APP_QA}/humanresource/dashboard/leaveStatus`,
    GET_ACTIVE_TASKS: `${process.env.REACT_APP_QA}/project/task/current/tasks`,
  },
  NOTIFICATION: {
    TODAY: `${process.env.REACT_APP_QA}/member/notifications/today`,
    TODAY_ANNOUNCEMENT: `${process.env.REACT_APP_QA}/member/notifications/today-announcement`,
  },
  PROJECT_SETTINGS: {
    FETCH_TASK: `${process.env.REACT_APP_QA}/project/task/allTasks`,
    UPDATE_TASK: `${process.env.REACT_APP_QA}/project/task/status/update`,
    UPDATE_BOARD_SETTING: `${process.env.REACT_APP_QA}/project/board/settings`,
    UPDATE_BACKLOG_TYPE: `${process.env.REACT_APP_QA}/project/backlog/type`,
    UPDATE_BOARD_TYPE: `${process.env.REACT_APP_QA}/project/board/type`,
    UPDATE_CAP_COUNT: `${process.env.REACT_APP_QA}/project/capCount`,
    TIMESHEETUPDATE: `${process.env.REACT_APP_QA}/project/timesheetSettings`,
  },
};

let PROD = {
  PROJECT: {
    FETCH: `${process.env.REACT_APP_PROD}/project/projects`,
    CREATE: `${process.env.REACT_APP_PROD}/project/create`,
    DELETE: `${process.env.REACT_APP_PROD}/project/deactivate`,
    ACTIVATE: `${process.env.REACT_APP_PROD}/project/activate`,
    UPDATE: `${process.env.REACT_APP_PROD}/project/project`,
    FETCH_BY_ID: `${process.env.REACT_APP_PROD}/project/project`,
    RECENTS: `${process.env.REACT_APP_PROD}/project/recents`,
    SPRINT_LIST: `${process.env.REACT_APP_PROD}/project/report/sprint/list`,
    SPRINT_TASK_COUNT: `${process.env.REACT_APP_PROD}/project/task/task/sprit/list`,
    SPRINT_REPORT: `${process.env.REACT_APP_PROD}/project/task/sprint/task`,
    PROJECT_ROADMAP: `${process.env.REACT_APP_PROD}/project/project/roadmap`,
    PROJECT_ASSIGNED_LIST: `${process.env.REACT_APP_PROD}/project/projects/list`,
    // Project Assignments
    ASSIGNMENT: `${process.env.REACT_APP_PROD}/project/assignment`,
    ASSIGN_REASSIGN: `${process.env.REACT_APP_PROD}/project/assignment?member=`,
    ASSIGN: `${process.env.REACT_APP_PROD}/project/assignment/assigned?member=`,
    PROJECTASSIGNEDMEMBERS: `${process.env.REACT_APP_PROD}/project/assignment/assigned/`,

    //files
    FOLDER_CREATE: `${process.env.REACT_APP_PROD}/project/add-file`,
    FOLDER_LIST: `${process.env.REACT_APP_PROD}/project/get-files`,
    FILE_UPLOAD: `${process.env.REACT_APP_PROD}/project/upload-files`,
    FOLDER_DELETE: `${process.env.REACT_APP_PROD}/project/delete-file`,
    FOLDER_LIST_BY_ID: `${process.env.REACT_APP_PROD}/project/get-file`,
    FOLDER_FILE_DOCSURL_DELETE: `${process.env.REACT_APP_PROD}/project/delete-docsfile`,
  },
  TASK: {
    FETCH: `${process.env.REACT_APP_PROD}/project/task/task`,
    FETCH_TASK_BY_SPRINT: `${process.env.REACT_APP_PROD}/project/task/`,
    START_TIMER: `${process.env.REACT_APP_PROD}/project/task/task/start`,
    STOP_TIMER: `${process.env.REACT_APP_PROD}/project/task/task/stop`,
    DELETE_MULTIPLE: `${process.env.REACT_APP_PROD}/project/task/multiple`,
    KANBAN: `${process.env.REACT_APP_PROD}/project/task/kanban`,
    IMPORT: `${process.env.REACT_APP_PROD}/project/task/import`,
    UPDATE_MULTIPLE: `${process.env.REACT_APP_PROD}/project/task/update/multiple`,
    STATUS_COUNT: `${process.env.REACT_APP_PROD}/project/task/status/count`,
  },
  MEMBER: {
    FETCH_DETAIL: `${process.env.REACT_APP_PROD}/member/member`,
    FETCH: `${process.env.REACT_APP_PROD}/member/members/active`,
    LIST: `${process.env.REACT_APP_PROD}/member/members-list`,
    INVITE: `${process.env.REACT_APP_PROD}/member/invite`,
    EDIT: `${process.env.REACT_APP_PROD}/member/member`,
    DELETE: `${process.env.REACT_APP_PROD}/member/deactivate`,
    ACTIVATE: `${process.env.REACT_APP_PROD}/member/activate`,
    ROLES: `${process.env.REACT_APP_PROD}/member/role`,
    MEMBER_LIST: `${process.env.REACT_APP_PROD}/member/org/members/`,
    GETALLROLES: `${process.env.REACT_APP_PROD}/member/role/getOrganizationRoles`,
    GET_ORGANIZATIONS: `${process.env.REACT_APP_PROD}/member/?/organization`,
    USER: `${process.env.REACT_APP_PROD}/member/user`,
    CANCEL_SUBCRIPTION: `${process.env.REACT_APP_PROD}/member/cancelsubscription`,
    BILLING_DETAILS: `${process.env.REACT_APP_PROD}/member/getsubscription`,
    UPGRADE_SUBCRIPTION: `${process.env.REACT_APP_PROD}/member/upgradesubscription`,
    TRANSACTION_HISTORY: `${process.env.REACT_APP_PROD}/member/subscriptionhistory`,
  },
  DEPARTMENT: {
    FETCH: `${process.env.REACT_APP_PROD}/member/department/`,
  },
  HR: {
    SOCKET: `${process.env.REACT_APP_WEB_SOCKET_PROD}`,
    SERIES: `${process.env.REACT_APP_PROD}/humanResource/attendence/count`,
  },
  DESIGNATION: {
    FETCH: `${process.env.REACT_APP_PROD}/member/designation/`,
  },
  SUB_TASK: {
    CREATE: `${process.env.REACT_APP_PROD}/project/task/subTask`,
  },
  BUG_TASK: {
    CREATE: `${process.env.REACT_APP_PROD}/project/task/bugTask`,
  },
  SPRINT: {
    FETCH: `${process.env.REACT_APP_PROD}/project/task/sprint`,
    FETCH_ACTIVE: `${process.env.REACT_APP_PROD}/project/task/sprint/active`,
    START: `${process.env.REACT_APP_PROD}/project/task/sprint/start`,
    COMPLETE: `${process.env.REACT_APP_PROD}/project/task/sprint/complete`,
  },
  CLOUD: {
    UPLOAD: `${process.env.REACT_APP_PROD}/image/profilepic`,
  },
  TIMESHEET: {
    CREATE: `${process.env.REACT_APP_PROD}/dailyActivity/activity`,
    DELETE: `${process.env.REACT_APP_PROD}/dailyActivity/activity/delete`,
    WORKING_HOURS: `${process.env.REACT_APP_PROD}/dailyActivity/workinghrs`,
    DELETE_BY_TASK: `${process.env.REACT_APP_PROD}/dailyActivity/activity/timelogs`,
    EXPORT: `${process.env.REACT_APP_PROD}/dailyActivity/export`,
    REJECT: `${process.env.REACT_APP_PROD}/dailyActivity/activity/status`,
    SERIES: `${process.env.REACT_APP_PROD}/dailyActivity/activity/countlist`,
    DELETE_MULTIPLE: `${process.env.REACT_APP_PROD}/dailyActivity/activity/multiple`,
  },
  SLACK: {
    CHANNEL_LIST: `${process.env.REACT_APP_PROD}/dailyActivity/activity/listchannel`,
    REMOVE_SLACK: `${process.env.REACT_APP_PROD}/project/projects/channel`,
  },
  WIKI: {
    WORKSPACE: `${process.env.REACT_APP_PROD}/wiki/workspace`,
    WORKSPACE_SHARE: `${process.env.REACT_APP_PROD}/wiki/workspace-share`,
    PAGE: `${process.env.REACT_APP_PROD}/wiki/page/content`,
    PAGE_SHARE: `${process.env.REACT_APP_PROD}/wiki/page-share`,
    CREATE_PAGE: `${process.env.REACT_APP_PROD}/wiki/page`,
    PARENT_PAGE: `${process.env.REACT_APP_PROD}/wiki/page/parent`,
    SUB: `${process.env.REACT_APP_PROD}/wiki/page/sub`,
    EXPORT: `${process.env.REACT_APP_PROD}/wiki/page/export`,
  },
  HOLIDAY: {
    CREATE: `${process.env.REACT_APP_PROD}/humanResource/holiday`,
    DELETE: `${process.env.REACT_APP_PROD}/humanResource/holiday/delete`,
    PUBLISH: `${process.env.REACT_APP_PROD}/humanResource/holiday/publish`,
  },
  ASSET: {
    CREATE_ASSET: `${process.env.REACT_APP_PROD}/humanResource/asset`,
    DELETE_ASSET: `${process.env.REACT_APP_PROD}/humanResource/asset`,
    REQUEST_ASSET: `${process.env.REACT_APP_PROD}/humanResource/asset/request`,
    APPROVE_ASSET: `${process.env.REACT_APP_PROD}/humanResource/asset/approve`,
    COUNT: `${process.env.REACT_APP_PROD}/humanResource/asset/listcount`,
    STATUS_COUNT: `${process.env.REACT_APP_PROD}/humanResource/asset/statuscount`,
    ASSET_CATEGORY: `${process.env.REACT_APP_PROD}/humanResource/asset/category`,
    DELETE_ASSET_CATEGORY: `${process.env.REACT_APP_PROD}/humanResource/asset/delete-category`,
    ASSET_CATEGORY_DEPARTMENT: `${process.env.REACT_APP_PROD}/humanResource/asset/department`,
    MAINTENANCE_HISTORY: `${process.env.REACT_APP_PROD}/humanResource/asset/history`,
    MOVE_TO_MAINTENANCE: `${process.env.REACT_APP_PROD}/humanResource/asset/maintenance`,
  },
  LEAVE_REQUEST: {
    CREATE: `${process.env.REACT_APP_PROD}/humanResource/`,
    CREATE_LEAVE_POLICY: `${process.env.REACT_APP_PROD}/humanResource/leave_policy`,
    DELETE_LEAVE: `${process.env.REACT_APP_PROD}/humanResource/delete`,
    STATUS_UPDATE: `${process.env.REACT_APP_PROD}/humanResource/status`,
    GET_LEAVE_COUNT: `${process.env.REACT_APP_PROD}/humanResource/stats`,
    GET_WHO_ON_LEAVE: `${process.env.REACT_APP_PROD}/humanResource/leaveStat`,
    CHANGE_STATUS: `${process.env.REACT_APP_PROD}/humanResource/status`,
    LEAVE_HISTORY: `${process.env.REACT_APP_PROD}/humanResource/history`,
    EXPORT: `${process.env.REACT_APP_PROD}/humanResource/export`,
    GETLEAVEPOLICIESFORMEMBER: `${process.env.REACT_APP_PROD}/humanResource/leave_policy/member`,
  },
  RECOGNITION: {
    CREATE: `${process.env.REACT_APP_PROD}/humanResource/recognition/`,
  },
  PAYSLIP: {
    GET: `${process.env.REACT_APP_PROD}/humanResource/payslip/bindData`,
    GET_DASHBOARD: `${process.env.REACT_APP_PROD}/humanResource/payslip/dashboard`,
    EXPORT: `${process.env.REACT_APP_PROD}/humanResource/payslip/export`,
    GENERATE_PAYSLIP: `${process.env.REACT_APP_PROD}/humanResource/payslip/`,
  },
  PAYROLL: {
    GET: `${process.env.REACT_APP_PROD}/humanResource/salary/employee/list`,
    UPDATE: `${process.env.REACT_APP_PROD}/humanResource/salary/`,
  },
  ATTENDANCE: {
    GET: `${process.env.REACT_APP_PROD}/humanResource/attendance`,
  },
  ANNOUNCEMENT: {
    CREATE: `${process.env.REACT_APP_PROD}/humanResource/announcement`,
    COMMENT: `${process.env.REACT_APP_PROD}/humanResource/announcement_comment`,
  },
  BIRTHDAY: {
    GET: `${process.env.REACT_APP_PROD}/member/birthday`,
    STATUS: `${process.env.REACT_APP_PROD}/member/birthday/upcoming`,
    TODAY: `${process.env.REACT_APP_PROD}/member/birthday/today`,
  },
  AUTHENTICATION: {
    SIGNIN: `${process.env.REACT_APP_PROD}/member/login`,
    SIGNUP: `${process.env.REACT_APP_PROD}/member/admin/signup`,
    PLAN_TYPE: `${process.env.REACT_APP_PROD}/member/admin/payment`,
    SIGNUPWITHGOOGLE: `${process.env.REACT_APP_PROD}/member/admin/signup/google`,
    ORG_SIGNUP: `${process.env.REACT_APP_PROD}/member/organization/signup`,
    SIGNUP_VERIFY: `${process.env.REACT_APP_PROD}/member/otp/verify`,
    SIGNUP_RESEND: `${process.env.REACT_APP_PROD}/member/otp/resend`,
    FORGOT_PASSWORD: `${process.env.REACT_APP_PROD}/member/password/forgot`,
    CHANGE_PASSWORD: `${process.env.REACT_APP_PROD}/member/password/change`,
    USER_VERIFY: `${process.env.REACT_APP_PROD}/member/user/verify`,
    OTP_VERIFY: `${process.env.REACT_APP_PROD}/member/member/otp/verify`,
    MEMBER_RESEND: `${process.env.REACT_APP_PROD}/member/member/otp/resend`,
  },
  SCRATCHPAD: {
    CREATE: `${process.env.REACT_APP_PROD}/scratchpad/scratchpad/`,
    COLOR: `${process.env.REACT_APP_PROD}/scratchpad/colors/`,
    SETBACKGROUND_COLOR: `${process.env.REACT_APP_PROD}/scratchpad/scratchpad/background/color`,
    SETBACKGROUND_IMAGE: `${process.env.REACT_APP_PROD}/scratchpad/scratchpad/:scratchpadid/background/image`,
    PIN_SCRATCHPAD: `${process.env.REACT_APP_PROD}/scratchpad/scratchpad/pin`,
  },
  RELEASE: {
    CREATE: `${process.env.REACT_APP_PROD}/project/release`,
    FETCH: `${process.env.REACT_APP_PROD}/project/release`,
    FETCH_TASK: `${process.env.REACT_APP_PROD}/project/release/task`,
    TASK_ADD: `${process.env.REACT_APP_PROD}/project/release/taskAdd`,
    DELETE: `${process.env.REACT_APP_PROD}/project/release/delete`,
    TASK_DELETE: `${process.env.REACT_APP_PROD}/project/release/taskDelete`,
    EXPORT: `${process.env.REACT_APP_PROD}/project/release/export`,
  },
  DASHBOARD: {
    ONGOING_TASK: `${process.env.REACT_APP_PROD}/project/dashboard`,
    PROJECT_LIST: `${process.env.REACT_APP_PROD}/project/dashboard`,
    FETCH_LEAVE: `${process.env.REACT_APP_PROD}/humanResource/dashboard`,
    FETCH_HOLIDAY: `${process.env.REACT_APP_PROD}/humanResource/dashboard`,
    LEAVE_POLICY: `${process.env.REACT_APP_PROD}/humanResource/history`,
    TIMESHEET: `${process.env.REACT_APP_PROD}/dailyActivity/totalhrs`,
    FETCH_DESIGNATION: `${process.env.REACT_APP_PROD}/member/members-list`,
    GET_ASSIGNED_PROJECT: `${process.env.REACT_APP_PROD}/project/dashboard/assignedProjects`,
    GET_UPCOMING_SPRINT: `${process.env.REACT_APP_PROD}/project/dashboard/upcomingSprints`,
    GET_PROJECT_RELEASE: `${process.env.REACT_APP_PROD}/project/dashboard/release`,
    GET_EMPLOYEE_LEAVE: `${process.env.REACT_APP_PROD}/humanresource/dashboard/leaveStatus`,
    GET_ACTIVE_TASKS: `${process.env.REACT_APP_PROD}/project/task/current/tasks`,
  },
  NOTIFICATION: {
    TODAY: `${process.env.REACT_APP_PROD}/member/notifications/today`,
    TODAY_ANNOUNCEMENT: `${process.env.REACT_APP_PROD}/member/notifications/today-announcement`,
  },
  PROJECT_SETTINGS: {
    FETCH_TASK: `${process.env.REACT_APP_PROD}/project/task/allTasks`,
    UPDATE_TASK: `${process.env.REACT_APP_PROD}/project/task/status/update`,
    UPDATE_BOARD_SETTING: `${process.env.REACT_APP_PROD}/project/board/settings`,
    UPDATE_BACKLOG_TYPE: `${process.env.REACT_APP_PROD}/project/backlog/type`,
    UPDATE_BOARD_TYPE: `${process.env.REACT_APP_PROD}/project/board/type`,
    UPDATE_CAP_COUNT: `${process.env.REACT_APP_PROD}/project/capCount`,
  },
  SETTINGS: {
    CREATE_ROLE: `${process.env.REACT_APP_PROD}/member/role/createRole`,
    GET_ROLE: `${process.env.REACT_APP_PROD}/member/role/getOrganizationRoles`,
    UPDATE_ROLE: `${process.env.REACT_APP_PROD}/member/role/updateRole`,
    DELETE_ROLE: `${process.env.REACT_APP_PROD}/member/role/deleteRole`,
    BIRTHDAY_CCEMAIL: `${process.env.REACT_APP_PROD}/member/birthdaymail`,
  },
};

let STAGING = {
  PROJECT: {
    FETCH: `${process.env.REACT_APP_STAGE}/project/projects`,
    CREATE: `${process.env.REACT_APP_STAGE}/project/create`,
    DELETE: `${process.env.REACT_APP_STAGE}/project/deactivate`,
    DELETE: `${process.env.REACT_APP_STAGE}/project/activate`,
    UPDATE: `${process.env.REACT_APP_STAGE}/project/project`,
    FETCH_BY_ID: `${process.env.REACT_APP_STAGE}/project/project`,
    RECENTS: `${process.env.REACT_APP_STAGE}/project/recents`,
    SPRINT_LIST: `${process.env.REACT_APP_STAGE}/project/report/sprint/list`,
    SPRINT_TASK_COUNT: `${process.env.REACT_APP_STAGE}/project/task/task/sprit/list`,
    SPRINT_REPORT: `${process.env.REACT_APP_STAGE}/project/task/sprint/task`,
    PROJECT_ROADMAP: `${process.env.REACT_APP_STAGE}/project/project/roadmap`,
    PROJECT_ASSIGNED_LIST: `${process.env.REACT_APP_STAGE}/project/projects/list`,
    // Project Assignments
    ASSIGNMENT: `${process.env.REACT_APP_STAGE}/project/assignment`,
    ASSIGN_REASSIGN: `${process.env.REACT_APP_STAGE}/project/assignment?member=`,
    ASSIGN: `${process.env.REACT_APP_STAGE}/project/assignment/assigned?member=`,
    PROJECTASSIGNEDMEMBERS: `${process.env.REACT_APP_STAGE}/project/assignment/assigned/`,
  },
  TASK: {
    FETCH: `${process.env.REACT_APP_STAGE}/project/task/task`,
    FETCH_TASK_BY_SPRINT: `${process.env.REACT_APP_STAGE}/project/task/`,
    START_TIMER: `${process.env.REACT_APP_STAGE}/project/task/task/start`,
    STOP_TIMER: `${process.env.REACT_APP_STAGE}/project/task/task/stop`,
    DELETE_MULTIPLE: `${process.env.REACT_APP_STAGE}/project/task/multiple`,
    KANBAN: `${process.env.REACT_APP_STAGE}/project/task/kanban`,
    IMPORT: `${process.env.REACT_APP_STAGE}/project/task/import`,
    UPDATE_MULTIPLE: `${process.env.REACT_APP_STAGE}/project/task/update/multiple`,
    STATUS_COUNT: `${process.env.REACT_APP_STAGE}/project/task/status/count`,
  },
  MEMBER: {
    // FETCH: '/member/members',
    FETCH: `${process.env.REACT_APP_STAGE}/member/members/active`,
    LIST: `${process.env.REACT_APP_STAGE}/member/members-list`,
    INVITE: `${process.env.REACT_APP_STAGE}/member/invite`,
    EDIT: `${process.env.REACT_APP_STAGE}/member/member`,
    DELETE: `${process.env.REACT_APP_STAGE}/member/deactivate`,
    ACTIVATE: `${process.env.REACT_APP_STAGE}/member/activate`,
    ROLES: `${process.env.REACT_APP_STAGE}/member/role`,
    MEMBER_LIST: `${process.env.REACT_APP_STAGE}/member/org/members/`,
    GETALLROLES: `${process.env.REACT_APP_STAGE}/member/role/getOrganizationRoles`,
    GET_ORGANIZATIONS: `${process.env.REACT_APP_STAGE}/member/?/organization`,
    USER: `${process.env.REACT_APP_STAGE}/member/user`,
  },
  DEPARTMENT: {
    FETCH: `${process.env.REACT_APP_STAGE}/member/department/`,
  },
  HR: {
    SOCKET: `${process.env.REACT_APP_WEB_SOCKET_STAGE}`,
    SERIES: `${process.env.REACT_APP_STAGE}/humanResource/attendence/count`,
  },
  DESIGNATION: {
    FETCH: `${process.env.REACT_APP_STAGE}/member/designation/`,
  },
  SUB_TASK: {
    CREATE: `${process.env.REACT_APP_STAGE}/project/task/subTask`,
  },
  BUG_TASK: {
    CREATE: `${process.env.REACT_APP_STAGE}/project/task/bugTask`,
  },
  SPRINT: {
    FETCH: `${process.env.REACT_APP_STAGE}/project/task/sprint`,
    FETCH_ACTIVE: `${process.env.REACT_APP_STAGE}/project/task/sprint/active`,
    START: `${process.env.REACT_APP_STAGE}/project/task/sprint/start`,
    COMPLETE: `${process.env.REACT_APP_STAGE}/project/task/sprint/complete`,
  },
  CLOUD: {
    UPLOAD: `${process.env.REACT_APP_STAGE}/image/profilepic`,
  },
  TIMESHEET: {
    CREATE: `${process.env.REACT_APP_STAGE}/dailyActivity/activity`,
    DELETE: `${process.env.REACT_APP_STAGE}/dailyActivity/activity/delete`,
    WORKING_HOURS: `${process.env.REACT_APP_STAGE}/dailyActivity/workinghrs`,
    DELETE_BY_TASK: `${process.env.REACT_APP_STAGE}/dailyActivity/activity/timelogs`,
    EXPORT: `${process.env.REACT_APP_STAGE}/dailyActivity/export`,
    REJECT: `${process.env.REACT_APP_STAGE}/dailyActivity/activity/status`,
    SERIES: `${process.env.REACT_APP_STAGE}/dailyActivity/activity/countlist`,
    DELETE_MULTIPLE: `${process.env.REACT_APP_STAGE}/dailyActivity/activity/multiple`,
  },
  WIKI: {
    WORKSPACE: `${process.env.REACT_APP_STAGE}/wiki/workspace`,
    WORKSPACE_SHARE: `${process.env.REACT_APP_STAGE}/wiki/workspace-share`,
    PAGE: `${process.env.REACT_APP_STAGE}/wiki/page/content`,
    PAGE_SHARE: `${process.env.REACT_APP_STAGE}/wiki/page-share`,
    CREATE_PAGE: `${process.env.REACT_APP_STAGE}/wiki/page`,
    PARENT_PAGE: `${process.env.REACT_APP_STAGE}/wiki/page/parent`,
    SUB: `${process.env.REACT_APP_STAGE}/wiki/page/sub`,
    EXPORT: `${process.env.REACT_APP_STAGE}/wiki/page/export`,
  },
  HOLIDAY: {
    CREATE: `${process.env.REACT_APP_STAGE}/humanResource/holiday`,
    DELETE: `${process.env.REACT_APP_STAGE}/humanResource/holiday/delete`,
    PUBLISH: `${process.env.REACT_APP_STAGE}/humanResource/holiday/publish`,
  },
  ASSET: {
    CREATE_ASSET: `${process.env.REACT_APP_STAGE}/humanResource/asset`,
    DELETE_ASSET: `${process.env.REACT_APP_STAGE}/humanResource/asset`,
    REQUEST_ASSET: `${process.env.REACT_APP_STAGE}/humanResource/asset/request`,
    APPROVE_ASSET: `${process.env.REACT_APP_STAGE}/humanResource/asset/approve`,
    COUNT: `${process.env.REACT_APP_STAGE}/humanResource/asset/listcount`,
    STATUS_COUNT: `${process.env.REACT_APP_STAGE}/humanResource/asset/statuscount`,
    ASSET_CATEGORY: `${process.env.REACT_APP_STAGE}/humanResource/asset/category`,
    ASSET_CATEGORY_DEPARTMENT: `${process.env.REACT_APP_STAGE}/humanResource/asset/department`,
  },
  LEAVE_REQUEST: {
    CREATE: `${process.env.REACT_APP_STAGE}/humanResource/`,
    CREATE_LEAVE_POLICY: `${process.env.REACT_APP_STAGE}/humanResource/leave_policy`,
    DELETE_LEAVE: `${process.env.REACT_APP_STAGE}/humanResource/delete`,
    STATUS_UPDATE: `${process.env.REACT_APP_STAGE}/humanResource/status`,
    GET_LEAVE_COUNT: `${process.env.REACT_APP_STAGE}/humanResource/stats`,
    GET_WHO_ON_LEAVE: `${process.env.REACT_APP_STAGE}/humanResource/leaveStat`,
    CHANGE_STATUS: `${process.env.REACT_APP_STAGE}/humanResource/status`,
    LEAVE_HISTORY: `${process.env.REACT_APP_STAGE}/humanResource/history`,
    EXPORT: `${process.env.REACT_APP_STAGE}/humanResource/export`,
    GETLEAVEPOLICIESFORMEMBER: `${process.env.REACT_APP_STAGE}/humanResource/leave_policy/member`,
  },
  RECOGNITION: {
    CREATE: `${process.env.REACT_APP_STAGE}/humanResource/recognition/`,
  },
  PAYSLIP: {
    GET: `${process.env.REACT_APP_STAGE}/humanResource/payslip/bindData`,
    GET_DASHBOARD: `${process.env.REACT_APP_STAGE}/humanResource/payslip/dashboard`,
    EXPORT: `${process.env.REACT_APP_STAGE}/humanResource/payslip/export`,
    GENERATE_PAYSLIP: `${process.env.REACT_APP_STAGE}/humanResource/payslip/`,
  },
  PAYROLL: {
    GET: `${process.env.REACT_APP_STAGE}/humanResource/salary/employee/list`,
    UPDATE: `${process.env.REACT_APP_STAGE}/humanResource/salary/`,
  },
  ATTENDANCE:{
    GET: `${process.env.REACT_APP_STAGE}/humanResource/attendance`,
  },
  ANNOUNCEMENT: {
    CREATE: `${process.env.REACT_APP_STAGE}/humanResource/announcement`,
    COMMENT: `${process.env.REACT_APP_STAGE}/humanResource/announcement_comment`,
  },
  BIRTHDAY: {
    GET: `${process.env.REACT_APP_STAGE}/member/birthday`,
    STATUS: `${process.env.REACT_APP_STAGE}/member/birthday/upcoming`,
    TODAY: `${process.env.REACT_APP_STAGE}/member/birthday/today`,
  },
  AUTHENTICATION: {
    SIGNIN: `${process.env.REACT_APP_STAGE}/member/login`,
    SIGNUP: `${process.env.REACT_APP_STAGE}/member/admin/signup`,
    PLAN_TYPE: `${process.env.REACT_APP_STAGE}/member/admin/payment`,
    SIGNUPWITHGOOGLE: `${process.env.REACT_APP_STAGE}/member/admin/signup/google`,
    ORG_SIGNUP: `${process.env.REACT_APP_STAGE}/member/organization/signup`,
    SIGNUP_VERIFY: `${process.env.REACT_APP_STAGE}/member/otp/verify`,
    SIGNUP_RESEND: `${process.env.REACT_APP_STAGE}/member/otp/resend`,
    FORGOT_PASSWORD: `${process.env.REACT_APP_STAGE}/member/password/forgot`,
    CHANGE_PASSWORD: `${process.env.REACT_APP_STAGE}/member/password/change`,
    USER_VERIFY: `${process.env.REACT_APP_STAGE}/member/user/verify`,
    OTP_VERIFY: `${process.env.REACT_APP_STAGE}/member/member/otp/verify`,
    MEMBER_RESEND: `${process.env.REACT_APP_STAGE}/member/member/otp/resend`,
  },
  SCRATCHPAD: {
    CREATE: `${process.env.REACT_APP_STAGE}/scratchpad/scratchpad/`,
    COLOR: `${process.env.REACT_APP_STAGE}/scratchpad/colors/`,
    SETBACKGROUND_COLOR: `${process.env.REACT_APP_STAGE}/scratchpad/scratchpad/background/color`,
    SETBACKGROUND_IMAGE: `${process.env.REACT_APP_STAGE}/scratchpad/scratchpad/:scratchpadid/background/image`,
    PIN_SCRATCHPAD: `${process.env.REACT_APP_STAGE}/scratchpad/scratchpad/pin`,
  },
  RELEASE: {
    CREATE: `${process.env.REACT_APP_STAGE}/project/release`,
    FETCH: `${process.env.REACT_APP_STAGE}/project/release`,
    FETCH_TASK: `${process.env.REACT_APP_STAGE}/project/release/task`,
    TASK_ADD: `${process.env.REACT_APP_STAGE}/project/release/taskAdd`,
    DELETE: `${process.env.REACT_APP_STAGE}/project/release/delete`,
    TASK_DELETE: `${process.env.REACT_APP_STAGE}/project/release/taskDelete`,
    EXPORT: `${process.env.REACT_APP_STAGE}/project/release/export`,
  },
  DASHBOARD: {
    ONGOING_TASK: `${process.env.REACT_APP_STAGE}/project/dashboard`,
    PROJECT_LIST: `${process.env.REACT_APP_STAGE}/project/dashboard`,
    FETCH_LEAVE: `${process.env.REACT_APP_STAGE}/humanResource/dashboard`,
    FETCH_HOLIDAY: `${process.env.REACT_APP_STAGE}/humanResource/dashboard`,
    LEAVE_POLICY: `${process.env.REACT_APP_STAGE}/humanResource/history`,
    TIMESHEET: `${process.env.REACT_APP_STAGE}/dailyActivity/totalhrs`,
    FETCH_DESIGNATION: `${process.env.REACT_APP_STAGE}/member/members-list`,
    GET_ASSIGNED_PROJECT: `${process.env.REACT_APP_STAGE}/project/dashboard/assignedProjects`,
    GET_UPCOMING_SPRINT: `${process.env.REACT_APP_STAGE}/project/dashboard/upcomingSprints`,
    GET_PROJECT_RELEASE: `${process.env.REACT_APP_STAGE}/project/dashboard/release`,
    GET_EMPLOYEE_LEAVE: `${process.env.REACT_APP_STAGE}/humanresource/dashboard/leaveStatus`,
    GET_ACTIVE_TASKS: `${process.env.REACT_APP_STAGE}/project/task/current/tasks`,
  },
  NOTIFICATION: {
    TODAY: `${process.env.REACT_APP_STAGE}/member/notifications/today`,
    TODAY_ANNOUNCEMENT: `${process.env.REACT_APP_STAGE}/member/notifications/today-announcement`,
  },
  PROJECT_SETTINGS: {
    FETCH_TASK: `${process.env.REACT_APP_STAGE}/project/task/allTasks`,
    UPDATE_TASK: `${process.env.REACT_APP_STAGE}/project/task/status/update`,
    UPDATE_BOARD_SETTING: `${process.env.REACT_APP_STAGE}/project/board/settings`,
    UPDATE_BACKLOG_TYPE: `${process.env.REACT_APP_STAGE}/project/backlog/type`,
    UPDATE_BOARD_TYPE: `${process.env.REACT_APP_STAGE}/project/board/type`,
    UPDATE_CAP_COUNT: `${process.env.REACT_APP_STAGE}/project/capCount`,
    TIMESHEETUPDATE: `${process.env.REACT_APP_STAGE}/project/timesheetSettings`,
  },
};
export { DEV, QA, PROD, STAGING };

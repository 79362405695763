import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import constants from "../../constants";
import { getWithAuth } from "../../services/authentication";
import EmptyScreen from "../common/empty/emptyScreen";
import EmptyLeaveImage from "./../../assets/leave-empty.svg";
import { DateFormat } from "../../utility/utility";

export default function LeaveInfoDetails({
  updateItem,
  leaveInfoDialog,
  setLeaveInfoDialog,
}) {
  const token = localStorage.getItem("token");
  const [currentTab, setCurrentTab] = useState({});
  const [allLeavePolicy, setAllLeavePolicy] = useState([]);
  const [leaveInfo, setLeaveInfo] = useState([]);
  const toast = useRef(null);

  //pagination leave request
  const [maxRecord, setMaxRecord] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);

  useEffect(() => {
    setInitialLeavePolicy();
    getLeaveInfo();
  }, []);

  useEffect(() => {
    getLeaveInfo();
  }, [currentTab]);

  useEffect(() => {
    getLeaveInfo();
  }, [limit, skip]);

  const handlePagination = (e) => {
    setLimit(e.value);
    setSkip(1);
    setFrom(1);
    setTo(e.value);
  };

  async function setInitialLeavePolicy() {
    try {
      const LEAVEPOLICYURL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      const leavePolicyResponse = await getWithAuth(LEAVEPOLICYURL, token);
      if (leavePolicyResponse.status === 200) {
        setAllLeavePolicy(leavePolicyResponse.data.leavePolicys);
        if (leavePolicyResponse.data.leavePolicys?.length) {
          const currentTab = {
            _id: leavePolicyResponse.data.leavePolicys[0]?._id,
            title: leavePolicyResponse.data.leavePolicys[0]?.title,
          };
          setCurrentTab(currentTab);
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  // function leaveDateFrom(emp) {
  //   return (
  //     <p>
  //       {new Date(emp.from).toLocaleDateString("en-uk", {
  //         month: "numeric",
  //         day: "numeric",
  //         year: "numeric",
  //       })}
  //     </p>
  //   );
  // }
  // function leaveDateTo(emp) {
  //   return (
  //     <>
  //       {new Date(emp.to).toLocaleDateString("en-uk", {
  //         month: "numeric",
  //         day: "numeric",
  //         year: "numeric",
  //       })}
  //     </>
  //   );
  // }

  const status = (data) => {
    switch (data.status) {
      case "Approved":
        return (
          <div className="approved-btn">
            <div className="avatar-container-size">
              <div className="occupied-font leave-status-align">Approved</div>
            </div>
          </div>
        );
      case "Declined":
        return (
          <div className="approved-btn declined-btn">
            <div className="avatar-container-size">
              <div className="declined-font leave-status-align">Declined</div>
            </div>
          </div>
        );
      case "Pending":
        return (
          <div className="approved-btn pending-btn">
            <div className="avatar-container-size">
              <div className="requested-font leave-status-align">Pending</div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  function employeeName(emp) {
    return (
      <div>
        <p>{emp.userDetails.fullName}</p>
      </div>
    );
  }

  async function getLeaveInfo() {
    if (currentTab?._id && updateItem?.userDetails?._id) {
      const response = await getWithAuth(
        constants.API.LEAVE_REQUEST.CREATE +
          `?leavePolicyId=${currentTab._id}&member=${updateItem.userDetails._id}&limit=${limit}&skip=${skip}`,
        token
      );
      setLeaveInfo(response?.data?.leaveRequests);
      setMaxRecord(response?.data.requestsCount);
    }
  }

  //pagination leave request
  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => handlePagination(e)}
          />
        </>
      );
    },

    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord > to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip - 1);
              setFrom(from - limit);
              setTo(to - limit);
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip + 1);
              setFrom(skip + limit);
              setTo(to + limit);
            }}
            disabled={to > maxRecord ? true : false}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Leave Details"
        visible={leaveInfoDialog}
        style={{ width: "70vw" }}
        onHide={() => {
          setLeaveInfoDialog(false);
        }}
      >
        {updateItem.length !== 0 ? (
          <div className="grid asset-table-font">
            <div className="col-3 dialoggrid"> Employee Name</div>
            <div className="col-3 dialoggrid">
              {updateItem?.userDetails?.fullName}
            </div>
            <div className="col-3 dialoggrid"> From Date</div>
            <div className="col-3 dialoggrid">
              {DateFormat(updateItem.from)}
            </div>
            <div className="col-3 dialoggrid"> Employee ID</div>
            <div className="col-3 dialoggrid">
              {updateItem?.userDetails?.employeeId}
            </div>
            <div className="col-3 dialoggrid"> To Date</div>
            <div className="col-3 dialoggrid">{DateFormat(updateItem.to)}</div>
            <div className="col-3 dialoggrid"> Role</div>
            <div className="col-3 dialoggrid">
              {updateItem?.userDetails?.work?.role}
            </div>
            <div className="col-3 dialoggrid"> Number of days</div>
            <div className="col-3 dialoggrid"> {updateItem?.days}</div>
            <div className="col-3 dialoggrid"> Leave Type</div>
            <div className="col-3 dialoggrid"> {updateItem?.title}</div>
            <div className="col-3 dialoggrid"> Reason for Leave</div>
            <div className="col-3 dialoggrid"> {updateItem?.reason}</div>
            <div className="col-3 dialoggrid"> Status</div>
            <div className="col-3 dialoggrid"> {updateItem?.status}</div>
            <div className="col-3 dialoggrid"> Applied Date</div>
            <div className="col-3 dialoggrid">
              {DateFormat(updateItem.requestedAt)}
            </div>
          </div>
        ) : (
          <></>
        )}
        <br />
        <h4 className="flex justify-content-center">
          Leave History of the Employee
        </h4>
        <div>
          <div className="grid mt-4 flex justify-content-around">
            {allLeavePolicy.map((leavepolicy, id) => {
              return (
                <div
                  key={id}
                  className={
                    currentTab._id === leavepolicy._id
                      ? "leave-history-btn-active"
                      : "leave-history-btn"
                  }
                  onClick={() => {
                    setCurrentTab({
                      _id: leavepolicy._id,
                      title: leavepolicy._id,
                    });
                  }}
                >
                  {leavepolicy.title}
                </div>
              );
            })}
          </div>
        </div>

        {/* Leave Table */}
        <br />
        {leaveInfo.length > 0 ? (
          <div className="table-box mt-5 ml-0">
            <div className="asset-table-size">
              <DataTable
                className="asset-table-font"
                sortMode="multiple"
                responsiveLayout="scroll"
                value={leaveInfo}
                paginatorClassName="justify-content-end custom-dropdown"
                paginator
                paginatorTemplate={paginate}
                rows={limit}
              >
                <Column field={employeeName} header="Employee"></Column>
                <Column
                  field={(e) => DateFormat(e.from)}
                  header="From"
                ></Column>
                <Column field={(e) => DateFormat(e.to)} header="To"></Column>
                <Column field="days" header="Days"></Column>
                <Column field="reason" header="Reason"></Column>
                <Column field={status} header="Status"></Column>
              </DataTable>
            </div>
          </div>
        ) : (
          <EmptyScreen
            img={EmptyLeaveImage}
            description="No Leave Request for this policy.."
          />
        )}
      </Dialog>
    </div>
  );
}

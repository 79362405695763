export const GetRoutesInOrder=(routes)=>{
    const humanResourceIndex=routes.findIndex(r => r === 'Human Resource');
    if(humanResourceIndex !==-1){
        routes[humanResourceIndex]="Human Resources"
    }
    routes.push("Timesheet","Scratchpad");
    let routesOrder=["Dashboard","Project","Wiki","Timesheet","Human Resources","Scratchpad","Users","Settings"];
    let finalRoute=[];
    routesOrder.forEach((route)=>{
     if(routes.includes(route)){
        finalRoute.push(route)
     }   
    });
    return finalRoute;
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  payslipData: [],
  dashboardData: [],
  userList: [],
  search:"",
  skip: 1,
  limit: 10,
  from: 1,
  to: 10,
  maxRecord: 0,
};

export const payslipDashboard = createAsyncThunk(
  "payslip/Dashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.PAYSLIP.GET_DASHBOARD;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data.message);
    }
  }
);


export const getPayslipData = createAsyncThunk(
  "get/payslipData",
  async (payload, { rejectWithValue}) => {
    try {
      const { skip, limit, search } = payload;
      const data = {month:payload.month, year:payload.year }
      const url = constants.API.PAYSLIP.GET +
      `?skip=${skip}&limit=${limit}&search=${search}`;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data.message);
    }
  }
);

export const exportPayslipData = createAsyncThunk(
  "export/payslip",
  async (payload, { rejectWithValue }) => {
    try {
      const API = constants.API.PAYSLIP.EXPORT;
      const response = await api.post(API, payload, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "PaySlip.pdf";
      a.click();
      window.URL.revokeObjectURL(url);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data.message);
    }
  }
);

export const getPayrollUserList = createAsyncThunk(
  "get/getPayrollUserList",
  async (data, { rejectWithValue }) => {
    try {
      const { skip, limit, search } = data;
      const url = constants.API.PAYROLL.GET + 
      `?skip=${skip}&limit=${limit}&search=${search}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createPayrollUserList = createAsyncThunk(
  "post/CreatePayrollUserList",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.PAYROLL.UPDATE;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePayrollUserList = createAsyncThunk(
  "patch/UpdatePayrollUserList",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.PAYROLL.UPDATE + `${payload._id}`;
      const response = await api.patch(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const generatePayslip = createAsyncThunk(
  "post/generatePayslip",
  async (payload, { rejectWithValue }) => {
    try {
      const url = constants.API.PAYSLIP.GENERATE_PAYSLIP;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const payslipSlice = createSlice({
  name: "payslip",
  initialState,
  reducers: {
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [getPayslipData.fulfilled]: (state, action) => {
      state.payslipData = action.payload.payslip;
      state.maxRecord = action.payload.requestsCount;
    },
    [payslipDashboard.fulfilled]: (state, action) => {
      state.dashboardData = action.payload;
    },
    [getPayrollUserList.fulfilled]: (state, action) => {
      state.userList = action.payload.employee;
      state.maxRecord = action.payload.requestsCount;
    },
  },
});

export const { setSkip, setLimit, setTo,setSearch, setFrom, setMaxRecord } =
  payslipSlice.actions;

export default payslipSlice.reducer;

import React from "react";
import "./steps.scss";

const checkIconStyle = {
  color: "#FFFFFF",
  fontSize: "0.7rem",
  fontWeight: "bold",
};

const Steps = ({ activeIndex, model }) => {
  const handleComplete = (index) => {
    if (activeIndex > index) return "step-bg";
    else return "";
  };
  const ruler = (index) => {
    if (activeIndex >= index) return "step-theme-color";
    else return "";
  };

  return (
    <div className="flex flex-column row-gap-3">
      <div className="flex">
        {model.map((_, index) => (
          <div className="flex  align-items-center" key={index}>
            {index !== 0 && <hr className={`step-divider ${ruler(index)}`} />}
            <div className="step flex flex-column  align-items-center justify-content-center">
              <div
                className={`step-number  steping ${
                  activeIndex === index ? "activeBorder" : "border"
                } ${handleComplete(index)}`}
              >
                {Boolean(activeIndex > index) ? (
                  <i className="pi pi-check" style={checkIconStyle} />
                ) : (
                  <p
                    className={`${
                      activeIndex === index ? "activeSemiCircle" : "semiCircle"
                    }`}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex  column-gap-6">
        {model.map((step, index) => {
          return (
            <div
              key={index}
              className={`step-label ${
                activeIndex === index ? "activeText" : "txt"
              }`}
            >
              {step}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Steps;

import React, { useEffect, useState, useRef } from "react";
import { SpeedDial } from "primereact/speeddial";
import UpdateRole from "./updateRole";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import constants from "../../constants";
import { deleteWithAuth } from "../../services/authentication";
import { Toast } from "primereact/toast";
import triangle from "../../assets/triangle.svg";
import round from "../../assets/round.svg";
import rectangle from "../../assets/rectangle.svg";
import document from "../../assets/document.svg";

export default function RoleTable({ roles, setRoles, update, setUpdate }) {
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const [updateRoleDialog, setUpdateRoleDialog] = useState(false);
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);

  const [seletedRole, setSeletedRole] = useState({});

  const [modules, setModules] = useState([]);
  useEffect(() => {
    setModules([]);
    setModulePermission();
  }, [roles]);

  function setModulePermission() {
    setModules([]);
    let mod = [];
    for (let role of roles) {
      const moduleArray = [
        "Dashboard",
        "Project",
        "Wiki",
        "Users",
        "Settings",
        "Human Resource",
      ];
      let moduleWithPermission = {};
      for (let module of moduleArray) {
        let index = role.module.findIndex((mod) => {
          return mod.moduleName === module;
        });
        if (index === -1) {
          moduleWithPermission[module] = -1;
        } else if (role.module[index].permission === "View") {
          moduleWithPermission[module] = 0;
        } else if (role.module[index].permission === "Edit") {
          moduleWithPermission[module] = 1;
        } else if (role.module[index].permission === "Admin") {
          moduleWithPermission[module] = 2;
        }
      }
      mod.push(moduleWithPermission);
    }
    setModules(mod);
  }
  async function deleteHandler() {
    try {
      const url = constants.API.SETTINGS.DELETE_ROLE + `/${seletedRole._id}`;
      await deleteWithAuth(url, token);
      setSeletedRole({});
      setDeleteRoleDialog(!deleteRoleDialog);
      setUpdate(!update);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  let div = [
    <div className="flex flex-row">
      <img src={round} />
    </div>,
    <div className="flex flex-row">
      <img src={round} /> &nbsp;&nbsp;
      <img src={rectangle} />
    </div>,
    <div className="flex flex-row">
      <img src={round} />
      &nbsp;&nbsp;
      <img src={rectangle} />
      &nbsp;&nbsp;
      <img src={triangle} />
    </div>,
  ];
  const options = [
    {
      label: "Edit",
      icon: "pi pi-pencil",

      command: () => {
        setUpdateRoleDialog(!updateRoleDialog);
        return;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteRoleDialog(!deleteRoleDialog);
      },
    },
  ];
  return (
    <div>
      <Toast ref={toast} />
      {updateRoleDialog && (
        <UpdateRole
          updateRoleDialog={updateRoleDialog}
          setUpdateRoleDialog={setUpdateRoleDialog}
          seletedRole={seletedRole}
          setSeletedRole={setSeletedRole}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      {deleteRoleDialog && (
        <DeleteDialog
          deleteDialog={deleteRoleDialog}
          setDeleteDialog={setDeleteRoleDialog}
          onClick={deleteHandler}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>
              <div className="module-font">Role Name</div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Dashboard
              </div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Projects
              </div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Wiki
              </div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Users
              </div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Settings
              </div>
            </th>
            <th>
              <div className="module-font flex module-align">
                <img src={document} />
                &nbsp; Human Resource
              </div>
            </th>
            <th>
              <div className="module-font settings-action-align">Action</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {modules.length > 0 &&
            modules.map((role, i) => {
              return (
                <tr key={i}>
                  <td className="role-table-border">
                    <div className="role-type-align">
                      {roles[i]?.role?.role_name}{" "}
                    </div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">{div[role.Dashboard]}</div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">{div[role.Project]}</div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">{div[role.Wiki]}</div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">{div[role.Users]}</div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">{div[role.Settings]}</div>
                  </td>
                  <td className="role-table-border">
                    <div className="role-type-align">
                      {div[role["Human Resource"]]}
                    </div>
                  </td>
                  <td className="role-table-border ml-2">
                    <div className="role-action">
                      <SpeedDial
                        radius={40}
                        direction="right"
                        model={options}
                        onClick={() => setSeletedRole(roles[i])}
                        showIcon="pi pi-ellipsis-v"
                        hideIcon="pi pi-times"
                        buttonClassName="p-button-outlined action-btn-design"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

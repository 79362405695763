import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { getUsersByProject } from "../../../store/features/Projects/Project";
import Alphabet from "../../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../../utility/utility";

export default function MembersInfo({
  membersInfo,
  setMembersInfo,
  updateItem,
}) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { projectId } = useParams();

  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Employee Profile"
        visible={membersInfo}
        style={{ width: "70vw" }}
        onHide={() => {
          setMembersInfo(!membersInfo);
        }}
      >
        <div className="flex">
          {updateItem?.memberDetails?.basic?.profilePic ? (
            <img
              className="members-info-image"
              src={updateItem?.memberDetails?.basic?.profilePic}
            />
          ) : (
            <Alphabet
              width="100px"
              height="100px"
              fontSize="40px"
              letter={fn_firstcharacter(
                updateItem?.memberDetails?.basic?.fullName
              )}
            />
          )}
          <h3 className="ml-3 mt-5">
            {updateItem?.memberDetails?.basic?.fullName}
          </h3>
        </div>

        <div className="grid asset-table-font mt-3">
          <div className="col-3 dialoggrid"> Employee Name</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.basic?.fullName
              ? updateItem?.memberDetails?.basic?.fullName
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Employee ID</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.work?.employeeId
              ? updateItem?.memberDetails?.work?.employeeId
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Role</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.work?.role
              ? updateItem?.memberDetails?.work?.role
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Designation</div>
          <div className="col-3 dialoggrid">
            {updateItem?.designationDetails?.designation
              ? updateItem?.designationDetails?.designation
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid">Current Address </div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.contact?.currentAddress
              ? updateItem?.memberDetails?.contact?.currentAddress
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Permanent Address</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.contact?.permanentAddress
              ? updateItem?.memberDetails?.contact?.permanentAddress
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Emergency Contact Number</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.contact?.emergencyContactNumber
              ? updateItem?.memberDetails?.contact?.emergencyContactNumber
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Personal Email</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.contact?.personalEmail
              ? updateItem?.memberDetails?.contact?.personalEmail
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Blood group</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.personal?.bloodGroup
              ? updateItem?.memberDetails?.personal?.bloodGroup
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> DOB</div>
          <div className="col-3 dialoggrid">
            {new Date(
              updateItem?.memberDetails?.personal?.dob
                ? updateItem?.memberDetails?.personal?.dob
                : "N/A"
            ).toLocaleDateString("en-uk", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
          <div className="col-3 dialoggrid"> Gender</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.personal?.gender
              ? updateItem?.memberDetails?.personal?.gender
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Nationality</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.personal?.nationality
              ? updateItem?.memberDetails?.personal?.nationality
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Phone</div>
          <div className="col-3 dialoggrid">
            {updateItem?.memberDetails?.personal?.phone
              ? updateItem?.memberDetails?.personal?.phone
              : "N/A"}
          </div>
          <div className="col-3 dialoggrid"> Date Of Joining</div>
          <div className="col-3 dialoggrid">
            {new Date(
              updateItem?.memberDetails?.work?.dateofJoining
                ? updateItem?.memberDetails?.work?.dateofJoining
                : "N/A"
            ).toLocaleDateString("en-uk", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

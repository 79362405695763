import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Button from "../../common/button/button";
import Input from "../../common/inputtext/input-field";
import { Editor } from "primereact/editor";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { updateSprint,getSprintList } from "../../../store/features/Projects/Sprint";
import { Toast } from "primereact/toast";

export default function EditSprint({ editSprintDialog, setEditSprintDialog,selectedItem,setSelectedItem }) {
  const { allUsers } = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { projectId } = useParams();

 
  const [userOption, setUserOptions] = useState([]);
  const [updateItem, setUpdateItem] = useState({});


  useEffect(() => {
    if (allUsers) {
      let options = [];
      allUsers.forEach((user) => {
        options.push({ value: user._id, label: user.basic.fullName });
      });
      setUserOptions(options);
    }
  }, [allUsers]);

  useEffect(() => {
    if (JSON.stringify(selectedItem) != "{}") {
      setUpdateItem({ ...selectedItem });
    }
  }, [selectedItem]);

 

  const weekDropdown = [
    { label: "1 Week", value: "1 Week" },
    { label: "2 Weeks", value: "2 Weeks" },
    { label: "3 Weeks", value: "3 Weeks" },
    { label: "4 Weeks", value: "4 Weeks" },
    { label: "Custom", value: "Custom" },
  ];

  const inputHandler = (e,field) => {
    let data = {...updateItem}
     data[field]= e;  
     data = {...data}
     setUpdateItem(data)

  }
 
  const submitHandler = async () => {
    let data = {  ...updateItem };
    dispatch(updateSprint(data));
    setEditSprintDialog(!editSprintDialog)
    dispatch(getSprintList(projectId));
  };


  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Edit Sprint"
        visible={editSprintDialog}
        style={{ width: "50vw" }}
        onHide={() => {
            setEditSprintDialog(!editSprintDialog)
        }}
      >
        <div className="mt-5">
          <Input
            className="asset-input"
            placeholder="Sprint Name"
            name="title"
            label="Sprint Name"
            type="text"
            required={true}
            value={updateItem.title}
            onChange={(e) => inputHandler(e.target.value,"title")}
          ></Input>
        </div>

        <div className="mt-5">
          <Editor
            style={{ height: "200px" }}
            value={updateItem.goal}
            onTextChange={(e) =>
                inputHandler(e.htmlValue,"goal")
            }
          />
        </div>

        <div className="mt-5">
          <Dropdown
            options={userOption}
            placeholder="Sprint Owner"
            value={updateItem.sprintOwner}
            onChange={(e) =>
                inputHandler(e.target.value,"sprintOwner")
            }
          ></Dropdown>
        </div>

        <div className="mt-5">
          <Dropdown
            options={weekDropdown}
            placeholder="Duration"
            value={updateItem.duration}
            onChange={(e) =>
                inputHandler(e.target.value,"duration")
            }
          ></Dropdown>
        </div>

        <div className="flex mt-5">
          <div className="col-6">
            <Calendar
              placeholder="Start Date"
              dateFormat="dd/mm/yy"
              value={new Date(updateItem.startDate)}
              readOnlyInput
              onChange={(e) =>
                inputHandler(e.target.value,"startDate")
              }
            ></Calendar>
          </div>
          <div className="col-6">
            <Calendar
              placeholder="End Date"
              dateFormat="dd/mm/yy"
              value={new Date(updateItem.endDate)}
              readOnlyInput
              onChange={(e) =>
                inputHandler(e.target.value,"endDate")
              }
            ></Calendar>
          </div>
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button label="Submit" onClick={() => submitHandler()}></Button>
        </div>
      </Dialog>
    </div>
  );
}

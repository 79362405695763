import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FolderCard from "../../component/project/projectFiles/folderCard";
import InputIcon from "../../component/common/inputIcon/inputIcon";
import Button from "../../component/common/button/button";
import CreateFolder from "../../component/project/projectFiles/createFolder";
import {
  getFilesList,
  setProjectId,
  setSearch,
} from "../../store/features/Projects/File";
import { useParams } from "react-router-dom";

export default function ProjectFiles() {
  
  const [createFileDialog, setCreateFileDialog] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { projectId } = useParams();

  useEffect(() => {
    dispatch(getFilesList({ projectId: projectId, search: searchValue }));
  }, [searchValue]);

  return (
    <>
      {createFileDialog && (
        <CreateFolder
          createFileDialog={createFileDialog}
          setCreateFileDialog={setCreateFileDialog}
        />
      )}
      <div className="flex flex-column row-gap-4 p-4">
        <div className="flex align-items-center justify-content-between ">
          <InputIcon
            placeholder="Search"
            onChange={(e) => {
              dispatch(setSearch(e.target.value));
              setSearchValue(e.target.value);
            }}
          />

          <Button
            className="create-btn"
            label="Create Folder"
            onClick={() => setCreateFileDialog(!createFileDialog)}
          />
        </div>
        <FolderCard />
      </div>
    </>
  );
}

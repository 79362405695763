import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
roles:[],
status:''
};

export const getRoles = createAsyncThunk("/roles", async (orgId,{rejectWithValue}) => {
  try {
    const url = constants.API.SETTINGS.GET_ROLE + '/' + orgId;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: {
    [getRoles.pending]: (state) => {
      state.isLoading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
     state.roles = action.payload;
    },
    [getRoles.rejected]: (state, action) => {
      state.status = "error";
    }
  },
});

export const { resetStatus } = roleSlice.actions;

export default roleSlice.reducer;






import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import constants from "../../constants";
import {
  getWithAuth,
  putWithAuth,
  postWithAuth,
} from "./../../services/authentication";
import { Toast } from "primereact/toast";
import { useDispatch,useSelector } from "react-redux";
import { getUsers } from "../../store/features/Users/User";

export default function ({ manageProjectDialog, setManageProjectDialog ,updateItem}) {
  const [projectList, setProjectList] = useState([]);
  const [assignedProject,setAssignedProject]=useState([]);
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const dispatch=useDispatch();
  const {skip,limit,search}=useSelector(store=>store.users)

  // get project Details
  async function getProjectList() {
    const url = constants.API.PROJECT.ASSIGN_REASSIGN+updateItem._id;
    const response = await getWithAuth(url, token);
    const data = response.data;
    setAssignedProject([...data.assignedProjects]);
    setProjectList([...data.assignedProjects,...data.unAssignedProjects]);
  }

  //add & remove project

  const projectUpdateHandler = async (e, project) => {
    try {
        const postProject = { projectId:project._id , userId:updateItem._id };
        const url = constants.API.PROJECT.ASSIGN_REASSIGN + updateItem._id;
    
        if (e.checked === true) {
          await postWithAuth(url, postProject, token);
            let updatedProject=[...assignedProject];
            updatedProject=[...updatedProject,project];
            setAssignedProject([...updatedProject]);
        }
        if(e.checked === false) {
            await putWithAuth(url, postProject, token);
            let updatedProject=assignedProject.filter(p=>{
             return p._id!==project._id
             })
             setAssignedProject(updatedProject);
         }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.message,
        detail: "",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    if(updateItem !=={}){
      getProjectList();
    }
  }, [updateItem]);
  const checkHandler=(project)=>{
   const index=assignedProject.findIndex(p=>{
      return p._id===project._id;
    })
    if(index === -1){
      return false;
    }
    else{
      return true;
    }
  }

  return (
    <div>
      <Toast ref={toast}></Toast>
      {/* Manage Projects */}
      <Dialog
        header="Manage Project"
        visible={manageProjectDialog}
        style={{ width: "35vw" }}
        onHide={async() => {
          await dispatch(getUsers({skip,limit,search}));
          setManageProjectDialog(!manageProjectDialog);
        }}
      >
        <div className="grid">
          {projectList.map((project) => {
            return (
              <div className="p-2 inline-flex col-6 field">
                <Checkbox
                  inputId={project}
                  value={project._id}
                  onChange={(e) => projectUpdateHandler(e, project)}
                  checked={checkHandler(project)}
                ></Checkbox>
                &nbsp;&nbsp;
                <label
                  htmlFor={project}
                  className="p-checkbox-label module-font"
                >
                  {project.projectDetails.projectName}
                </label>
              </div>
            );
          })}
        </div>
      </Dialog>
    </div>
  );
}

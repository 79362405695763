import React, { useEffect, useState, useRef } from "react";
import { SpeedDial } from "primereact/speeddial";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  deleteRelease,
  getReleaseList,
  getTaskByRelease,
  setSelectedRelease,
} from "../../../store/features/Projects/Release";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import UpdateRelease from "./updateRelease";
import document from "../../../assets/document.svg";
import ReleaseAddTask from "./releaseAddTask";
import { DateFormat, fn_firstcharacter } from "../../../utility/utility";
import Alphabet from "../../common/alphabet/alphabet";

export default function ReleaseTable() {
  const { projectId } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { allUsers } = useSelector((store) => store.users);
  const { allReleases, selectedRelease } = useSelector(
    (store) => store.release
  );

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});

  const [updateReleaseDialog, setUpdateReleaseDialog] = useState(false);

  const [addReleaseTaskDialog, setAddReleaseTaskDialog] = useState(false);

  useEffect(() => {
    if (selectedRelease._id)
      dispatch(
        getTaskByRelease({
          projectId: projectId,
          releaseId: selectedRelease._id,
        })
      );
  }, [selectedRelease]);

  //Action button
  let items = [
    {
      label: "Add Release Sub-Task",
      icon: "pi pi-plus-circle",
      command: () => {
        setAddReleaseTaskDialog(!addReleaseTaskDialog);
      },
    },
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setUpdateReleaseDialog(!updateReleaseDialog);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      dispatch(deleteRelease(updateItem?._id)).then((res) => {
        if (res.payload.message === "Release deleted successfully") {
          toast.current.show({
            severity: "success",
            summary: "Success message",
            detail: "Deleted Successfully",
            life: 2000,
          });
          dispatch(getReleaseList(updateItem?.projectId)).then(() => {
            setDeleteDialog(!deleteDialog);
          });
        }
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <DeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        onClick={deleteHandler}
      />
      <UpdateRelease
        updateReleaseDialog={updateReleaseDialog}
        setUpdateReleaseDialog={setUpdateReleaseDialog}
        selectedItem={updateItem}
        setSelectedItem={setUpdateItem}
      />
      <ReleaseAddTask
        addReleaseTaskDialog={addReleaseTaskDialog}
        setAddReleaseTaskDialog={setAddReleaseTaskDialog}
        updateItem={updateItem}
      />

      <div className="flex">
        {/* Release card */}
        {allReleases.map((release, id) => {
          return (
            <div
              key={id}
              className={
                release._id === selectedRelease._id
                  ? "active-release-card-size"
                  : "release-card-size"
              }
              onClick={() => {
                let id = { projectId: projectId, releaseId: release._id };
                dispatch(getTaskByRelease(id)); // for filling releases task in table
                dispatch(setSelectedRelease(release)); //for shifting active class
              }}
            >
              <div>
                <div className="release-card-header-font ml-3 mt-3">
                  {release?.name}
                </div>

                <div className="flex release-action-btn">
                  <SpeedDial
                    model={items}
                    radius={50}
                    direction="right"
                    showIcon="pi pi-ellipsis-v"
                    hideIcon="pi pi-times"
                    buttonClassName="p-button-outlined action-btn-design"
                    onClick={() => {
                      setUpdateItem(release);
                    }}
                  />
                </div>
              </div>

              <p className="release-card-desc-font ml-3 mt-2">
                {release?.description}
              </p>

              <div className="flex mt-3 ml-3">
                <img src={document} />
                <p className="release-card-desc-font ml-2">
                  {release?.taskDetails}
                </p>
              </div>

              <div className="flex justify-content-between">
                <div className="endDate-btn ml-3 mt-3">
                  {DateFormat(release?.endDate)}
                </div>

                <div className="mr-4 mt-2">
                  {allUsers?.map((name, id) => {
                    if (name?._id === release.createdBy) {
                      return <img
                        key={id}
                        className="projects-img-size flex justify-content-center"
                        src={name?.basic?.profilePic}
                      /> ? (
                        <Alphabet
                          letter={fn_firstcharacter(name?.basic?.fullName)}
                        />
                      ) : (
                        <></>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

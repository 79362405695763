import React from "react";

export default function EmptyScreen({ img, title, description,header}) {
  return (
    <div>
      <div className="grid flex justify-content-center">
        <h3>{header}</h3>
      </div>
      <div className="grid flex justify-content-center mt-4">
        <img src={img} alt="image" />
      </div>
      <div className="grid flex justify-content-center">
        <h3>{title}</h3>
      </div>
      <div className="grid flex justify-content-center announcement-time mt-3">
        {description}
      </div>
    </div>
  );
}

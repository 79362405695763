import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  getUpcomingSprint: [],
  leaveStatus: [],
  activeTasks: [],
  hrsAdminSeriesData: [],
  totalAdminHrsDuration: {},
  timeSheetDetails: [],
};

//time spent api

export const getTimeSpent = createAsyncThunk(
  "/hr/getTimeSpent",
  async (endpoint, {rejectWithValue}) => {
    try {
      const url = constants.API.DASHBOARD.TIMESHEET + endpoint;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// upcoming sprint api
export const getUpcomingSprintApi = createAsyncThunk(
  "/getUpcomingSprint",
  async (_,{rejectWithValue}) => {
    try {
      const url = constants.API.DASHBOARD.GET_UPCOMING_SPRINT;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLeaveStatus = createAsyncThunk(
  "/leave-status",
  async (_, {rejectWithValue}) => {
    try {
      const response = await api.get(constants.API.DASHBOARD.LEAVE_POLICY);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getActiveTasks = createAsyncThunk(
  "/current-tasks",
  async (_, {rejectWithValue}) => {
    try {
      const response = await api.get(constants.API.DASHBOARD.GET_ACTIVE_TASKS);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashBoard",
  initialState,
  reducers: {},
  extraReducers: {
    [getUpcomingSprintApi.fulfilled]: (state, action) => {
      state.getUpcomingSprint = action.payload;
    },

    [getLeaveStatus.fulfilled]: (state, action) => {
      state.leaveStatus = action.payload.policies;
    },
    
    [getActiveTasks.fulfilled]: (state, action) => {
      state.activeTasks = action.payload;
    },

    [getTimeSpent.fulfilled]: (state, { payload }) => {
      state.totalAdminHrsDuration = payload.totalDuration;
      state.hrsAdminSeriesData = [];
      if (payload.hoursSeries && payload.hoursSeries?.length > 0) {
        payload.hoursSeries.map((element) => {
          state.hrsAdminSeriesData.push(
            Number(`${element.totalHrs}.${element.totalMins}`)
          );
        });
      }
    },
  },
});

export default dashboardSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  editWikipage,
  getAllPageApi,
  getSubpage,
  removePage,
  wikiCreateSpaceApi,
} from "../../../store/features/wiki";
import CreateSpace from "../create-space-modal/createSpace";
import "./pagetree.scss";
import { Toast } from "primereact/toast";
import Image from "../../../utility/image";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import ExportWiki from "./exportwiki";
import { Dialog } from "primereact/dialog";
import {
  PageOption,
  replaceDotMethod,
  SubOption,
  WIKI_CHECKBOX_SPACE_ARRAY,
} from "../../../utility/utility";
import Checkboxes from "../../common/checkbox/checkbox";
import AddWikiSpace from "../wiki-spaces/addWikiSpace";

import { Dropdown } from "primereact/dropdown";
import Button from "../../common/button/button";

export default function PageTree() {
  const toast = useRef(null);
  const op = useRef(null);
  const oc = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // common Toast fn
  const toastMsg = ({ type, message }) => {
    toast.current.show({
      severity: type,
      summary: message,
      life: 3000,
    });
  };

  const { subPageArray, getAllPage, subPageList, useDetails } = useSelector(
    (store) => store.wiki
  );

  const [isCreateSpace, setIsCreateSpace] = useState(false);
  const [selectedPage_id, setSelectedPage_id] = useState(null);

  const [hightlightText, setHightlightText] = useState("");

  const [selectedCheckBox, setselectedCheckBox] = useState("");
  const [changeParentData, setChangeParentData] = useState({
    isOpen: false,
    dropdownData: [],
    selectedParent: null,
  });

  const [_3dot, set_3dot] = useState({});
  const [isexpend, setISExpend] = useState(false);

  const [privateSettingDialog, setPrivateSettingDialog] = useState(false);
  const [exportWikiDialog, setWikiExportDialog] = useState(false);

  const [pageId, setPageId] = useState("");
  const [selectItem, setselectItem] = useState(null);
  const [isChangeParent, setIsChangeParent] = useState(false);

  useEffect(() => {
    // set the active state when component is render
    if (getAllPage) {
      setSelectedPage_id(getAllPage[0]?._id);
      setselectItem(getAllPage[0]);
    }
  }, []);

  const handleCreatePage = () => {
    dispatch(
      wikiCreateSpaceApi({ parentPageId: selectedPage_id, private: false })
    ).then(({ payload }) => {
      getSubpageDetails(payload.page.parentPageId);
    });
  };

  const getSubpageDetails = (items) => {
    const payload = {
      orgId: useDetails?.orgId,
      selectedWikiPage: items,
      memberId: useDetails?.memberOrgId,
    };
    dispatch(getSubpage(payload));
  };

  const handleIcon = (items) => {
    setSelectedPage_id(items._id);
    getSubpageDetails(items._id);
    setPageId("");
  };

  const handleRemovePage = (item, page) => {
    dispatch(removePage({ orgId: useDetails?.orgId, pageId: item._id })).then(
      ({ payload }) => {
        if (payload.message === "Page delete success.") {
          toastMsg({ type: "success", message: payload.message });
          if (page === "subPage") getSubpageDetails(item.parentPageId);
          else if (page === "Page") {
            _getAllPageApi();
          }
        }
      }
    );
    setselectItem(null);
  };

  const _getAllPageApi = () => {
    dispatch(
      getAllPageApi({
        orgId: useDetails?.orgId,
        memberId: useDetails?.memberOrgId,
      })
    );
  };

  const handleOverlayDialog = (item) => {
    setHightlightText(item.label);
    op.current.hide();
    switch (item.label) {
      case "Delete":
        handleRemovePage(_3dot, "Page");
        break;
      case "Add":
        handleCreatePage(_3dot, "Page");
        break;
      case "Privacy Settings":
        setPrivateSettingDialog(!privateSettingDialog);
        setselectedCheckBox(_3dot.private ? "Private" : "Public");
        break;
      default:
        window.location.reload();
        break;
    }
  };

  const handleGetDocs = (item) => {
    setselectItem(item);
    setPageId(item._id);
  };

  const overlaySubPage = (name) => {
    oc.current.hide();
    switch (name) {
      case "Export":
        setWikiExportDialog(!exportWikiDialog);
        break;
      case "Delete Page":
        if (pageId) {
          const data = subPageArray.filter((x) => x._id === pageId);
          handleRemovePage(data[0], "subPage");
        }
        getSubpageDetails(pageId);
        break;
      case "Change Parent Page":
        setIsChangeParent(() => {
          if (selectedPage_id && getAllPage) {
            const data = getAllPage.find((x) => x._id === selectedPage_id);
            return data["private"] ? true : false;
          }
        });
        setChangeParentData((prev) => {
          return {
            ...prev,
            isOpen: !changeParentData.isOpen,
            dropdownData:
              getAllPage &&
              getAllPage.map((item) => {
                return {
                  ...item,
                  ["label"]: item.name,
                };
              }),
          };
        });
        break;
      default:
        window.location.reload();
    }
  };

  const handleCheckBox = (item) => {
    setselectedCheckBox(item.label);
    const payload = { ..._3dot, private: Boolean(item.label === "Private") };
    dispatch(editWikipage(payload)).then(() => {
      _getAllPageApi();
      setPrivateSettingDialog(!privateSettingDialog);
    });
  };

  const handleChangeParent = () => {
    if (subPageList) {
      const payload = {
        _id: subPageList._id,
        parentPageId: changeParentData.selectedParent._id,
        name: subPageList.name,
        content: subPageList.content,
      };
      dispatch(editWikipage(payload)).then((res) => {
        setChangeParentData((prev) => {
          return {
            ...prev,
            isOpen: false,
            dropdownData: [],
            selectedParent: null,
          };
        });
        if (!res.error) {
          _getAllPageApi();
          handleIcon(selectItem);
          toastMsg({ type: "success", message: res.payload.message });
        } else {
          toastMsg({ type: "error", message: res.payload.message });
        }
      });
    }
  };

  const handlePage = (item) => {
    setselectItem(item);
    setISExpend(!isexpend);
    handleIcon(item);
  };

  const handle3_dot = (e, item) => {
    set_3dot(item);
    op.current.toggle(e);
  };

  const _isSubpage = (item) => {
    if (isexpend && subPageArray && selectedPage_id === item._id) return true;
    return false;
  };

  const overlayStyle = ({ label }) => {
    if (hightlightText === label) {
      return {
        background: "#e8eaf6",
        border: "0.25rem",
      };
    }
    return {};
  };

  const _active = ({ _id }) => {
    if (_id === selectedPage_id) return true;
    return false;
  };

  return (
    <>
      <Toast ref={toast} />

      {exportWikiDialog && (
        <ExportWiki
          exportWikiDialog={exportWikiDialog}
          setWikiExportDialog={setWikiExportDialog}
          pageId={pageId}
        />
      )}

      {/* pageTree space side bar component */}
      {isCreateSpace && (
        <CreateSpace visible={isCreateSpace} isCheck={setIsCreateSpace} />
      )}

      <div className="flex  justify-content-evenly col-12 min-h-screen">
        <div className="col-3 bg-white p-3 out-line overflow-y-visible">
          <div className="flex flex-column row-gap-3 py-3">
            <div className="flex flex-column row-gap-2">
              <p id="pagetree">Page tree</p>
              <div
                className="add__space flex  column-gap-1 align-items-center justify-content-center"
                onClick={() => setIsCreateSpace(!isCreateSpace)}
              >
                <i className="pi pi-plus-circle" />
                <span>Add space</span>
              </div>
            </div>

            <div className="flex flex-column row-gap-2">
              {getAllPage &&
                getAllPage.map((_page, id) => {
                  return (
                    <div key={id}>
                      <div
                        className={
                          _active(_page)
                            ? "cursor-pointer flex align-items-center column-gap-2  spacetree__container checking-color"
                            : "cursor-pointer flex align-items-center column-gap-2 spacetree__container"
                        }
                      >
                        <Tooltip target=".wiki" mouseTrack />
                        <div>
                          {isexpend && _active(_page) ? (
                            <i className="font pi pi-angle-down" />
                          ) : (
                            <i className="font pi pi-angle-right" />
                          )}
                        </div>

                        {/* Private Folder Logic */}
                        {_page.private && (
                          <>
                            <img
                              className="wiki"
                              src={Image.PrivateIcon}
                              alt="Private-icon"
                              data-pr-tooltip="This page is visible for u only"
                            />
                          </>
                        )}

                        <div className="flex align-items-center  justify-content-between w-full h-full">
                          <span
                            className="wiki__text__color w-11 "
                            onClick={() => {
                              navigate(`/wiki/${selectedPage_id}`);
                              handlePage(_page);
                            }}
                          >
                            {replaceDotMethod(_page.name, 25)}
                          </span>

                          <span className="flex  w-1">
                            <i
                              className="font pi pi-ellipsis-v"
                              onClick={(e) => handle3_dot(e, _page)}
                            />
                          </span>
                        </div>
                      </div>

                      <div>
                        {_isSubpage(_page) &&
                          subPageArray.map((_subPage, id) => {
                            return (
                              <div
                                key={id}
                                className={` ${
                                  _subPage._id === pageId
                                    ? "checking-color-active"
                                    : ""
                                } flex  align-items-center justify-content-between cursor-pointer m-2`}
                              >
                                <div className="flex column-gap-2  align-items-center justify-content-between">
                                  <i className="font pi pi-angle-right" />
                                  <span
                                    className="wiki__text__color page__name__width"
                                    onClick={() => handleGetDocs(_subPage)}
                                  >
                                    {replaceDotMethod(_subPage.name, 25)}
                                  </span>
                                </div>

                                <div>
                                  <i
                                    className="font pi pi-ellipsis-v px-2"
                                    onClick={(e) => {
                                      oc.current.toggle(e);
                                      handleGetDocs(_subPage);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* Wiki Editor component */}
        <div className="col-8 bg-white out-line">
          {Boolean(selectItem && Object.keys(selectItem).length) ? (
            <AddWikiSpace selectedPage={selectItem} />
          ) : (
            <div className="col-12 flex justify-content-center align-items-center  ">
              {""}
            </div>
          )}
        </div>
      </div>

      {/* Page Action Overlay */}
      <OverlayPanel ref={op} className="overlaypanel">
        <div className="flex flex-column row-gap-2 p-3">
          {PageOption.map((list, id) => {
            return (
              <div key={id}>
                <div
                  onClick={() => handleOverlayDialog(list)}
                  style={overlayStyle(list)}
                  className="p-2 hover-list"
                >
                  <label>{list.label}</label>
                </div>
              </div>
            );
          })}
        </div>
      </OverlayPanel>

      {/* SubPage Action  */}
      <OverlayPanel ref={oc} className="overlaypanel-demo">
        <div className="flex flex-column row-gap-2 p-3">
          {SubOption.map((list, id) => {
            return (
              <div key={id}>
                <div
                  onClick={() => overlaySubPage(list.label)}
                  className="p-2 hover-list"
                >
                  <label>{list.label}</label>
                </div>
              </div>
            );
          })}
        </div>
      </OverlayPanel>

      {/* private setting modal */}
      <Dialog
        header="Private Setting"
        visible={privateSettingDialog}
        style={{ width: "20vw" }}
        onHide={() => setPrivateSettingDialog(!privateSettingDialog)}
      >
        <>
          <div className="flex align-items-center column-gap-4">
            {WIKI_CHECKBOX_SPACE_ARRAY.map((item, id) => {
              return (
                <div className="flex column-gap-2" key={id}>
                  <Checkboxes
                    value={item.ischecked}
                    onChange={() => handleCheckBox(item)}
                    checked={item.label === selectedCheckBox}
                    name={item.name}
                  />
                  <label>{item.label}</label>
                </div>
              );
            })}
          </div>
        </>
      </Dialog>

      {/* changeParent modal */}
      <Dialog
        header="Change Parent"
        visible={changeParentData.isOpen}
        style={{ width: "40vw" }}
        onHide={() =>
          setChangeParentData((prev) => {
            return {
              ...prev,
              isOpen: !changeParentData.isOpen,
              dropdownData: [],
              selectedParent: {},
            };
          })
        }
      >
        <>
          {!isChangeParent && (
            <div className="flex flex-column  row-gap-2">
              <div className="flex  column-gap-2">
                <label className="col-3">Change Parent</label>
                <Dropdown
                  value={changeParentData.selectedParent}
                  options={changeParentData?.dropdownData}
                  onChange={(e) =>
                    setChangeParentData((prev) => {
                      return { ...prev, selectedParent: e.target.value };
                    })
                  }
                  placeholder="Select a parent"
                />
              </div>
              <div
                id="save-btn"
                className="flex align-items-center justify-content-end"
              >
                <Button
                  label="Save"
                  onClick={handleChangeParent}
                  disabled={!changeParentData.selectedParent}
                />
              </div>
            </div>
          )}
          {isChangeParent && (
            <>{"Can't change the Parent for Private Pages"}</>
          )}
        </>
      </Dialog>
    </>
  );
}

import { Dialog } from "primereact/dialog";
import React, { useEffect, useState, useRef } from "react";
import Input from "../common/inputtext/input-field";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Button from "./../../component/common/button/button";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
} from "../../services/authentication";
import InputNumber from "../common/inputnumber/input-number";
import constants from "../../constants";

export default function Leavepolicy({
  setShowLeavePolicyDialog,
  showLeavePolicyDialog,
}) {
  const token = localStorage.getItem("token");
  const [addLeavePolicyDialog, setAddLeavePolicyDialog] = useState(false);
  const [rollOver, setRollOver] = useState(true);
  const [submitDisable, setSubmitDisable] = useState(true);
  const typeDropdown = [
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ];
  const toast = useRef(null);
  const [designation, setDesination] = useState([]);
  const [leavePolicy, setLeavePolicy] = useState({
    title: "",
    type: "",
    count: "",
  });
  const [notIncludes, setNotInclues] = useState([]);
  const [allLeavePolicy, setAllLeavePolicy] = useState([]);
  const [editLeavePolicyDialog, setEditLeavePolicyDialog] = useState(false);
  const [editLeavePolicy, setEditLeavePolicy] = useState({
    title: "",
    type: "",
    count: "",
  });
  const [editSubmitDisable, setEditSubmitDisable] = useState(true);
  useEffect(() => {
    getAllPolicy();
  }, []);
  async function getAllPolicy() {
    try {
      const LEAVEPOLICYURL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      let leavePolicyResponse = await getWithAuth(LEAVEPOLICYURL, token);
      setAllLeavePolicy(leavePolicyResponse.data.leavePolicys);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  async function getDesignation() {
    try {
      setNotInclues([]);
      setDesination([]);
      const DESGINATIONURL = constants.API.DESIGNATION.FETCH;
      let response = await getWithAuth(DESGINATIONURL, token);
      let des = [];
      response.data.designations.forEach((e) => {
        des.push({ label: e.designation, value: e._id });
      });
      setDesination(des);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  useEffect(() => {
    let y = Object.values(leavePolicy).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [leavePolicy]);
  useEffect(() => {
    let y = Object.values(editLeavePolicy).every((val) => val !== "");
    if (y === true) {
      setEditSubmitDisable(false);
    } else {
      setEditSubmitDisable(true);
    }
  }, [editLeavePolicy]);
  const editChangeHandler = (e) => {
    if (e.target.name !== "count" && e.target.name !== "thresholdMonths") {
      setEditLeavePolicy({
        ...editLeavePolicy,
        [e.target.name]: e.target.value,
      });
    } else {
      setEditLeavePolicy({
        ...editLeavePolicy,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };

  function changeHandler(e) {
    if (e.target.name !== "count" && e.target.name !== "thresholdMonths") {
      setLeavePolicy({ ...leavePolicy, [e.target.name]: e.target.value });
    } else {
      setLeavePolicy({
        ...leavePolicy,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  }

  async function submitHandler() {
    const data = {
      ...leavePolicy,
      notIncludes: notIncludes,
      carryForward: rollOver,
    };
    try {
      const URL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      await postWithAuth(URL, data, token);
      setNotInclues([]);
      setDesination([]);
      getAllPolicy();
      setAddLeavePolicyDialog(!addLeavePolicyDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  async function statusChangeHandler(index, value) {
    let allPolicy = allLeavePolicy;
    if (allPolicy[index]?.status !== undefined) {
      allPolicy[index].status = value;
      const URL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      await putWithAuth(URL, allPolicy[index], token);
      getAllPolicy();
    }
  }
  const getStatus = (data) => {
    const id = data._id;
    const index = allLeavePolicy.findIndex((e) => {
      return e._id === id;
    });
    const options = [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" },
    ];
    return (
      <Dropdown
        options={options}
        placeholder="Status"
        style={{ width: "10vw", height: "3vw" }}
        value={allLeavePolicy[index].status}
        onChange={(e) => statusChangeHandler(index, e.value)}
      />
    );
  };
  const editPolicy = (data) => {
    return (
      <Button
        className="pl-1"
        icon="pi pi-pencil"
        onClick={() => {
          getDesignation();
          setEditLeavePolicy(data);
          setEditLeavePolicyDialog(true);
          setNotInclues(data.notIncludes);
        }}
      />
    );
  };
  const editSubmitHandler = async () => {
    const data = {
      ...editLeavePolicy,
      notIncludes: notIncludes,
      carryForward: rollOver,
    };
    try {
      const URL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      await putWithAuth(URL, data, token);
      getAllPolicy();
      setNotInclues([]);
      getDesignation();
      setEditLeavePolicyDialog(!editLeavePolicyDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Add Leave Policy"
        visible={addLeavePolicyDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          getDesignation();
          setAddLeavePolicyDialog(!addLeavePolicyDialog);
          setLeavePolicy({});
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Title"
                name="title"
                label="Title"
                type="text"
                onChange={changeHandler}
              ></Input>
            </div>

            <div className="col-6">
              <Dropdown
                options={typeDropdown}
                placeholder="Type"
                style={{ width: "50vw" }}
                value={leavePolicy.type}
                onChange={(e) =>
                  setLeavePolicy({ ...leavePolicy, type: e.value })
                }
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Leave Count"
                name="count"
                label="Leave Count"
                type="number"
                onChange={changeHandler}
              ></Input>
            </div>
            <div className="col-6">
              <MultiSelect
                placeholder="Not Applicable For"
                options={designation}
                value={notIncludes}
                onChange={(e) => setNotInclues(e.value)}
              />
            </div>
            <div className="col-6  mt-3">
              <Input
                className="asset-input"
                placeholder="Max"
                name="thresholdMonths"
                label="Max"
                type="number"
                onChange={changeHandler}
              ></Input>
            </div>
          </div>

          <div className="grid mt-3 ml-2">
            Enable Roll Over / Carry Forward
            <InputSwitch
              className="ml-2"
              checked={rollOver}
              name="visible"
              onChange={(e) => {
                setRollOver(e.value);
              }}
            />
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={submitDisable}
            ></Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Leave Policy"
        visible={showLeavePolicyDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setShowLeavePolicyDialog(!showLeavePolicyDialog);
        }}
      >
        <div>
          <Button
            onClick={() => {
              getDesignation();
              setAddLeavePolicyDialog(!addLeavePolicyDialog);
            }}
            className="primary flex flex-row mb-3"
            label="Add"
          ></Button>
        </div>
        <div className="table-box leave-policy-table">
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              responsiveLayout="scroll"
              value={allLeavePolicy}
            >
              <Column field="title" header="Name"></Column>
              <Column field="count" header="Leave Count"></Column>
              <Column field="type" header="Type"></Column>
              <Column field={getStatus} header="Status"></Column>
              <Column field={editPolicy} header="Action"></Column>
            </DataTable>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Edit Leave Policy"
        visible={editLeavePolicyDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setNotInclues([]);
          setEditLeavePolicyDialog(!editLeavePolicyDialog);
          setEditLeavePolicy({});
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Title"
                name="title"
                label="Title"
                type="text"
                value={editLeavePolicy.title}
                onChange={editChangeHandler}
              ></Input>
            </div>

            <div className="col-6">
              <Dropdown
                options={typeDropdown}
                placeholder="Type"
                style={{ width: "50vw" }}
                value={editLeavePolicy.type}
                onChange={(e) =>
                  setEditLeavePolicy({ ...editLeavePolicy, type: e.value })
                }
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Leave Count"
                name="count"
                label="Leave Count"
                type="number"
                value={editLeavePolicy.count}
                onChange={editChangeHandler}
              ></Input>
            </div>
            <div className="col-6">
              <MultiSelect
                placeholder="Not Applicable For"
                options={designation}
                value={notIncludes}
                onChange={(e) => setNotInclues(e.value)}
              />
            </div>
            <div className="col-6  mt-3">
              <Input
                className="asset-input"
                placeholder="Max"
                name="thresholdMonths"
                label="Max"
                type="number"
                value={editLeavePolicy.thresholdMonths}
                onChange={editChangeHandler}
              ></Input>
            </div>
          </div>

          <div className="grid mt-3 ml-2">
            Enable Roll Over / Carry Forward
            <InputSwitch
              className="ml-2"
              checked={editLeavePolicy.carryForward}
              name="visible"
              onChange={(e) => {
                setEditLeavePolicy({
                  ...editLeavePolicy,
                  carryForward: e.value,
                });
              }}
            />
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={editSubmitHandler}
              disabled={editSubmitDisable}
            ></Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import {
  deleteAssetCategoryApi,
  getAllCategoryApi,
  getAssetDepartmentApi,
} from "../../../store/features/HumanResource/asset";
import { useSelector } from "react-redux";
import Spinner from "../../../component/common/spinner/spinner";
import "./asset.scss";
import Image from "../../../utility/image";
import { SpeedDial } from "primereact/speeddial";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../../component/common/confirm-delete-dialog/deleteDialog";
import { Toast } from "primereact/toast";
import Button from "../../../component/common/button/button";
import InputIcon from "../../../component/common/inputIcon/inputIcon";
import { AssetCategoryDialog } from "./asset-category-dialog";
import Alphabet from "../../../component/common/alphabet/alphabet";
import {
  fn_firstcharacter,
  PaginationDropdown,
  isAdminAccess,
} from "../../../utility/utility";
import {
  setFrom,
  setSearch,
  setSkip,
  setTo,
  setLimit,
} from "../../../store/features/HumanResource/asset";
import { Dropdown } from "primereact/dropdown";
import SearchIconBar from "../../../component/common/search-icon-searchBar/searchIconBar";
import ReadMore from "../../../component/common/read-more/readMore";

export const AssetCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();
  const { getAllAsset, isLoading, skip, limit, from, to, search, maxRecord } =
    useSelector((store) => store.assets);
  const hrAdmin = isAdminAccess("Human Resource");
  const [getAssetCategory, setAssetCategory] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [createDialog, setCreateDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const usersAdmin = isAdminAccess("Human Resource");


  useEffect(() => {
    dispatch(getAllCategoryApi());
  }, []);

  useEffect(() => {
    setAssetCategory(getAllAsset);
  }, [getAllAsset]);

  useEffect(() => {
    dispatch(getAllCategoryApi({ skip, limit, search }));
  }, [skip, limit, to]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getAllCategoryApi({ skip, limit, search }));
      dispatch(setFrom(1));
      dispatch(setTo(10));
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [search]);

  const searchFn = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const handlePrevious = () => {
    if (skip > 1) {
      dispatch(setSkip(skip - 1));
      dispatch(setFrom(from - limit));
      dispatch(setTo(to - limit));
    }
  };

  const handleNext = () => {
    if (maxRecord >= to) {
      dispatch(setSkip(skip + 1));
      dispatch(setFrom(from + limit));
      dispatch(setTo(to + limit));
    }
  };

  //pagination
  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3" onClick={handlePrevious}>
              Previous
            </span>
          </button>
          <button type="button" className={options.className}>
            <span className="p-3" onClick={handleNext}>
              Next
            </span>
          </button>
        </>
      );
    },
  };

  const AssetName = ({ item }) => {
    return (
      <div className="flex align-items-center column-gap-2">
        {item.assetImage !== "" ? (
          <img
            className="border-circle"
            src={item.assetImage}
            alt={item.assetName}
            width={40}
            height={40}
          />
        ) : (
          <Alphabet letter={fn_firstcharacter(item.assetName)} />
        )}
        <p className="table_content_txt">{item.assetName}</p>
      </div>
    );
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setCreateDialog(!createDialog);
        setIsEditMode(true);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const Action = (item) => {
    return (
      <SpeedDial
        model={items}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
        onClick={() => setActionItem(item)}
      />
    );
  };

  const handleDelete = async () => {
    dispatch(deleteAssetCategoryApi({ _id: actionItem._id })).then((res) => {
      if (!res.error) {
        setDeleteDialog(!deleteDialog);
        dispatch(getAllCategoryApi({skip,limit,search}));
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Something went wrong",
        });
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {createDialog && (
        <AssetCategoryDialog
          visible={createDialog}
          setVisible={setCreateDialog}
          dispatch={dispatch}
          preFillValue={actionItem}
          isEditMode={isEditMode}
        />
      )}

      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          template={`Are you sure , want to delete ${actionItem.assetName} Asset Category`}
          onClick={handleDelete}
        />
      )}
      {isLoading ? (
        <div className="flex align-items-center justify-content-center h-screen">
          <Spinner value={isLoading} />
        </div>
      ) : (
        <div className="m-2 flex flex-column row-gap-4">
          <div className="flex align-items-center justify-content-end category-create-btn">
            <SearchIconBar
              placeholder="Search"
              value={search}
              onChange={(e) => {
                searchFn(e);
              }}
            />
            <div className="ml-6">
              {hrAdmin && (
                <Button
                  label="Create"
                  onClick={() => {
                    setActionItem({});
                    setIsEditMode(false);
                    setCreateDialog(!createDialog);
                  }}
                  className="create-btn"
                />
              )}
            </div>
          </div>

          <div className="table-box">
            <div className="asset-table-size">
              <DataTable
                className="asset-table-font"
                value={getAssetCategory}
                responsiveLayout="scroll"
                paginator
                paginatorTemplate={paginate}
                rows={limit}
                paginatorClassName="justify-content-end custom-dropdown"
              >
                <Column
                  field={(asset) => <AssetName item={asset} />}
                  header="Asset Name"
                />
                <Column field="assetPrefix" header="Asset Prefix " />
                <Column
                  field="assetDepartment.departmentName"
                  header="Category"
                />
                <Column field="totalAssets" header="Total Items" />
                <Column field="assetsInUse" header="In Use" />
                <Column
                  field="totalAssetsMaintenance"
                  header="In maintenance"
                />
                <Column
                body={(item) => (
                <div style={{ width: "300px", wordWrap: "break-word" }}>
                  <ReadMore text={item?.description} maxLength={60} />
                </div>
              )}
              header="Description" />
            
                <Column field="createdAt" header="Added on" />
                <Column
                  field={({ assetName, _id }) => (
                    <div
                      className="right_arrow flex align-items-center justify-content-center"
                      onClick={() => navigate(`/hr/assets/${_id}/${assetName}`)}
                    >
                      <img
                        src={Image.right_arrow}
                        alt="arrow"
                        width={15}
                        height={15}
                      />
                    </div>
                  )}
                  header=""
                />
              {usersAdmin && <Column body={Action} header="Action"></Column>}
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import Button from "../../../component/common/button/button";
import { ErrorToast } from "../../../component/common/error-component/errorToast";
import "./billingDetails.scss";
import { TransactionHistory } from "./Transaction/history";
import { Divider } from "primereact/divider";
import lockIcon from "../../../assets/lock.svg";
import { useDispatch } from "react-redux";
import {
  billingDetails,
  cancelSubcription,
} from "../../../store/features/Auth/login";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../../component/common/spinner/spinner";
import {
  ChipHighBtn,
  ChipLowBtn,
  ChipMediumBtn,
} from "../../../component/common/chip/chip";
import DeleteDialog from "../../../component/common/confirm-delete-dialog/deleteDialog";
import { UpgradePlan } from "./upgrade-plan/upgradePlan";
import { getUsers } from "../../../store/features/Users/User";
import { planWithFiveMembers, selectPlanMode } from "../../../utility/utility";

export const BillingDetails = () => {
  // orgID for squashApps = 5d032ec3fa0737140d022990
  const dispatch = useDispatch();
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { stripBillingDetails } = useSelector((store) => store.user);
  const {
    status,
    stripeSubscriptionEndDate,
    stripeCurrency,
    stripePlanAmount,
    stripePlanType,
    totalUsers,
    stripeCustomerId,
    stripeSubscriptionId,
  } = stripBillingDetails;
  const [loader, setloader] = useState(false);
  const [upgradeDialog, setUpgradeDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [user, setuser] = useState(0);
  const [selectPlanOption, setSelectPlanOption] = useState({});
  const currency_code = "₹";
  const [userAmount, setUserAmount] = useState(0);

  // we didnot charge any amount for the organication [squashApps]
  // const SQUASHAPPS__ORGANICATION = "642271642f1b9a203c414c95";
  // const SQUASHAPPS__ORGANICATION = "642271642f1b9a203c414c95";
  const SQUASHAPPS__ORGANICATION =
    process.env.REACT_APP_SQUASHAPPS_ORGANICATION_ID;

  const getBillingDetails = () => {
    setloader(true);
    if (memberDetails?.orgId)
      dispatch(billingDetails(memberDetails.orgId)).then(() =>
        setloader(false)
      );
    else setloader(false);
  };
  useEffect(() => {
    if (totalUsers <= 5) {
      setSelectPlanOption(() => {
        return planWithFiveMembers;
      });
    } else {
      setSelectPlanOption(() => {
        return selectPlanMode;
      });
    }
  }, [totalUsers]);

  useEffect(() => {
    if (Object.keys(selectPlanOption).length && stripePlanType) {
      setUserAmount((prev) => {
        prev = 0;
        return selectPlanOption.plan[stripePlanType];
      });
    }
  }, [selectPlanOption, stripePlanType]);

  useEffect(() => {
    if (memberDetails?.orgId !== SQUASHAPPS__ORGANICATION) {
      getBillingDetails();
    } else {
      const payload = {
        search: "",
        skip: 1,
        limit: 10,
        userStatus: "Active",
      };
      dispatch(getUsers(payload)).then((res) => {
        if (!res.error) setuser(res?.payload?.requestsCount);
      });
    }
  }, [memberDetails?.orgId]);

  const cancelSubscription = () => {
    const payload = {
      orgId: memberDetails?.orgId,
      customerId: stripeCustomerId,
      subscriptionId: stripeSubscriptionId,
    };
    dispatch(cancelSubcription(payload)).then((res) => {
      setCancelDialog(!cancelDialog);
      if (!res.error) getBillingDetails();
    });
  };

  const nextPayment = () => {
    const strip_endDate = moment(stripeSubscriptionEndDate).format(
      "MMMM Do YYYY"
    );
    const result = `${stripeCurrency?.toUpperCase()} 
   ${stripePlanAmount} on
   ${strip_endDate}`;
    return result;
  };

  useEffect(() => {
    billingStatus();
  }, [status]);

  const billingStatus = (status) => {
    if (status === "Trial") return <ChipMediumBtn label="Trial" />;
    else if (status === "Active") return <ChipLowBtn label="Active" />;
    else if (status === "Cancelled") return <ChipHighBtn label="Cancelled" />;
  };

  const trialDaysLeft = () => {
    const startDate = new Date().getDate();
    const result = new Date(stripeSubscriptionEndDate).getDate();
    return `${result - startDate} Days Left`;
  };
  const handleUpgradePlan = () => {
    setUpgradeDialog(!upgradeDialog);
  };

  return (
    <>
      {upgradeDialog && (
        <UpgradePlan
          visible={upgradeDialog}
          onHide={setUpgradeDialog}
          billingDetailsApi={getBillingDetails}
        />
      )}

      {cancelDialog && (
        <DeleteDialog
          template="Are you sure want to cancel the subscription"
          onClick={cancelSubscription}
          deleteDialog={cancelDialog}
          setDeleteDialog={setCancelDialog}
          title="Cancel Subcripion"
        />
      )}

      {!loader && (
        <div className="flex flex-column align-items-center row-gap-4">
          {/* {true && (
            <ErrorToast
              title="Recurring Payment Failed"
              description="Your recurring payment on credit card has failed. Please resolve this in 14 days to continue using Squadzen."
            />
          )} */}
          <div className="flex flex-column billings__details  w-full h-auto p-4">
            <div className="flex column-gap-2 w-full justify-content-between ">
              {memberDetails?.orgId !== SQUASHAPPS__ORGANICATION ? (
                <div className="flex flex-column row-gap-2">
                  <div className="flex align-items-center column-gap-2 pb-2">
                    <h2 className="billing__text">Billing</h2>
                    {billingStatus(status)}
                  </div>
                  <p className="Billing_txt">Current Plan</p>
                  <div className="flex align-items column-gap-2">
                    <h2 className="billing__text">{stripePlanType}</h2>
                    <p>({totalUsers} users)</p>
                  </div>
                  <p className="Billing_txt__light">
                    {currency_code}
                    {userAmount} per user. Unlimited Projects and Full Access
                  </p>
                  <p className="Billing_txt">Next Payment : {nextPayment()}</p>
                </div>
              ) : (
                <div className="flex flex-column row-gap-2">
                  <div className="flex align-items-center column-gap-2 pb-2">
                    <h2 className="billing__text">Billing</h2>
                    <ChipLowBtn label="Active" />
                  </div>
                  <p className="Billing_txt">Current Plan</p>
                  <div className="flex align-items column-gap-2">
                    <h2 className="billing__text">{"Annual"}</h2>
                    <p>({user} users)</p>
                  </div>
                  <p className="Billing_txt__light">
                    {currency_code}100 per user. Unlimited Projects and Full
                    Access
                  </p>
                </div>
              )}

              {memberDetails?.orgId !== SQUASHAPPS__ORGANICATION && (
                <div className="flex flex-column justify-content-end row-gap-4">
                  <div className="flex align-items-center column-gap-2">
                    {status === "Trial" && (
                      <div className="flex column-gap-2 align-items-center">
                        <i
                          className="pi pi-exclamation-circle"
                          style={{ color: "#FF006D", fontSize: "1rem" }}
                        />
                        <p className="highlight___text">{trialDaysLeft()}</p>
                      </div>
                    )}
                    {status !== "Cancelled" && (
                      <div className="flex align-items-center justify-content-end">
                        <Button
                          label="Upgrade Plan"
                          className="upgarde__plan primary"
                          onClick={handleUpgradePlan}
                        />
                      </div>
                    )}
                  </div>
                  {status !== "Cancelled" && (
                    <div className="flex align-items-center justify-content-end">
                      <button
                        className="p-2 info__btn"
                        onClick={() => {
                          setCancelDialog(!cancelDialog);
                        }}
                      >
                        Cancel Subscription
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Payment Method */}

            {/* <Divider />
            <div className="flex flex-column ">
              <label>Payment Method</label>
              <div className="flex align-items-center justify-content-between ">
                <div className="flex align-items-center column-gap-1">
                  <span className="payment__method">****** 5678</span>{" "}
                  <img src={lockIcon} alt="lock" width={15} height={15} />
                </div>
                <div className="flex align-items-center column-gap-5">
                  <div className="highlight___text">Remove</div>
                  <button className="p-2 info__btn">Change Card</button>
                </div>
              </div>
            </div> */}
          </div>

          {memberDetails?.orgId !== SQUASHAPPS__ORGANICATION && (
            <div className="flex flex-column billing__history billings__details w-full p-4 h-auto">
              <h2 className="p-2 billing__text ">{"Billing History"}</h2>
              <TransactionHistory subscriptionId={stripeSubscriptionId} />
            </div>
          )}
        </div>
      )}
      {loader && (
        <div className="flex align-items-center justify-content-center w-full h-full">
          <Spinner value={loader} />
        </div>
      )}
    </>
  );
};

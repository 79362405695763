import React from "react";
import { Avatar } from "primereact/avatar";

export default function AvatarToolTip({ label }) {
  return (
    <>
      {label?.length > 0 ? (
        <>
          <Avatar
            shape="circle"
            label={label.charAt(0).toUpperCase()}
            style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
          />
        </>
      ) : (
        <Avatar shape="circle" icon="pi pi-user" />
      )}
    </>
  );
}

export const AvatarImg = ({image}) => {
  return(
    <Avatar
     shape="circle"
     image={image}  />
  )
}
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  getAllProjects,
  deleteProject,
  getProjectDetailsById,
  activateProject,
} from "../../store/features/Projects/Project";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import { SpeedDial } from "primereact/speeddial";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import {
  setFrom,
  setTo,
  setSkip,
  setLimit,
} from "./../../store/features/Projects/Project";
import UpdateProject from "./updateProject";
import { useNavigate } from "react-router-dom";
import {
  PaginationDropdown,
  fn_firstcharacter,
  isAdminAccess,
} from "../../utility/utility";
import Alphabet from "../common/alphabet/alphabet";
import ProgressBar from "../common/progress-bar/progressBar";
import AvatarGroup from "../common/avatar-group/avatarGroup";
import { InputSwitch } from "primereact/inputswitch";
import EmptyScreen from "../common/empty/emptyScreen";
import emptyProject from "../../assets/empty-project.svg";
import { setProjectId } from "../../store/features/Timesheet/timesheet";

export default function ProjectListTable() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const {
    allprojects,
    skip,
    to,
    maxRecord,
    search,
    from,
    limit,
    projectStatus,
  } = useSelector((store) => store.project);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateProjectDialog, setUpdateProjectDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [update, setUpdate] = useState(false);
  const { assignedProjects } = useSelector((store) => store.project);
  const usersAdmin = isAdminAccess("Project");

  function projectNavigateHandler(data) {
    const index = assignedProjects.findIndex(
      (project) => project._id == data._id
    );
    if (index !== -1 || usersAdmin) {
      dispatch(setProjectId(data._id));
      dispatch(getProjectDetailsById(data._id));
      Navigate("/project/" + data._id);
    }
  }

  function projectName(data) {
    return (
      <div
        className="flex align-items-center"
        onClick={() => projectNavigateHandler(data)}
      >
        {data?.projectDetails?.companyLogo ? (
          <img
            className="projects-img-size flex"
            src={data?.projectDetails?.companyLogo}
          />
        ) : (
          <Alphabet
            letter={fn_firstcharacter(data?.projectDetails?.projectName)}
          />
        )}
        <div className="ml-2 dataTable__Text">
          {data?.projectDetails?.projectName}
        </div>
      </div>
    );
  }

  function projectLead(lead) {
    if (lead?.leadInfo?.basic?.fullName) {
      return (
        <div className="flex align-items-center">
          <Alphabet letter={fn_firstcharacter(lead.leadInfo?.basic.fullName)} />
          <div className="ml-2 dataTable__Text">
            {" "}
            {lead?.leadInfo?.basic?.fullName}{" "}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  function clientName(client) {
    if (client?.clientDetails?.basic?.fullName) {
      return (
        <div className="flex align-items-center">
          <Tooltip target=".disabled-button" />
          <div
            className="disabled-button "
            data-pr-tooltip={client?.clientDetails?.basic?.fullName}
            data-pr-position="bottom"
            data-pr-classname="blue-tooltip"
          >
            <Alphabet
              letter={fn_firstcharacter(client?.clientDetails?.basic?.fullName)}
            />
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  function totalSprints(sprint) {
    return (
      <div className="flex ml-5 align-items-center dataTable__Text">
        {sprint.totalSprints}
      </div>
    );
  }

  function sprintProgress(bar) {
    const activeSprint = bar.sprints.filter((active) => {
      return active.status === "Active";
    });
    return (
      <>
        <ProgressBar
          width={100}
          height={15}
          value={bar?.percentage}
          maxValue={100}
          color="#5a78ee"
        />
        {activeSprint?.map((status, index) => {
          return (
            <div key={index}>
              <div className="mt-1 label-font">
                current sprint: {status.title}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function deActivateController(item) {
    return (
      <InputSwitch
        checked={projectStatus === "Active" ? true : false}
        onChange={() => {
          setUpdateItem(item);
          projectStatusChangeHandler(item);
        }}
      />
    );
  }

  function projectStatusChangeHandler(item) {
    if (projectStatus === "Active") setDeleteDialog(true);
    else activateHandler(item);
  }

  let items = [];
  if (usersAdmin) {
    items = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          setUpdateProjectDialog(!updateProjectDialog);
        },
      },
    ];
  }

  const Action = (data) => (
    <div className="projectmembers-action">
      <SpeedDial
        model={items}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
        onClick={() => {
          setUpdateItem(data);
          setUpdate(!update);
        }}
      />
    </div>
  );

  const deleteHandler = async () => {
    try {
      let data = updateItem;
      const { payload } = dispatch(deleteProject(data._id));
      dispatch(getAllProjects({ skip, limit, search }));
      setDeleteDialog(!deleteDialog);
      toast.current.show({
        severity: "success",
        summary: "Success message",
        detail: "Deleted Successfully",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  
  const activateHandler = async (item) => {
    try {
      const { payload } = dispatch(activateProject(item._id));
      dispatch(getAllProjects({ skip, limit, search, projectStatus }));
      toast.current.show({
        severity: "success",
        summary: "Success message",
        detail: "Project Activated Successfully",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const handlePrevious = () => {
    dispatch(setSkip(skip - 1));
    dispatch(setFrom(from - limit));
    dispatch(setTo(to - limit));
  };

  const handleNext = () => {
    dispatch(setSkip(skip + 1));
    dispatch(setFrom(from + limit));
    dispatch(setTo(to + limit));
  };

  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = PaginationDropdown;

      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={handlePrevious}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={handleNext}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  return (
    <div>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
          template="Are you sure you want to deactivate this project?"
        />
      )}

      {updateProjectDialog && (
        <UpdateProject
          updateProjectDialog={updateProjectDialog}
          setUpdateProjectDialog={setUpdateProjectDialog}
          selectedItem={updateItem}
          setSelectedItem={setUpdateItem}
        />
      )}

      <div className="table-box mt-4">
        <div className="asset-table-size">
          {allprojects?.length ? (
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              value={allprojects}
              responsiveLayout="scroll"
              paginator
              paginatorTemplate={paginate}
              rows={limit}
              paginatorClassName="justify-content-end custom-dropdown"
            >
              <Column field={projectName} className="hover" header="Project" />
              <Column field={projectLead} header="Project Lead" />
              <Column
                field={(item) => (
                  <AvatarGroup item={item.members} size="medium" />
                )}
                header="Members"
              />
              <Column field={totalSprints} header="Total Sprints" />
              <Column body={clientName} header="Client" />
              <Column body={sprintProgress} header="Sprint Progress" />
              {usersAdmin && (
                <Column body={deActivateController} header="Deactivate" />
              )}
              {usersAdmin && <Column body={Action} header="Action"></Column>}
            </DataTable>
          ) : (
            <div
              style={{ minHeight: "70vh" }}
              className="flex justify-content-center align-items-center flex-column"
            >
              <EmptyScreen
                title="Time to create a Project"
                img={emptyProject}
                description="From tasks and time tracking to files and conversations, 
                everything you need to organize and complete projects in one place"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Input from "../../common/inputtext/input-field";
import { useSelector } from "react-redux";
import Alphabet from "../../common/alphabet/alphabet";
import {
  fn_firstcharacter,
} from "../../../utility/utility";
import { Tooltip } from "primereact/tooltip";
import ReadMore from "../../common/read-more/readMore";
import moment from "moment";

export default function TaskComments({ setUpdateValue, comments }) {
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const [comment, setComment] = useState("");

  const handleAddComment = ({key , keyCode }) => {
    if (key === "Enter" && keyCode === 13) {
      setComment("");
      const commentData = {
        comment,
        member: memberDetails.fullName,
        time: `${new Date()}`,
        postedBy: memberDetails.memberOrgId,
      };
      setUpdateValue((prev) => {
        return { ...prev, comments: [...comments, commentData] };
      });
    }
  };

  return (
    <div className="flex flex-column row-gap-3 mt-2">
      <Tooltip target=".task" />
      <Input
        name="comments"
        onKeyDown={handleAddComment}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
      <div className="flex flex-column row-gap-1 align-items-center">
        {comments.map(({ member, time, comment }, id) => {
          return (
            <div className="flex  justify-content-between w-full px-1" key={id}>
              <div className="col-8 flex column-gap-2">
                <div
                  className="task flex align-items-center justify-content-center"
                  data-pr-tooltip={member}
                  data-pr-position="bottom"
                  data-pr-classname="blue-tooltip"
                >
                  <Alphabet letter={fn_firstcharacter(member)} />
                </div>
                <span
                  style={{ color: "#82858d" }}
                  className="flex align-items-center justify-content-center"
                >
                  <ReadMore text={comment} maxLength={60} />
                </span>
              </div>
              <div className="col-4 time__font flex align-items-center">
                {moment(time).format("DD MMM YYYY h:mm a")}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { SpeedDial } from "primereact/speeddial";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import bigFolder from "../../../assets/big-folder.svg";
import smallFolder from "../../../assets/small-folder.svg";
import {
  deleteFile,
  getFilesList,
  setProjectId,
} from "../../../store/features/Projects/File";
import EmptyScreen from "../../common/empty/emptyScreen";

export default function FolderCard() {
  const { projectId: projectParamsId } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const allfilesStore = useSelector((store) => store.file);
  const { allFiles, projectId } = useSelector((store) => store.file);

  useEffect(() => {
    if (projectParamsId) {
      dispatch(setProjectId(projectParamsId));
      dispatch(getFilesList({projectId,search:""}));
    }
  }, [projectParamsId]);
 

  //Action button
  let items = [
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      let data = updateItem;
      dispatch(deleteFile(data._id)).then(() => {
        setDeleteDialog(!deleteDialog);
        dispatch(getFilesList({projectId,search:""}));
        toast.current.show({
          severity: "success",
          summary: "Success message",
          detail: "Deleted Successfully",
          life: 3000,
        });
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}

      {/* Big Folder Icon */}

      {allFiles?.length > 0 ? (
        <div className="flex flex-wrap" style={{ maxWidth: "1440px" }}>
          {allFiles?.map((file, id) => {
            return (
              <div key={id} className="pt-4 pl-3">
                <img src={bigFolder} />
                <div className="flex justify-content-between folder-date-align">
                  <div className="folder-date-font">
                    {new Date(file?.createdAt).toLocaleDateString("en-uk", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </div>

                  <div className="folder-action-btn-align">
                    <SpeedDial
                      model={items}
                      radius={50}
                      direction="right"
                      showIcon="pi pi-ellipsis-v"
                      hideIcon="pi pi-times"
                      buttonClassName="p-button-outlined folder-action-btn-design"
                      onClick={() => {
                        setUpdateItem(file);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="flex folder-name-align cursor-pointer"
                  onClick={() => {
                    navigate(`/project/${projectId}/files/${file._id}`);
                  }}
                >
                  <img src={smallFolder} alt="folder" />
                  <div className="folder-name-font ml-1">{file.folderName}</div>
                </div>
              </div>
            );
          })}{" "}
        </div>
      ) : (
        <>
          <div className="flex justify-content-center align-items-center">
            <EmptyScreen img={bigFolder} description="No Folder Available" />
          </div>
        </>
      )}
    </div>
  );
}

import SquadzenLogo from "../assets/squadzen-logo.svg";
import EmptyImg from "../assets/leave-empty.svg";
import emptySprint from "../assets/sprint-empty-state.svg";
import emptyWiki from "../assets/empty-wiki-page.svg";
import emptyAvatar from "../assets/empty-avatar.svg";
import emptyNotification from "../assets/empty-announcement.svg";
import emptyAsset from "../assets/empty-asset.svg";

import emptyBoard from "../assets/empty-board.svg";
import logo from "../assets/squadzen-logo.svg";

import bugImage from "../assets/bug.svg";
import storyImage from "../assets/story.svg";
import taskImage from "../assets/task.svg";

import lowPriorityImg from "../assets/Low.png";
import MediumPriorityImg from "../assets/Medium.png";

import todoImg from "../assets/To Do.svg";
import documentImage from "../assets/document.svg";

import addBtn from "../assets/add-new.svg";

import notifyBell from "../assets/announce-bell.svg";
import notify from "../assets/notifyBell.svg";
import birthdayBell from "../assets/birthday-cake.svg";

import settingIcon from "../assets/setting.svg";

import redBalloon from "../assets/red-balloon.svg";
import blueBalloon from "../assets/blue-balloon.svg";
import orangeBalloon from "../assets/orange-baloon.svg";

import leftLeaf from "../assets/leaf-left.svg";
import rightLeaf from "../assets/leaf-right.svg";

import recognition from "../assets/recognition.svg";

import rightArrow from "../assets/right-arrow.svg";
import downArrow from "../assets/down-arrow-2.svg";

import userIcon from "../assets/user.svg";
import dateIcon from "../assets/date.svg";
import chartIcon from "../assets/chart.svg";
import timeIcon from "../assets/time.svg";

import deleteIcon1 from "../assets/delete-2.svg";
import deleteIcon2 from "../assets/delete-icon.svg";
import deleteIcon3 from "../assets/delete-image.svg";
import deleteIcon4 from "../assets/delete.svg";

import PrivateIcon from "../assets/private-icon.svg";
import pinkArrow from "../assets/arrow-p.svg";
import blueArrow from "../assets/arrow-b.svg";

import memberLogo from "../assets/pink-users.svg";

import excelImg from "../assets/excel.svg";
import pdfImg from "../assets/pdf.svg";
import emptyLeave from "../assets/empty-leave-list.svg";

import B_downArrow from "../assets/round-down-arrow.svg";
import B_leftArrow from "../assets/round-left-arrow.svg";

// profile Field Image
import userFullname from "../assets/user-fullname.svg";
import userEmail from "../assets/user-email.svg";
import userPhone from "../assets/user-phone.svg";
import userDob from "../assets/user-dob.svg";
import userGender from "../assets/user-gender.svg";
import userBlood from "../assets/user-blood.svg";
import userDepartment from "../assets/user-department.svg";
import userNationality from "../assets/user-nationality.svg";
import userDoj from "../assets/user-doj.svg";
import userDesignation from "../assets/user-designation.svg";
import userLocation from "../assets/user-location.svg";

import roleTriangle from "../assets/triangle.svg";
import roleRound from "../assets/round.svg";
import roleRectangle from "../assets/rectangle.svg";

import blueDot from "../assets/blue-round.svg";
import pinkDot from "../assets/pink-round.svg";

import companyLogo from "../assets/company-logo.png";
import right_arrow from "../assets/ra.svg";
import uploadImg from "../assets/upload.svg"

import assign_user   from "../assets/assign-user.svg"
import delete_asset   from "../assets/delete_red.svg"
import maintanance_history   from "../assets/maintanance_history.svg"
import maintanance   from "../assets/maintanance.svg"
import print from "../assets/print.svg"
import disable   from "../assets/disable.svg"
import reject   from "../assets/reject.svg"
import approve   from "../assets/approve.svg"
import wrench   from "../assets/wrench.svg"
import danger   from "../assets/danger.svg"
import down_arrow from "../assets/down-arrow.svg";
import up_arrow from "../assets/up-arrow.svg"
import payslip_total_employee from "../assets/payslip-total-employee.svg"
import working_hours from "../assets/working-hours.svg"
import payroll_cost from "../assets/payroll-cost.svg"
import salary from "../assets/salary.svg"
import deduction from "../assets/deduction.svg"
import average_salary from "../assets/average-salary.svg"
import wallet from "../assets/wallet.svg"
import wallet_Ellipse from "../assets/wallet-Ellipse.svg"
import Doughnut from "../assets/Doughnut.svg"
import orange_indication from "../assets/orange_indication.svg"
import blue_indication from "../assets/blue_indication.svg"
import netpay_in from "../assets/netpay_in.svg"

const Image = {
  danger,
  wrench,
  approve,
  reject,
  disable,
  maintanance,
  maintanance_history,
  delete_asset,
  assign_user,
  uploadImg,
  right_arrow,
  companyLogo,
  emptyBoard,
  notify,
  blueDot,
  pinkDot,
  timeIcon,
  roleTriangle,
  roleRound,
  roleRectangle,
  B_downArrow,
  B_leftArrow,
  userFullname,
  userEmail,
  userPhone,
  userDob,
  userGender,
  userBlood,
  userDepartment,
  userNationality,
  userDoj,
  userDesignation,
  userLocation,
  bugImage,
  storyImage,
  taskImage,
  lowPriorityImg,
  MediumPriorityImg,
  emptyAvatar,
  todoImg,
  documentImage,
  EmptyImg,
  addBtn,
  notifyBell,
  birthdayBell,
  settingIcon,
  logo,
  emptyNotification,
  redBalloon,
  blueBalloon,
  orangeBalloon,
  leftLeaf,
  rightLeaf,
  recognition,
  emptyWiki,
  rightArrow,
  downArrow,
  emptySprint,
  userIcon,
  dateIcon,
  chartIcon,
  deleteIcon1,
  deleteIcon2,
  deleteIcon3,
  deleteIcon4,
  PrivateIcon,
  pinkArrow,
  blueArrow,
  memberLogo,
  SquadzenLogo,
  excelImg,
  pdfImg,
  emptyLeave,
  emptyAsset,
  print,
  down_arrow,
  up_arrow,
  payslip_total_employee,
  working_hours,
  payroll_cost,
  salary,
  deduction,
  average_salary,
  wallet,
  wallet_Ellipse,
  Doughnut,
  orange_indication,
  blue_indication,
  netpay_in
};

export default Image;

import React, { useState, useEffect, useRef } from "react";
import { Password } from "primereact/password";
import Button from "../../component/common/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  resetStatus,
  setIsLoading,
} from "../../store/features/Auth/changePassword";
import validate from "../../factory-function/validate";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { status, error, isLoading } = useSelector(
    (store) => store.changePassword
  );
  const toast = useRef(null);
  const { verificationCode, email } = useSelector((store) => store.otpVerify);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorDetails, setErrorDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(password).every((val) => val !== "");
    dispatch(setIsLoading(x && y));
  }, [errorDetails]);

  function changeHandler(e) {
    setPassword({ ...password, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      setErrorDetails({
        ...errorDetails,
        [e.target.name]: validate(
          { [e.target.name]: e.target.value },
          password.password
        ),
      });
    } else {
      setErrorDetails({
        ...errorDetails,
        [e.target.name]: validate({ [e.target.name]: e.target.value }),
      });
    }
  }

  const submitHandler = () => {
    const postDetails = {
      ...password,
      verificationCode,
      email,
    };
    dispatch(changePassword(postDetails)).then(res => {
      dispatch(resetStatus());
      if(!res.error){
        toast.current.show({
          severity: "success",
          summary: res.payload?.user?.message,
          detail: "Message Content",
          life: 3000,
        });
        setTimeout(() => {
          Navigate("/auth/login");
        }, 700);
      }
      else{
        toast.current.show({
          severity: "error",
          summary: "somethig went wrong",
          detail: "Message Content",
          life: 3000,
        });
      }
    })
  }

  return (
    <div className="flex align-items-center justify-content-center h-screen">
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-3 w-full">
        <div className="flex flex-column row-gap-2">
          <div className="h1-font">Forgot Password</div>
          <div className="paragraph-font">
            Fill the form to reset your password
          </div>
        </div>

        <div className="">
          <Password
            placeholder="New Password"
            name="password"
            onChange={changeHandler}
            label="Password"
            toggleMask
          />
          <span className="p-float-label password-text error-text">
            {errorDetails.password}
          </span>
        </div>
        <div className="">
          <Password
            placeholder="Confirm New Password"
            name="confirmPassword"
            onChange={changeHandler}
            label="Confirm Password"
            toggleMask
            feedback={false}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />
          <span className="p-float-label password-text error-text">
            {errorDetails.confirmPassword}
          </span>
        </div>
        <div className=" flex justify-content-end">
          <Button
            onClick={submitHandler}
            disabled={isLoading}
            label="Submit"
          />
        </div>
      </div>
    </div>
  );
}

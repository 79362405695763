import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTransactionHistory } from "../../../../store/features/Auth/login";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../../../component/common/spinner/spinner";

const table_column = {
  fontSize: "12px",
  color: "#87898a",
  fontWeight: "400",
};
const tabel_data = {
  fontSize: "14px",
  color: "#252b30",
  fontWeight: "500",
};

const transactionTable = [
  "DATE",
  "TYPE",
  "INVOICE NO",
  "PRICE",
  "INVOICE DOWNLOAD",
];

export const TransactionHistory = ({ subscriptionId }) => {
  const dispatch = useDispatch();
  const { transactionHistory } = useSelector((store) => store.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (subscriptionId) {
      setIsLoading(true);
      dispatch(getTransactionHistory(subscriptionId)).then(() =>
        setIsLoading(false)
      );
    }
  }, [subscriptionId]);

  const handleDownload = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {isLoading ? (
        <div className="flex align-items-center justify-content-center w-full h-full">
          <Spinner value={isLoading} />
        </div>
      ) : (
        <>
          {" "}
          <div className="flex">
            {transactionTable.map((column, id) => {
              return (
                <div
                  key={id}
                  className={`flex ${
                    column === transactionTable[2] ||
                    column === transactionTable[0]
                      ? "col-3"
                      : "col-2"
                  }`}
                  style={table_column}
                >
                  {column}
                </div>
              );
            })}
          </div>
          {transactionHistory.map((t, id) => {
            return (
              <div className="flex align-items-center" key={id}>
                <div className="col-3" style={tabel_data}>
                  {moment(t?.created).format("DD MMMM YYYY")}
                </div>
                <div className="col-2" style={tabel_data}>
                  {t.plan}
                </div>
                <div className="col-3" style={tabel_data}>
                  {t.invoice_number}
                </div>
                <div className="col-2" style={tabel_data}>
                  ₹ {t.amount}
                </div>
                <div className="col-2 text-center" style={tabel_data}>
                  <i
                    className="pi pi-download cursor-pointer"
                    style={{ fontSize: "1rem" }}
                    onClick={() => handleDownload(t.invoice_pdf)}
                  />
                </div>
              </div>
            );
          })}{" "}
        </>
      )}
    </>
  );
};

export default function getFirstAndLastDayOfMonth(dateValue){
    
    var date = new Date();
  date.setMonth(dateValue.getMonth())
  date.setFullYear(dateValue.getFullYear());
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const from=`${firstDay.getFullYear()}-${firstDay.getMonth()+1}-01`
  const to=`${lastDay.getFullYear()}-${lastDay.getMonth()+1}-${lastDay.getDate()}`
  return {from,to}
  }
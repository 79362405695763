import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getWithAuth, postWithAuth } from "../../services/authentication";
import constants from "./../../constants";
import settingsIcon from "./../../assets/settings.svg";
import Leavepolicy from "../../component/human-resource/leave-policy";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Button from "../../component/common/button/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import LeaveRequestDialog from "../../component/human-resource/leave-request-dialog";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import emptyAsset from "./../../assets/empty-recognition.svg";
import Spinner from "../../component/common/spinner/spinner";
import FilterDialog from "../../component/human-resource/filterDialog";
import LeaveInfoDetails from "../../component/human-resource/leaveInfoDetails";
import SearchIconBar from "../../component/common/search-icon-searchBar/searchIconBar";
import Alphabet from "../../component/common/alphabet/alphabet";
import {
  DateFormat,
  fn_firstcharacter,
  isAdminAccess,
} from "../../utility/utility";
import ReadMore from "../../component/common/read-more/readMore";

export default function LeaveRequest() {
  const hrAdmin = isAdminAccess("Human Resource");
  const [leaveRequest, setLeaveRequest] = useState([]);
  const [showLeavePolicyDialog, setShowLeavePolicyDialog] = useState(false);
  const [applyLeaveDialog, setApplyLeaveDialog] = useState(false);
  const [leavePolicyMember, setLeavePolicyMember] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [createLeaveRequest, setCreateLeaveRequest] = useState({
    from: "",
    leavePolicyId: "",
    to: "",
    reason: "",
  });
  const [submitDisable, setSubmitDisable] = useState(true);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [approveDialogue, setApproveDialogue] = useState(false);
  const [leaveInfoDialog, setLeaveInfoDialog] = useState(false);
  const [update, setUpdate] = useState(false);
  const [spinner, setSpinner] = useState(false);

  //filter
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterValue, setFilterValue] = useState({
    from: "",
    to: "",
    leavePolicyId: "",
    status: "",
  });
  const [search, setSearch] = useState("");

  //pagination leave request
  const [maxRecord, setMaxRecord] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);

  const token = localStorage.getItem("token");
  const toast = useRef(null);

  useEffect(() => {
    let x = Object.values(createLeaveRequest).every((val) => val !== "");
    if (x === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [createLeaveRequest]);

  useEffect(() => {
    getLeaveRequest();
    getLeavePolicyMember();
  }, []);

  useEffect(() => {
    getLeaveRequest();
  }, [update]);

  const itemsWithoutStatus = [
    {
      label: "Approve",
      icon: "pi pi-check",
      command: () => {
        setApproveDialogue(!approveDialogue);
      },
    },
    {
      label: "Reject",
      icon: "pi pi-times",
      command: () => {
        setRejectDialog(!approveDialogue);
      },
    },
    {
      label: "Details",
      icon: "pi pi-info-circle",
      command: () => {
        infoDialog();
      },
    },
  ];

  const itemsWithStatus = [
    {
      label: "Details",
      icon: "pi pi-info-circle",
      command: () => {
        infoDialog();
        setLeaveInfoDialog(!leaveInfoDialog);
      },
    },
  ];

  async function getLeaveRequest(searchString) {
    try {
      setSpinner(true);
      let from = "";
      let to = "";
      let searchStr = "";
      if (filterValue.from) {
        from = new Date(filterValue.from).toLocaleDateString();
      }
      if (filterValue.to) {
        to = new Date(filterValue.to).toLocaleDateString();
      }
      if (searchString) {
        searchStr = searchString;
      } else {
        searchStr = search;
      }
      const response = await getWithAuth(
        constants.API.LEAVE_REQUEST.CREATE +
          `?skip=${skip}&limit=${limit}&leavePolicyId=${
            filterValue.leavePolicyId ? filterValue.leavePolicyId : ""
          }&status=${
            filterValue.status ? filterValue.status : ""
          }&from=${from}&to=${to}&searchString=${searchStr}`,
        token
      );
      setMaxRecord(response.data.requestsCount);
      setLeaveRequest(response.data.leaveRequests);
      setSpinner(false);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  async function getLeavePolicyMember() {
    try {
      const response = await getWithAuth(
        constants.API.LEAVE_REQUEST.GETLEAVEPOLICIESFORMEMBER,
        token
      );
      let leavePolicy = [];
      response.data.leavePolicys.forEach((policy) =>
        leavePolicy.push({ label: policy.title, value: policy._id })
      );
      setLeavePolicyMember(leavePolicy);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  async function changeHandler(e) {
    setCreateLeaveRequest({
      ...createLeaveRequest,
      [e.target.name]: e.target.value,
    });
  }

  async function submitHandler() {
    try {
      const url = constants.API.LEAVE_REQUEST.CREATE;
      await postWithAuth(url, createLeaveRequest, token);
      setCreateLeaveRequest({});
      getLeaveRequest();
      setApplyLeaveDialog(!applyLeaveDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  function employeeAvatar(emp) {
    return (
      <div className="flex align-items-center">
        <Alphabet letter={fn_firstcharacter(emp.userDetails.fullName)} />
        <div className="ml-2">{emp.userDetails.fullName}</div>
      </div>
    );
  }

  function days(day) {
    return <div className="ml-2">{day.days}</div>;
  }

  function leaveDate(emp) {
    let x;
    if (emp.days !== 1) {
      x = `${DateFormat(emp.from)}
    -
    ${DateFormat(emp.to)}`;
    } else {
      x = `${DateFormat(emp.from)}`;
    }
    return x;
  }

  const infoDialog = () => {
    setLeaveInfoDialog(true);
  };

  const handlePagination = (e) => {
    setLimit(e.value);
    setSkip(1);
    setFrom(1);
    setTo(e.value);
  };

  const status = (data) => {
    switch (data.status) {
      case "Approved":
        return (
          <div className="approved-btn">
            <div className="avatar-container-size">
              <div className="occupied-font leave-status-align">Approved</div>
            </div>
          </div>
        );
      case "Declined":
        return (
          <div className="approved-btn declined-btn">
            <div className="avatar-container-size">
              <div className="declined-font leave-status-align">Declined</div>
            </div>
          </div>
        );
      case "Pending":
        return (
          <div className="approved-btn pending-btn">
            <div className="avatar-container-size">
              <div className="requested-font leave-status-align">Pending</div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const Action = (data) => {
    return (
      <div className="leave-action-btn flex flex-wrap">
        <Tooltip position="left" />
        <SpeedDial
          onClick={() => {
            setUpdateItem(data);
          }}
          radius={40}
          direction="right"
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
          buttonClassName="p-button-outlined action-btn-design"
          model={
            data.status === "Pending" && hrAdmin
              ? itemsWithoutStatus
              : itemsWithStatus
          }
        />
      </div>
    );
  };

  //pagination leave request
  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => handlePagination(e)}
          />
        </>
      );
    },

    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip - 1);
              setFrom(from - limit);
              setTo(to - limit);
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              setSkip(skip + 1);
              setFrom(from + limit);
              setTo(to + limit);
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };
  useEffect(() => {
    getLeaveRequest();
  }, [limit, skip]);

  return (
    <>
      <Toast ref={toast} />
      {showLeavePolicyDialog && (
        <Leavepolicy
          showLeavePolicyDialog={showLeavePolicyDialog}
          setShowLeavePolicyDialog={setShowLeavePolicyDialog}
        />
      )}

      <LeaveRequestDialog
        approveDialogue={approveDialogue}
        setApproveDialogue={setApproveDialogue}
        updateItem={updateItem}
        setUpdateItem={setUpdateItem}
        rejectDialog={rejectDialog}
        setRejectDialog={setRejectDialog}
        update={update}
        setUpdate={setUpdate}
      />

      <Dialog
        header="Apply Leave"
        visible={applyLeaveDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateLeaveRequest({});
          setApplyLeaveDialog(!applyLeaveDialog);
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Dropdown
                options={leavePolicyMember}
                placeholder="Leave Type"
                required={true}
                value={createLeaveRequest.leavePolicyId}
                onChange={(e) =>
                  setCreateLeaveRequest({
                    ...createLeaveRequest,
                    leavePolicyId: e.value,
                  })
                }
              />
            </div>
            <div className="col-6">
              <InputTextarea
                placeholder="Reason"
                name="reason"
                label="Reason"
                type="text"
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                value={createLeaveRequest.from}
                maxDate={createLeaveRequest.to}
                readOnlyInput
                onChange={(e) =>
                  setCreateLeaveRequest({
                    ...createLeaveRequest,
                    from: new Date(e.value),
                  })
                }
              />
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                dateFormat="dd/mm/yy"
                value={createLeaveRequest.to}
                minDate={createLeaveRequest.from}
                readOnlyInput
                onChange={(e) =>
                  setCreateLeaveRequest({
                    ...createLeaveRequest,
                    to: new Date(e.value),
                  })
                }
              />
            </div>
            <div className="col-6"></div>
          </div>
          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={submitDisable}
            />
          </div>
        </div>
      </Dialog>
      {/* Info Table */}
      <LeaveInfoDetails
        updateItem={updateItem}
        leaveInfoDialog={leaveInfoDialog}
        setLeaveInfoDialog={setLeaveInfoDialog}
      />
      <div className="mr-2 flex flex-row-reverse justify-content-start">
        <div className="assets-actions">
          <div className="flex ml-5">
            <div className="search-bar flex align-items-center mr-6">
              <SearchIconBar
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  getLeaveRequest(e.target.value);
                }}
              />
            </div>
            <FilterDialog
              setFilterDialog={setFilterDialog}
              filterDialog={filterDialog}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              update={update}
              setUpdate={setUpdate}
            />

            <div className="flex flex-row-reverse mr-6">
              {hrAdmin && (
                <img
                  className="ml-3 mt-2 hover"
                  style={{ height: "22px" }}
                  src={settingsIcon}
                  onClick={() =>
                    setShowLeavePolicyDialog(!showLeavePolicyDialog)
                  }
                />
              )}
              <Button
                label="Apply"
                className="create-btn"
                onClick={() => setApplyLeaveDialog(!applyLeaveDialog)}
              />
              <Button
                className="filter-button"
                label="Filter"
                icon="pi pi-filter"
                onClick={() => setFilterDialog(!filterDialog)}
              />
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="grid flex justify-content-center">
        {spinner === true && <Spinner value={spinner} />}
      </div>
      {leaveRequest && leaveRequest.length > 0 && spinner === false && (
        <div className="table-box table-alignment mt-1">
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              responsiveLayout="scroll"
              value={leaveRequest}
              paginator
              paginatorTemplate={paginate}
              rows={limit}
              paginatorClassName="justify-content-end custom-dropdown"
            >
              <Column field={employeeAvatar} header="Employee" />
              <Column
                field={(val) => (
                  <div style={{ width: "200px", wordWrap: "break-word" }}>
                    <ReadMore text={val.reason} maxLength={30} />
                  </div>
                )}
                header="Reason"
              />
              <Column field="title" header="Type" />
              <Column
                field={(val) => <>{DateFormat(val?.requestedAt)}</>}
                header="Applied"
              />
              <Column field={leaveDate} header="Date" />
              <Column field={days} header="Days" />
              <Column field={status} header="Status" />
              <Column field={Action} header="Action" />
            </DataTable>
          </div>
        </div>
      )}
      {!spinner && leaveRequest !== {} && leaveRequest.length === 0 && (
        <EmptyScreen
          img={emptyAsset}
          title={constants.EMPTY_SCREEN_TEXT[2].title}
          description={constants.EMPTY_SCREEN_TEXT[2].description}
        />
      )}
    </>
  );
}

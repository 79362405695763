import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import Input from "./../../component/common/inputtext/input-field";
import Button from "../common/button/button";
import { Checkbox } from "primereact/checkbox";
import "./createRole.scss";
import constants from "../../constants";
import { postWithAuth } from "../../services/authentication";
import { Toast } from "primereact/toast";

export default function CreateRole({
  createRoleDialog,
  setCreateRoleDialog,
  setUpdate,
  update,
}) {
  const token = localStorage.getItem("token");
  const toast = useRef(null);

  const [rolename, setRoleName] = useState('');
  const [modulePermission, setModulePermission] = useState([]);

  const [submitDisable,setSubmitDisable]=useState(true);  
  useEffect(()=>{
    if(modulePermission.length===0 || rolename==="" || rolename==={}){
      setSubmitDisable(true)
    }
    else{
      setSubmitDisable(false);
    }
  },[rolename,modulePermission])

  const changeHandler = (e) => {
    let index = modulePermission.findIndex((mod) => {
      return mod?.moduleName === e.target.id;
    });
    if (e.checked === true) {
      if (index === -1) {
        setModulePermission([
          ...modulePermission,
          { moduleName: e.target.id, permission: e.target.value },
        ]);
      } else {
        let data = [...modulePermission];
        data[index] = { moduleName: e.target.id, permission: e.target.value };
        setModulePermission(data);
      }
    } 
    else {
      let data = [...modulePermission];
      data.splice(index,1);
      setModulePermission(data);
    }
  };
  const checkHandler = (val) => {
    let index = modulePermission.findIndex((module) => {
      return module?.moduleName === val.moduleName;
    });
    if (index !== -1) {
      if (modulePermission[index].permission === val.permission) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const submitHandler = async () => {
    try {
      const url = constants.API.SETTINGS.CREATE_ROLE;
      let data = { rolename: rolename, modulePermission };
      await postWithAuth(url, data, token);
      setModulePermission([]);
      setRoleName("");
      setCreateRoleDialog(!createRoleDialog);
      setUpdate(!update);
    } catch (err) {
      if (typeof err.response.data.err === "object") {
        toast.current.show({
          severity: "error",
          summary: err.response.data.err.message,
          detail: "",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: err.response.data.err,
          detail: "",
          life: 3000,
        });
      }
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Create Role"
        visible={createRoleDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateRoleDialog(!createRoleDialog);
          setRoleName({});
          setModulePermission([]);
        }}
      >
        <div>
          <Input
            placeholder="Role Name"
            label="Role Name"
            onChange={(e) => {
              setRoleName(e.target.value);
            }}
          />
        </div>
        <div className="role-table-box"> 
        <div className="flex mt-5 ml-3">
          <div className="col-3 role-font">Dashboard</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                checked={checkHandler({
                  moduleName: "Dashboard",
                  permission: "View",
                })}
                id="Dashboard"
              ></Checkbox>
              <label className="ml-2 role-label-font" htmlFor="view">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                checked={checkHandler({
                  moduleName: "Dashboard",
                  permission: "Edit",
                })}
                id="Dashboard"
              ></Checkbox>
              <label className="ml-2 role-label-font" htmlFor="edit">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                checked={checkHandler({
                  moduleName: "Dashboard",
                  permission: "Admin",
                })}
                id="Dashboard"
              ></Checkbox>
              <label className="ml-2 role-label-font" htmlFor="admin">
                Admin
              </label>
            </div>{" "}
          </div>
        </div>

        <div className="flex mt-3 ml-3">
          <div className="col-3 role-font">Projects</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                id="Project"
                checked={checkHandler({
                  moduleName: "Project",
                  permission: "View",
                })}
              ></Checkbox>
              <label htmlFor="view" className="ml-2 role-label-font">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                checked={checkHandler({
                  moduleName: "Project",
                  permission: "Edit",
                })}
                id="Project"
              ></Checkbox>
              <label htmlFor="edit" className="ml-2 role-label-font">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                checked={checkHandler({
                  moduleName: "Project",
                  permission: "Admin",
                })}
                id="Project"
              ></Checkbox>
              <label htmlFor="admin" className="ml-2 role-label-font">
                Admin
              </label>
            </div>
          </div>
        </div>
        <div className="flex mt-3 ml-3">
          <div className="col-3 role-font">Wiki</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                id="Wiki"
                checked={checkHandler({
                  moduleName: "Wiki",
                  permission: "View",
                })}
              ></Checkbox>
              <label htmlFor="view" className="ml-2 role-label-font">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                id="Wiki"
                checked={checkHandler({
                  moduleName: "Wiki",
                  permission: "Edit",
                })}
              ></Checkbox>
              <label htmlFor="edit" className="ml-2 role-label-font">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                id="Wiki"
                checked={checkHandler({
                  moduleName: "Wiki",
                  permission: "Admin",
                })}
              ></Checkbox>
              <label htmlFor="admin" className="ml-2 role-label-font">
                Admin
              </label>
            </div>
          </div>
        </div>
        <div className="flex mt-3 ml-3">
          <div className="col-3 role-font">Users</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                id="Users"
                checked={checkHandler({
                  moduleName: "Users",
                  permission: "View",
                })}
              ></Checkbox>
              <label htmlFor="view" className="ml-2 role-label-font">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                id="Users"
                checked={checkHandler({
                  moduleName: "Users",
                  permission: "Edit",
                })}
              ></Checkbox>
              <label htmlFor="edit" className="ml-2 role-label-font">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                id="Users"
                checked={checkHandler({
                  moduleName: "Users",
                  permission: "Admin",
                })}
              ></Checkbox>
              <label htmlFor="admin" className="ml-2 role-label-font">
                Admin
              </label>
            </div>
          </div>
        </div>
        <div className="flex mt-3 ml-3">
          <div className="col-3 role-font">Settings</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                id="Settings"
                checked={checkHandler({
                  moduleName: "Settings",
                  permission: "View",
                })}
              ></Checkbox>
              <label htmlFor="view" className="ml-2 role-label-font">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                id="Settings"
                checked={checkHandler({
                  moduleName: "Settings",
                  permission: "Edit",
                })}
              ></Checkbox>
              <label htmlFor="edit" className="ml-2 role-label-font">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                id="Settings"
                checked={checkHandler({
                  moduleName: "Settings",
                  permission: "Admin",
                })}
              ></Checkbox>
              <label htmlFor="admin" className="ml-2 role-label-font">
                Admin
              </label>
            </div>
          </div>
        </div>
        <div className="flex mt-3 ml-3 mb-3">
          <div className="col-3 role-font">Human Resource</div>
          <div className="col-8  ml-2 flex justify-content-around">
            <div>
              <Checkbox
                inputId="view"
                value="View"
                onChange={changeHandler}
                id="Human Resource"
                checked={checkHandler({
                  moduleName: "Human Resource",
                  permission: "View",
                })}
              ></Checkbox>
              <label htmlFor="view" className="ml-2 role-label-font">
                View
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="edit"
                value="Edit"
                onChange={changeHandler}
                id="Human Resource"
                checked={checkHandler({
                  moduleName: "Human Resource",
                  permission: "Edit",
                })}
              ></Checkbox>
              <label htmlFor="edit" className="ml-2 role-label-font">
                Edit
              </label>
            </div>
            <div className="ml-7">
              <Checkbox
                inputId="admin"
                value="Admin"
                onChange={changeHandler}
                id="Human Resource"
                checked={checkHandler({
                  moduleName: "Human Resource",
                  permission: "Admin",
                })}
              ></Checkbox>
              <label htmlFor="admin" className="ml-2 role-label-font">
                Admin
              </label>
            </div>
          </div>
        </div>
</div>

        

        <div className="flex justify-content-end mr-6 mt-4">
        <Button label="Submit" onClick={submitHandler} disabled={submitDisable}></Button>
         </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import PieChart from "../../component/common/chart/chartComponent";
import constants from "./../../constants";
import { getWithAuth } from "../../services/authentication";
import Announcement from "../../component/human-resource/announcement";

export default function Home() {
  const [todayLeave, setTodayLeave] = useState([]);
  const [tomorrowLeave, setTomorrowLeave] = useState([]);
  const [employeeCount, setEmployeeCount] = useState();
  const [todayLeaveCount, setTodayLeaveCount] = useState();
  const [assetStatusCount, setAssetStatusCount] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    getLeaveEmployees();
    getAllEmployees();
    getStatusCount();
  }, []);

  async function getLeaveEmployees() {
    const employeeLeaveURL = constants.API.LEAVE_REQUEST.GET_WHO_ON_LEAVE;
    const response = await getWithAuth(employeeLeaveURL, token);
    setTodayLeave(response.data.today);
    setTodayLeaveCount(response.data.today.length);
    setTomorrowLeave(response.data.tomorrow);
  }
  async function getAllEmployees() {
    const url = constants.API.MEMBER.LIST;
    const response = await getWithAuth(url, token);
    const workingEmployeeCount = response.data.requestsCount - 1;
    setEmployeeCount(workingEmployeeCount);
  }

  async function getStatusCount() {
    const url = constants.API.ASSET.STATUS_COUNT;
    const response = await getWithAuth(url, token);
    const status = response.data;
    const AssetType = ["Occupied", "Requested", "Retired", "UnAssigned"];
    let assetStatus = [];
    for (let type of AssetType) {
      const index = status.findIndex((val) => val._id === type);
      if (index !== -1) {
        assetStatus.push(status[index].count);
      } else {
        assetStatus.push(0);
      }
    }
    setAssetStatusCount(assetStatus);
  }

  return (
    <div>
      {employeeCount!==undefined && todayLeaveCount!==undefined && assetStatusCount!==undefined ? (
        <PieChart
          todayLeaveCount={todayLeaveCount}
          employeeCount={employeeCount}
          assetStatusCount={assetStatusCount}
        ></PieChart>
      ) : (
        <></>
      )}
      <Announcement></Announcement>

    </div>
  );
}

import React from "react";
import "./progressBar.scss";

const ProgressBar = ({ value, maxValue, color, width, height }) => {
  const percent = Math.floor((value / maxValue) * 100);
  const _width = `${percent}%`;
  const styles = {
    width: _width,
    color,
    background: color,
  };

  return (
    <div className="flex align-items-center column-gap-2">
      <div className="outer" style={{ width, height }}>
        <div className="inner" style={styles}>&nbsp;</div>
      </div>
      <div className="flex column-gap-2">
        <span style={{ color: color }}>{percent}</span>
        <span> of {maxValue} %</span>
      </div>
    </div>
  );
};

export default ProgressBar;

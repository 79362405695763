import React, { useState } from "react";

const ReadMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {text.length > maxLength && (
        <>
          {isExpanded ? (
            <span>
              {text}
              <div onClick={() => setIsExpanded(false)} style={{color:"#6366F1",cursor:"pointer"}}>Read less</div>
            </span>
          ) : (
            <span>
              {text.substring(0, maxLength)}...
              <div onClick={() => setIsExpanded(true)} style={{color:"#6366F1",cursor:"pointer"}}>Read more</div>
            </span>
          )}
        </>
      )}
      {text.length <= maxLength && <p>{text}</p>}
    </div>
  );
};

export default ReadMore;


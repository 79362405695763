import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Button from "../../component/common/button/button";
import Input from "../../component/common/inputtext/input-field";
import { useDispatch, useSelector} from "react-redux";
import {
  createPayrollUserList,
  updatePayrollUserList,
  getPayrollUserList,
} from "../../store/features/HumanResource/payslip.store";

const defaultFormValue = {
  CTC: "",
  basicEPF: "",
  variablePay: "",
  accountNumber: "",
  accountName: "",
  ifscCode: "",
};

export default function CreateProject({
  createDialog,
  setCreateDialog,
  rowData,
  payroll_page,
}) {
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [payrollDetails, setPayrollDetails] = useState(defaultFormValue);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (rowData.salary) {
      setPayrollDetails({
        ...rowData.salary,
      });
    }
  }, [rowData]);

  const changeHandler = (e) => {
    setPayrollDetails({
      ...payrollDetails,
      [e.target.name]: e.target.value,
    });
  };

  const closeDialog = () => {
    setCreateDialog(!createDialog);
    setPayrollDetails(defaultFormValue);
  };

  const submitHandler = async () => {
    if (rowData.salary) {
      const payload = {
        ...payrollDetails,
        orgId: rowData.work.orgId,
        employeeId: rowData.memberId,
        employeeName: rowData.basic.fullName,
        role: rowData.work.role,
        designationId: rowData.work.designationId,
        dateOfJoin: rowData.work.dateOfJoining,
      };
      dispatch(updatePayrollUserList(payload)).then((c) => {
        if (c.meta.requestStatus === "fulfilled") {
          closeDialog();
          payroll_page();
          toast.current.show({
            severity: "success",
            summary: "PayRoll Updated Successfully",
            detail: "",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Failed to Update PayRoll",
            detail: "",
            life: 3000,
          });
        }
      });
    } else {
      const payload = {
        ...payrollDetails,
        orgId: rowData.work.orgId,
        employeeId: rowData.memberId,
        employeeName: rowData.basic.fullName,
        role: rowData.work.role,
        designationId: rowData.work.designationId,
        dateOfJoin: rowData.work.dateOfJoining,
      };
      dispatch(createPayrollUserList(payload)).then((c) => {
        if (c.meta.requestStatus === "fulfilled") {
          closeDialog();
          payroll_page()
          toast.current.show({
            severity: "success",
            summary: "PayRoll Created Successfully",
            detail: "",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Failed to Create PayRoll",
            detail: "",
            life: 3000,
          });
        }
      });
    }
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Payroll Settings"
        visible={createDialog}
        style={{ width: "50vw" }}
        onHide={closeDialog}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Input
                name="CTC"
                label="Basic Salary"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.CTC}
              />
            </div>
            <div className="col-6">
              <Input
                name="basicEPF"
                label="Basic EPF Salary"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.basicEPF}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                name="variablePay"
                label="Variable Pay"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.variablePay}
              />
            </div>
            <div className="col-6">
              <Input
                name="accountNumber"
                label="Account No"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.accountNumber}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                name="accountName"
                label="Account Name"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.accountName}
              />
            </div>
            <div className="col-6">
              <Input
                name="ifscCode"
                label="IFSC Code"
                type="text"
                onChange={changeHandler}
                value={payrollDetails.ifscCode}
              />
            </div>
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={!submitDisable}
              loading={isLoading}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

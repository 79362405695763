import React, { useEffect, useState, useRef } from "react";
import CreateRole from "../../component/settings/createRole";
import Button from "./../../component/common/button/button";
import { Toast } from "primereact/toast";
import RoleTable from "../../component/settings/roleTable";
import constants from "../../constants";
import { getWithAuth } from "../../services/authentication";
import "./../../component/settings/roleTable.scss";
import Spinner from "./../../component/common/spinner/spinner";
import Image from "../../utility/image";

export default function Roles() {
  const [createRoleDialog, setCreateRoleDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  const toast = useRef(null);
  const getRoles = async () => {
    try {
      setSpinner(true);
      const url = constants.API.SETTINGS.GET_ROLE + `/${user.orgId}`;
      const response = await getWithAuth(url, token);
      setRoles(response.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      toast.current.show({
        severity: "error",
        summary: err?.response?.dat?.err,
        detail: "",
        life: 3000,
      });
    }
  };
  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getRoles();
  }, [update]);

  const AdmistratorRole = [
    {
      title: "View only",
      img: Image.roleRound,
    },
    {
      title: "Edit only",
      img: Image.roleRectangle,
    },
    {
      title: "Administrator",
      img: Image.roleTriangle,
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      {createRoleDialog && (
        <CreateRole
          createRoleDialog={createRoleDialog}
          setCreateRoleDialog={setCreateRoleDialog}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      <div className="flex align-items-center justify-content-between">
        <div className="flex flex-column row-gap-3 ">
          <div className="setting__active font-semibold">Administrator</div>
          <div className="flex align-items-center column-gap-4">
            {AdmistratorRole.map((role, id) => {
              return (
                <div key={id} className="flex align-items-center column-gap-2">
                  <img src={role.img} alt={role.title} />
                  <p>{role.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex align-items-center">
          <Button
            className="create-btn"
            label="Create Role"
            onClick={() => setCreateRoleDialog(!createRoleDialog)}
          />
        </div>
      </div>
      <div className="mt-4">
        {spinner === false ? (
          <RoleTable
            roles={roles}
            setRoles={setRoles}
            update={update}
            setUpdate={setUpdate}
          />
        ) : (
          <Spinner value={spinner} />
        )}
      </div>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  user: {},
  isLoading: false,
  status: "",
  error: "",
  verificationCode: "",
  email: "",
  token: "",
};

export const otpVerify = createAsyncThunk(
  "auth/otp-verify",
  async (name, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.SIGNUP_VERIFY, {
        email: name.email,
        verificationCode: name.verificationCode,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const otpMemberVerify = createAsyncThunk(
  "auth/member/otp-verify",
  async (payload, {rejectWithValue}) => {
    try {
       const response = await api.put(constants.API.AUTHENTICATION.OTP_VERIFY,payload)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

const otpVerifySlice = createSlice({
  name: "otpVerify",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
  },
  extraReducers: {
    [otpVerify.pending]: (state) => {
      state.isLoading = true;
    },
    [otpVerify.fulfilled]: (state, action) => {
      state.token = action.payload.user.token;
      state.status = "success";
      state.isLoading = false;
      state.verificationCode = action.meta.arg.verificationCode;
      state.email = action.meta.arg.email;
    },
    [otpVerify.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { resetStatus, setIsLoading } = otpVerifySlice.actions;

export default otpVerifySlice.reducer;

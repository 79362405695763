import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HrNavLink, HR_MODULE, HR_MODULE_USER } from "../../utility/utility";
import { isAdminAccess,superAdmin } from "../../utility/utility";

const HREmbedRoute = () => {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const [active, setActive] = useState("");

  const adminAccess = isAdminAccess("Human Resource");

  useEffect(() => {
    if (adminAccess) {
      const hrPath = HR_MODULE.hr_tab.filter((x) => x.path === pathname);
      if (hrPath[0]) return setActive(pathname);
      return setActive(HR_MODULE.hr_tab[0].path);
    } else {
      const hrPath = HR_MODULE_USER.hr_tab.filter((x) => x.path === pathname);
      if (hrPath[0]) return setActive(pathname);
      return setActive(HR_MODULE_USER.hr_tab[0].path);
    }
  }, []);

  const clickHandler = (e) => {
    setActive(e.path);
    HrNavLink(e, Navigate);
  };

  return (
    <div className="flex ml-4">
      {superAdmin()  &&
        HR_MODULE.hr_tab.map((item, id) => {
          return (
            <button
              key={id}
              onClick={() => clickHandler(item)}
              className={
                active === item.path
                  ? "nav-button active-button cursor-pointer"
                  : "nav-button cursor-pointer"
              }
            >
              {item.tab_name}
            </button>
          );
        })}
      { !superAdmin() &&
        HR_MODULE_USER.hr_tab.map((item, id) => {
          return (
            <button
              key={id}
              onClick={() => clickHandler(item)}
              className={
                active === item.path
                  ? "nav-button active-button cursor-pointer"
                  : "nav-button cursor-pointer"
              }
            >
              {item.tab_name}
            </button>
          );
        })}
    </div>
  );
};

export default HREmbedRoute;

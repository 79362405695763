import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../utility/image";
import "./authentication.scss";

function AuthenticationIndex() {
  const Navigate = useNavigate();
  return (
    <div className="w-full p-5 sideBar flex flex-column row-gap-4 ">
      <div className="flex align-items-center column-gap-3">
        <img
          src={Image.SquadzenLogo}
          onClick={() => Navigate("/auth/login")}
          alt="SquadzenLogo"
          width={40}
          height={40}
        />
        <p className="side__text">Squadzen</p>
      </div>
      <div className="flex flex-column row-gap-3">
        <p className="side__desc">Organize your work</p>
        <p className="side__desc">like never before...</p>
      </div>
    </div>
  );
}
export default AuthenticationIndex;

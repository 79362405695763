import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreateCount,
  setSelectedScratchpad,
} from "../../store/features/Scratchpad/scratchpad";
import { setScratchpadCreate } from "../../store/features/Scratchpad/scratchpad";
import { dataFormat_short , Fn_scratchpad_note } from "../../utility/utility";

export default function ScratchpadCard({ scratchpad }) {
  const dispatch = useDispatch();
  const { selectedScratchpad } = useSelector((store) => store.scratchpad);

  const cardClickHandler = () => {
    dispatch(setCreateCount(0));
    dispatch(setSelectedScratchpad(scratchpad));
    dispatch(setScratchpadCreate(false));
  }

  return (
    <div
      onClick={cardClickHandler}
      className="p-3 flex flex-column row-gap-3 w-full cursor-pointer scratchpad-card-border"
      style={
        scratchpad._id === selectedScratchpad._id
          ? { backgroundColor: "#f5f5f0" }
          : { backgroundColor: "#ffffff" }
      }
    >
      <div className="scratchpad-font">{dataFormat_short(scratchpad?.updatedAt)}</div>
      <div className="scratchpad-card-header-font">{scratchpad.title}</div>
      <div className="scratchpad-card-font">{Fn_scratchpad_note(scratchpad?.note)}</div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { fn_firstcharacter } from "../../../utility/utility";
import Alphabet from "../alphabet/alphabet";
import "./avatarGroup.scss";
import { Tooltip } from "primereact/tooltip";

export default function AvatarGroup({ item, size }) {
  const [width, setwidth] = useState("");
  const [height, setheight] = useState("");

  useEffect(() => {
    switch (size) {
      case "small":
        setwidth("25px");
        setheight("25px");
        break;
      case "medium":
        setwidth("33px");
        setheight("33px");
        break;
      case "large":
        setwidth("40px");
        setheight("40px");
        break;

      default:
        setwidth("33px");
        setheight("33px");
        break;
    }
  }, []);

  return (
    <>
      {item && item.length > 0 ? (
        <div className="avatar__group">
          <Tooltip target=".disabled-button" />
          {item.slice(0, 3).map((item, id) => {
            if (item.url)
              return (
                <div
                  key={id}
                  style={{ marginLeft: "-8px" }}
                  data-pr-tooltip={item.name}
                  data-pr-position="bottom"
                  data-pr-classname="blue-tooltip "
                  className="disabled-button cursor-pointer"
                >
                  <img
                    src={item.url}
                    alt={item.name}
                    style={{
                      borderRadius: "50%",
                      border: "1px solid #FFFFFF",
                      width: "33px",
                      height: "33px",
                    }}
                  />
                </div>
              );
            return (
              <div
                key={id}
                style={{ marginLeft: "-8px" }}
                data-pr-tooltip={item.name}
                data-pr-position="bottom"
                data-pr-classname="blue-tooltip "
                className="disabled-button cursor-pointer"
              >
                <Alphabet
                  letter={fn_firstcharacter(item.name)}
                  width={width}
                  height={height}
                  fontSize={size ? "10px" : "14px"}
                  border="1px solid #FFFFFF"
                />
              </div>
            );
          })}
          {Math.sign(item.length - 3) === 1 ? (
            <div style={{ marginLeft: "-8px" }}>
              <div
                style={{
                  width: width,
                  height: height,
                  border: "1px solid #FFFFFF",
                  backgroundColor: "rgb(90, 120, 238)",
                  borderRadius: "50%",
                }}
                className="flex align-items-center justify-content-center"
              >
                <span
                  style={{ fontSize: size ? "10px" : "14px", color: "white" }}
                  className="font-bold"
                >{`+ ${item.length - 3}`}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

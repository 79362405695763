import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";
import constants from "../../constants";
import { getWithAuth, putWithAuth } from "../../services/authentication";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import { Dialog } from "primereact/dialog";
import round from "../../assets/round.svg";
import Spinner from "../common/spinner/spinner";
import UpdateUser from "./updateUser";
import Manageproject from "./manageProject";
import { Dropdown } from "primereact/dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  setSkip,
  setLimit,
  deleteUser,
  getUsers,
  setFrom,
  setTo,
  activateUser,
} from "../../store/features/Users/User";
import { getDesignation } from "../../store/features/Users/designation";
import { getRoles } from "../../store/features/Users/role";
import Alphabet from "../common/alphabet/alphabet";
import { PaginationDropdown, fn_firstcharacter } from "../../utility/utility";
import { InputSwitch } from "primereact/inputswitch";

export default function UserTable() {
  const {
    users,
    skip,
    limit,
    maxRecord,
    isLoading,
    search,
    from,
    to,
    userStatus,
  } = useSelector((store) => store.users);
  const usersStore = useSelector((store) => store.users);
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [assignedProjects, setAssignedProjects] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const [updateUserDialog, setUpdateUserDialog] = useState(false);
  const [manageProjectDialog, setManageProjectDialog] = useState(false);

  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  let usersAdmin = user?.modulePermission?.filter(
    (modules) => modules?.moduleName === "Users"
  );
  const modulePermission = [...usersAdmin];
  if (usersAdmin?.length === 0 || usersAdmin[0]?.permission !== "Admin") {
    usersAdmin = false;
  } else {
    usersAdmin = true;
  }

  let items = [];
  

  const handlePrevious = () => {
    if (skip > 1) {
      dispatch(setSkip(skip - 1));
      dispatch(setFrom(from - limit));
      dispatch(setTo(to - limit));
    }
  };
  
  const handleNext = () => {
    if(maxRecord >= to){
      dispatch(setSkip(skip + 1));
      dispatch(setFrom(from + limit));
      dispatch(setTo(to + limit));
    }
  };


  

  if (usersAdmin) {
    items = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          dispatch(getRoles(memberDetails?.orgId));
          setUpdateUserDialog(!updateUserDialog);

          dispatch(getDesignation(updateItem?.departmentId));
        },
      },
      {
        label: "Manage Project",
        icon: "pi pi-desktop",
        command: () => {
          setManageProjectDialog(!manageProjectDialog);
        },
      },
    ];
  } else {
    items = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          dispatch(getRoles(memberDetails?.orgId));
          setUpdateUserDialog(!updateUserDialog);
          dispatch(getDesignation(updateItem?.departmentId));
        },
      },
    ];
  }

  const Action = (data) => {
    if (modulePermission[0].permission === "Edit") {
      if (data._id === memberDetails?.memberOrgId) {
        return (
          <div className="user-table-action-btn">
            <SpeedDial
              model={items}
              radius={50}
              onClick={() => {
                setUpdateItem(data);
              }}
              direction="right"
              showIcon="pi pi-ellipsis-v"
              hideIcon="pi pi-times"
              buttonClassName="p-button-outlined action-btn-design"
            />
          </div>
        );
      }
    }
    if (usersAdmin) {
      return (
        <div className="user-table-action-btn">
          <SpeedDial
            model={items}
            radius={50}
            onClick={() => {
              setUpdateItem(data);
            }}
            direction="right"
            showIcon="pi pi-ellipsis-v"
            hideIcon="pi pi-times"
            buttonClassName="p-button-outlined action-btn-design"
          />
        </div>
      );
    }
  };

  const userActivateAndDeactivateHandler = async (email) => {
    if (memberDetails.email == email) {
      return toast.current.show({
        severity: "error",
        summary: "Currently Logged in User",
        detail: "",
        life: 3000,
      });
    }
    if (userStatus == "InActive") {
      let emailArray = [];
      emailArray.push(email);
      const emailListObj = {
        emailList: emailArray,
      };
      const response = await dispatch(activateUser(emailListObj));
      if (!response?.error) {
        dispatch(getUsers(usersStore));
        toast.current.show({
          severity: "success",
          summary: "Activated Successfully",
          detail: "",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Some Error Occured",
          detail: "",
          life: 3000,
        });
      }
    } else {
      setDeleteDialog(!deleteDialog);
    }
  };

  const UserCheckBox = (data) => {
    return (
      <InputSwitch
        checked={userStatus === "Active" ? true : false}
        onChange={() => {
          setUpdateItem(data);
          userActivateAndDeactivateHandler(data.email);
        }}
      />
    );
  };

  //pagination
  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = PaginationDropdown
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {to} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3" onClick={handlePrevious}>
              Previous
            </span>
          </button>
          <button
            type="button"
            className={options.className}
          >
            <span className="p-3" onClick={handleNext}>
              Next
            </span>
          </button>
        </>
      );
    },
  };

  const deleteHandler = async () => {
    let emailArray = [];
    emailArray.push(updateItem.email);
    const email = {
      emailList: emailArray,
    };
    const response = dispatch(deleteUser(email));
    setDeleteDialog(!deleteDialog);
    if (!response?.error) {
      dispatch(getUsers(usersStore));
      toast.current.show({
        severity: "success",
        summary: "User Deactivated",
        detail: "",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Some Error Occured",
        detail: "",
        life: 3000,
      });
    }
  };

  //get project Details
  async function getProjectList(memberId) {
    const url = constants.API.PROJECT.ASSIGN_REASSIGN + memberId;
    const response = await getWithAuth(url, token);
    const data = response.data;
    setProjectList(data.assignedProjects);
  }

  function employeeName(member) {
    return (
      <div className="flex">
        {member?.basic?.profilePic ? (
          <img className="img-size" src={member.basic.profilePic} />
        ) : (
          <Alphabet letter={fn_firstcharacter(member.basic.fullName)} />
        )}
        <div className="ml-2 flex align-items-center">
          {member.basic.fullName}
        </div>
      </div>
    );
  }

  function designation(member) {
    return (
      <div>
        <p>
          {member?.designationName?.length > 0
            ? member?.designationName
            : "N/A"}
        </p>
      </div>
    );
  }

  function role(member) {
    return (
      <div>
        <p>{member.work.role}</p>
      </div>
    );
  }

  function projectHandler(memberId) {
    getProjectList(memberId);
    setAssignedProjects(!assignedProjects);
  }

  function projects(member) {
    return (
      <div
        className="projects-round"
        onClick={() => projectHandler(member?._id)}
      >
        <p>{member.projects}</p>
      </div>
    );
  }

  function status({work}) {
    const status = work?.status
    return (
      <div
        className={ status === "Pending" ? "pending-round" : "active-round" }
      >
        <p>{status === "InActive" ? "Inactive" : status }</p>
      </div>
    );
  }

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
          template="Are you sure you want to deactivate this user?"
          title="Deactivate User"
        />
      )}
      {updateUserDialog && (
        <UpdateUser
          updateUserDialog={updateUserDialog}
          setUpdateUserDialog={setUpdateUserDialog}
          updateItem={updateItem}
          setUpdateItem={setUpdateItem}
        />
      )}
      {manageProjectDialog && (
        <Manageproject
          manageProjectDialog={manageProjectDialog}
          setManageProjectDialog={setManageProjectDialog}
          updateItem={updateItem}
        />
      )}

      {/* Assigned Projects */}
      <Dialog
        header="Assigned Projects"
        visible={assignedProjects}
        style={{ width: "25vw" }}
        onHide={() => {
          setAssignedProjects(!assignedProjects);
          setProjectList([]);
        }}
      >
        <>
          {projectList.map((project, id) => {
            return (
              <div className="flex" key={id}>
                <img src={round} />
                <div className="assigned-project-font ml-3">
                  {project.projectDetails.projectName}
                </div>
              </div>
            );
          })}
        </>
      </Dialog>
      <br />
      {isLoading ? (
        <div className="grid flex justify-content-center">
          <Spinner value={isLoading}></Spinner>
        </div>
      ) : (
        <div className="table-box">
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              responsiveLayout="scroll"
              value={users}
              paginator
              paginatorTemplate={paginate}
              rows={limit}
              paginatorClassName="justify-content-end custom-dropdown"
            >
              <Column field={employeeName} header="Name" />
              <Column field={designation} header="Designation" />
              <Column field="email" header="Email" />
              <Column field={role} header="Role" />
              <Column body={projects} header="Projects" />
              <Column body={status} header="Status" />
              {usersAdmin && (
                <Column
                  body={UserCheckBox}
                  header={userStatus == "Active" ? "Deactivate" : "Activate"}
                />
              )}
              {usersAdmin && <Column body={Action} header="Action" />}
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteWithAuth, getWithAuth } from "../../../services/authentication";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { SpeedDial } from "primereact/speeddial";
import constants from "../../../constants";
import { Dropdown } from "primereact/dropdown";
import Button from "../../common/button/button";
import TaskMoveDialog from "./taskMoveDialog";
import UpdateTaskDialog from "../tasks/updateTaskDialog";
import { setActiveTask } from "../../../store/features/Projects/Task";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import { Toast } from "primereact/toast";
import { ChipBtn } from "../../common/chip/chip";
import bug from "../../../assets/bug.svg";
import story from "../../../assets/story.svg";
import taskfile from "../../../assets/task.svg";
import { PaginationDropdown, fn_color } from "../../../utility/utility";
import TaskCreateInput from "../taskCreateInput";
import Image from "../../../utility/image";

export default function ProjectBacklogTable() {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const { projectId } = useParams();
  const [backlogItem, setbacklogItem] = useState([]);
  const [maxRecord, setMaxRecord] = useState(0);
  const [show, setShow] = useState([]);
  const token = localStorage.getItem("token");
  const toast = useRef(null);

  //move task
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [taskMoveDialog, setTaskMoveDialog] = useState(false);
  const [timesheetApproval, setTimesheetApproval] = useState({});
  const [approvalDialog, setApprovalDialog] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateTaskDialog, setUpdateTaskDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedTaskId, setSeletedTaskId] = useState("");

  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getBacklogData();
  }, [update, limit, skip]);

  useEffect(() => {
    if (selectedMembers?.length || selectAll) setApprovalDialog(true);
    else setApprovalDialog(false);
  }, [selectedMembers?.length, selectAll]);

  const getBacklogData = async () => {
    const url =
      constants.API.TASK.FETCH + "?projectId=" + projectId + `&skip=${skip}`;
    const data = await getWithAuth(url, token);
    if (data) {
      setbacklogItem(data?.data?.backlogDetails);
      setMaxRecord(data.data?.totalCount);
    }
  };

  const deleteHandler = async () => {
    try {
      const url = constants.API.TASK.FETCH + "?taskId=" + selectedTaskId;
      await deleteWithAuth(url, token);
      setUpdate(!update);
      setDeleteDialog(false);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setUpdateTaskDialog(!updateTaskDialog);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const onSelectionChange = (event) => {
    const value = event.value;
    setTimesheetApproval(value);
    setSelectedMembers(value);
    setSelectAll(value.length === backlogItem.length);
  };

  const Action = (data) => {
    return (
      <SpeedDial
        model={items}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
        onClick={() => {
          dispatch(setActiveTask(data));
          setSeletedTaskId(data._id);
        }}
      />
    );
  };

  const taskImg = (props) => {
    if (props === "Bug") return bug;
    else if (props === "Task") return taskfile;
    else if (props === "Story") return story;
    else return bug;
  };

  function taskType(task) {
    return (
      <div className="flex">
        <img
          src={taskImg(task.type)}
          alt={task.type}
          data-pr-tooltip={task.type}
        />
        <p className="ml-2">{task.type}</p>
      </div>
    );
  }

  function title(title) {
    const titleLength = title.title.toString().length;
    if (titleLength) {
      if (titleLength > 80) {
        return (
          <div className="asset-name-align">
            {show.findIndex((s) => s._id == title._id) !== -1
              ? title.title
              : title.title.slice(0, 80)}
            <div
              className="read-more-button hover"
              onClick={() => {
                let index = show.findIndex((s) => s._id == title._id);
                if (index !== -1) {
                  let nonShow = show.filter((s) => s._id !== title._id);
                  setShow(nonShow);
                } else {
                  setShow([...show, { _id: title._id, value: true }]);
                }
              }}
            >
              {show.findIndex((s) => s._id == title._id) !== -1
                ? "Read less"
                : "Read more"}
            </div>
          </div>
        );
      } else {
        return <div className="asset-name-align">{title.title}</div>;
      }
    }
  }

  const status = (status) => {
    return (
      <div
        style={{
          color: fn_color(status?.status),
          fontWeight: "600",
          fontSize: "14px",
        }}
      >
        {status?.status}
      </div>
    );
  };

  //convert date format
  const DateFormator = (date) => {
    if (date)
      return new Date(date).toLocaleDateString("en-uk", {
        month: "short",
        day: "numeric",
        year: "numeric",
        weekday: "short",
      });
  };

  const paginate = {
    RowsPerPageDropdown: () => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <div className="flex justify-content-end backlog-paginate-dropdown">
            <span
              className="mt-3"
              style={{ color: "var(--text-color)", userSelect: "none" }}
            >
              Items per page:{" "}
            </span>
            <div>
              <Dropdown
                value={limit}
                options={dropdownOptions}
                onChange={(e) => {
                  setLimit(e.value);
                  setSkip(10);
                  setFrom(1);
                  setTo(e.value);
                }}
              />
            </div>
          </div>
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip - 10);
              setFrom(from - limit);
              setTo(to - limit);
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              setSkip(skip + 10);
              setFrom(from + limit);
              setTo(to + limit);
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  return (
    <div className="backlog w-full flex flex-column row-gap-3">
      {taskMoveDialog && (
        <TaskMoveDialog
          taskMoveDialog={taskMoveDialog}
          setTaskMoveDialog={setTaskMoveDialog}
          taskList={selectedMembers}
          update={update}
          setUpdate={setUpdate}
        />
      )}

      {updateTaskDialog && (
        <UpdateTaskDialog
          taskDialog={updateTaskDialog}
          setUpdateTaskDialog={setUpdateTaskDialog}
          getBacklogData={getBacklogData}
        />
      )}

      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}

      <div className="sprint-container">
        <div className="flex align-items-center column-gap-2 p-2 ">
          <img
            src={collapsed ? Image.B_downArrow : Image.B_leftArrow}
            width={30}
            height={40}
            onClick={() => setCollapsed(!collapsed)}
          />
          <p className="sprint-header-font">Backlogs</p>
        </div>
      </div>

      {collapsed && (
        <div className="flex flex-column row-gap-2">
          <Toast ref={toast} />
          <TaskCreateInput getBacklogData={getBacklogData} />
          {approvalDialog && (
            <div className="flex  p-2 justify-content-end">
              <div
                className="flex p-1 justify-content-center align-items-center column-gap-2 backlog-movetask-btn cursor-pointer "
                onClick={() => setTaskMoveDialog(!taskMoveDialog)}
              >
                <p>Move to</p>
                <i className="pi pi-angle-down sprint__move__to " />
              </div>
            </div>
          )}

          <>
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              responsiveLayout="scroll"
              paginator
              paginatorTemplate={paginate}
              rows={limit}
              value={backlogItem}
              onSelectionChange={onSelectionChange}
              selectAll={selectAll}
              selection={selectedMembers}
            >
              <Column
                selectionMode="multiple"
                selectionAriaLabel="name"
                headerStyle={{ width: "3em" }}
              />
              <Column
                body={(data) => <ChipBtn label={data?.priority} />}
                header="Priority"
              />
              <Column field={taskType} header="Type" />
              <Column field={title} header="Title" />
              <Column field={status} header="Progress" />
              <Column
                field={(item) => DateFormator(item.createdAt)}
                header="Start Date"
              />
              <Column
                field={(item) => DateFormator(item?.updatedAt)}
                header="End Date"
              />
              <Column
                body={Action}
                headerClassName="members-action"
                header="Action"
              />
            </DataTable>
          </>
        </div>
      )}
    </div>
  );
}

let { PROD } = require("./constants.api");

module.exports = {
  // SERVER_URL: '',
  SERVER_URL: "http://139.59.23.157/api",
  SNACKBAR_TIMEOUT: 1000,
  ZERO: 0,
  TWENTY: 20,
  DEBOUNCE_DELAY: 1500,
  DEBOUNCE_DELAY_SEARCH: 800,
  EMAIL_PATTERN: /\S+@\S+\.\S+/,
  PASSWORD_PATTERN:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_/+|-])[A-Za-z\d!@#$%^&*(),.?":{}|<>_/+|-]{8,}$/,
  EMPTY_SCREEN_TEXT: [
    {
      title: "No Recognition Yet",
      description:
        "All your Recognition entries made in the tasks will be shown here.",
    },
    {
      title: "No Holiday are Added Yet",
      description:
        "All your holiday entries made in the tasks will be shown here.",
    },
    {
      title: "No Leave Request",
      description: "All your leave request entries made will be shown here.",
    },
    {
      title: "No Assets",
      description: "All your asset entries made will be shown here.",
    },
    {
      title: "No Announcements",
      description: "All your announcement entries made will be shown here.",
    },
    { description: "No One is on Leave Tomorrow" },
    { description: "No One is on Leave Today" },
     {description:"No Timesheet Logged for mentioned date"}
  ],
  BLOOD_GROUP:["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
  GENDER:["Male", "Female", "Others"],
  COLORS: [
    "#42A5F5",
    "#26A69A",
    "#EC407A",
    "#8D6E63",
    "#66BB6A",
    "#FFA726",
    "#AB47BC",
  ],
  RELEASE_COLORS: [
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "blue",
    "indigo",
    "purple",
    "pink",
  ],
  ROLES: {
    ADMIN: "Admin",
    USER: "User",
  },
  BIRTHDAY: "Birthday",
  RECOGNITION: "Recognition",
  NUMBERS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  HOURS: Array.from({ length: 13 }).map((v, i) => ("0" + i).slice(-2)),
  MINUTES: Array.from({ length: 60 }).map((v, i) => ("0" + i).slice(-2)),
  DAYS_IN_MONTH: Array.from({ length: 29 }).map((v, i) => i + 1),
  MONTHS_IN_YEAR: Array.from({ length: 11 }).map((v, i) => i + 1),
  MERIDIEMS: ["am", "pm"],
  PERMISSION_TAB_TITLE: [
    { MODULES: "Modules" },
    { BACKLOG: "Backlog" },
    { BOADRS: "Boards" },
    { RELEASE: "Release" },
    { TIMESHEET: "Timesheet" },
  ],
  ROUTE: {
    PROJECT_CREATE: "/project/create",
    PROJECT: "/project",
    PROJECT_EDIT: "/project/edit",
    PROJECT_INVITE: "/project/invite",
    BOARD_LIST: "/project/board",
    LEFT_NAV: "Back.Logs",
    BACK_LOGS: "/project/task",
    TIMESHEET: "/project/task/timesheet",
    PROJECT_NOTE: "/project/task/projectNote",
    WIKI: "/wiki",
    WIKI_CREATE: "/wiki/create",
    WIKI_PAGE: "Wiki.PageList",
    ASSET: "/hr/asset",
    LEAVE_REQUEST: "/hr/leaves",
    HOLIDAY: "/hr/holiday",
    RECOGNITION: "/hr/recognition",
    PAYSLIP: "/hr/payslip",
    ATTENDANCE: "/hr/attendance",
    ANNOUNCEMENT: "/hr/announcements",
    PERMISSION_REQUEST: "/hr/permissions",
    WORK_FROM_HOME: "/hr/wfh",
    BIRTHDAY: "/hr/birthday",
    AUTHENTICATION: "/auth",
    SIGNIN: "/auth/signin",
    SIGNUP: "/auth/signup",
    ORG_SIGNUP: "OrgSignUp",
    VERIFICATION: "Verification",
    CHANGE_PASSWORD: "ChangePassword",
    FORGOT_PASSWORD: "/auth/forgot-password",
    OTP_VERIFY: "OTPVerification",
    SCRATCHPAD: "/hr/scratchpad",
    USER_OTP_VERIFY: "UserSignupVerify",
  },
  API: PROD,
  MAXDATE: new Date().toISOString().substr(0, 10),
  ONE: 1,
  TEN: 10,
  ROWS_PER_PAGE_ITEMS: [20, 30, 40],
  PROJECT_ROWS_PER_PAGE: [10, 20, 30],

  EIGHTY: 80,
  STORY_POINT_VALIDATION: "Story point must be numbers",
  NUMBER_PATTERN: /^[0-9]*$/,
  REMOVE_ALPHA_PATTERN: /\D/g,
  DATE_RANGE: [
    {
      key: "today",
      highlight: true,
      dates: new Date(),
    },
    {
      highlight: {
        borderWidth: "1px",
      },
      contentStyle: {
        color: "white",
      },
    },
  ],
  PROJECT: "Project",
  DASHBOARD: "Dashboard",
  USERS: "Users",
  SETTINGS: "Settings",
  HUMAN_RESOURCE: "Human Resource",
  ADMIN: "Admin",
  EDIT: "Edit",
  VIEW: "View",
  UNAUTHOURIZED: 401,
  INVALID_OTP: "Invalid OTP",
  DELETE_SUCCESS: "Succesfully Deleted",
  TRY_LATER: "Please try again, later",
  EMP_ID_EXIST: "Employee ID is already in use. Please try another",
  SIX: 6,
  STATUS_TYPE: [
    { name: "All", value: null },
    { name: "To Do", value: "To Do" },
    { name: "In Progress", value: "In Progress" },
    { name: "Review", value: "Review" },
    { name: "Done", value: "Done" },
  ],
  SETTINGS_TASK_STATUS: [
    { value: "toDo", title: "To Do" },
    { value: "inProgress", title: "In Progress" },
    { value: "review", title: "Review" },
    { value: "done", title: "Done" },
  ],
  TASK_ASSIGN: "You cannot move this task without assigning to someone",
  TIMELOG_REVIEW: "Please add timelog before moving to review",
  TIMELOG_DONE: "Please add timelog before moving to done",
  BUG_CLOSE: "Please close all the open bugs",
  ENABLE_PROJECT_SETTINGS: "Please enable this option in project settings",
  INTERNAL_SERVER_ERROR: "Something went wrong",
  LEAVE_STATUS: ["Pending", "Approved", "Declined"],
  TASK_STATUS: ["To Do", "In Progress", "Review", "Done"],
  TASK_COLOR: {
    "In Progress": "#FF8400",
    "To Do": "#00A3FF",
    Review: "#871BAD",
    Done: "#23AD1B",
  },
  KANBAN_TASK_COLOR: {
    "In Progress": "#ECDDCD",
    "To Do": "#E0F8FF",
    Review: "#D9D0E2",
    Done: "#D1E2D0",
  },
  TASK_PRIORITY: ["Low", "Medium", "High"],
  CHARTOPTIONS: {
    chart: {
      type: "area",
      plotBorderWidth: 1,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [],
      allowDecimals: false,
      title: {
        text: "Days",
      },
      plotLines: [
        {
          color: "#FF00A8",
          zIndex: 10,
          width: 2,
          value: "",
        },
      ],
    },
    yAxis: {
      title: {
        text: "",
      },
      allowDecimals: false,
      lineWidth: 1,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: "Date {point.key} :",
      pointFormat: " Task {point.y}",
    },
    plotOptions: {
      spline: {
        marker: {
          enable: false,
        },
      },
      series: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.7 },
          stops: [
            [0, "#CAC5FF"],
            [2, "#FFFFFF"],
          ],
        },
      },
    },
    series: [
      {
        name: "Days",
        color: "#5143D4",
        data: [],
      },
    ],
    annotations: [
      {
        draggable: false,
        shapes: [
          {
            point: [
              {
                x: 0,
                xAxis: -1,
                y: 0,
                yAxis: 0,
              },
              {
                x: 0,
                xAxis: 0,
                y: 0,
                yAxis: 0,
              },
            ],
            type: "path",
            stroke: "#52DD81",
            dashStyle: "Dash",
            strokeWidth: 1.5,
          },
        ],
      },
    ],
  },
  DASHBOARD_CHARTOPTIONS: {
    chart: {
      type: "areaspline",
      plotBorderWidth: 1,
      height: 260,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
      allowDecimals: false,
      title: {
        text: null,
      },
      plotLines: [
        {
          color: "#FF00A8",
          zIndex: 10,
          width: 1,
          value: 4,
          label: {
            text: "Today",
          },
          style: {
            color: "#000210",
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: "",
      },
      allowDecimals: false,
      lineWidth: 1,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "#161616",
      borderColor: "black",
      borderRadius: 10,
      borderWidth: 3,
      headerFormat: null,
      pointFormat:
        " <span style='color:white;'>Hours </span> <span style='color:white; background:blue; padding:3px'>{point.y} </span> ",
    },
    plotOptions: {
      enabled: false,
      spline: {
        marker: {
          enable: false,
        },
      },
      series: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.7 },
          stops: [
            [0, "#CAC5FF"],
            [2, "#FFFFFF"],
          ],
        },
      },
    },
    series: [
      {
        name: "Days",
        color: "#5143D4",
        data: [],
      },
    ],
  },
  ADMIN_DASHBOARD_CHARTOPTIONS: {
    chart: {
      type: "areaspline",
      plotBorderWidth: 1,
      height: 240,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
      allowDecimals: false,
      title: {
        text: null,
      },
      plotLines: [
        {
          color: "#FF00A8",
          zIndex: 10,
          width: 1,
          value: 4,
          label: {
            text: "Today",
          },
          style: {
            color: "#000210",
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: "",
      },
      allowDecimals: false,
      lineWidth: 1,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "#161616",
      borderColor: "black",
      borderRadius: 10,
      borderWidth: 3,
      headerFormat: null,
      pointFormat:
        " <span style='color:white;'>Hours </span> <span style='color:white; background:blue; padding:3px'>{point.y} </span> ",
    },
    plotOptions: {
      enabled: false,
      spline: {
        marker: {
          enable: false,
        },
      },
      series: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.7 },
          stops: [
            [0, "#CAC5FF"],
            [2, "#FFFFFF"],
          ],
        },
      },
    },
    series: [
      {
        name: "Days",
        color: "#5143D4",
        data: [],
      },
    ],
  },
  HEX_COLOUR_CODE : [
    "#f32d7d", "#4cb8f1", "#fa5001", "#f3ab29", "#7FDBFF",
    "#FFDC00", "#39CCCC", "#F012BE", "#01FF70", "#85144b",
    "#3D9970", "#B10DC9", "#2E8B57", "#F012BE", "#7FDBFF",
    "#7FDBFF", "#2ECC40", "#FF4136", "#FFDC00", "#0074D9",
    "#FF851B", "#85144b", "#01FF70", "#B10DC9", "#3D9970",
    "#39CCCC", "#F012BE", "#2E8B57", "#7FDBFF", "#2ECC40",
    "#FF4136", "#0074D9", "#FF851B", "#FFDC00", "#01FF70",
    "#3D9970", "#F012BE", "#85144b", "#2E8B57", "#39CCCC",
    "#B10DC9", "#7FDBFF", "#2ECC40", "#FF4136", "#FF851B",
    "#FFDC00", "#0074D9", "#01FF70", "#3D9970", "#F012BE",
    "#2E8B57", "#39CCCC", "#85144b", "#B10DC9", "#7FDBFF",
    "#2ECC40", "#FF4136", "#0074D9", "#FF851B", "#FFDC00",
    "#3D9970", "#01FF70", "#F012BE", "#7FDBFF", "#2E8B57",
    "#39CCCC", "#B10DC9", "#85144b", "#2ECC40", "#FF851B",
    "#FF4136", "#0074D9", "#FFDC00", "#3D9970", "#01FF70",
    "#F012BE", "#7FDBFF", "#39CCCC", "#2E8B57", "#B10DC9",
    "#2ECC40", "#FF4136", "#FF851B", "#0074D9", "#FFDC00",
    "#3D9970", "#01FF70", "#85144b", "#39CCCC", "#F012BE",
    "#2E8B57", "#7FDBFF", "#B10DC9", "#FF4136", "#2ECC40"
  ] 
};

import React, { useEffect, useState } from "react";
import MemberContainer from "../../../component/common/member-container/memberContainer";
import "./projectOverView.scss";
import { useSelector, useDispatch } from "react-redux";
import OverViewDashBoard from "../../../component/project/projectOverView/overView-component/overViewDashBoard";
import CustomCarousel from "../../../component/project/projectOverView/overView-carousel/carousel";
import { getReleaseList } from "../../../store/features/Projects/Release";
import { useParams } from "react-router-dom";
import OverViewChart from "../../../component/project/projectOverView/overview-chart/overviewChart";
import { getTaskList } from "../../../store/features/Projects/Task";
import EmptyScreen from "../../../component/common/empty/emptyScreen";
import Image from "../../../utility/image";
import { getUsersByProject } from "../../../store/features/Projects/Project";
import grpUsers from "../../../assets/grp-users.svg";

export default function ProjectOverView() {
  const dispatch = useDispatch();
  const { projectMemberDetails } = useSelector((store) => store.project);

  const { allReleases } = useSelector((store) => store.release);
  const { projectId } = useParams();
  const [count, setCount] = useState({});

  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);

  useEffect(() => {
    dispatch(getReleaseList(projectId));
    dispatch(getTaskList({ projectId })).then((res) => {
      setCount(() => {
        let totalCount = {
          todo: res.payload?.statusBasedCount["To Do"],
          inProgress: res.payload?.statusBasedCount["In Progress"],
          review: res.payload?.statusBasedCount["Review"],
          done: res.payload?.statusBasedCount["Done"],
          backlog: res.payload?.totalCount,
        };
        return totalCount;
      });
    });
    return () => {};
  }, []);

  const overViewArray = [
    {
      id: 0,
      count: count?.backlog ? count?.backlog : 0,
      name: "All",
      icon: "pi pi-arrow-down-right",
      // progress: "00",
      colorCode: "#F27E11",
    },
    {
      id: 1,
      count: count?.todo ? count?.todo : 0,
      name: "To Do Tasks",
      icon: "pi pi-arrow-up-right",
      // progress: "+2",
      colorCode: "#FF006D",
    },
    {
      id: 2,
      count: count?.inProgress ? count?.inProgress : 0,
      name: "In Progress",
      icon: "pi pi-arrow-down-right",
      // progress: "+4",
      colorCode: "#5A78EE",
    },
    {
      id: 3,
      count: count?.review ? count?.review : 0,
      name: "In Review",
      icon: "pi pi-arrow-down-right",
      // progress: "+3",
      colorCode: "#FFB516",
    },
    {
      id: 4,
      count: count?.done ? count?.done : 0,
      name: "Done",
      icon: "pi pi-arrow-down-right",
      // progress: "+13",
      colorCode: "#02D5A3",
    },
  ];

  return (
    <>
      <div className="flex column-gap-3 w-12 p-3 max-h-screen">
        <div className="flex flex-column w-9 h-full">
          <div className="flex flex-column oviewStyle px-4">
            <div className="flex column-gap-3  mt-3 ">
              {overViewArray.map((item, id) => {
                return <OverViewDashBoard item={item} key={id} />;
              })}
            </div>
            {/* Line chart*/}
            <div className="mt-3 ">
              <OverViewChart />
            </div>
          </div>
          <div className="flex  justify-content-center align-items-center mt-2">
            <div className="w-full">
              {allReleases?.length > 0 ? (
                <CustomCarousel item={allReleases} type="Release" />
              ) : (
                <>
                  <EmptyScreen
                    img={Image.EmptyImg}
                    description="No Release Available Yet"
                    title=""
                    header=""
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-3 oviewStyle p-3  overflow-y-scroll">
          <h2 className="w-full team__txt">Team</h2>
          {projectMemberDetails && projectMemberDetails.length > 0 ? (
            projectMemberDetails.map(({ memberDetails }, id) => {
              return (
                <MemberContainer
                  image={memberDetails.basic.profilePic}
                  name={memberDetails.basic.fullName}
                  title={memberDetails.basic.fullName}
                  desc={memberDetails.work.designationId?.designation}
                  key={id}
                  borderRadius="18%"
                />
              );
            })
          ) : (
            <>
              <div className="mt-5">
                <img
                  className="empty-team"
                  src={grpUsers}
                  style={{ width: "17%" }}
                />
                <div className="grid flex justify-content-center announcement-time mt-3">
                  No Teams Members In This Project
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

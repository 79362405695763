import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  allReleases: [],
  allTasks: [],
  task_unCheck: [],
  allReleaseTasks: [],
  selectedRelease: {},
  skip: 1,
  from: 1,
  limit: 10,
  to: 10,
  search: "",
  maxRecord: "",
  error: "",
  status: "",
  projectId: "",
};

//Releases
export const getReleaseList = createAsyncThunk(
  "/release-list",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.RELEASE.FETCH + `?projectId=${id}` + `&limit=${initialState.limit}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createRelease = createAsyncThunk(
  "/create-release",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.RELEASE.CREATE, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateRelease = createAsyncThunk(
  "/update-release",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.put(constants.API.RELEASE.CREATE, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteRelease = createAsyncThunk(
  "/delete-release",
  async (id, {rejectWithValue}) => {
    try {
      const payload = { releases: [id] };
      const response = await api.put(constants.API.RELEASE.DELETE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

//Tasks

export const getAllTasksList = createAsyncThunk(
  "/alltasks-list",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.RELEASE.FETCH_TASK + `?projectId=${id}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getAllTaskSetting = createAsyncThunk(
  "/alltasks-list/status",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.RELEASE.FETCH_TASK + `?projectId=${data.id}&${data.type}` ;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);


export const addTask = createAsyncThunk("/add-task", async (data, {rejectWithValue}) => {
  try {
    const response = await api.put(constants.API.RELEASE.TASK_ADD, data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const getTaskByRelease = createAsyncThunk(
  "/taskrelease-list",
  async (id, {rejectWithValue}) => {
    try {
      const url =
        constants.API.RELEASE.FETCH_TASK +
        `?projectId=${id.projectId}&releaseId=${id.releaseId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "/delete-task",
  async (id, {rejectWithValue}) => {
    try {
      const payload = { taskList: [id] };
      const response = await api.put(constants.API.RELEASE.TASK_DELETE, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const releaseSlice = createSlice({
  name: "release",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setSelectedRelease: (state, action) => {
      state.selectedRelease = action.payload;
    },
  },
  extraReducers: {
    //Releases
    [getReleaseList.pending]: (state) => {
      state.isLoading = true;
    },
    [getReleaseList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allReleases = action.payload.releases;
      if (action.payload.releases.length > 0) {
        const index = state.allReleases.findIndex(
          (release) => release._id === state.selectedRelease._id
        );

        if (JSON.stringify(state.selectedRelease) === "{}" || index === -1) {
          state.selectedRelease = action.payload.releases[0];
        } else {
          state.selectedRelease = action.payload.releases[index];
        }
      }
    },
    [getReleaseList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },

    [createRelease.pending]: (state) => {},
    [createRelease.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createRelease.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [updateRelease.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [updateRelease.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [updateRelease.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
    [deleteRelease.pending]: (state) => {},
    [deleteRelease.fulfilled]: (state, action) => {
      state.allReleaseTasks = []
      state.deleteStatus = "success";
    },
    [deleteRelease.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },

    //Tasks
    [getAllTasksList.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllTasksList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allTasks = action.payload.tasks;
    },
    [getAllTasksList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
   
    // task status move modal 
    [getAllTaskSetting.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllTaskSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.task_unCheck = action.payload.tasks.map((i)=> {
      i["isSelect"] = false
      return i
      })
    },
    [getAllTaskSetting.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },


    [addTask.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [addTask.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [addTask.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
    [getTaskByRelease.pending]: (state) => {
      state.isLoading = true;
    },
    [getTaskByRelease.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allReleaseTasks = action.payload.tasks;
    },
    [getTaskByRelease.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [deleteTask.pending]: (state) => {},
    [deleteTask.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteTask.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
  },
});
export const {
  resetStatus,
  setSkip,
  setLimit,
  setFrom,
  setTo,
  setSearch,
  setMaxRecord,
  setProjectId,
  setSelectedRelease,
} = releaseSlice.actions;
export default releaseSlice.reducer;

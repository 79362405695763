import { AuthRoute } from "./router/authentication";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./router/protectedRoutes";
import { useEffect  } from "react";

import Dashboard from "./pages/Dashboard/dashboard";
import { getMemberDetails} from "./store/features/UserDetails/UserDetails";
import { useDispatch } from "react-redux";

import Users from "./pages/Users/Users";
import Timesheet from "./pages/Timesheet/timesheet";
import Scratchpad from "./pages/Scratchpad/Scratchpad";
import Wiki from "./pages/Wiki/Wiki";


import HRLayout from "./router/Layouts/HRLayout";
import Assets from "./pages/HR Managemnet/asset";
import LeaveRequest from "./pages/HR Managemnet/leave-request";
import Holiday from "./pages/HR Managemnet/holiday";
import Recognition from "./pages/HR Managemnet/recognition";
import PaySlip from "./pages/HR Managemnet/payslip";
import PayRoll from "./pages/HR Managemnet/payroll";
import Attendance from "./pages/HR Managemnet/attendance";
import Announcement from "./pages/HR Managemnet/announcement";

import ProjectLayout from "./router/Layouts/ProjectLayout";
import ProjectBacklog from './pages/Project/projectBacklog'
import ProjectFiles from './pages/Project/projectFiles'
import ProjectMembers from './pages/Project/projectMembers'
import ProjectRelease from "./pages/Project/projectRelease";
import ProjectTimesheet from './pages/Project/projectTimesheet'
import ProjectFolderFiles from "./pages/Project/projectFolderFiles";
import ProjectSettings from "./pages/Project/project-setting/projectSetting"
import ProjectOverView from "./pages/Project/projectOver-view/projectOverView";

import SettingsLayout from "./router/Layouts/SettingsLayout";
import Roles from "./pages/Settings/roles";
import Email from "./pages/Settings/email";
import { BillingDetails } from "./pages/Settings/Billing/billingDetails";
import { ProjectTask } from "./pages/Project/project-task/projectTask";
import Unauthorized from "./component/common/unauthorized/unauthorized";
import { AssetCategory } from "./pages/HR Managemnet/AssetCategory/all-asset";



function App() {
  
  const token = localStorage.getItem("token");
  //getting member details with module permission and checking initially if roles and permission are changed.
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) dispatch(getMemberDetails(token));
  }, []);

  

  return (
    <>
      <AuthRoute />
      <Routes>
        <Route  path="/expire"  element={<Unauthorized  />} exact   />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Dashboard />} exact></Route>
          <Route path="/timesheet" element={<Timesheet />} exact></Route>
          <Route path="/scratchpad" element={<Scratchpad />} exact></Route>
          <Route path="/users" element={<Users />} exact></Route>
          <Route path="/wiki" element={<Wiki />} />
          <Route path="/wiki/:pageId" element={<Wiki />} />
          <Route path="/hr" element={<HRLayout />}>
            <Route path="assets" element={<AssetCategory />} exact  />
            <Route path="assets/:assetId/:assetName" element={<Assets />}  />
            <Route path="leave-request" element={<LeaveRequest />} exact />
            <Route path="holiday-calendar" element={<Holiday />} exact />
            <Route path="recognition" element={<Recognition />} exact />
            <Route path="payslip" element={<PaySlip />} exact />
            <Route path="payroll" element={<PayRoll />} exact />
            <Route path="attendance" element={<Attendance/>} exact/> 
            <Route path="announcement" element={<Announcement />} exact />
          </Route>
          <Route path="/project" exact element={<ProjectLayout />}>
            <Route path=":projectId" element={<ProjectOverView />} exact />
            <Route path=":projectId/tasks" element={<ProjectTask />} exact />
            <Route path=":projectId/release" element={<ProjectRelease />} exact />
            <Route path=":projectId/members" element={<ProjectMembers />} exact />
            <Route path=":projectId/backlog" element={<ProjectBacklog />} exact />
            <Route path=":projectId/timesheet" element={<ProjectTimesheet />} exact />           
            <Route path=":projectId/files" element={<ProjectFiles />} exact />           
            <Route path=":projectId/files/:folderId" element={<ProjectFolderFiles />} exact />
            <Route path=":projectId/settings" element={<ProjectSettings />} exact />
          </Route>
          <Route path="/settings" element={<SettingsLayout />}>
            <Route path="role" element={<Roles />} exact />
            <Route path="email" element={<Email />} exact />
            <Route path="billing" element={<BillingDetails />} exact />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;

import { Dialog } from "primereact/dialog";
import React from "react";
import Button from "../button/button";

export default function DeleteDialog({
  deleteDialog,
  setDeleteDialog,
  onClick,
  template = "Are you sure want to delete" ,
  title = "Delete Confirmation",
}) {
  return (
    <Dialog
      header={title}
      visible={deleteDialog}
      style={{ width: "30vw" }}
      onHide={() => {
        setDeleteDialog(!deleteDialog);
      }}
    >
      <div className="flex flex-column row-gap-4">
        <h3 style={{ lineHeight: "28px" }}>{template}</h3>

        <div className="flex justify-content-end column-gap-4">
          <div
            className="announcement-time flex align-items-center"
            onClick={() => setDeleteDialog(!deleteDialog)}
          >
            Cancel
          </div>
          <Button label="Confirm" onClick={onClick} />
        </div>
      </div>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { TaskCardComponent } from "./task-card";
import "./index.scss";
import AddTaskForSprint from "../../../component/project/tasks/addTaskForSprint";
import { Badge } from "primereact/badge";

const styleSheet = {
  plusIcon: { color: "#505158", fontSize: "1rem", cursor: "pointer" },
  boardDragColor: { backgroundColor: "#dbdde1" },
};

export const Tasklist = ({ list, projectId, task }) => {
  const { toDo, Inprogress, Review, Done } = list;
  const [taskListState, setTaskListState] = useState({
    taskDialog: false,
    status: "",
  });

  const { taskDialog, status } = taskListState;
  
  const handleAddTask = (boardName) => {
    setTaskListState((prev) => {
      return {
        ...prev,
        taskDialog: true,
        status: boardName,
      };
    });
  };

  const BoardHeader = ({ boardName, fn, list }) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div className="flex column-gap-2">
          <p className="board_txt">{boardName}</p>
          <Badge value={list.length} severity="info" />
        </div>
        <div className="board_plus_box flex align-items-center justify-content-center">
          <i className="pi pi-plus" style={styleSheet.plusIcon} onClick={fn} />
        </div>
      </div>
    );
  };

  return (
    <>
      {taskDialog && (
        <AddTaskForSprint
          task={task[0]}
          taskDialog={taskDialog}
          setTaskDialog={setTaskListState}
          status={status}
          projectId={projectId}
        />
      )}

      <div className="flex column-gap-2  h-screen w-full col-12">
        {/* To Do */}
        <Droppable droppableId="To Do">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="board_layout board_width p-2"
              style={snapshot.isDraggingOver ? styleSheet.boardDragColor : {}}
            >
              <BoardHeader
                boardName="Todo"
                list={toDo}
                fn={() => handleAddTask("To Do")}
              />
              {toDo?.map((todo, index) => (
                <TaskCardComponent item={todo} index={index} key={todo._id} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {/* Inprogress */}
        <Droppable droppableId="Inprogress">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="board_layout board_width p-2"
              style={snapshot.isDraggingOver ? styleSheet.boardDragColor : {}}
            >
              <BoardHeader
                boardName="Inprogress"
                list={Inprogress}
                fn={() => handleAddTask("Inprogress")}
              />
              {Inprogress?.map((pending, index) => (
                <TaskCardComponent
                  item={pending}
                  index={index}
                  key={pending._id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {/* Review */}
        <Droppable droppableId="Review">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="board_layout board_width p-2"
              style={snapshot.isDraggingOver ? styleSheet.boardDragColor : {}}
            >
              <BoardHeader
                boardName="Review"
                list={Review}
                fn={() => handleAddTask("Review")}
              />
              {Review?.map((review, index) => (
                <TaskCardComponent
                  item={review}
                  index={index}
                  key={review._id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {/* Done */}
        <Droppable droppableId="Done">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="board_layout board_width p-2"
              style={snapshot.isDraggingOver ? styleSheet.boardDragColor : {}}
            >
              <BoardHeader
                boardName="Done"
                list={Done}
                fn={() => handleAddTask("Done")}
              />
              {Done?.map((done, index) => (
                <TaskCardComponent item={done} index={index} key={done._id} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SettingTab, isAdminAccess } from "../../utility/utility";
import { useSelector } from "react-redux";

const SettingsEmbeddedRoute = () => {
  const [_settingTab, setSettingTab] = useState([]);
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const [active, setActive] = useState(null);
  const hrAdmin = isAdminAccess("Settings");

  useEffect(() => {
    setSettingTab(() => {
      if (!hrAdmin) return SettingTab?.filter((tab) => tab !== "Billing");
      return SettingTab;
    });
  }, [hrAdmin]);


  useEffect(() => {
    if (pathname === "/settings/role") setActive("Roles & Permission");
    else if (pathname === "/settings/email") setActive("Email");
    else if (pathname === "/settings/billing") setActive("Billing");
  }, []);

  const clickHandler = (isActiveTab) => {
    setActive(isActiveTab);
    switch (isActiveTab) {
      case "Roles & Permission":
        Navigate("/settings/role");
        break;
      case "Email":
        Navigate("/settings/email");
        break;
      case "Billing":
        Navigate("/settings/billing");
        break;
      default:
        Navigate("/settings/role");
        break;
    }
  };

  return (
    <div className="flex flex-column row-gap-3">
      {_settingTab.map((item, id) => {
        return (
          <p
            key={id}
            onClick={() => clickHandler(item)}
            className={`${
              active === item
                ? "setting__role setting__active"
                : "setting__role"
            }`}
          >
            {item}
          </p>
        );
      })}
    </div>
  );
};

export default SettingsEmbeddedRoute;

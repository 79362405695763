import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import {
  removeImage,
  resetRemoveImageUrlStatus,
  uploadImage,
  resetImageUrl,
} from "../../../store/features/Image/image";
import { useDispatch, useSelector } from "react-redux";

const chooseOptions = {
  icon: "pi pi-fw pi-upload",
  iconOnly: true,
  className: "choose__file",
};


export default function TaskFileUpload({ setUpdateValue , attachments }) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [imageName, setImageName] = useState("");



  const fileUploadRef = useRef(null);
  const [totalSize, setTotalSize] = useState("");
  const [removeImageUrl, setRemoveImageUrl] = useState("");
  const { imageUrl, removeImageUrlStatus } = useSelector(
    (store) => store.image
  );

  
  useEffect(() => {
    if (imageUrl !== "") {
      setUpdateValue(prev => {
        return {...prev, attachments : [
          ...attachments,
          { isExist: true, imageUrl, name: imageName, isLink: false },
        ] }
      })
      dispatch(resetImageUrl());
    }
  }, [imageUrl]);



  useEffect(() => {
    if (imageUrl == "" && removeImageUrlStatus == "success") {
      const data = attachments.filter(
        (attachement) => attachement.imageUrl !== removeImageUrl
      );
      setUpdateValue(prev => {
        return {...prev, attachments : data}
      })
      setRemoveImageUrl("");
      dispatch(resetRemoveImageUrlStatus());
    }
  }, [removeImageUrlStatus]);


  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const uploadHandler = async (e) => {
    try {
      const fileData = new FormData();
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", async () => {
            fileData.append("file", files[0]);
            setImageName(files[0].name);
            dispatch(uploadImage({ folderName: "task", data: fileData })).then(
              (res) => {
                e.options.clear();
                if (res.payload.URL) {
                  toast.current.show({
                    severity: "success",
                    summary: "Success Message",
                    detail: res.payload.message,
                    life: 3000,
                  });
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: "upload issuse",
                    detail: "",
                    life: 3000,
                  });
                }
              }
            );
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const removeAttachment = async (attachment) => {
    setRemoveImageUrl(attachment.imageUrl);
    dispatch(removeImage({ folderName: "task", data: attachment.imageUrl }));
  };


  return (
    <>
      <div
        className="flex flex-column row-gap-2
        justify-content-center align-items-center p-2"
      >
        <Toast ref={toast} />
        <label>File upload</label>
        <FileUpload
          ref={fileUploadRef}
          maxFileSize={1000000}
          name="demo"
          url="./upload"
          mode="basic"
          auto
          customUpload
          onError={onTemplateClear}
          onClear={onTemplateClear}
          chooseOptions={chooseOptions}
          uploadHandler={uploadHandler}
        />
      </div>
      <div
        className="flex flex-column row-gap-2 p-1"
        style={
          attachments.length >= 3
            ? { height: "100px", overflow: "scroll" }
            : { maxHeight: "100px" }
        }
      >
        {attachments.map((attachment, id) => {
          return (
            <div
              key={id}
              className="preview__img flex justify-content-between align-items-center"
            >
              <div
                onClick={() => window.open(attachment.imageUrl)}
                className="flex"
              >
                <p style={{ fontSize: "14px" }}>{attachment.name}</p>
              </div>
              <div>
                <i
                  className="pi pi-times-circle cursor-pointer"
                  style={{ fontSize: "1em", color: "red" }}
                  onClick={() => removeAttachment(attachment)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}



import React , { useState }  from "react";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import "./taskCard.scss";
import Image from "../../../utility/image";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTask,
  setSelectedTask,
} from "../../../store/features/Projects/Task";
import UpdateTaskDialog from "../tasks/updateTaskDialog";
import { fn_firstcharacter } from "../../../utility/utility";
import Alphabet from "../../common/alphabet/alphabet";

export default function TaskCard({ task, active, sprint }) {
  const dispatch = useDispatch();
  const { selectedTask } = useSelector(
    (store) => store.task
  );

    const [updateTaskDialog, setUpdateTaskDialog] = useState(false)

  function checkboxHandler(e) {
    dispatch(setSelectedTask({ _id: task._id, sprintId: task.sprintId }));
  }

  function taskCheckBoxChecked(task) {
    if (
      selectedTask[task.sprintId] &&
      selectedTask[task.sprintId].task.includes(task._id)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const editClickHandler = () => {
    dispatch(setActiveTask(task));
    setUpdateTaskDialog(!updateTaskDialog)
  };

  const taskImg = (props) => {
    if (props === "Bug") return Image.bugImage;
    else if (props === "Task") return Image.taskImage;
    else if (props === "Story") return Image.storyImage;
  };

  const Priority = (props) => {
    if (props === "Low") return Image.lowPriorityImg;
    else if (props === "Medium") return Image.MediumPriorityImg;
    else if (props === "High") return Image.MediumPriorityImg;
  };

  return (
    <>
      {updateTaskDialog && (
        <UpdateTaskDialog
          taskDialog={updateTaskDialog}
          setUpdateTaskDialog={setUpdateTaskDialog}
        />
      )}

      <Tooltip target=".logo" />

      <div className="checkbox-layout flex w-full justify-content-between p-2">
        <div className="flex align-items-center column-gap-2">
          <img
            width={20}
            height={20}
            src={Priority(task.priority)}
            alt="bug"
            data-pr-tooltip={task.priority}
          />

          {sprint?.status !== "Completed" && (
            <Checkbox
              checked={taskCheckBoxChecked(task)}
              onMouseUp={(e) => checkboxHandler(e)}
            />
          )}

          <img
            width={20}
            height={20}
            src={taskImg(task.type)}
            alt={task.type}
            data-pr-tooltip={task.type}
          />

          <p>{task.taskId}</p>
          <p>{task.title}</p>
        </div>

        <div className="flex align-items-center column-gap-2">
          {task.storyPoint && task.storyPoint.length > 0 ? (
            <div className="tasks__count flex align-items-center justify-content-center">
              {task.storyPoint}
            </div>
          ) : (
            <div className="tasks__round__count flex align-items-center justify-content-center p-1">
              {0}
            </div>
          )}

          <div onClick={editClickHandler} className="flex align-items-center cursor-pointer">
            <img src={Image.todoImg} alt="ToDo" />
          </div>

          <h1>{task?.assignee?.fullName}</h1>

          {task?.assignee ? (
            <Alphabet
              letter={fn_firstcharacter(task?.assignee?.basic?.fullName)}
            />
          ) : (
            <img
              className="cursor-pointer"
              width={33}
              height={33}
              src={Image.emptyAvatar}
              alt="unassigned"
            />
          )}
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import Input from "../../component/common/inputtext/input-field";
import Button from "../../component/common/button/button";
import {
  forgetPassword,
  resetStatus,
} from "../../store/features/Auth/forgetPassword";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import validate from "./../../factory-function/validate";
import { setIsLoading } from "./../../store/features/Auth/forgetPassword";

export default function ForgotPassword() {
  
  const toast = useRef(null);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [loader, setloader] = useState(false)
  const [email, setEmail] = useState({
    email: "",
  });
  const { status, error, isLoading } = useSelector(
    (state) => state.forgetPassword
  );

  const [errorDetails, setErrorDetails] = useState({
    email: "",
  });

  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(email).every((val) => val !== "");
    dispatch(setIsLoading(x && y));
  }, [errorDetails]);


  useEffect(() => {
    if (status === "error") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "",
        life: 3000,
      });
    }
    if (status === "success") {
      const Email = encodeURIComponent(email.email)
      Navigate(`/auth/otp-verify?email=${Email}`);
    }
    dispatch(resetStatus());
  }, [status, error]);

  function changeHandler(e) {
    setEmail({ ...email, [e.target.name]: e.target.value });
    setErrorDetails({
      ...errorDetails,
      [e.target.name]: validate({ [e.target.name]: e.target.value }),
    });
  }

  function submitHandler() {
    setloader(true);
    dispatch(forgetPassword(email.email)).then(()=> setloader(false))
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-4">

        <div className="flex flex-column row-gap-2">
          <p className="h1-font">Forgot Password?</p>
          <p className="paragraph-font">Fill the form to reset your password</p>
        </div>

        <div>
          <Input
            placeholder="Your Email"
            type="email"
            name="email"
            onChange={changeHandler}
            label="Email"
          />
          <span className="error-text">{errorDetails.email}</span>
        </div>

        <div className="flex justify-content-end">
          <Button
            onClick={submitHandler}
            label="Send OTP"
            disabled={isLoading}
            loading={loader}
          />
        </div>

      </div>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  scratchpad: [],
  status: "",
  error: "",
  limit: 10,
  skip: 1,
  maxRecord: "",
  tempScratchpad: {},
  selectedScratchpad: {},
  deleteDialog: false,
  scratchpadCreate: false,
  imageURL: "",
  addImageStatus: "",
  removeImageStatus: "",
  createCount: 0,
};

export const getScratchpad = createAsyncThunk(
  "/get-scratchpad",
  async (state, { rejectWithValue }) => {
    try {
      const url =
        constants.API.SCRATCHPAD.CREATE +
        `?limit=${state.limit}&limit=${state.skip}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const createScratchpad = createAsyncThunk(
  "/create-scratchpad",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SCRATCHPAD.CREATE;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateScratchpad = createAsyncThunk(
  "/update-scratchpad",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SCRATCHPAD.CREATE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteScratchpad = createAsyncThunk(
  "/delete-scratchpad",
  async (id, { rejectWithValue }) => {
    try {
      const url = constants.API.SCRATCHPAD.CREATE + id;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const addScratchpadImage = createAsyncThunk(
  "/add-image-scratchpad",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const url = constants.API.SCRATCHPAD.CREATE + id + "/background/image";
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const removeScratchpadImage = createAsyncThunk(
  "/remove-image-scratchpad",
  async (id, { rejectWithValue }) => {
    try {
      const url = constants.API.SCRATCHPAD.CREATE + id + "/background/image";
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const scratchpadSlice = createSlice({
  name: "scratchpad",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setCreateCount: (state, action) => {
      state.createCount = action.payload;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setScratchpadCreate: (state, action) => {
      state.scratchpadCreate = action.payload;
    },
    setRemoveImageStatus: (state, action) => {
      state.removeImageStatus = "";
    },
    setLimit: (state, action) => {
      state.skip = action.payload;
    },
    setDeleteDialog: (state, action) => {
      state.deleteDialog = action.payload;
    },
    setSelectedScratchpad: (state, action) => {
      state.selectedScratchpad = action.payload;
    },
    resetImageUploadStatus: (state, action) => {
      state.imageURL = "";
      state.addImageStatus = "";
    },
  },
  extraReducers: {
    [getScratchpad.pending]: (state) => {
      state.isLoading = true;
    },
    [getScratchpad.fulfilled]: (state, action) => {
      state.scratchpad = action.payload.scratchPads;
      if (action.payload.scratchPads.length > 0) {
        const index = state.scratchpad.findIndex(
          (scrat) => scrat._id === state.selectedScratchpad._id
        );

        if (JSON.stringify(state.selectedScratchpad) == "{}" || index == -1) {
          state.selectedScratchpad = action.payload.scratchPads[0];
        } else {
          state.selectedScratchpad = action.payload.scratchPads[index];
        }
      }
      state.maxRecord = action.payload.requestsCount;
      state.isLoading = false;
    },
    [getScratchpad.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = false;
    },
    [createScratchpad.pending]: (state) => {
      state.isLoading = true;
    },
    [createScratchpad.fulfilled]: (state, action) => {
      state.tempScratchpad = action.payload.scratchPads;
      state.createCount = state.createCount + 1;
      if (state.createCount == 2) {
        state.status = "success";
        state.scratchpadCreate = false;
        state.createCount = 0;
      }
      state.isLoading = false;
    },
    [createScratchpad.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
    [updateScratchpad.pending]: (state) => {
      state.isLoading = true;
    },
    [updateScratchpad.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [updateScratchpad.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
    [deleteScratchpad.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteScratchpad.fulfilled]: (state, action) => {
      state.status = "success";
      state.deleteDialog = false;
      state.isLoading = false;
    },
    [deleteScratchpad.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = false;
    },
    [addScratchpadImage.pending]: (state) => {
      state.isLoading = true;
    },
    [addScratchpadImage.fulfilled]: (state, action) => {
      state.imageURL = action.payload.message.image;
      state.addImageStatus = "success";
      state.isLoading = false;
    },
    [addScratchpadImage.rejected]: (state, action) => {
      state.addImageStatus = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
    [removeScratchpadImage.pending]: (state) => {
      state.isLoading = true;
    },
    [removeScratchpadImage.fulfilled]: (state, action) => {
      state.removeImageStatus = "success";
      state.isLoading = false;
    },
    [removeScratchpadImage.rejected]: (state, action) => {
      state.removeImageStatus = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  resetStatus,
  setLimit,
  setSkip,
  setCreateCount,
  setRemoveImageStatus,
  setSelectedScratchpad,
  resetImageUploadStatus,
  setDeleteDialog,
  setScratchpadCreate,
} = scratchpadSlice.actions;

export default scratchpadSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import ProjectTimesheetTable from "../../component/project/projectTimesheet/projectTimesheetTable";
import DropdownFilter from "./../../component/timesheet/dropdownFilter";
import TimesheetFilter from "./../../component/timesheet/timesheetFilter";
import ExportTimesheet from "./../../component/timesheet/exportTimesheet";
import InputIcon from "./../../component/common/inputIcon/inputIcon";
import Button from "./../../component/common/button/button";
import { useDispatch } from "react-redux";
import {
  setSearch,
  getTimesheet,
  setProjectId,
} from "../../store/features/Timesheet/timesheet";
import exportImage from "./../../assets/export.svg";
import { useSelector } from "react-redux";
import AddProjectTimesheet from "../../component/project/projectTimesheet/addProjectTimesheet";
import { getStartAndEndDate } from "../../factory-function/getStartDateAndEndDate";
import { useParams } from "react-router-dom";
import { isAdminAccess } from "../../utility/utility";

export default function ProjectTimesheet() {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [exportDialog, setExportDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [addTimesheetDialog, setAddTimesheetDialog] = useState(false);
   const hrAdmin = isAdminAccess("Human Resource")

  useEffect(() => {
    if(projectId) dispatch(setProjectId(projectId));
  }, []);

   
  useEffect(() => {
    dispatch(getTimesheet());
  }, [searchValue]);



  return (
    <div className="flex flex-column row-gap-4">
      {addTimesheetDialog && (
        <AddProjectTimesheet
          addTimesheetDialog={addTimesheetDialog}
          setAddTimesheetDialog={setAddTimesheetDialog}
        />
      )}

      {exportDialog && (
        <ExportTimesheet
          exportDialog={exportDialog}
          setExportDialog={setExportDialog}
        />
      )}

      <div className="flex  align-items-center justify-content-between column-gap-2 mx-4">
        <div className="search-bar">
          <InputIcon
            placeholder="Search"
            onChange={(e) => {
              dispatch(setSearch(e.target.value));
              setSearchValue(e.target.value);
            }}
          />
        </div>

        <div className="flex  align-items-center column-gap-2 justify-content-end">
          <DropdownFilter module="projectTimesheet" />
          <>
            <Button
              className="filter-button"
              label="Filter"
              icon="pi pi-filter"
              onClick={() => setFilterDialog(!filterDialog)}
            />
            <TimesheetFilter
              setFilterDialog={setFilterDialog}
              filterDialog={filterDialog}
              hrAdmin={hrAdmin}
              showFilterAllProject={false}
            />
          </>

          <>
            <Button
              label="Create"
              onClick={() => setAddTimesheetDialog(!addTimesheetDialog)}
            />
          </>

          {hrAdmin && (
            <img
              src={exportImage}
              width={20}
              height={20}
              className="hover"
              onClick={() => setExportDialog(!exportDialog)}
            />
          )}
        </div>
      </div>
      <ProjectTimesheetTable hrAdmin={hrAdmin} />
    </div>
  );
}

import React, { useState } from "react";
import { ChipLowBtn } from "../../../component/common/chip/chip";
import {
  completeSprint,
  getSprintList,
} from "../../../store/features/Projects/Sprint";
import CompleteSprintModel from "../../../component/project/tasks/completeSprintModel";
import { useDispatch } from "react-redux";
import Button from "../../../component/common/button/button";

const _dateFormat = (date) => {
  return new Date(date).toLocaleDateString("en-uk", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const ActiveSprint = ({ currentSprint, projectId }) => {
  const [completeDialog, setCompleteDialog] = useState(false);
  const dispatch = useDispatch();

  const handleComplete = () => {
    dispatch(completeSprint(currentSprint._id)).then((res) => {
      setCompleteDialog(!completeDialog)
      if (!res.error)
        dispatch(getSprintList(projectId))
    });
  };

  return (
    <>
      {completeDialog && (
        <CompleteSprintModel
          completeSprintDialog={completeDialog}
          setCompleteSprintDialog={setCompleteDialog}
          sprintCompleteHandler={handleComplete}
          // hasTasks={hasTasks}
        />
      )}
      <div className="flex align-items-center justify-content-between mx-4">
        <div className="flex align-items-center column-gap-2">
          <div className="announcement-time">Sprint Name</div>
          <ChipLowBtn
            label={currentSprint?.title}
            className="chip__high__btn chip"
          />
        </div>

        <div className="flex  align-items-center column-gap-2">
          <div className="flex align-items-center column-gap-2 ">
            <div className="announcement-time ">Duration :</div>
            <div className="endDate-btn">
              {_dateFormat(currentSprint?.startDate)}
            </div>
            <p className="announcement-time ">{"-"}</p>
            <div className="endDate-btn">
              {_dateFormat(currentSprint?.endDate)}
            </div>
          </div>
          <Button
            className="create-btn"
            label="Complete Sprint"
            onClick={() => setCompleteDialog(!completeDialog)}
          />
        </div>
      </div>
    </>
  );
};

import React from "react";
import { FileUpload } from "primereact/fileupload";
import Button from "../../common/button/button";
import "./fileUpload.scss";

export default function ProfileUpload({
  labelName,
  uploadHandler,
  profileImg,
  remove,
}) {
  const chooseOptions = {
    icon: "pi pi-fw pi-upload",
    iconOnly: true,
    className: "choose__file",
  };

  return (
    <>
      <div
        className="flex flex-column  align-items-center gap-2 my-4"
        style={{ minHeight: "100px" }}
      >
        {( profileImg !== "" &&  profileImg !== undefined && profileImg !== null) ? (
          <>
            <img src={profileImg} className="profile__img" />
            <Button
              className="primary"
              label="Remove Image"
              onClick={remove}
            />
          </>
        ) : (
          <>
            <label>{labelName}</label>
            <FileUpload
              name="demo"
              url="./upload"
              mode="basic"
              accept="image/*"
              auto
              customUpload
              chooseOptions={chooseOptions}
              uploadHandler={uploadHandler}
            />
          </>
        )}
      </div>
    </>
  );
}

import formatDate from "./formatDate";

export function getStartAndEndDate(value){
    switch (value){
        case "Today":
          var curr = new Date(); // get current date
          return {startDate:formatDate(curr),endDate:formatDate(curr)}
       case "Current Week":
            var curr = new Date(); // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            return {startDate:formatDate( new Date(curr.setDate(first))),endDate:formatDate(new Date(curr.setDate(last)))}
        case "Last Week":
            var d = new Date(); // get current date
            var last = d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000);// First day is the day of the month - the day of the week
            var first =  d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000); // last day is the first day + 6
            return {startDate:formatDate(first),endDate:formatDate(last)}
        case "Current Month":
            var date = new Date();
            var first = new Date(date.getFullYear(), date.getMonth(), 1);
            var last = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            return {startDate:formatDate(first),endDate:formatDate(last)}
        case "Last Month":
            var date = new Date();
            var first = new Date(date.getFullYear(), date.getMonth()-1, 1);
            var last = new Date(date.getFullYear(), date.getMonth(), 0);
           return {startDate:formatDate(first),endDate:formatDate(last)}
    }
}
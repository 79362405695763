import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import DashboardProfile from "../../component/dashboard/dashboardProfile";
import LeaveStatus from "../../component/dashboard/leaveStatus";
import ProjectSprintsTable from "../../component/dashboard/projectSprintsTable";
import TimeSpent from "../../component/dashboard/timeSpent";
import UpcomingSprints from "../../component/dashboard/upcomingSprints";
import CustomCarousel from "../../component/project/projectOverView/overView-carousel/carousel";
import { getAllProjects } from "../../store/features/Projects/Project";
import Image from "../../utility/image";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { allprojects } = useSelector((store) => store.project);

  useEffect(() => {
    dispatch(getAllProjects({ skip: 0, limit: 10, search: "" }));
  }, []);

  return (
    <>
      <div className="flex">
        <div className="col-9 flex flex-column">
          <div className="flex col-12">
            <div className="col-6">
              <TimeSpent />
            </div>
            <div className="col-6">
              <LeaveStatus />
            </div>
          </div>
          <div>
            {allprojects && allprojects.length > 0 ? (
              <CustomCarousel item={allprojects} type="Projects" />
            ) : (
              <EmptyScreen
                img={Image.EmptyImg}
                description="No Project Available Yet"
              />
            )}
          </div>
        </div>
        <div className="col-3">
          <DashboardProfile />
        </div>
      </div>
      <div className="flex">
        <div className="col-9 flex flex-column">
          <ProjectSprintsTable />
        </div>
        <div className="col-3">
          <UpcomingSprints />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import Input from "../../common/inputtext/input-field";
import { Calendar } from "primereact/calendar";
import Button from "../../common/button/button";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  updateRelease,
  getReleaseList,
} from "../../../store/features/Projects/Release";

export default function UpdateRelease({
  updateReleaseDialog,
  setUpdateReleaseDialog,
  selectedItem,
  setSelectedItem
}) {
  const toast = useRef(null);
  const dispatch = useDispatch();  
  const { projectId } = useParams();
  const [updateItem, setUpdateItem] = useState({});


  useEffect(() => {
    dispatch(getReleaseList(projectId));
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedItem) != "{}") {
      setUpdateItem({ ...selectedItem });
    }
  }, [selectedItem]);

  const inputHandler = (e,field) => {
    let data = {...updateItem}
     data[field]= e;   
     data = {...data}
     setUpdateItem(data)

  }

  const submitHandler = async () => {
    let data = {  ...updateItem };
    dispatch(updateRelease(data));
    setUpdateReleaseDialog(!updateReleaseDialog);
    dispatch(getReleaseList(projectId));
  };


  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Edit Release"
        visible={updateReleaseDialog}
        style={{ width: "40vw" }}
        onHide={() => {
          setUpdateReleaseDialog(!updateReleaseDialog);
          
        }}
      >
        <div className="mt-4">
          <Input
            className="asset-input"
            placeholder="Project Name"
            name="name"
            label="Project Name"
            type="text"
            value={updateItem.name}
            onChange={(e) => inputHandler(e.target.value,"name")}
            required={true}
          ></Input>
        </div>

        <div className="grid mt-3">
          <div className="col-6 pr-3">
            <Calendar
              dateFormat="dd/mm/yy"
              placeholder="Start Date"
              readOnlyInput
              value={new Date(updateItem.startDate)}
              onChange={(e) => inputHandler(e.target.value,"startDate")}
            ></Calendar>
          </div>
          <div className="col-6">
            <Calendar
              dateFormat="dd/mm/yy"
              placeholder="End Date"
              readOnlyInput
              value={new Date(updateItem.endDate)}
              onChange={(e) => inputHandler(e.target.value,"endDate")}
            ></Calendar>
          </div>
        </div>

        <div className="mt-4">
          <Input
            className="asset-input"
            placeholder="Description"
            name="description"
            label="Description"
            type="text"
            value={updateItem.description}
            onChange={(e) => inputHandler(e.target.value,"description")}
            required={true}
          ></Input>
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button label="Submit" onClick={submitHandler}></Button>
        </div>
      </Dialog>
    </div>
  );
}

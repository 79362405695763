import React, { useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import StoryCard from "../../../component/project/projectstorycard";

export const TaskCardComponent = ({ item, index }) => {
  
  
  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided,snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="flex flex-column row-gap-2"
          >
          <StoryCard  story={item} active={snapshot.isDragging}/>
          </div>
        );
      }}
    </Draggable>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import { GetRoutesInOrder } from "../../../factory-function/getRoutesInOrder";
import api from "../../../axios/axios-interceptor";

const initialState = {
  memberDetails: {},
  skip: 0,
  limit: 10,
  notifications: [],
};

export const getMemberDetails = createAsyncThunk(
  "/getmemberDetails",
  async (_, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.USER;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getNotications = createAsyncThunk(
  "/notification",
  async (data, {rejectWithValue}) => {
    try {
      const { skip, limit } = data;
      const url =
        constants.API.NOTIFICATION.TODAY + `?limit=${limit}&skip=${skip}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const memberDetailsSlice = createSlice({
  name: "getMemberDetails",
  initialState,
  reducers: {
    setMemberDetailsAfterLogin: (state, action) => {
      state.memberDetails = action.payload;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },

    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: {
    [getMemberDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMemberDetails.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      localStorage.setItem("user", JSON.stringify(action.payload?.memberDetails));
      state.memberDetails = action.payload?.memberDetails;
      let routes = state.memberDetails.modulePermission.map((mod) => {
        return mod.moduleName;
      });

      state.memberDetails.routes = GetRoutesInOrder(routes);
    },
    [getMemberDetails.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = true;
      state.error = action.payload;
    },
    [getNotications.pending]: (state) => {
      state.isLoading = true;
    },
    [getNotications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload.result;
    },
    [getNotications.rejected]: (state, action) => {
      state.isLoading = false;
      state.status = "error";
    },
  },
});

export const { setMemberDetailsAfterLogin, setLimit, setSkip, setUser } =
  memberDetailsSlice.actions;

export default memberDetailsSlice.reducer;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Navbar from "../component/common/navbar";

export default function ProtectedRoutes() {
  const token = localStorage.getItem("token");
  return token ? (
    <div>
      <Navbar />
      <div
        className="template-background pt-5"
        style={{ margin: "auto", maxWidth: "1440px", minHeight: "90vh" }}
      >
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/auth/login" />
  );
}

import React, { useState, useEffect } from "react";
import trash from "../../assets/trash.svg";
import { Editor } from "primereact/editor";
import Button from "./../common/button/button";
import Input from "./../common/inputtext/input-field";
import { useDispatch, useSelector } from "react-redux";
import {
  createScratchpad,
  deleteScratchpad,
  updateScratchpad,
  setDeleteDialog as deleteDialogfun,
  resetStatus,
  removeScratchpadImage,
  resetImageUploadStatus,
} from "../../store/features/Scratchpad/scratchpad";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import AddScratchpadImage from "./AddScratchpadImage";
import {
  dataFormat_long,
  first_Chart,
  fn_firstcharacter,
  isEmptyObject,
} from "../../utility/utility";
import { Tooltip } from "primereact/tooltip";
import Alphabet from "../common/alphabet/alphabet";

export default function ScartchpadEditorDialog() {
  const dispatch = useDispatch();
  const scratchpad = useSelector((store) => store.scratchpad);
  const { selectedScratchpad, deleteDialog, scratchpadCreate, error, status } =
    useSelector((store) => store.scratchpad);
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  //update
  const [updateNote, setUpdateNote] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [edit, setEdit] = useState(false);

  //image dialog
  const [addImageDialog, setAddImageDialog] = useState(false);

  //validation
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (note === "" || title === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [note, title]);

  useEffect(() => {
    if (!isEmptyObject(selectedScratchpad)) {
      setUpdateNote(selectedScratchpad.note);
      setUpdateTitle(selectedScratchpad?.title);
    }
  }, [selectedScratchpad]);

  const submitHandler = () => {
    const data = { ...scratchpad.tempScratchpad };
    data.title = title;
    data.note = note;
    data.id = data._id;
    data.isTemp = false;
    dispatch(createScratchpad(data));
  };

  const updateHandler = () => {
    const data = { ...selectedScratchpad };
    if (selectedScratchpad.image && selectedScratchpad.image != "") {
      if (!updateNote.includes("<img")) {
        dispatch(removeScratchpadImage(selectedScratchpad._id));
        data.image = "";
      }
    }
    dispatch(updateScratchpad({...data, title : updateTitle ,note : updateNote, id : data._id }));
    setEdit(false)
  };

  const deleteHandler = () => {
    dispatch(deleteScratchpad(selectedScratchpad?._id));
  };

  function setDeleteDialog(val) {
    dispatch(deleteDialogfun(val));
  }

  useEffect(() => {
    if (status == "success") {
      setNote("");
      setTitle("");
      dispatch(resetStatus());
    }
  }, [status]);


  const CreatedBy = ({ item }) => {
    return (
      <div className="flex align-items-center justify-content-center column-gap-1">
        {item.profilePic && item.profilePic != "" ? (
          <img
            className="border-circle"
            src={item?.profilePic}
            alt={item?.fullName}
            height={40}
            width={40}
          />
        ) : (
          <Alphabet letter={fn_firstcharacter(item?.fullName)} />
        )}
        <span className="scratchpad-body-font">{item?.fullName}</span>
      </div>
    );
  };

  const EditorHeader = (
    <>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="0">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="0">Normal</option>
        </select>
        <select className="ql-font">
          <option></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-bold" aria-label="Bold"></button>
        <button
          type="button"
          className="ql-italic"
          aria-label="Italic"
        ></button>
        <button
          type="button"
          className="ql-underline"
          aria-label="Underline"
        ></button>
      </span>
      <span className="ql-formats">
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </span>
      <span className="ql-formats">
        <button
          type="button"
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          type="button"
          className="ql-list"
          value="bullet"
          aria-label="Unordered List"
        ></button>
        <select className="ql-align">
          <option defaultValue></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span className="ql-formats">
        <button
          type="button"
          className="ql-link"
          aria-label="Insert Link"
        ></button>
        <button
          type="button"
          className="ql-code-block"
          aria-label="Insert Code Block"
        ></button>
      </span>
      <span className="ql-formats">
        <button
          type="button"
          className="ql-clean"
          aria-label="Remove Styles"
        ></button>
      </span>
    </>
  );



  if (scratchpadCreate) {
    return (
      <div>
        {/* <AddScratchpadImage setAddImageDialog={setAddImageDialog} addImageDialog={addImageDialog}></AddScratchpadImage> */}
        {
          <div className="scratchpad-dialog-border">
            <div className="flex justify-content-between align-items-center mb-4">
              <Input
                className="input_box scratchpad-header-font"
                placeholder="title"
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <div>
                <Button
                  label="Create"
                  onClick={submitHandler}
                  disabled={disable}
                />
              </div>
            </div>
            <Editor
              style={{ height: "400px" }}
              value={note}
              onTextChange={(e) => setNote(e.htmlValue)}
              headerTemplate={EditorHeader}
            />
          </div>
        }
      </div>
    );
  } else if (JSON.stringify(selectedScratchpad) !== "{}") {
    return (
      <div>
        <AddScratchpadImage
          setAddImageDialog={setAddImageDialog}
          addImageDialog={addImageDialog}
        />
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
        <div className="scratchpad-dialog-border">
          <div className="flex flex-column row-gap-3 w-full mb-4">
            <div className="scratchpad__nav">
              {selectedScratchpad.title
                ? `Scratchpad > ${selectedScratchpad.title}`
                : "Scratchpad"}
            </div>
            <div className="flex align-items-center justify-content-between">
              {!edit ? (
                <div className="scratchpad__title">
                  {selectedScratchpad.title}
                </div>
              ) : (
                <Input
                  className="col scratchpad-header-font"
                  placeholder="title"
                  label="Title"
                  value={updateTitle}
                  onChange={(e) => setUpdateTitle(e.target.value)}

                />
              )}
              <div className="flex column-gap-4">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="pi pi-save hover custom-target-icon"
                  style={{ color: "#82858d" }}
                  onClick={updateHandler}
                  data-pr-tooltip="save"
                  data-pr-position="top"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                ></i>
                <i
                  className="pi pi-pencil hover"
                  style={{ color: "#82858d" }}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                ></i>
                <i
                  className="pi pi-trash hover"
                  style={{ color: "#82858d" }}
                  onClick={() => dispatch(deleteDialogfun(!deleteDialog))}
                />
                <i
                  className="pi pi-image hover"
                  style={{ color: "#82858d" }}
                  onClick={() => setAddImageDialog(!addImageDialog)}
                ></i>
              </div>
            </div>
            <div className="flex column-gap-3 align-items-center ">
              <div className="w-7rem">Created By</div>
              <CreatedBy item={memberDetails} />
            </div>
            <div className="flex column-gap-3 align-items-center ">
              <div className="w-7rem">Last Modified</div>
              <div className="scratchpad-body-font">
                {dataFormat_long(selectedScratchpad?.updatedAt)}
              </div>
            </div>
          </div>
          <Editor
            style={{ height: "320px" }}
            value={selectedScratchpad.note}
            headerTemplate={EditorHeader}
            onTextChange={(e) => setUpdateNote(e.htmlValue)}
          />
        </div>
      </div>
    );
  }
}

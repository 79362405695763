import React, { useRef, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./checkoutform.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  editActiveIndex,
  selectPlanApi,
} from "../../../store/features/Auth/signup";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { getNames } from "country-list";
import Button from "../../../component/common/button/button";
import { setActiveIndexExistingStep } from "../../../store/features/Auth/login";

export default function CheckoutForm({isExisting}) {
  const toast = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectplanPayload } = useSelector((store) => store.signUp);
  const [paymentData, setPaymentData] = useState({
    cardHolderName: "",
    country: "",
  });
  const [loader, setloader] = useState(false);

  const handlePayment = async () => {
    setloader(true);
    const { error, token } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );
    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: error,
        life: 3000,
      });
    } else {
      const payload = {
        ...selectplanPayload,
        ...paymentData,
        ["cardToken"]: token.id,
      };
      dispatch(selectPlanApi(payload)).then((res) => {
        if (!res.error) {
          toast.current.show({
            severity: "success",
            detail: "Account Created Successfully",
            life: 3000,
          });
          setTimeout(() => {
            setloader(false);
            navigate("/auth/login");
            dispatch(editActiveIndex(0));
          }, 1000);
        } else {
          setloader(false);
          toast.current.show({
            severity: "error",
            detail: "Error in Cards Details Try with another card details",
            life: 3000,
          });
        }
      });
    }
  };

  const handleBack = () => {
    if(isExisting)  dispatch(setActiveIndexExistingStep(1))
    else dispatch(editActiveIndex(2));
  };

  const optionsStyle = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const [isCardValid, setIsCardValid] = useState(false);
  const [isExpiryValid, setExpiryValid] = useState(false);

  const handleCardChange = (event) => {
    setIsCardValid(event.complete && event.error === undefined);
  };
  const handleExpiryChange = (event) => {
    setExpiryValid(event.complete && event.error === undefined);
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-3">
        <div className="flex flex-column row-gap-4">
          <div className="text-4xl font-semibold text-900">Payment</div>
          <div className="text-base font-normal text-400">
            To Finalize your Subscription, kindly complete the payment.
          </div>
        </div>
        <form id="payment-form" className="flex flex-column row-gap-2">
          <div
            className="flex flex-column col-12 row-gap-1"
            style={{ height: "80px" }}
          >
            <label className="label-style" htmlFor="Cname">
              Name on Card
            </label>
            <input
              id="Cname"
              className="cardname card-number-box "
              type="text"
              placeholder="Card Name"
              value={paymentData.cardHolderName}
              onChange={(e) =>
                setPaymentData((prev) => {
                  return { ...prev, cardHolderName: e.target.value };
                })
              }
            />
          </div>
          <div
            className="col-12 flex flex-column row-gap-2"
            style={{ height: "80px" }}
          >
            <label className=" label-style">Card Number</label>
            <CardNumberElement
              className="card-number-box"
              options={optionsStyle}
              onChange={handleCardChange}
            />
          </div>

          <div
            className="flex align-items-center justify-content-between column-gap-2"
            style={{ height: "80px" }}
          >
            <div className="col-6 flex flex-column row-gap-2">
              <label className=" label-style">Expiry</label>
              <CardExpiryElement
                className="card-number-box"
                options={optionsStyle}
                onChange={handleExpiryChange}
              />
            </div>
            <div
              className="col-6 flex flex-column row-gap-2"
              style={{ height: "80px" }}
            >
              <label className=" label-style">CVV</label>
              <CardCvcElement
                className="card-number-box"
                options={optionsStyle}
              />
            </div>
          </div>

          <div className="flex flex-column col-12 row-gap-2">
            <label className=" label-style" htmlFor="Cname">
              Country
            </label>
            <Dropdown
              placeholder="Country"
              options={getNames()}
              value={paymentData.country}
              name="Country"
              onChange={(e) =>
                setPaymentData((prev) => {
                  return { ...prev, country: e.target.value };
                })
              }
            />
          </div>
        </form>
        <div className="flex align-items-center justify-content-between">
          {/* <Button label="Back" onClick={handleBack} /> */}

          <div className="flex align-items-center justify-content-end">
            <button
              className="info__back__btn"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
          <Button
            label="Continue"
            loading={loader}
            onClick={handlePayment}
            disabled={
              !isCardValid ||
              !isExpiryValid ||
              paymentData.country === "" ||
              paymentData.cardHolderName === ""
            }
          />
        </div>
      </div>
    </>
  );
}

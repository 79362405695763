import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import Button from "./../../../component/common/button/button";
import { PanelMenu } from "primereact/panelmenu";
import { useSelector, useDispatch } from "react-redux";
import "./../../timesheet/updateTimesheet.scss";
import Input from "./../../../component/common/inputtext/input-field";
import { getTaskListBySprint } from "./../../../store/features/Projects/Task";
import TaskImage from "./../../../assets/task.svg";
import StoryImage from "./../../../assets/story.svg";
import BugImage from "./../../../assets/bug.svg";
import {
  createTimeSheet,
  getTimesheet,
  resetUpdateStatus,
  updateTimesheet,
} from "./../../../store/features/Timesheet/timesheet";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { getZeroHM } from "../../../utility/utility";

export default function AddProjectTimesheet({
  addTimesheetDialog,
  setAddTimesheetDialog,
}) {
  const dispatch = useDispatch();
  const { sprintList } = useSelector((store) => store.sprint);
  const { taskList } = useSelector((store) => store.task);
  const { updateStatus, updateError } = useSelector((store) => store.timesheet);
  const timesheetStore = useSelector((store) => store.timesheet);
  const [showSprintList, setShowSprintList] = useState(false);
  const [active, setActive] = useState([]);
  const [upcomming, setUpcomming] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [sprint, setSprint] = useState({});
  const [workingHours, setWorkingHours] = useState(getZeroHM());
  const [taskOption, setTaskOption] = useState([]);
  const [taskValue, setTaskValue] = useState({});
  const [loomLink, setLoomLink] = useState("");
  const [description, setDescription] = useState("");
  const [activityDate, setActivityDate] = useState("");
  const [submitDisable, setSubmitDisable] = useState(true);
  const [addTimesheet, setAddTimesheet] = useState({});
  const { projectId } = useParams();

  const toast = useRef(null);
  function emptyState() {
    setSubmitDisable(false);
    setActive([]);
    setUpcomming([]);
    setCompleted([]);
  }
  useEffect(() => {
    if (sprint._id !== "" && JSON.stringify(sprint) !== "{}") {
      dispatch(getTaskListBySprint({ ...sprint }));
      setShowSprintList(false);
    }
  }, [sprint]);
  useEffect(() => {
    if (JSON.stringify(sprint) !== "{}" && sprint.name != "") {
      if (JSON.stringify(taskValue) !== "{}") {
        if (taskValue._id != "") setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    } else {
      setSubmitDisable(false);
    }
  }, [taskValue, sprint]);

  useEffect(() => {
    if (
      loomLink !== "" &&
      description !== "" &&
      activityDate !== "" &&
      workingHours != ""
    ) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [loomLink, description, activityDate, workingHours]);

  useEffect(() => {
    if (taskList) {
      let taskOption = [];
      taskList.forEach((task) => {
        taskOption.push({
          type: task.type,
          title: task.title,
          taskId: task.taskId,
          label: taskOptionTemplate(task),
          _id: task._id,
        });
      });
      setTaskOption(taskOption);
    }
  }, [taskList]);

  useEffect(() => {
    let upcome = [];
    let act = [];
    let complete = [];
    //Active
    sprintList.forEach((sprint) => {
      if (sprint.status == "Active") {
        act.push({
          label: sprint.title,
          _id: sprint._id,
          projectId: sprint.projectId,
          command: (item) => {
            setSprint({
              name: item.item.label,
              _id: item.item._id,
              projectId: item.item.projectId,
            });
            setTaskValue({});
          },
        });
      }
      //Upcomming
      if (sprint.status == "Planned") {
        upcome.push({
          label: sprint.title,
          _id: sprint._id,
          projectId: sprint.projectId,
          command: (item) => {
            setSprint({
              name: item.item.label,
              _id: item.item._id,
              projectId: item.item.projectId,
            });
            setTaskValue({});
          },
        });
      }
      //Completed
      if (sprint.status == "Completed") {
        complete.push({
          label: sprint.title,
          _id: sprint._id,
          projectId: sprint.projectId,
          command: (item) => {
            setSprint({
              name: item.item.label,
              _id: item.item._id,
              projectId: item.item.projectId,
            });
            setTaskValue({});
          },
        });
      }
    });
    setUpcomming(upcome);
    setActive(act);
    setCompleted(complete);
  }, [sprintList]);

  const items = [
    {
      label: "Backlog",
      icon: "pi pi-fw pi-file",
      command: () => {
        setTaskValue({});
        setSprint({ name: "Backlog", projectId: addTimesheet.projectId });
      },
    },
    {
      label: "Active Sprints",
      icon: "pi pi-fw pi-pencil",
      items: active,
    },
    {
      label: "Upcoming Sprints",
      icon: "pi pi-fw pi-user",
      items: upcomming,
    },
    {
      label: "Completed Sprints",
      icon: "pi pi-fw pi-calendar",
      items: completed,
    },
  ];
  
  const submitHandler = async () => {
    let data = { ...addTimesheet };
    if (sprint) {
      data.sprintId = sprint._id;
    }
    if (taskValue) {
      data.taskId = taskValue._id;
    }
    data.loomLink = loomLink;
    data.description = description;
    const duration = {
      hours: workingHours.getHours(),
      minutes: workingHours.getMinutes(),
    };
    data.duration = duration;
    data.activityDate = activityDate;
    data.projectId = projectId;
    dispatch(createTimeSheet(data)).then((res) => {
      setAddTimesheetDialog(!addTimesheetDialog);
      if(!res.error){
        toast.current.show({
          severity: "success",
          summary: res.payload.message,
          life: 3000,
        });
        setTaskValue({});
        setWorkingHours(getZeroHM());
        setSprint({});
        setActivityDate("");
        setLoomLink("");
        setDescription("");
        dispatch(getTimesheet());
        dispatch(resetUpdateStatus());
      }
      else{
        toast.current.show({
          severity: "error",
          summary: res?.payload?.message ? res?.payload?.message : "something went wrong",
          life: 3000,
        });
      }
    })
  };

  const taskOptionTemplate = (option) => {
    if (option) {
      let image;
      if (option.type === "Task") {
        image = <img src={TaskImage}></img>;
      }
      if (option.type === "Story") {
        image = <img src={StoryImage}></img>;
      }
      if (option.type === "Bug") {
        image = <img src={BugImage}></img>;
      }

      return (
        <div className="country-item">
          <div>
            <span className="timesheet-table-header-font">
              {" "}
              {image} {option?.taskId} {option?.title}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Create Timesheet"
        visible={addTimesheetDialog}
        style={{ width: "40vw" }}
        onHide={() => {
          emptyState();
          setTaskValue({});
          setWorkingHours(getZeroHM());
          setSprint({});
          setAddTimesheetDialog(!addTimesheetDialog);
        }}
      >
        <div className="flex">
          <div className="label-font ml-2 pb-2">Date</div>
          <p style={{ color: "red" }}>*</p>
        </div>

        <div className="grid">
          <Calendar
            className="update-timesheet-box"
            label="Date of Birth"
            dateFormat="dd/mm/yy"
            name="dob"
            readOnlyInput
            value={new Date(activityDate)}
            onChange={(e) => setActivityDate(e.value)}
            maxDate={new Date()}
          />
        </div>

        <div className="flex mt-3">
          <div className="label-font ml-2 pb-2">Duration (HH:MM)</div>
          <p style={{ color: "red" }}>*</p>
        </div>
        <div className="grid">
          <Calendar
            className="update-timesheet-box"
            timeOnly
            showTime
            hourFormat="24"
            onChange={(e) => {
              setWorkingHours(new Date(e.target.value))}}
            value={workingHours}
          />
        </div>

        <div className="label-font ml-2 mt-3">Sprint Name</div>

        <div className="mt-5 update-timesheet-box">
          <div className="update-user-label-align">
            <div
              onClick={() => {
                setShowSprintList(!showSprintList);
              }}
            >
              <Dropdown
                disabled={true}
                className="drop-z-index"
                value={sprint.name}
                placeholder={<h4 style={{ color: "black" }}>{sprint.name}</h4>}
              />
            </div>
            {showSprintList && (
              <PanelMenu
                className="drop-z-index"
                model={items}
                style={{ width: "300px", height: "200px" }}
              />
            )}
          </div>
        </div>
{/* 
        <div className="label-font ml-2 mt-3">Work Items</div>
        <div className="my-2">
          <Dropdown
            className="update-timesheet-input"
            options={taskOption}
            placeholder={taskValue.type && taskOptionTemplate(taskValue)}
            value={taskValue}
            onChange={(e) => {
              setTaskValue(e.target.value);
            }}
          />
        </div> */}

        <div className="mt-4">
          <div className="flex">
            <div className="label-font ml-2 pb-2">Loom Link</div>
            <p style={{ color: "red" }}>*</p>
          </div>
          <Input
            value={loomLink}
            onChange={(e) => setLoomLink(e.target.value)}
          />
        </div>

        <div className="mt-4">
          <div className="flex">
            <div className="label-font ml-2 pb-2">Description</div>
            <p style={{ color: "red" }}>*</p>
          </div>
          <InputTextarea
            className="description-box"
            autoResize
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="flex justify-content-end mt-5 mr-3">
          <Button
            onClick={submitHandler}
            disabled={submitDisable}
            label="Submit"
          />
        </div>
      </Dialog>
    </div>
  );
}

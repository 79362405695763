import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProjectList } from "../../store/features/Projects/Project";
import {
  getTimesheet,
  resetFilter,
  setFilterFrom,
  setFilterStatus,
  setFilterTo,
  setFrom,
  setLimit,
  setMemberId,
  setProjectId,
  setSkip,
} from "../../store/features/Timesheet/timesheet";
import { getAllUsers } from "../../store/features/Users/User";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import Button from "../common/button/button";
import { Dropdown } from "primereact/dropdown";
import formatDate from "../../factory-function/formatDate";
import { useParams } from "react-router-dom";

export default function ({
  filterDialog,
  setFilterDialog,
  hrAdmin,
  showFilterAllProject = true,
}) {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const [toggleFilter, setToggleFilter] = useState(false);
  const { filterFrom, filterTo, memberId } = useSelector(
    (store) => store.timesheet
  );
  const { projectList } = useSelector((store) => store.project);
  const { allUsers } = useSelector((store) => store.users);
  const [userOption, setUserOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [project, setproject] = useState(projectId);

  useEffect(() => {
    dispatch(getProjectList());
    dispatch(getAllUsers());
  }, []);


  useEffect(() => {
    if (projectList) {
      let options = [];
      projectList.forEach((project) => {
        options.push({
          value: project._id,
          label: project.projectDetails.projectName,
        });
      });
      setProjectOptions(options);
    }
  }, [projectList]);

  useEffect(() => {
    if (allUsers) {
      let options = [];
      allUsers.forEach((user) => {
        options.push({ value: user._id, label: user.basic.fullName });
      });
      setUserOptions(options);
    }
  }, [allUsers]);

  const handleProject = (e) => {
    setproject(e.value);
    dispatch(setProjectId(e.value));
  };
 
  const specificProjectFilter = () => {
    if(!showFilterAllProject) dispatch(setProjectId(projectId))
    dispatch(getTimesheet());
  }
  const filterHandler = () => {
    dispatch(setFilterStatus());
    dispatch(setSkip(1));
    dispatch(setLimit(10));
    dispatch(setFrom(1));
    setFilterDialog(!filterDialog);
    setToggleFilter(!toggleFilter);
    specificProjectFilter()
  };

  const resetHandler = async () => {
    dispatch(resetFilter());
    dispatch(setSkip(1));
    dispatch(setFrom(1));
    dispatch(setLimit(10));
    setFilterDialog(!filterDialog);
    setToggleFilter(!toggleFilter);
    specificProjectFilter()
  };

  return (
    <Dialog
      header="Timesheet Filter"
      visible={filterDialog}
      style={{ width: "30vw" }}
      position="right"
      onHide={() => {
        setFilterDialog(!filterDialog);
      }}
    >
      <div>
        <div className="grid mt-3">
          <div className="col-6">
            <div className="label-font ml-2 pb-2">From</div>
            <Calendar
              placeholder="From"
              dateFormat="dd/mm/yy"
              value={new Date(filterFrom)}
              readOnlyInput
              onChange={(e) => dispatch(setFilterFrom(formatDate(e.value)))}
            />
          </div>

          <div className="col-6">
            <div className="label-font ml-2 pb-2">To</div>
            <Calendar
              placeholder="To"
              dateFormat="dd/mm/yy"
              value={new Date(filterTo)}
              readOnlyInput
              onChange={(e) => dispatch(setFilterTo(formatDate(e.value)))}
            />
          </div>
        </div>

        <div className="flex">
          {hrAdmin && (
            <div className={`${!showFilterAllProject ? "col-12" : "col-6"}`}>
              <div className="label-font ml-2 mt-3 pb-2">Employee Name</div>
              <Dropdown
                options={userOption}
                placeholder="Employee Name"
                value={memberId}
                onChange={(e) => dispatch(setMemberId(e.value))}
              />
            </div>
          )}
          {showFilterAllProject && (
            <div className="col-6">
              <div className="label-font ml-2 mt-3 pb-2">Project</div>
              <Dropdown
                options={projectOptions}
                placeholder="Project Name"
                onChange={handleProject}
                value={project}
              />
            </div>
          )}
        </div>
      </div>

      <div className="grid justify-content-around mt-3">
        <Button label="Filter" onClick={filterHandler} />
        <Button label="Reset" onClick={resetHandler} />
      </div>
    </Dialog>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SpeedDial } from "primereact/speeddial";
import { Dropdown } from "primereact/dropdown";
import document from "../../../assets/document.svg";
import dateIcon from "../../../assets/date.svg";
import time from "../../../assets/time.svg";
import userImage from "../../../assets/user.svg";
import { getSprintList } from "../../../store/features/Projects/Sprint";
import {
  getTimesheet,
  updateTimesheet,
  setProjectId,
  setLimit,
  setSkip,
  setFrom,
  setTo,
  deleteTimesheet,
} from "./../../../store/features/Timesheet/timesheet";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import UpdateTimesheet from "../../timesheet/updateTimesheet";
import { Toast } from "primereact/toast";
import {
  fn_firstcharacter,
  PaginationDropdown,
} from "../../../utility/utility";
import Alphabet from "../../common/alphabet/alphabet";
import ReadMore from "../../common/read-more/readMore";

export default function ProjectTimesheetTable({ hrAdmin }) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { projectId: projectParamsId } = useParams();

  // retrive data from store
  const { timesheet, limit, skip, from, to, maxRecord, projectId } =
    useSelector((store) => store.timesheet);

  const [updateItem, setUpdateItem] = useState({});
  const [show, setShow] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateTimesheetDialog, setUpdateTimesheetDialog] = useState(false);
  const [update, setUpdate] = useState(false);

  const [timesheetApproval, setTimesheetApproval] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [approvalDialog, setApprovalDialog] = useState(false);

  useEffect(() => {
    dispatch(setProjectId(projectParamsId));
  }, []);

  useEffect(() => {
    dispatch(getTimesheet());
  }, [skip, limit, from, to, projectId]);

  useEffect(() => {
    if (selectedMembers?.length || selectAll?.length) {
      setApprovalDialog(false);
    }
  }, [selectedMembers?.length, selectAll?.length]);

  const DateFormat = (date) => {
    return new Date(date).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
      weekday: "short",
    });
  };

  const deleteHandler = () => {
    dispatch(deleteTimesheet(updateItem)).then((res) => {
      setDeleteDialog(!deleteDialog);
      if (!res.error) {
        dispatch(getTimesheet());
        toast.current.show({
          severity: "success",
          summary: res.payload?.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: res?.payload?.message
            ? res?.payload?.message
            : "Something went wrong",
          life: 3000,
        });
      }
    });
  };

  const CommonHeader = ({ title, icon }) => {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={icon} height={16} />
        <div className="flex">&nbsp;{title}</div>
      </div>
    );
  };

  const projectsName = (projectName) => {
    return (
      <div className="flex align-items-center">
        {projectName?.project?.projectDetails?.companyLogo ? (
          <img
            className="projects-img-size flex justify-content-center"
            src={projectName?.project?.projectDetails?.companyLogo}
          />
        ) : (
          <Alphabet letter={fn_firstcharacter(projectName?.projectName)} />
        )}
        <div className="flex">&nbsp; {projectName?.projectName}</div>
      </div>
    );
  };

  const onSelectionChange = (event) => {
    const value = event.value;
    setTimesheetApproval(value);
    setSelectedMembers(value);
    setSelectAll(value.length === timesheet);
  };

  const logTime = (time) => {
    let hourstr = time.duration.hours;
    let minutesstr = time.duration.minutes;
    if (hourstr / 10 <= 1) {
      hourstr = "0" + hourstr;
    }
    if (minutesstr / 10 <= 1) {
      minutesstr = "0" + minutesstr;
    }
    return (
      <div className="flex ">
        {hourstr} : {minutesstr} Hrs
      </div>
    );
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        dispatch(getSprintList(updateItem.projectId));
        setUpdateTimesheetDialog(!updateTimesheetDialog);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const Action = (data) => {
    return (
      <div className="timesheet-action">
        <SpeedDial
          model={items}
          radius={50}
          direction="right"
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
          buttonClassName="p-button-outlined action-btn-design"
          onClick={() => {
            setUpdateItem(data);
          }}
        />
      </div>
    );
  };

  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },

    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
              dispatch(setFrom(from - limit));
              dispatch(setTo(to - limit));
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              dispatch(setSkip(skip + 1));
              dispatch(setFrom(from + limit));
              dispatch(setTo(to + limit));
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  const submitHandler = (e) => {
    timesheetApproval.map((timesheet) => {
      setApprovalDialog(!approvalDialog);
      dispatch(updateTimesheet({ ...timesheet, approvalStatus: e })).then(
        (res) => {
          if (!res.error) dispatch(getTimesheet());
        }
      );
    });
  };

  const option = [
    {
      icon: <i className="pi pi-check text-teal-500" />,
      name: "Approved",
    },
    {
      icon: <i className="pi pi-times text-red-500" />,
      name: "Rejected",
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}
      {updateTimesheetDialog && (
        <UpdateTimesheet
          updateTimesheetDialog={updateTimesheetDialog}
          setUpdateTimesheetDialog={setUpdateTimesheetDialog}
          updateItem={updateItem}
          setUpdateItem={setUpdateItem}
          update={update}
          projectId={projectParamsId}
        />
      )}

      {(selectedMembers?.length || selectAll?.length) && !approvalDialog && (
        <div className="flex align-items-center justify-content-center w-full">
          <div className="flex status-approval-bg align-items-center column-gap-4 px-2">
            <p className="status-header-font">Status Approval : </p>
            {option.map((o, id) => {
              return (
                <div
                  className="flex  align-items-center column-gap-2 cursor-pointer"
                  key={id}
                  onClick={() => submitHandler(o.name)}
                >
                  {o.icon}
                  <span>{o.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="table-box">
        <div className="asset-table-size">
          <DataTable
            className="asset-table-font timesheet-table-header-font"
            sortMode="multiple"
            value={timesheet}
            responsiveLayout="scroll"
            paginator
            paginatorTemplate={paginate}
            rows={limit}
            paginatorClassName="justify-content-end custom-dropdown"
            onSelectionChange={onSelectionChange}
            selectAll={selectAll}
            selection={selectedMembers}
          >
            {hrAdmin && (
              <Column
                selectionMode="multiple"
                selectionAriaLabel="name"
                headerStyle={{ width: "3em" }}
              />
            )}
            <Column
              field={projectsName}
              header={<CommonHeader title="Project Name" icon={document} />}
            />
            <Column
              body={(item) => (
                <div style={{ width: "300px", wordWrap: "break-word" }}>
                  <ReadMore text={item?.description} maxLength={60} />
                </div>
              )}
              header={<CommonHeader title="Description" icon={document} />}
            />
            <Column
              field={(item) => DateFormat(item.activityDate)}
              header={<CommonHeader title="Date" icon={dateIcon} />}
            />
            <Column
              field={logTime}
              header={<CommonHeader title="Log Time" icon={time} />}
            />
            <Column
              body={(item) => <>{item?.memberDetails?.basic.fullName}</>}
              header={<CommonHeader title="Created" icon={userImage} />}
            />
            <Column
              body={(item) => <>{item?.approvalStatus}</>}
              header="Approval"
            />
            <Column body={Action} header="Action" />
          </DataTable>
        </div>
      </div>
    </>
  );
}

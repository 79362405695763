import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
} from "../../services/authentication";
import constants, { API } from "./../../constants";
import Button from "./../../component/common/button/button";
import Input from "./../../component/common/inputtext/input-field";
import { Toast } from "primereact/toast";
import { SpeedDial } from "primereact/speeddial";
import { useSelector } from "react-redux";
import Spinner from "../../component/common/spinner/spinner";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import emptyAsset from "./../../assets/empty-recognition.svg";
import DeleteDialog from "../../component/common/confirm-delete-dialog/deleteDialog";
import SearchIconBar from "../../component/common/search-icon-searchBar/searchIconBar";
import { isAdminAccess } from "../../utility/utility";

export default function Holiday() {
  const [holiday, setHoliday] = useState([]);
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const val = useSelector((store) => store.user);
  const [editModel, setEditModel] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [submitDisable, setSubmitDisable] = useState(true);
  const [editSubmitDisable, setEditSubmitDisable] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [maxRecord, setMaxRecord] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);
  const [deleteDialog, setDeleteDialog] = useState(false);
 const hrAdmin = isAdminAccess("Human Resource")


  const [createHoliday, setCreateHoliday] = useState({
    title: "",
    type: "",
    from: "",
    to: "",
  });

  const [editHoliday, setEditHoliday] = useState({
    title: "",
    type: "",
    from: "",
    to: "",
  });

  useEffect(() => {
    let y = Object.values(createHoliday).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [createHoliday]);
  useEffect(() => {
    let y = Object.values(editHoliday).every((val) => val !== "");
    if (y === true) {
      setEditSubmitDisable(false);
    } else {
      setEditSubmitDisable(true);
    }
  }, [editHoliday]);
  useEffect(() => {
    getHolidays("");
  }, []);

  //get Holidays
  async function getHolidays(search) {
    try {
      setSpinner(true);
      let url = constants.API.HOLIDAY.CREATE + `?limit=${limit}&skip=${skip}`;
      if (search !== "" && search !== undefined) {
        url = url + `&search=${search}`;
      }
      const data = await getWithAuth(url, token);
      setMaxRecord(data.data.requestsCount);
      const orderedData = data.data.holidays;
      let numericOrder = orderedData?.map((data, i) => {
        data = { ...data, id: i + 1 };
        return data;
      });
      if (numericOrder) {
        setHoliday(numericOrder);
        setSpinner(false);
      }
    } catch (err) {
      setSpinner(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  //change iso string to normal date
  let fromDate = (data) => {
    const from = new Date(data.from).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return from;
  };
  let toDate = (data) => {
    const to = new Date(data.to).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return to;
  };

  let days = (data) => {
    const date1 = new Date(data.from);
    const date2 = new Date(data.to);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return <div className="ml-4">{parseInt(diffDays) + 1}</div>
  };

  //delete holiday
  const deleteHandler = async () => {
    const url = `${constants.API.HOLIDAY.DELETE}`;
    const ids = {
      ids: updateItem._id,
    };
    try {
      const data = await putWithAuth(url, ids, token);
      setDeleteDialog(!deleteDialog);
      if (data) {
        getHolidays("");
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      val: val,
      command: () => {
        setEditHoliday(updateItem);
        setEditModel(!editModel);
        return;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  //Action button
  const Action = (data) => (
    <div className="asset-action">
      <SpeedDial
        model={items}
        onClick={() => setUpdateItem(data)}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
      />
    </div>
  );

  // create Holiday
  const [requestDialog, setRequestDialog] = useState(false);

  const leaveTypeDropdown = [
    { label: "Public", value: "Public" },
    { label: "Optional", value: "Optional" },
  ];

  const changeHandler = (e) => {
    setCreateHoliday({ ...createHoliday, [e.target.name]: e.target.value });
  };

  const createHandler = async () => {
    try {
      const url = constants.API.HOLIDAY.CREATE;
      await postWithAuth(url, createHoliday, token);

      setRequestDialog(!requestDialog);
      setCreateHoliday({ title: "", type: "", from: "", to: "" });
      getHolidays("");
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const updateHolidayChangeHandler = (e) => {
    setEditHoliday({ ...editHoliday, [e.target.name]: e.target.value });
  };

  const updateHandler = async () => {
    try {
      await putWithAuth(`${API.HOLIDAY.CREATE}`, editHoliday, token);
      setEditModel(!editModel);
      getHolidays("");
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    getHolidays();
  }, [limit, skip]);

  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              setLimit(e.value);
              setSkip(1);
              setFrom(1);
              setTo(e.value);
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip - 1);
              setFrom(from - limit);
              setTo(to - limit);
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              setSkip(skip + 1);
              setFrom(from + limit);
              setTo(to + limit);
            }}
            disabled={to >= maxRecord ? true : false}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  return (
    <div>
      <DeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        onClick={deleteHandler}
      ></DeleteDialog>
      <div className="mr-6 flex flex-row-reverse justify-content-start">        
        <div className="assets-actions">
          <div className="flex ml-5">
          <div className="search-bar flex align-items-center mr-7">
          <SearchIconBar
            placeholder="Search"
            onChange={(e) => {
              getHolidays(e.target.value);
            }}
          ></SearchIconBar>
        </div>
          {hrAdmin ? (
            <Button
              className="create-btn"
              label="Add"
              onClick={() => setRequestDialog(!requestDialog)}
            ></Button>
          ) : (
            <></>
          )}
          </div>
       
        </div>
      </div>

      {/* Add holiday dialog */}
      <Toast ref={toast} />
      <Dialog
        header="Add Holiday"
        visible={requestDialog}
        style={{ width: "35vw" }}
        onHide={() => setRequestDialog(!requestDialog)}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-12">
              <Input
                className="asset-input"
                placeholder="Title"
                name="title"
                label="Title"
                type="text"
                onChange={changeHandler}
              ></Input>
            </div>

            <div className="col-12">
              <Dropdown
                options={leaveTypeDropdown}
                placeholder="Status"
                value={createHoliday.type}
                onChange={(e) =>
                  setCreateHoliday({ ...createHoliday, type: e.value })
                }
              ></Dropdown>
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                maxDate={createHoliday.to}
                value={createHoliday.from}
                readOnlyInput
                onChange={(e) =>
                  setCreateHoliday({
                    ...createHoliday,
                    from: new Date(e.value),
                  })
                }
              ></Calendar>
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                dateFormat="dd/mm/yy"
                value={createHoliday.to}
                minDate={createHoliday.from}
                readOnlyInput
                onChange={(e) =>
                  setCreateHoliday({
                    ...createHoliday,
                    to: new Date(e.value),
                  })
                }
              ></Calendar>
            </div>
            <div className="col-6"></div>
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              className="primary"
              onClick={createHandler}
              disabled={submitDisable}
            ></Button>
          </div>
        </div>
      </Dialog>

      {/* Edit dialog */}
      <Dialog
        header="Edit Holiday"
        visible={editModel}
        style={{ width: "35vw" }}
        onHide={() => {
          setEditModel(!editModel);
          setEditHoliday({ title: "", type: "", from: "", to: "" });
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-12">
              <Input
                className="asset-input"
                placeholder="Title"
                name="title"
                label="Title"
                type="text"
                onChange={updateHolidayChangeHandler}
                value={editHoliday.title}
              ></Input>
            </div>

            <div className="col-12">
              <Dropdown
                options={leaveTypeDropdown}
                placeholder="Status"
                value={editHoliday.type}
                readOnlyInput
                onChange={(e) =>
                  setEditHoliday({ ...createHoliday, type: e.value })
                }
              ></Dropdown>
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                dateFormat="dd/mm/yy"
                placeholder="From"
                value={new Date(editHoliday.from)}
                maxDate={new Date(editHoliday.to)}
                readOnlyInput
                onChange={(e) => {
                  setEditHoliday({
                    ...editHoliday,
                    from: new Date(e.value),
                  });
                }}
              ></Calendar>
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                dateFormat="dd/mm/yy"
                value={new Date(editHoliday.to)}
                minDate={new Date(editHoliday.from)}
                onChange={(e) =>
                  setEditHoliday({ ...editHoliday, to: new Date(e.value) })
                }
              ></Calendar>
            </div>
            <div className="col-6"></div>
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              className="primary"
              onClick={updateHandler}
              disabled={editSubmitDisable}
            ></Button>
          </div>
        </div>
      </Dialog>
      <br />
      {/* Holiday Table */}
      <div className="grid flex justify-content-center">
        {spinner === true ? <Spinner value={spinner}></Spinner> : <></>}
      </div>
      {holiday && holiday.length > 0 && spinner === false ? (
        <div className="table-box table-alignment mt-1">
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              responsiveLayout="scroll"
              value={holiday}
              paginator
              paginatorClassName="justify-content-end custom-dropdown"
              paginatorTemplate={paginate}
              rows={limit}
            >
              <Column bodyClassName="holiday-id-align" field="id" header="Holiday" ></Column>
              <Column field="title" header="Name"></Column>
              <Column field="type" header="Type"></Column>
              <Column field={days} header="No of Days" ></Column>
              <Column field={fromDate} header="From" ></Column>
              <Column field={toDate} header="To" ></Column>
              {hrAdmin ? (
                <Column field={Action} header="Action"></Column>
              ) : (
                <></>
              )}
            </DataTable>
          </div>
        </div>
      ) : (
        <></>
      )}
      {spinner === false && holiday && holiday.length === 0 ? (
        <EmptyScreen
          img={emptyAsset}
          title={constants.EMPTY_SCREEN_TEXT[1].title}
          description={constants.EMPTY_SCREEN_TEXT[1].description}
        ></EmptyScreen>
      ) : (
        <></>
      )}
    </div>
  );
}

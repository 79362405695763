import React from 'react'
import "primeicons/primeicons.css";
import "./unauthorized.scss";

export default function Unauthorized() {
  return (
    <div>
    <div className='unauthorized-page flex justify-content-center '>
      <i className="warning pi pi-exclamation-triangle " style={{ 'fontSize': '10em' }}></i>
    </div>

    <div className='heading flex justify-content-center '>
      <h1 className='heading '>Unauthorized 401</h1>

    </div>
  
  </div>
  )
}
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Chart } from "primereact/chart";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllSprintList,
  getSprintTaskCount,
} from "../../../../store/features/Projects/Sprint";
import constants from "../../../../constants";
import { getWithAuth } from "../../../../services/authentication";

export default function OverViewChart() {

  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [doneTaskCount,setDoneTaskCount]=useState([]);
  const [dateArray,setDateArray]=useState([]);

  useEffect(()=>{
    getTaskCount();
  },[])
  async function getTaskCount(){
    const url=constants.API.TASK.STATUS_COUNT+`?projectId=${projectId}`;
    const token=localStorage.getItem("token")
    const response=await getWithAuth(url,token);
    setDoneTaskCount(response.data);
    const dates=[];
    for(let i=1;i<=response.data.length;i++){
      dates.push(i.toString());
    }
    setDateArray(dates);
  }

  const [lineStylesData,setLineStyleDate] = useState({
    labels: dateArray,
    datasets: [
      {
        label: "Task Done",
        data: doneTaskCount,
        fill: true,
        borderColor: "#FF006D",
        borderWidth: 2,
        tension: 0.4,
        backgroundColor: "rgba(255, 0, 109, 0.1)",
      },
    ],
  });
  useEffect(()=>{
    if(dateArray.length > 0 && doneTaskCount.length > 0){
      setLineStyleDate({labels: dateArray,
        datasets: [
          {
            label: "Task Done",
            data: doneTaskCount,
            fill: true,
            borderColor: "#FF006D",
            borderWidth: 2,
            tension: 0.4,
            backgroundColor: "rgba(255, 0, 109, 0.1)",
          },
        ],})
    }
  },[dateArray,doneTaskCount])

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };
  const { basicOptions } = getLightTheme();
  return (
    <div>
      <Chart
        className="line-chart-height"
        type="line"
        data={lineStylesData}
        options={basicOptions}
      />
    </div>
  );
}

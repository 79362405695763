import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import Input from "../common/inputtext/input-field";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import constants from "./../../constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getDesignation,
  resetDesignation,
  createDesignation,
  setDesignation as setDesignations,
} from "../../store/features/Users/designation";
import {
  getDepartment,
  createDepartment,
  setDepartment as setDepartments,
} from "../../store/features/Users/department";
import {
  getUsers,
  resetUpdateStatus,
  updateUser,
} from "../../store/features/Users/User";
import { getWithAuth, postWithAuth } from "../../services/authentication";
import Button from "../common/button/button";
import ProfileUpload from "../common/file-upload/fileUpload";
import { isAdminAccess } from "../../utility/utility";
import { InputSwitch } from "primereact/inputswitch";


const { getNames } = require("country-list");
export default function ({
  updateUserDialog,
  setUpdateUserDialog,
  updateItem,
  setUpdateItem,
}) {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const { departments } = useSelector((store) => store.department);
  const { designations } = useSelector((store) => store.designation);
  const { roles } = useSelector((store) => store.role);
  const { updateUserStatus, updateUserError } = useSelector(
    (store) => store.users
  );
  const usersStore = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUploadDisable, setImageUploadDisable] = useState(false);
  const usersAdmin = isAdminAccess("Users");

  useEffect(() => {
    const departmentId = updateItem?.departmentId;
    //check if department exist in array
    const index = departments.findIndex((department) => {
      return department._id === departmentId;
    });
    if (departmentId !== undefined && index === -1) {
      getDepartmentById(departmentId);
    }
    if (departmentId !== undefined) {
      setDepartment(departmentId);
    }
  }, [updateItem?.departmentId]);

  useEffect(() => {
    const designationId = updateItem?.work?.designationId;
    const index = designations.findIndex((designation) => {
      return designation._id === designationId;
    });

    if (designationId !== undefined && index === -1) {
      getDesignationById(designationId);
    }
    setDesignation(designationId);
  }, [updateItem?.work?.designationId]);
  useEffect(() => {
    if (updateUserError !== "" && updateUserStatus === "error") {
      toast.current.show({
        severity: "error",
        summary: updateUserError,
        detail: "",
        life: 3000,
      });
    }
    if (updateUserStatus === "success") {
      dispatch(resetUpdateStatus());
    }
  }, [updateUserError, updateUserStatus]);

  async function getDepartmentById(departmentId) {
    let url = constants.API.DEPARTMENT.FETCH + departmentId;
    const response = await getWithAuth(url, token);
    dispatch(setDepartments([...departments, response.data.department]));
  }
  async function getDesignationById(designationId) {
    let url = constants.API.DESIGNATION.FETCH + designationId;
    const response = await getWithAuth(url, token);
    dispatch(setDesignations([...designations, response.data.designation]));
  }

  const rolesOption = roles.map((role) => {
    return { label: role?.role?.role_name, value: role._id };
  });
  const departmentOptions = departments?.map((department) => {
    return { label: department?.department, value: department?._id };
  });
  const designationOptions = designations?.map((designation) => {
    return { label: designation?.designation, value: designation?._id };
  });

  const changeHandler = (e, object_name) => {
    const embeddedObject = {
      ...updateItem[object_name],
      [e.target.name]: e.target.value,
    };
    const updatedObject = { ...updateItem, [object_name]: embeddedObject };
    setUpdateItem(updatedObject);
  };

  const createDesignationHandler = async (e) => {
    if (e.key == "Enter") {
      const index = designationOptions?.findIndex((designation) => {
        return designation.label === designation;
      });
      if (index === -1 && department !== "") {
        await dispatch(createDesignation({ designation, department }));
        setDesignation("");
        await dispatch(getDesignation(department));
      }
    }
  };
  const designationChangeHandler = (e) => {
    setDesignation(e.value);
  };
  const createDepartmentHandler = async (e) => {
    if (e.key == "Enter") {
      const index = await departmentOptions?.findIndex((department) => {
        return department.label === department;
      });
      if (index === -1) {
        setDepartment("");
        setDesignation("");
        dispatch(createDepartment(department));
        dispatch(getDepartment(department));
      }
    }
  };
  const departmentChangeHandler = async (e) => {
    setDepartment(e.value);
    const id = e.value;
    const index = await departmentOptions.findIndex((department) => {
      return department.value === e.value;
    });
    if (index !== -1) {
      dispatch(getDepartment());
      dispatch(resetDesignation());
      setDesignation("");
      dispatch(getDesignation(id));
    }
  };

  // image upload and delete
  const uploadHandler = async (e) => {
    try {
      setLoading(true);
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            uploadAssetProfile(files[0]);
          });
        } else {
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
      setImageUploadDisable(!imageUploadDisable);
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  function setProfilePic(url) {
    const embeddedObject = { ...updateItem.basic, profilePic: url };
    const updatedObject = { ...updateItem, basic: embeddedObject };
    setUpdateItem(updatedObject);
  }
  const uploadAssetProfile = async (data) => {
    const fileData = new FormData();
    fileData.append("file", data);
    const url = constants.API.CLOUD.UPLOAD + "/profilepic/upload";
    const response = await postWithAuth(url, fileData, token);
    if (response) {
      setImageUploadDisable(!imageUploadDisable);
      setProfilePic(response.data.URL);
      setLoading(false);
      return;
    }
  };
  const imageRemoveHandler = async () => {
    try {
      setLoading(true);
      if (
        updateItem?.basic?.profilePic !== "" ||
        updateItem?.basic !== undefined
      ) {
        const url = constants.API.CLOUD.UPLOAD + "/profilepic/delete";
        await postWithAuth(url, { url: updateItem.basic.profilePic }, token);
        setProfilePic("");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  //submit

  const submitHandler = async () => {
    let updatedValues = { ...updateItem };
    updatedValues.departmentId = department;

    let work = { ...updatedValues.work };

    work.designationId = designation;
    if (department !== "") {
      const deptIndex = departmentOptions.findIndex((dept) => {
        return dept.value === department;
      });
      updatedValues.departmentName = departmentOptions[deptIndex]?.label;
    } else {
      updatedValues.departmentName = "";
      delete updatedValues.departmentId;
    }

    if (designation !== "") {
      const designationIndex = designationOptions.findIndex((desig) => {
        return desig.value === designation;
      });
      updatedValues.designationName =
        designationOptions[designationIndex]?.label;
    } else {
      updatedValues.designationName = "";
      delete work.designationId;
    }

    const roleIndex = rolesOption.findIndex((role) => {
      return role.value === updateItem.work.roleId;
    });
    work.role = rolesOption[roleIndex]?.label;
    updatedValues.work = { ...work };
    let data = { memberOrgId: updateItem._id, updatedValues };
    dispatch(updateUser(data)).then((res) => {
     
      if (!res.error) {
        setUpdateUserDialog(!updateUserDialog);
        toast.current.show({
          severity: "success",
          summary: res.payload.message,
          detail: "",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: res.payload,
          detail: "",
          life: 3000,
        });
      }
    });

    dispatch(getUsers(usersStore));
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Update User"
        visible={updateUserDialog}
        style={{ width: "70vw" }}
        onHide={() => {
          setUpdateUserDialog(!updateUserDialog);
        }}
      >
        <div className="grd mt-3">
          <div className="col-12 update-user-label-align">
            <ProfileUpload
              remove={imageRemoveHandler}
              uploadHandler={uploadHandler}
              labelName="Click Here to Upload"
              profileImg={updateItem?.basic?.profilePic}
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="col-4">
            <Input
              label="Full Name"
              placeholder="Full Name"
              name="fullName"
              value={updateItem?.basic?.fullName}
              onChange={(e) => changeHandler(e, "basic")}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Date of Birth</span>
            <Calendar
              placeholder="Date of Birth"
              label="Date of Birth"
              dateFormat="dd/mm/yy"
              name="dob"
              readOnlyInput
              value={new Date(updateItem?.personal?.dob)}
              onChange={(e) => changeHandler(e, "personal")}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Date of Joining</span>

            <Calendar
              placeholder="Date of Joining"
              label="Date of Joining"
              name="dateOfJoining"
              dateFormat="dd/mm/yy"
              value={new Date(updateItem?.work?.dateOfJoining)}
              onChange={(e) => changeHandler(e, "work")}
              readOnlyInput
              disabled={!usersAdmin}
            />
          </div>
        </div>

        <div className="grid mt-3">
          <div className="col-4">
            <Input
              label="Employee Id"
              placeholder="Employee Id"
              name="employeeId"
              value={updateItem?.work?.employeeId}
              onChange={(e) => changeHandler(e, "work")}
              disable={!usersAdmin}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Nationality</span>
            <Dropdown
              placeholder="Nationality"
              options={getNames()}
              value={updateItem?.personal?.nationality}
              name="nationality"
              onChange={(e) => changeHandler(e, "personal")}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Role</span>
            <Dropdown
              placeholder="Role"
              options={rolesOption}
              value={updateItem?.work?.roleId}
              name="roleId"
              onChange={(e) => changeHandler(e, "work")}
              disabled={!usersAdmin}
            />
          </div>
        </div>

        <div className="grid mt-3">
          <div className="col-4 update-user-label-align">
            <span className="label-font">Department</span>
            <Dropdown
              tooltip="Select or Type and Press Enter to Create New Department"
              tooltipOptions={{
                position: "bottom",
                className: "tooltip-font-size",
              }}
              placeholder="Department"
              editable={true}
              options={departmentOptions}
              value={department}
              onKeyDown={createDepartmentHandler}
              onChange={departmentChangeHandler}
              name="departmentId"
              disabled={!usersAdmin}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Designation</span>

            <Dropdown
              tooltip="Select or Type and Press Enter to Create New Designation"
              tooltipOptions={{
                position: "bottom",
                className: "tooltip-font-size",
              }}
              placeholder="Designation"
              editable={true}
              options={designationOptions}
              value={designation}
              onKeyDown={createDesignationHandler}
              disabled={department === "" || !usersAdmin}
              onChange={designationChangeHandler}
              name="desinationId"
            />
          </div>
          <div className="col-4">
            <Input
              label="Reference Name"
              placeholder="Reference Name"
              name="referenceName1"
              value={updateItem?.contact?.referenceName1}
              onChange={(e) => changeHandler(e, "contact")}
            />
          </div>
        </div>

        <div className="grid mt-3">
          <div className="col-4 update-user-label-align">
            <span className="label-font">Reference Number</span>

            <PhoneInput
              label="Reference Number"
              placeholder="Reference Number"
              country={"in"}
              value={updateItem?.contact?.referenceContactNumber1}
              name="referenceContactNumber1"
              onChange={(e) => {
                const embeddedObject = {
                  ...updateItem.contact,
                  referenceContactNumber1: e,
                };
                const updatedObject = {
                  ...updateItem,
                  contact: embeddedObject,
                };
                setUpdateItem(updatedObject);
              }}
            />
          </div>
          <div className="col-4">
            <Input
              label="Current Address"
              placeholder="Current Address"
              value={updateItem?.contact?.currentAddress}
              name="currentAddress"
              onChange={(e) => changeHandler(e, "contact")}
            />
          </div>
          <div className="col-4">
            <Input
              label="Personal Email"
              placeholder="Personal Email"
              value={updateItem?.contact?.personalEmail}
              name="personalEmail"
              onChange={(e) => changeHandler(e, "contact")}
            />
          </div>
        </div>

        <div className="grid mt-3">
          <div className="col-4">
            <Input
              label="Permanent Address"
              placeholder="Permanent Address"
              value={updateItem?.contact?.permanentAddress}
              name="permanentAddress"
              onChange={(e) => changeHandler(e, "contact")}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Emergency number</span>

            <PhoneInput
              label="Emergency number"
              placeholder="Emergency number"
              name="emergencyContactNumber"
              country={"in"}
              value={updateItem?.contact?.emergencyContactNumber}
              onChange={(e) => {
                const embeddedObject = {
                  ...updateItem.contact,
                  emergencyContactNumber: e,
                };
                const updatedObject = {
                  ...updateItem,
                  contact: embeddedObject,
                };
                setUpdateItem(updatedObject);
              }}
            />
          </div>
          <div className="col-4 update-user-label-align">
            <span className="label-font">Gender</span>

            <Dropdown
              placeholder="Gender"
              options={constants.GENDER}
              name="gender"
              onChange={(e) => changeHandler(e, "personal")}
              value={updateItem?.personal?.gender}
            />
          </div>
        </div>

        <div className="col-12 grid mt-3">
          <div className="col-4 update-user-label-align">
            <span className="label-font">Blood Group</span>

            <Dropdown
              options={constants.BLOOD_GROUP}
              value={updateItem?.personal?.bloodGroup}
              placeholder="Blood Group"
              name="bloodGroup"
              onChange={(e) => changeHandler(e, "personal")}
            />
          </div>
        <div className="grid mt-3">
            <div style={{display:'flex'}} >
                <p className="mr-2 announcement-time">
                  Probation
                </p>
                <InputSwitch
                  name="isProbation"
                  checked={updateItem?.work?.isProbation}
                  onChange={(e)=> changeHandler(e, 'work')}
                />
            </div>
          </div>
        </div>

        <div className="grid flex justify-content-end mt-2 mr-3">
          <Button loading={loading} onClick={submitHandler} label="Submit" />
        </div>
      </Dialog>
    </div>
  );
}

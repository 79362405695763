import React from "react";
import { fn_firstcharacter, Fn_notification , replaceDotMethod } from "../../../utility/utility";
import Image from "../../../utility/image";
import Alphabet from "../alphabet/alphabet";

export default function Notification({ item }) {
  return (
    <div className="notify__container">
      <p className="title-notification">Notifications</p>
      {item.map((notify, id) => {
        if (notify.type === "announcement") {
          return (
            <div className="flex p-2 column-gap-2  " key={id}>
              {notify.createdBy?.basic?.profilePic ? (
                <img
                  className="notificationImage flex justify-content-center align-items-center"
                  src={notify.createdBy?.basic?.profilePic}
                  alt="announcement"
                />
              ) : (
                <div className="flex justify-content-center align-items-center ">
                  <Alphabet
                    letter={fn_firstcharacter(notify.createdBy?.basic?.fullName)}
                  />
                </div>
              )}
              <div className="flex-column" style={{ width: "240px" }}>
                <p className="announcement">
                  Announcement - {replaceDotMethod(notify?.title, 15)}
                </p>
                <p className="description">
                  {Fn_notification(notify?.description)}
                </p>
                <p className="notification-date">
                  {new Date(notify?.date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
              <div className="flex  justify-content-center">
                <img
                  src={Image.notifyBell}
                  alt="announceBell"
                  width={30}
                  height={30}
                />
              </div>
            </div>
          );
        } else if (notify.type === "birthday") {
          return (
            <div
              className="flex p-2 justify-content-between column-gap-2"
              key={id}
            >
              {notify?.profilePic ? (
                <img
                  className="notificationImage"
                  src={notify.profilePic}
                  alt="birthday"
                />
              ) : (
                <div className="flex justify-content-center">
                  <Alphabet
                    letter={fn_firstcharacter(notify?.fullName)}
                  />
                </div>
              )}
              <p className="today flex align-items-center">Today - {notify?.fullName}'s Birthday </p>
              <div className="flex justify-content-center align-items-center">
                <img
                  src={Image.birthdayBell}
                  alt="birthday-logo"
                  width={30}
                  height={30}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

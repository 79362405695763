import loginReducer from "../features/Auth/login";
import signUpReducer from "../features/Auth/signup";
import forgetPasswordReducer from "../features/Auth/forgetPassword";
import otpVerifyReducer from "../features/Auth/otpVerify";
import changePasswordReducer from "./../features/Auth/changePassword";
import orgSignUpReducer from "./Auth/orgSignUp";
import memberDetailsReducer from './UserDetails/UserDetails'
import designation from "./Users/designation";
import department from "./Users/department";
import role from "./Users/role";
import User from "./Users/User";
import timesheet from "./Timesheet/timesheet";
import Sprint from "./Projects/Sprint";
import Task from "./Projects/Task";
import Project from "./Projects/Project";
import Release from "./Projects/Release"
import scratchpad from "./Scratchpad/scratchpad";
import image from "./Image/image";
import File from "./Projects/File";
import projectSetting from "./Projects/ProjectSetting";
import dashBoard from "./DashBoard/index";
import wiki from "./wiki/index";
import assets from "./HumanResource/asset";
import paySlip from "./HumanResource/payslip.store";
import attendance from "./HumanResource/attendance.store"; 

export const reducers = {
  dashBoard: dashBoard,
  wiki:wiki,
  user: loginReducer,
  signUp: signUpReducer,
  forgetPassword: forgetPasswordReducer,
  otpVerify: otpVerifyReducer,
  changePassword: changePasswordReducer,
  orgSignUp: orgSignUpReducer,
  memberDetails:memberDetailsReducer,
  designation:designation,
  department:department,
  role:role,
  users:User,
  timesheet:timesheet,
  sprint:Sprint,
  task:Task,
  project:Project,
  release:Release,
  projectSetting:projectSetting,
  file:File,
  scratchpad:scratchpad,
  image:image,
  assets:assets,
  paySlip:paySlip,
  attendance:attendance,
};

import React from "react";
import { Dropdown } from "primereact/dropdown";
const SelectField = ({
  value,
  options,
  onChange,
  placeholder,
  optionLabel,
}) => {
  return (
    <div>
      <Dropdown
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        optionLabel={optionLabel}
      />
    </div>
  );
};
export default SelectField;

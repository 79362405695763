import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUsersByProject } from "../../../store/features/Projects/Project";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { SpeedDial } from "primereact/speeddial";
import MembersInfo from "./membersInfo";
import { DateFormat } from "../../../utility/utility";

export default function ProjectMemberTable() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { projectMemberDetails } = useSelector((store) => store.project);
  const [membersInfo, setMembersInfo] = useState(false);
  const [updateItem,setUpdateItem] = useState({});
  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);
  
  const userName = (data) => {
    return data?.memberDetails?.basic?.fullName;
  };
  function getDesignation(data) {
    return data?.designationDetails?.designation?.length > 0 ? data?.designationDetails?.designation : "N/A" ;
  }
  function getAddedDate(data) {
    return DateFormat(data.updatedAt)
  }
  const getRole = (data) => {
    return data?.memberDetails?.work.role;
  };

  const items = [
    {
      label: "Info",
      icon: "pi pi-info-circle",
      command: () => {
        setMembersInfo(!membersInfo);
      },
    },
  ];

  const Action = (data) => {
    return (
      <div className="members-action">
        <SpeedDial
          model={items}
          radius={50}
          direction="right"
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
          buttonClassName="p-button-outlined action-btn-design"
          onClick={()=> {
           setUpdateItem(data)
          }
        }
        />
      </div>
    );
  };
  return (
    <div>
      <MembersInfo
        membersInfo={membersInfo}
        setMembersInfo={setMembersInfo}
        updateItem={updateItem}
        setUpdateItem={setUpdateItem}
      />
      <div className="table-box">
        <div className="asset-table-size">
          <DataTable
            className="asset-table-font"
            sortMode="multiple"
            responsiveLayout="scroll"
            value={projectMemberDetails}
          >
            <Column field={userName} header="Username" />
            <Column field={getDesignation} header="Designation" />
            <Column field={getRole} header="Role" />
            <Column field={getAddedDate} header="Added Date" />
            <Column body={Action} header="Action" />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import PrimaryButton from "../../../component/common/button/button";
import BoardSetting from "../../../component/project/projectSetting/boardSetting/boardSetting";
import TaskType from "../../../component/project/projectSetting/taskType/taskType";
import { useSelector, useDispatch } from "react-redux";
import "./projectSetting.scss";
import { Toast } from "primereact/toast";
import ProjectDetails from "../../../component/project/projectSetting/projectDetails/projectDetails";

export default function ProjectSetting() {
  const dispatch = useDispatch();
  const toast = useRef(null);



  return (
    <>
      <Toast ref={toast} />
   
      <div className="p-3 border-black-alpha-10 border-round border-400 ">     
        {/* <<<<<<<<<<<<<Task Move for Backlogs>>>>>>>>>> */}
        {/* <div className="pl-3">
          <BoardSetting projectDetail={projectDetails} dispatch_Fn={dispatch} />
          <TaskType projectDetail={projectDetails} dispatch_Fn={dispatch} />
        </div> */}
        <div className="pl-3">
          <ProjectDetails  />
        </div>
      </div>
    </>
  );
}

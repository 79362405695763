import React, { useEffect, useState, useRef } from "react";
import Button from "../common/button/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import InputField from "../common/inputtext/input-field";
import constants from "../../constants";
import { putWithAuth } from "../../services/authentication";

export default function LeaveRequestDialog({
  approveDialogue,
  setApproveDialogue,
  updateItem,
  setUpdateItem,
  rejectDialog,
  setRejectDialog,
  update,
  setUpdate,
}) {
  const [reason, setReason] = useState("");
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  async function requestSubmitHandler() {
    try {
      let url = constants.API.LEAVE_REQUEST.CHANGE_STATUS;
      let data = updateItem;
      data.status = "Approved";
      await putWithAuth(url, data, token);
      setUpdateItem({});
      setUpdate(!update);
      setReason("");
      setApproveDialogue(!approveDialogue);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  async function rejectSubmitHandler() {
    try {
      let url = constants.API.LEAVE_REQUEST.CHANGE_STATUS;
      let data = updateItem;
      data.status = "Declined";
      data.rejectedReason = reason;
      await putWithAuth(url, data, token);
      setUpdateItem({});
      setReason("");
      setRejectDialog(!rejectDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Approve Leave"
        visible={approveDialogue}
        style={{ width: "30vw" }}
        onHide={() => {
          setApproveDialogue(!approveDialogue);
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="flex">
              <h4>Are you sure you want to approve the leave?</h4>
            </div>

            <br />
            <div className="flex flex-row-reverse mt-5 w-full">
              <Button label="Approve" onClick={requestSubmitHandler}></Button>
              <div className="mr-4 mt-2">
                <div
                  className="announcement-time"
                  onClick={() => setApproveDialogue(!approveDialogue)}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Reject Leave"
        visible={rejectDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setRejectDialog(!rejectDialog);
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <h5>Are you sure you want to reject the leave?</h5>
            </div>
            <div className="col-12">
              <InputField
                placeholder="Reason"
                label="Reason"
                onChange={(e) => setReason(e.target.value)}
              />
            </div>

            <div className="col-3 mt-3">
              <Button label="Reject" onClick={rejectSubmitHandler}></Button>
            </div>
            <div className="col-3 mt-3 mr-5">
              <Button
                label="Cancel"
                onClick={() => setRejectDialog(!rejectDialog)}
              ></Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

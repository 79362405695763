import React, { useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { getStartAndEndDate } from "../../factory-function/getStartDateAndEndDate";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimesheet,
  setFilterDropdown,
} from "../../store/features/Timesheet/timesheet";

export default function DropdownFilter({projectTimesheet}) {
  const dispatch = useDispatch();

  const { filterDropDown } = useSelector((store) => store.timesheet);
  const timesheet = useSelector((store) => store.timesheet);

  const timesheetFilterDropdown = [
    { label: "Today", value: getStartAndEndDate("Today") },
    { label: "Current Week", value: getStartAndEndDate("Current Week") },
    { label: "Last Week", value: getStartAndEndDate("Last Week") },
    { label: "Current Month", value: getStartAndEndDate("Current Month") },
    { label: "Last Month", value: getStartAndEndDate("Last Month") },
  ];

  useEffect(() => {
    dispatch(getTimesheet());
  }, [filterDropDown]);

  
  function changeHandler(e) {
    dispatch(setFilterDropdown(e.target.value));
  }

  return (
    <div className="drop-input">
      <Dropdown
        options={timesheetFilterDropdown}
        onChange={changeHandler}
        value={filterDropDown}
        disabled={timesheet.filterStatus}
      />
    </div>
  );
}

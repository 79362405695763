import { Dialog } from "primereact/dialog";
import React, { useEffect, useState, useRef } from "react";
import Button from "../../component/common/button/button";
import RecogitionCard from "../../component/human-resource/recogitionCard";
import constants from "../../constants";
import { getWithAuth, postWithAuth } from "../../services/authentication";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Input from "../../component/common/inputtext/input-field";
import { Toast } from "primereact/toast";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import emptyAsset from "./../../assets/empty-recognition.svg";
import Spinner from "../../component/common/spinner/spinner";
import SearchIconBar from "../../component/common/search-icon-searchBar/searchIconBar";
import { isAdminAccess } from "../../utility/utility";

export default function Recognition() {
  const token = localStorage.getItem("token");

  const toast = useRef(null);
  const [allRecognition, setAllRecognition] = useState([]);
  const [createRecognitionDialog, setCreateRecognitionDialog] = useState(false);
  const [createRecognition, setCreateRecogntion] = useState({
    employeeId: "",
    recognition: "",
    from: "",
    to: "",
  });
  const [employeeDropdown, setEmployeeDropdown] = useState([]);
  const [update, setUpdate] = useState(false);
  const [filter, setFilter] = useState("1");
  const [search, setSearch] = useState("");
  const [submitDisable, setSubmitDisable] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const hrAdmin = isAdminAccess("Human Resource")
 

  useEffect(() => {
    getAllRecognition(filter, search);
    getEmployeeList();
  }, []);

  useEffect(() => {
    getAllRecognition(filter, search);
  }, [update]);

  useEffect(() => {
    let y = Object.values(createRecognition).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [createRecognition]);

  async function getAllRecognition(filter, search) {
    setSpinner(true);
    let url = constants.API.RECOGNITION.CREATE + `?filterOptions=${filter}`;
    if (search !== "") {
      url = url + `&search=${search}`;
    }
    const response = await getWithAuth(url, token);
    let allRecognition = [];
    response.data.recognitions.forEach((recognition) => {
      recognition.recognitions.forEach((rec) => {
        allRecognition.push(rec);
      });
    });
    setAllRecognition(allRecognition);
    setSpinner(false);
  }
  async function getEmployeeList() {
    const url = constants.API.MEMBER.LIST;
    const response = await getWithAuth(url, token);
    const empData = [];
    response.data.members.forEach((member) => {
      empData.push({ label: member.basic.fullName, value: member._id });
    });
    setEmployeeDropdown(empData);
  }

  const DateFormated = (date) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const formatDate = new Date(date?.getTime() + oneDay);
    return formatDate.toISOString();
  };
  async function submitHandler() {
    try {
      const data = createRecognition;
      data["from"] = DateFormated(data.from);
      data["to"] = DateFormated(data.to);
      const url = constants.API.RECOGNITION.CREATE;
      await postWithAuth(url, data, token);
      setCreateRecogntion({});
      setCreateRecognitionDialog(!createRecognitionDialog);
      getAllRecognition(filter, search);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  const filterOptions = [
    { label: "Current Month", value: "1" },
    { label: "Last Month", value: "-1" },
    { label: "Last 3 Months", value: "-3" },
    { label: "Last 6 Month", value: "-6" },
    { label: "This Year", value: "12" },
  ];

  return (
    <div>
      <Toast ref={toast} />
      <div className="mr-6 flex flex-row-reverse justify-content-start">
        <div className="assets-actions">
          <div className="flex ml-5">
            <div className="search-bar flex align-items-center">
              <SearchIconBar
                placeholder="Search"
                label="Search"
                name="search"
                type="text"
                onChange={(e) => {
                  setSearch(e.target.value);
                  getAllRecognition(filter, e.target.value);
                }}
              ></SearchIconBar>
            </div>
            <div className="drop-input mr-4">
              <Dropdown
                options={filterOptions}
                value={filter}
                onChange={(e) => {
                  setFilter(e.value);
                  getAllRecognition(e.value, search);
                }}
              ></Dropdown>
            </div>
            {hrAdmin ? (
              <Button
                className="create-btn"
                label="Create"
                onClick={() => {
                  setCreateRecognitionDialog(!createRecognitionDialog);
                }}
              ></Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="grid flex justify-content-center">
        {spinner === true ? <Spinner value={spinner}></Spinner> : <></>}
      </div>{" "}
      {allRecognition && allRecognition.length > 0 && spinner === false ? (
        <div>
          <RecogitionCard
            allRecognition={allRecognition}
            employeeDropdown={employeeDropdown}
            update={update}
            setUpdate={setUpdate}
          ></RecogitionCard>
        </div>
      ) : (
        <></>
      )}
      {spinner === false && allRecognition && allRecognition.length === 0 ? (
        <EmptyScreen
          img={emptyAsset}
          title={constants.EMPTY_SCREEN_TEXT[0].title}
          description={constants.EMPTY_SCREEN_TEXT[0].description}
        ></EmptyScreen>
      ) : (
        <></>
      )}
      <Dialog
        header="Create Recognition"
        visible={createRecognitionDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateRecognitionDialog(!createRecognitionDialog);
          setCreateRecogntion({});
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Dropdown
                options={employeeDropdown}
                placeholder="Employee"
                value={createRecognition.employeeId}
                onChange={(e) =>
                  setCreateRecogntion({
                    ...createRecognition,
                    employeeId: e.value,
                  })
                }
              ></Dropdown>
            </div>

            <div className="col-6">
              <Input
                placeholder="Recognition"
                name="recognition"
                label="Recognition"
                type="text"
                onChange={(e) =>
                  setCreateRecogntion({
                    ...createRecognition,
                    recognition: e.target.value,
                  })
                }
              ></Input>
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                value={createRecognition.from}
                onChange={(e) =>
                  setCreateRecogntion({
                    ...createRecognition,
                    from: new Date(e.value),
                  })
                }
                maxDate={createRecognition.to}
                readOnlyInput
              ></Calendar>
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                minDate={createRecognition.from}
                readOnlyInput
                dateFormat="dd/mm/yy"
                value={createRecognition.to}
                onChange={(e) =>
                  setCreateRecogntion({
                    ...createRecognition,
                    to: new Date(e.value),
                  })
                }
              ></Calendar>
            </div>
          </div>
          <div className="grid justify-content-end mt-3">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={submitDisable}
            ></Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
// import Input from "../../component/common/inputtext/input-field";
// import Button from "../../component/common/button/button";
import validate from "../../../factory-function/validate";
import Input from "../../../component/common/inputtext/input-field";
import Button from "../../../component/common/button/button";
import { setActiveIndexExistingStep, setExistingAccountEmail } from "../../../store/features/Auth/login";
import { useDispatch } from "react-redux";





export const ExistingCustomer = () => {
  const dispatch =  useDispatch();


  const [existingUserEmail, setExistingUserEmail] = useState("");
  const [errorDetails, setErrorDetails] = useState("");

  const submitHandler = () => {
    dispatch(setExistingAccountEmail(existingUserEmail))
    dispatch(setActiveIndexExistingStep(1))
  };

  

  return (
    <>
      <div className="flex flex-column row-gap-4">
        <div className="flex flex-column row-gap-2">
          <p className="h1-font">Already have an account</p>
          <p className="paragraph-font">Fill the Email to Continue</p>
        </div>

        <div>
          <Input
            placeholder="Your Email"
            type="email"
            name="email"
            onChange={(e) => {
              setExistingUserEmail(e.target.value);
              setErrorDetails(validate({ email : e.target.value }));
            }}
            label="Email"
          />
           <span className="error-text">{errorDetails}</span>
        </div>

        <div className="flex justify-content-end">
          <Button
            onClick={submitHandler}
            label="Contine"
            disabled={existingUserEmail === "" || errorDetails !== ""}
          />
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/common/button/button";
import {
  otpMemberVerify,
  otpVerify,
  resetStatus,
  setIsLoading,
} from "../../store/features/Auth/otpVerify";
import { Toast } from "primereact/toast";
import {
  signUpResendOtp,
  resetOtpStatus,
  editActiveIndex,
} from "../../store/features/Auth/signup";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const styleSheet = {
  countDown: {
    color: "rgba(220, 58, 97, 1)",
    fontWeight: "400",
  },
  notReceived: {
    color: "rgba(0, 0, 0, 1)",
  },
  resend: {
    color: "rgba(105, 105, 105, 1)",
  },
};

export default function VerifyOtp() {
  const location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const toast = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const queryEmail = queryParams.get("email");
  const queryorgId = queryParams.get("orgId");
  const { email, resendOtpStatus, resendOtpError } = useSelector(
    (store) => store.signUp
  );
  const { status, error, isLoading } = useSelector((store) => store.otpVerify);

  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [loader, setloader] = useState(false);

  setTimeout(() => {
    if (seconds > 0) setSeconds(seconds - 1);
  }, 1000);

  useEffect(() => {
    if (otp.length < 6) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));
  }, [otp]);

  const submitHandler = () => {
    setloader(true);
    if (queryEmail && queryorgId) {
      const payload = {
        email: queryEmail,
        orgId: queryorgId,
        verificationCode: parseInt(otp),
      };
      dispatch(otpMemberVerify(payload)).then((res) => {
        setloader(false);
        if (!res.error) {
          toast.current.show({
            severity: "success",
            summary: "User Created Successfully ",
            life: 2000,
          });
          setTimeout(() => {
            Navigate("/auth/login");
          }, 500);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Incorrect OTP",
            life: 3000,
          });
        }
      });
    } else {
      const otpDetails = {
        email: email,
        verificationCode: parseInt(otp),
      };
      dispatch(otpVerify(otpDetails)).then((res) => {
        setloader(false);
        if (!res.error) dispatch(editActiveIndex(1));
      });
    }
  };

  useEffect(() => {
    if (status === "error") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "Message Content",
        life: 3000,
      });
    }
    if (status === "success") {
      toast.current.show({
        severity: "success",
        summary: "SignUp in Progress",
        detail: "Message Content",
        life: 3000,
      });
    }
    dispatch(resetStatus());
  }, [status]);

  useEffect(() => {
    if (resendOtpStatus != "") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resendOtpStatus,
        life: 3000,
      });
      dispatch(resetOtpStatus());
      setSeconds(60);
    } else if (resendOtpError != "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resendOtpError,
        life: 3000,
      });
    }
  }, [resendOtpError, resendOtpStatus]);

  const resetOtpHandler = () => {
    if (seconds === 0) {
      const emailData = queryEmail ? queryEmail : email;
      dispatch(signUpResendOtp(emailData));
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-5">
        <div className="flex flex-column row-gap-2">
          <div className="h1-font">Verify Your Email Account</div>
          <div className="paragraph-font">
            Please enter the verification code we sent to your email
          </div>
        </div>

        <OtpInput
          value={otp}
          onChange={(otp) => setOtp(otp)}
          numInputs={6}
          separator={<p style={{ padding: "10px" }} />}
          isInputNum={true}
          shouldAutoFocus={true}
          containerStyle={"otpContainer"}
          inputStyle={"otpInputs"}
          focusStyle={"otpInputsFocus"}
        />

        <div className="paragraph-font flex justify-content-between">
          <span style={styleSheet.countDown} className="text-sm">
            {seconds / 10 > 1 ? `00:${seconds}` : `00:0${seconds}`}
          </span>
          <div className="flex align-items-center column-gap-1">
            <p className="text-sm" style={styleSheet.notReceived}>
              Didn't receive code ?
            </p>
            <span
              className="text-sm cursor-pointer"
              style={seconds ? styleSheet.resend : styleSheet.countDown}
              onClick={() => resetOtpHandler()}
            >
              Resend
            </span>
          </div>
        </div>

        <div className="flex justify-content-end">
          <Button
            onClick={submitHandler}
            label="Submit"
            loading={loader}
            disabled={otp.length < 6}
          />
        </div>
      </div>
    </>
  );
}

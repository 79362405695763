import React from "react";
import { Checkbox } from "primereact/checkbox";
export default function Checkboxes({ onChange, value, checked,name , disabled = false }) {
  return (
    <Checkbox
      inputId="binary"
      value={value}
      onChange={onChange}
      checked={checked}
      name={name}
      disabled={disabled}
    />
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../component/common/inputtext/input-field";
import Button from "../../component/common/button/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
const { getNames } = require("country-list");

export default function Payment() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  return (
    <div className="flex align-items-center justify-content-center h-screen">
      <Toast ref={toast} />
      <div>
        <div className="h1-font">Payment</div>
        <div className="plan-font mt-2">
          To Finalize your Subscription, kindly complete the payment.
        </div>
        <div className="mt-5">
          <Input
            placeholder="Name on Card"
            name="fullName"
            label="Name on Card"
            type="text"
            onChange={""}
            value={""}
          />
        </div>
        <div className="mt-5">
          <Input
            placeholder="Card Number"
            name="cardNumber"
            label="Card Number"
            type="text"
            onChange={""}
            value={""}
          />
        </div>

        <div className="mt-5 flex justify-content-between">
          <Input
            placeholder="Expiry"
            name="expiry"
            label="Expiry"
            type="text"
            onChange={""}
            value={""}
          />
          <Input
            placeholder="CVV"
            name="cvv"
            label="CVV"
            type="text"
            onChange={""}
            value={""}
          />
        </div>

        <div className="mt-5">
          <Dropdown
            placeholder="Nationality"
            options={getNames()}
            value={""}
            name="nationality"
            onChange={""}
          />
        </div>

        <div className="flex justify-content-end mt-5">
          <Button label="Continue" />
        </div>
      </div>
    </div>
  );
}

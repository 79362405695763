import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  imageUrl: "",
  removeImageUrlStatus: "",
};

export const uploadImage = createAsyncThunk(
  "/upload-image",
  async ({ data, folderName }, {rejectWithValue}) => {
    try {
      const url = constants.API.CLOUD.UPLOAD + `/${folderName}/upload`;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removeImage = createAsyncThunk(
  "/remove-image",
  async ({ data, folderName }, {rejectWithValue}) => {
    try {
      const url = constants.API.CLOUD.UPLOAD + `/${folderName}/delete`;
      const response = await api.post(url, { url: data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    resetImageUrl: (state, action) => {
      state.imageUrl = "";
    },
    resetRemoveImageUrlStatus: (state, action) => {
      state.removeImageUrlStatus = "";
    },
  },
  extraReducers: {
    [uploadImage.pending]: (state) => {},
    [uploadImage.fulfilled]: (state, action) => {
      state.status = "success";
      state.imageUrl = action.payload.URL;
    },
    [uploadImage.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [removeImage.pending]: (state) => {},
    [removeImage.fulfilled]: (state, action) => {
      state.removeImageUrlStatus = "success";
    },
    [removeImage.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export const { resetStatus, resetImageUrl, resetRemoveImageUrlStatus } =
  imageSlice.actions;

export default imageSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Button from "../../common/button/button";
import Input from "../../common/inputtext/input-field";
import { Editor } from "primereact/editor";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import {
  createSprint,
  getSprintList,
} from "../../../store/features/Projects/Sprint";
import { Toast } from "primereact/toast";
import { DateFormat, weekDropdown } from "../../../utility/utility";
import { getAllUsers } from "../../../store/features/Users/User";

export default function CreateSprint({ addSprintDialog, setAddSprintDialog }) {
  const { allUsers } = useSelector((store) => store.users);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [userOption, setUserOptions] = useState([]);
  const [sprintDetails, setSprintDetails] = useState({
    title: "",
    goal: "",
    sprintOwner: "",
    duration: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    dispatch(getAllUsers());
    return () => {
      setSprintDetails({});
    };
  }, []);

  useEffect(() => {
    if (allUsers) {
      let options = [];
      allUsers.forEach((user) => {
        options.push({ value: user._id, label: user.basic.fullName });
      });
      setUserOptions(options);
    }
  }, [allUsers]);

  const selectedWeek = (value) => {
    const today = new Date();
    setSprintDetails((prev) => {
      return { ...prev, startDate: today, duration: value };
    });
    switch (value) {
      case "1 Week":
        setSprintDetails((prev) => {
          const date = {
            ...prev,
            endDate: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000),
          };
          return date;
        });
        break;
      case "2 Weeks":
        setSprintDetails((prev) => {
          const date = {
            ...prev,
            endDate: new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000),
          };
          return date;
        });
        break;
      case "3 Weeks":
        setSprintDetails((prev) => {
          const date = {
            ...prev,
            endDate: new Date(today.getTime() + 21 * 24 * 60 * 60 * 1000),
          };
          return date;
        });
        break;
      case "4 Weeks":
        setSprintDetails((prev) => {
          const date = {
            ...prev,
            endDate: new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000),
          };
          return date;
        });
        break;
      default:
        setSprintDetails((prev) => {
          const date = { ...prev, endDate: "", startDate: "" };
          return date;
        });
        break;
    }
  };

  const submitHandler = () => {
    const payload = {
      ...sprintDetails,
      startDate: DateFormat(sprintDetails.startDate),
      endDate: DateFormat(sprintDetails.endDate),
      ["projectId"]: projectId,
    };

    dispatch(createSprint(payload)).then((res) => {
      if (!res?.error) {
        setAddSprintDialog(!addSprintDialog);
        dispatch(getSprintList(projectId));
      } else {
        toast.current.show({
          severity: "error",
          summary: res?.payload,
          detail: "",
          life: 2000,
        });
      }
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Create Sprint"
        visible={addSprintDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setAddSprintDialog(!addSprintDialog);
          setSprintDetails({});
        }}
      >
        <div className="flex flex-column row-gap-3">
          <div className="col-12 mt-3">
            <Input
              className="asset-input"
              placeholder="Sprint Name"
              name="title"
              label="Sprint Name"
              type="text"
              required={true}
              onChange={(e) => {
                setSprintDetails((prev) => {
                  return { ...prev, title: e.target.value };
                });
              }}
            />
          </div>

          <div className="col-12">
            <Editor
              className="editor-border"
              style={{ height: "200px" }}
              value={sprintDetails.goal}
              onTextChange={(e) => {
                setSprintDetails((prev) => {
                  return { ...prev, goal: e.htmlValue };
                });
              }}
            />
          </div>

          <div className="col-12">
            <Dropdown
              options={userOption}
              placeholder="Sprint Owner"
              value={sprintDetails.sprintOwner}
              onChange={(e) => {
                setSprintDetails((prev) => {
                  return { ...prev, sprintOwner: e.value };
                });
              }}
            />
          </div>

          <div className="col-12">
            <Dropdown
              options={weekDropdown}
              placeholder="Duration"
              value={sprintDetails.duration}
              onChange={(e) => selectedWeek(e.target.value)}
            />
          </div>

          <div className="flex">
            <div className="col-6">
              <Calendar
                placeholder="Start Date"
                dateFormat="dd/mm/yy"
                value={sprintDetails.startDate}
                readOnlyInput
                onChange={(e) =>
                  setSprintDetails((prev) => {
                    return { ...prev, startDate: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-6">
              <Calendar
                placeholder="End Date"
                dateFormat="dd/mm/yy"
                value={sprintDetails.endDate}
                readOnlyInput
                onChange={(e) =>
                  setSprintDetails((prev) => {
                    return { ...prev, endDate: e.target.value };
                  })
                }
              />
            </div>
          </div>

          <div className="flex justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={
                !sprintDetails.title ||
                !sprintDetails.startDate ||
                !sprintDetails.endDate ||
                !sprintDetails?.sprintOwner
              }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import txt from "../../../assets/filetypeicon/txt-icon.svg";
import docx from "../../../assets/filetypeicon/doc-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  resetWikiExportUrl,
  exportWikiDocs,
} from "../../../store/features/wiki";

export default function ExportWiki({
  exportWikiDialog,
  setWikiExportDialog,
  pageId,
}) {
  const dispatch = useDispatch();
  const { wikiExportUrl, subPageArray } = useSelector((store) => store.wiki);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (wikiExportUrl !== "") {
      window.open(wikiExportUrl, "_blank", "noopener,noreferrer");
      dispatch(resetWikiExportUrl());
    }
  }, [wikiExportUrl]);

  return (
    <div>
      <Dialog
        header="Export"
        visible={exportWikiDialog}
        style={{ width: "30vw" }}
        onHide={() => setWikiExportDialog(!exportWikiDialog)}
      >
        <div className="grid flex">
          <div className="ml-7">
            <img
              src={txt}
              style={{ height: "100px" }}
              className="hover"
              onClick={() => {
                dispatch(
                  exportWikiDocs({
                    orgId: user?.orgId,
                    pageId: pageId,
                    extension: "txt",
                  })
                );
                setWikiExportDialog(!exportWikiDialog);
              }}
            ></img>
          </div>
          <div className="ml-7">
            <img
              src={docx}
              style={{ height: "100px" }}
              className="hover"
              onClick={() => {
                dispatch(
                  exportWikiDocs({
                    orgId: user?.orgId,
                    pageId: pageId,
                    extension: "docs",
                  })
                );
                setWikiExportDialog(!exportWikiDialog);
              }}
            ></img>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/button/button";
import Input from "../../common/inputtext/input-field";
import validate from "../../../factory-function/validate";
import objectEquals from "./../../../factory-function/objectEquals";


export default function TaskAddLink({ setUpdateValue, links }) {

  const toast = useRef(null);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [linkErrorMessage, setLinkErrorMessage] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [selectUpdatedLink, setSelectedUpdatedLink] = useState("");



  useEffect(() => {
    const linkError = validate({ website: url });
    setLinkErrorMessage(linkError);
    if (linkError == "" && url != "") setLinkError(true);
    else setLinkError(false);
  }, [url]);

  const addLinkHandler = () => {
    if (isUpdate) {
      let filter = links.filter(
        (link) => !objectEquals(link, selectUpdatedLink)
      );
      filter.push({ name: name, url: url });
      setUpdateValue(prev => {
        return {...prev,links : filter }
      })
      updateLink();
    } else {
      const checkIfLinkExists = links.findIndex((link) => {
        return link.name === name;
      });

      if (checkIfLinkExists !== -1) {
        return toast.current.show({
          severity: "error",
          summary: "Link name already Exists",
          detail: "",
          life: 3000,
        });
      } else {
        setUpdateValue(prev => {
          return {...prev,links : [...links, { name, url }] }
        })
        updateLink();
      }
    }
  }


  const updateLink = () => {
    setName("");
    setUrl("");
    if (isUpdate) setIsUpdate(false);
  }

  const deleteHandler = (linkurl) => {
    const deletedLink = links.filter((link) => link.url != linkurl);
    setUpdateValue(prev => {
      return {...prev,links : deletedLink }
    })
  };

  const updateHandler = (link) => {
    setName(link.name);
    setUrl(link.url);
    setSelectedUpdatedLink({ name: link.name, url: link.url });
    setIsUpdate(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-2">
        <h4> Attach Links</h4>
        <div>
          <span className="label-font">Paste any link here...</span>
          <Input
            name="attach"
            placeholder="Paste any link here..."
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <span className="text-red-500">{linkErrorMessage}</span>
        </div>

        {linkError && (
          <div className="flex flex-column row-gap-2 mt-3">
            <Input
              label="Link Title"
              placeholder="Link Title"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="flex justify-content-end">
              <Button label="Submit" onClick={addLinkHandler} disabled={name === ""} />
            </div>
          </div>
        )}

        <div className="flex flex-column row-gap-2">
          {links.map((link, id) => {
            return (
              <div
                key={id}
                className="flex align-content-center justify-content-between link-layout cursor-pointer"
              >
                <div className="flex align-content-center justify-content-center">
                  <p
                    className="add-link-font"
                    onClick={() => window.open(link.url)}
                  >
                    {link.name}
                  </p>
                </div>
                <div className="flex column-gap-3 align-content-center justify-content-between">
                  <i
                    className="pi pi-pencil"
                    onClick={() => updateHandler(link)}
                  />
                  <i
                    className="pi pi-trash"
                    onClick={() => deleteHandler(link.url)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import ScratchpadCard from "./ScratchpadCard";

export default function ScratchpadCardContainer() {
  const { scratchpad } = useSelector((store) => store.scratchpad);

  return (
    <div className="flex flex-column gap-4 mt-4 overflow-y-scroll" style={{background: "#fafcff",height: "80vh"}}>
      {scratchpad.map((scratch, id) => {
        return <ScratchpadCard scratchpad={scratch} key={id} />;
      })}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch } from "react-redux";
import { getProfileDetails } from "../../../store/features/Users/User";
import { Divider } from "primereact/divider";
import EditProfile from "./editProfile";
import Alphabet from "../alphabet/alphabet";
import { DateFormat, fn_firstcharacter } from "../../../utility/utility";
import ProgressBar from "../progress-bar/progressBar";
import Image from "../../../utility/image";

export default function MyProfile({ myProfileDialog, setMyProfileDialog }) {
  const dispatch = useDispatch();
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { profileDetails } = useSelector((store) => store.users);
  const [editProfileDialog, setEditProfileDialog] = useState(false);
  const { basic, personal, work, designationName, contact, email } =
    profileDetails;

  useEffect(() => {
    let memberOrgId = memberDetails.memberOrgId;
    if (memberOrgId) {
      dispatch(getProfileDetails(memberOrgId));
    }
  }, [memberDetails]);

  const profileDetailsbar = () => {
    const data = [...getProfileData, ...getReference];
    data.push({
      id: 1,
      fieldName: "profile",
      value: isEmptyValue(basic?.profilePic),
      image: null,
    });
    const total_field = { ...data };
    const filled_Fields = [];
    Object.keys(total_field).forEach((key) => {
      if (
        total_field[key].value === "" ||
        total_field[key].value === "N/A" ||
        total_field[key].value === "invalid format"
      ) {
        return;
      }
      filled_Fields.push(total_field[key]);
    });

    const percentage = (filled_Fields.length / data.length) * 100;
    return percentage;
  };

  const isEmptyValue = (v) => {
    return v ? v : "N/A";
  };

  const isEmptyValueregex = (v) => {
    if (v) {
      // Use a regular expression to match only alphabetic characters
      const alphabeticRegex = /[a-zA-Z]+/g;
      const matches = v.match(alphabeticRegex);

      // Join the matched alphabetic characters together into a string
      return matches ? matches.join("") : "N/A";
    } else {
      return "N/A";
    }
  };

  const getProfileData = [
    {
      id: 1,
      fieldName: "Full Name",
      value: isEmptyValueregex(basic?.fullName),
      image: Image.userFullname,
    },
    {
      id: 2,
      fieldName: "Email",
      value: isEmptyValue(email),
      image: Image.userEmail,
    },
    {
      id: 3,
      fieldName: "Phone Number",
      value: isEmptyValue(personal?.phone),
      image: Image.userPhone,
    },
    {
      id: 4,
      fieldName: "Date of Birth",
      value: isEmptyValue(DateFormat(personal?.dob)),
      image: Image.userDob,
    },
    {
      id: 5,
      fieldName: "Gender",
      value: isEmptyValue(personal?.gender),
      image: Image.userGender,
    },
    {
      id: 6,
      fieldName: "Blood Group",
      value: isEmptyValue(personal?.bloodGroup),
      image: Image.userBlood,
    },
    {
      id: 7,
      fieldName: "Role",
      value: isEmptyValue(work?.role),
      image: Image.userFullname,
    },
    {
      id: 8,
      fieldName: "Department",
      value: isEmptyValue(designationName),
      image: Image.userDepartment,
    },
    {
      id: 9,
      fieldName: "Nationality",
      value: isEmptyValue(personal?.nationality),
      image: Image.userNationality,
    },
    {
      id: 10,
      fieldName: "Employee Id",
      value: isEmptyValue(work?.employeeId),
      image: Image.userFullname,
    },
    {
      id: 11,
      fieldName: "Date Of Joining",
      value: isEmptyValue(DateFormat(work?.dateOfJoining)),
      image: Image.userDoj,
    },
    {
      id: 12,
      fieldName: "Designation",
      value: isEmptyValue(designationName),
      image: Image.userDesignation,
    },
  ];

  const getReference = [
    {
      id: 1,
      fieldName: "Reference Name",
      value: isEmptyValue(contact?.referenceName1),
      image: Image.userFullname,
    },
    {
      id: 2,
      fieldName: "Reference Contact Number",
      value: isEmptyValue(contact?.referenceContactNumber1),
      image: Image.userPhone,
    },
    {
      id: 3,
      fieldName: "Personal Email",
      value: isEmptyValue(contact?.personalEmail),
      image: Image.userEmail,
    },
    {
      id: 4,
      fieldName: "Current Address",
      value: isEmptyValue(contact?.currentAddress),
      image: Image.userLocation,
    },
    {
      id: 5,
      fieldName: "Permanent Address",
      value: isEmptyValue(contact?.permanentAddress),
      image: Image.userLocation,
    },
  ];

  const ColumnTemplateDesign = ({ image, fieldName, value }) => {
    return (
      <div className="flex flex-column col-4 row-gap-2">
        <div className="flex column-gap-2 align-items-center">
          <img src={image} alt={fieldName} width={16} height={16} />
          <div className="field__name">{fieldName}</div>
        </div>
        <div>
          <p className="text-sm mx-4 font-normal">{value}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <EditProfile
        setEditProfileDialog={setEditProfileDialog}
        editProfileDialog={editProfileDialog}
      />
      <Dialog
        header="My Profile"
        visible={myProfileDialog}
        style={{ width: "80vw" }}
        onHide={() => {
          setMyProfileDialog(!myProfileDialog);
        }}
      >
        <div className="flex align-items-center column-gap-3">
          {basic?.profilePic ? (
            <img className="members-info-image" src={basic?.profilePic} />
          ) : (
            <Alphabet
              width="100px"
              height="100px"
              fontSize="40px"
              letter={fn_firstcharacter(basic?.fullName)}
            />
          )}

          <div className="flex flex-column row-gap-2">
            <h4>Profile Details</h4>
            <div>
              <ProgressBar
                width={150}
                height={15}
                value={profileDetailsbar()}
                maxValue={100}
                color="#5a78ee"
              />
            </div>
            <div className="release-card-desc-font">
              Complete your profile to experience Squadzen fully!
            </div>
          </div>
        </div>

        {/* Personal Info */}

        <div className="flex justify-content-between mt-3">
          <div className="announcement-time ml-2">Personal Info</div>
          <div
            className="flex mr-2"
            onClick={() => setEditProfileDialog(!editProfileDialog)}
          >
            <i className="pi pi-pencil mr-2 edit-profile-font"></i>
            <div
              style={{ cursor: "pointer" }}
              className="announcement-time edit-profile-font"
            >
              Edit Profile
            </div>
          </div>
        </div>
        <Divider />

        <div className="flex flex-wrap">
          {getProfileData.map((user) => (
            <ColumnTemplateDesign
              key={user.id}
              image={user.image}
              fieldName={user.fieldName}
              value={user.value}
            />
          ))}
        </div>

        <div className="announcement-time mt-5 ml-3">My Reference</div>
        <Divider />

        <div className="flex flex-wrap">
          {getReference.map((reference) => (
            <ColumnTemplateDesign
              key={reference.id}
              image={reference.image}
              fieldName={reference.fieldName}
              value={reference.value}
            />
          ))}
        </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import constants from "../../../constants";
import { getWithAuth } from "../../../services/authentication";
import EmployeeLeaveList from "../../human-resource/employeeLeaveList";
import EmptyScreen from "../empty/emptyScreen";
import Image from "../../../utility/image";

const PieChart = ({ employeeCount, todayLeaveCount, assetStatusCount }) => {
  const [employeePercentage, setEmployeePercentage] = useState([]);
  // const [assetPercentage, setAssetPercentage] = useState([]);
  const [todayLeave, setTodayLeave] = useState([]);
  const [tomorrowLeave, setTomorrowLeave] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getLeaveEmployees();
    const total = employeeCount + todayLeaveCount;
    const percentage = [
      isNaN((employeeCount / total) * 100)
        ? 0
        : ((employeeCount / total) * 100).toFixed(2),

      isNaN((todayLeaveCount / total) * 100)
        ? 0
        : ((todayLeaveCount / total) * 100).toFixed(2),
    ];
    setEmployeePercentage(percentage);
    // let totalAssets = assetStatusCount
    //   ? assetStatusCount.reduce(function (prev, cur) {
    //       return prev + cur;
    //     }, 0)
    //   : 0;
    // const assetPer = [
    //   ((assetStatusCount[0] / totalAssets) * 100).toFixed(2),
    //   ((assetStatusCount[1] / totalAssets) * 100).toFixed(2),
    //   ((assetStatusCount[2] / totalAssets) * 100).toFixed(2),
    //   ((assetStatusCount[3] / totalAssets) * 100).toFixed(2),
    // ];
    // setAssetPercentage(assetPer);
  }, []);

  const [employeeChartData] = useState({
    // labels: ["Working","Leave"],
    datasets: [
      {
        data: [employeeCount, todayLeaveCount],
        backgroundColor: ["#F32D7D", "#4CB8F1"],
      },
    ],
  });
  const [assetsChartData] = useState({
    // labels: ['Occupied','Requested','Retired','UnAssigned'],
    datasets: [
      {
        data: assetStatusCount,
        backgroundColor: ["#F32D7D", "#F3AB29", "#4CB8F1", "#FA5001"],
      },
    ],
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });
  async function getLeaveEmployees() {
    const employeeLeaveURL = constants.API.LEAVE_REQUEST.GET_WHO_ON_LEAVE;
    const response = await getWithAuth(employeeLeaveURL, token);
    setTodayLeave(response.data.today);
    setTomorrowLeave(response.data.tomorrow);
  }

  return (
    <div>
      <div className="grid ml-4">
        <div className="col-8 flex">
          {/* Leave Chart */}
          <div className="col-6">
            <div className="chart-contaniner">
              <div>
                <div>
                  {[employeeCount, todayLeaveCount]?.every(
                    (element) => element === 0
                  ) ? (
                    <div style={{ padding: "50px" }}>
                      <EmptyScreen
                        img={Image.emptyLeave}
                        description="Currently no leaves "
                      />
                    </div>
                  ) : (
                    <>
                      <Chart
                        type="doughnut"
                        data={employeeChartData}
                        options={lightOptions}
                      />
                      <div>
                        <h4 className="mt-3 ml-4 scratchpad-card-header-font">
                          All Employees
                        </h4>
                        <div className="flex mt-2 ml-6">
                          <div className="leave-color mt-1"></div>
                          <div className="pie-chart-data-font ml-2 mb-1 flex">
                            Leave
                            <div className="leavechart-leavepercentage">
                              {parseInt(employeePercentage[1])} %
                            </div>
                          </div>
                        </div>
                        <div className="flex mt-2 ml-6">
                          <div className="working-color mt-1"></div>
                          <div className="pie-chart-data-font ml-2 mb-1 flex">
                            Working
                            <div className="leavechart-workingpercentage">
                              {parseInt(employeePercentage[0])} %
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Asset chart */}
          <div className="col-6">
            <div className="chart-contaniner">
              <div>
                <div className="h-1">
                  {assetStatusCount?.every((element) => element === 0) ? (
                    <div style={{ padding: "50px" }}>
                      <EmptyScreen
                        img={Image.emptyAsset}
                        description="No Assets Assigned "
                      />
                    </div>
                  ) : (
                    <>
                      <Chart
                        type="pie"
                        data={assetsChartData}
                        options={lightOptions}
                      />
                      <div>
                        <h4 className="mt-3 ml-4 scratchpad-card-header-font">
                          Assets
                        </h4>

                        <div className="flex">
                          <div className="flex mt-2 ml-8">
                            <div className="occupied-color mt-1"></div>
                            <p className="pie-chart-data-font ml-2 mb-1">
                              Occupied
                              {/* - {assetPercentage[0]}% */}
                            </p>
                          </div>
                          <div className="flex mt-2 ml-8">
                            <div className="requested-color mt-1"></div>
                            <p className="pie-chart-data-font ml-2 mb-1">
                              Requested
                              {/* - {assetPercentage[1]}% */}
                            </p>
                          </div>
                        </div>

                        <div className="flex">
                          <div className="flex mt-2 ml-8">
                            <div className="retired-color mt-1"></div>
                            <p className="pie-chart-data-font ml-2 mb-1">
                              Retired
                              {/* - {assetPercentage[2]}% */}
                            </p>
                          </div>
                          <div className="flex mt-2 chart-data-align">
                            <div className="unassigned-color mt-1"></div>
                            <p className="pie-chart-data-font ml-2 mb-1">
                              Unassigned
                              {/* - {assetPercentage[3]}% */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* leave */}
        <div className="col-4">
          <EmployeeLeaveList
            todayLeave={todayLeave}
            tomorrowLeave={tomorrowLeave}
          ></EmployeeLeaveList>
        </div>
      </div>
    </div>
  );
};

export default PieChart;

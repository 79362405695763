import React, { useRef, useState, useEffect } from "react";
import "./announcementCard.scss";
import { SpeedDial } from "primereact/speeddial";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import Input from "../common/inputtext/input-field";
import Button from "../common/button/button";
import { Dropdown } from "primereact/dropdown";
import constants from "../../constants";
import { deleteWithAuth, putWithAuth } from "../../services/authentication";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import Alphabet from "../common/alphabet/alphabet";
import { fn_firstcharacter, isAdminAccess } from "../../utility/utility";
import Image from "../../utility/image";
export default function RecogitionCard({
  allRecognition,
  employeeDropdown,
  update,
  setUpdate,
}) {
  const token = localStorage.getItem("token");
  const [updateItem, setUpdateItem] = useState({});
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const toast = useRef(null);
  const hrAdmin = isAdminAccess("Human Resource");
  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setEditDialog(!editDialog);
        return;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      const url = constants.API.RECOGNITION.CREATE + "/" + updateItem._id;
      await deleteWithAuth(url, token);
      setUpdate(!update);
      setUpdateItem({});
      setDeleteDialog(!deleteDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  const updateHandler = async () => {
    try {
      const url = constants.API.RECOGNITION.CREATE;
      await putWithAuth(url, updateItem, token);
      setUpdate(!update);
      setUpdateItem({});
      setEditDialog(!editDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  useEffect(() => {
    let y = Object.values(updateItem).every((val) => val !== "");
    if (y === true) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [updateItem]);

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          onClick={deleteHandler}
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
        />
      )}
      <Dialog
        header="Edit Recognition"
        visible={editDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setEditDialog(!editDialog);
          setUpdateItem({});
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Dropdown
                options={employeeDropdown}
                placeholder="Employee"
                value={updateItem.employeeId}
                onChange={(e) =>
                  setUpdateItem({ ...updateItem, employeeId: e.value })
                }
              />
            </div>

            <div className="col-6">
              <Input
                placeholder="Recognition"
                name="recognition"
                label="Recognition"
                value={updateItem.recognition}
                type="text"
                onChange={(e) =>
                  setUpdateItem({ ...updateItem, recognition: e.target.value })
                }
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                maxDate={new Date(updateItem.to)}
                readOnlyInput
                dateFormat="dd/mm/yy"
                value={new Date(updateItem.from)}
                onChange={(e) =>
                  setUpdateItem({ ...updateItem, from: e.value })
                }
              />
            </div>
            <div className="col-6"></div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                minDate={new Date(updateItem.from)}
                readOnlyInput
                dateFormat="dd/mm/yy"
                value={new Date(updateItem.to)}
                onChange={(e) => setUpdateItem({ ...updateItem, to: e.value })}
              />
            </div>
          </div>
          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={updateHandler}
              // disabled={submitDisable}
            />
          </div>
        </div>
      </Dialog>
      <br />
      <div className="flex flex-wrap gap-3 p-4 w-full">
        {allRecognition.map((recognition, id) => {
          return (
            <Card className="flex" key={id}>
              <div className="flex flex-column" style={{ width: "270px" }}>
                <div className="flex flex-column row-gap-1 justify-content-center align-items-center">
                  <div className="announcement-header-font flex justify-content-around">
                    {recognition.recognition}
                  </div>
                  <div className="flex justify-content-around">
                    {recognition.recognizedMonth}
                  </div>
                </div>

                <div className="flex justify-content-center align-items-center">
                  <img
                    src={Image.recognition}
                    alt="recognition"
                    width={100}
                    height={100}
                  />
                </div>

                <div className="flex justify-content-between align-items-center">
                  <div className="flex justify-content-center align-items-center column-gap-2">
                    {recognition.designation && (
                      <h4>{recognition.designation}</h4>
                    )}
                    {recognition.profilePic ? (
                      <img
                        className="img-size"
                        src={recognition.profilePic}
                        alt="profileimg"
                      />
                    ) : (
                      <Alphabet
                        letter={fn_firstcharacter(recognition.empName)}
                      />
                    )}
                    <div className="announcement-header-font">
                      {recognition.empName}
                    </div>
                  </div>

                  <div className="flex">
                    {hrAdmin && (
                      <SpeedDial
                        model={items}
                        onClick={() => setUpdateItem(recognition)}
                        radius={50}
                        direction="left"
                        showIcon="pi pi-ellipsis-v"
                        hideIcon="pi pi-times"
                        buttonClassName="p-button-outlined action-btn-design"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}

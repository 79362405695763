import React from "react"
import { useEffect, useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";

import {
    getAttendanceData,
    setFrom,
    setSkip,
    setSearch,
    setTo,
    setLimit,
  } from "../../store/features/HumanResource/attendance.store";
import { PaginationDropdown } from "../../utility/utility";
import InputIcon from "../../component/common/inputIcon/inputIcon";

export default function Attendance(){
    const [date, setDate] = useState(new Date());
    const dispatch = useDispatch();
    const {
        attendanceData,
        skip,
        limit,
        search,
        from,
        to,
        maxRecord,
      } = useSelector((state) => state.attendance);
    
    const attendance_page =(e, customMonth,customYear)=>{
      const datemonth = customMonth || moment(date).format("MMMM");
      const dateyear = customYear || moment(date).format("YYYY");
      const fromdate = moment(date).subtract(1, "month").date(26).format("DD-MMM-YYYY") 
      const todate =  moment(date).date(25).format("DD-MMM-YYYY") 
        const payload = {
            skip,
            limit,
            search,
            fromDate: fromdate,
            toDate: todate,
            month: datemonth ? datemonth : "",
            year: parseFloat(dateyear ? dateyear : ""),
        };
        dispatch(getAttendanceData(payload));
    }
    useEffect(
        (e) => {
          const timeOut = setTimeout(() => {
            dispatch(setFrom(1));
            dispatch(setTo(10));
          }, 300);
          return () => {
            clearTimeout(timeOut);
          };
        },
        [search]
      );

    useEffect(
        (e) => {
          attendance_page(e);
        },
        [skip, limit, to,search]
      );

      const dateFilter = (e) => {  
        setDate(e?.target.value);
        const customMonth = moment(e?.target.value).format("MMMM");
        const customYear = Number(moment(e?.target.value).format("YYYY"));
        attendance_page(e, customMonth,customYear); 
      };

      const searchFn = (e) => {
        dispatch(setSearch(e?.target.value));   
      };

    const handlePrevious = (e) => {
        if (skip > 1) {
          dispatch(setSkip(skip - 1));
          dispatch(setFrom(from - limit));
          dispatch(setTo(to - limit));
        }
      };
    
    const handleNext = (e) => {
      if (maxRecord >= to) {
        dispatch(setSkip(skip + 1));
        dispatch(setFrom(from + limit));
        dispatch(setTo(to + limit));
      }
    };
   
    
      //pagination
      const paginate = {
        RowsPerPageDropdown: () => {
          const dropdownOptions = PaginationDropdown;
          return (
            <>
              <span
                className="mx-1"
                style={{ color: "var(--text-color)", userSelect: "none" }}
              >
                Items per page:{" "}
              </span>
              <Dropdown
                value={limit}
                options={dropdownOptions}
                onChange={(e) => {
                  dispatch(setLimit(e.value));
                  dispatch(setSkip(1));
                  dispatch(setFrom(1));
                  dispatch(setTo(e.value));
                }}
              />
            </>
          );
        },
        CurrentPageReport: (options) => {
          return (
            <>
              <span
                style={{
                  color: "var(--text-color)",
                  userSelect: "none",
                  width: "120px",
                  textAlign: "center",
                }}
              >
                {/* {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord} */}
                {from} - {to} of {maxRecord}
              </span>
              <button
                type="button"
                className={options.className}
                disabled={from === 1 ? true : false}
              >
                <span className="p-3" onClick={handlePrevious}>
                  Previous
                </span>
              </button>
              <button type="button" className={options.className}>
                <span className="p-3" onClick={handleNext}>
                  Next
                </span>
              </button>
            </>
          );
        },
      };
  
    return(
        <>
        <div className="payroll_search mr-4">
        <div className="filter_section">
            <div className="dropdown_input mr-4">
                <Calendar
                value={date}
                onChange={dateFilter}
                view="month"
                dateFormat="mm/yy"
                // placeholder="Select Month"
                />
            </div>
        </div>
        <InputIcon
            placeholder="Search"
            value={search}
            onChange={(e) => searchFn(e)}
            // onChange={(e) => searchFn(e.target.value, date)}

        />
        </div>
        
        <div className="m-4 flex flex-column row-gap-4">
            <DataTable
            className="asset-table-font"
            value={attendanceData}
            responsiveLayout="scroll"
            dataKey="employeeId"
            paginator
            paginatorTemplate={paginate}
            rows={limit}
            paginatorClassName="justify-content-end custom-dropdown"
            >
                <Column field="Name" header="Name" />
                <Column field="month" header="Month" />
                <Column field="employeeId" header="Employee ID" />
                <Column field="DaysInMonth" header="Days in Month" />
                <Column field="actualDays" header="Actual Working Day" />
                <Column field="present" header="Present" />
                <Column field="leave" header="Leave" />
                <Column field="Holiday" header="Holiday" />

            </DataTable>
        </div>
        </>
    )
}
import React, { useEffect, useRef, useState } from "react";
import users from "../../../../assets/user.svg";
import folder from "../../../../assets/grey-folder.svg";
import date from "../../../../assets/date.svg";
import Alphabet from "../../../common/alphabet/alphabet";
import { DateFormat, fn_firstcharacter, isAdminAccess } from "../../../../utility/utility";
import DeleteDialog from "../../../common/confirm-delete-dialog/deleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RemoveSlackChannel } from "../../../../store/features/Projects/ProjectSetting";
import { Button } from "primereact/button";
import AvatarGroup from "../../../common/avatar-group/avatarGroup";

export default function ProjectDetails() {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { projectDetails } = useSelector((store) => store.project);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const usersAdmin = isAdminAccess("Project")





  const deleteHandler = () => {
    dispatch(RemoveSlackChannel(projectDetails._id)).then(() => {
      setDeleteDialog(!deleteDialog);
      toast?.current?.show({
        severity: "success",
        summary: "Removed",
        detail: "Slack Channel Removed Successfully",
        life: 3000,
      });
    });
  };

  return (
    <>
      {deleteDialog && <DeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        onClick={deleteHandler}
      />}

      <div className="asset-table-font">General</div>
      <div className="flex column-gap-8 mt-3">
        <div className="flex flex-column">
          <div className="flex">
            <img style={{ height: "16px" }} src={folder} />
            <div className="project-setting-header ml-2">Project Name</div>
          </div>

          <div className="flex align-items-center mt-2 ml-3">
            {projectDetails?.projectDetails?.companyLogo ? (
              <img
                className="projects-img-size flex justify-content-center"
                src={projectDetails?.projectDetails?.companyLogo}
              />
            ) : (
              <Alphabet
                letter={fn_firstcharacter(
                  projectDetails?.projectDetails?.projectName
                )}
              />
            )}
            <div className="ml-2 project-setting-header">
              {projectDetails?.projectDetails?.projectName}
            </div>
          </div>
        </div>

        <div className="flex flex-column ">
          <div className="flex">
            <img style={{ height: "16px" }} src={users} />
            <div className="project-setting-header ml-2">Project Manager</div>
          </div>
          <div className="flex align-items-center mt-2 ml-3">
            {projectDetails?.leadInfo?.basic?.profilePic ? (
              <img
                className="projects-img-size flex justify-content-center"
                src={projectDetails?.leadInfo?.basic?.profilePic}
              ></img>
            ) : (
              <Alphabet
                letter={fn_firstcharacter(
                  projectDetails?.leadInfo?.basic?.fullName
                )}
              />
            )}
            <div className="ml-2 project-setting-header">
              {projectDetails?.leadInfo?.basic?.fullName}
            </div>
          </div>
        </div>

        <div className="flex flex-column ">
          <div className="flex">
            <img style={{ height: "16px" }} src={users} />
            <div className="project-setting-header ml-2">Participants</div>
          </div>
          <div className="flex mt-2 ml-3">
            <AvatarGroup item={projectDetails.members} size="medium" />
          </div>
        </div>

        <div className="flex flex-column ">
          <div className="flex">
            <img style={{ height: "16px" }} src={date} />
            <div className="project-setting-header ml-2">Due Date</div>
          </div>
          <div className="endDate-btn mt-2 ml-3">
            {DateFormat(projectDetails?.projectDetails?.endDate)}
          </div>
        </div>

        {usersAdmin && projectDetails?.projectDetails?.slackChannel  &&
        <div className="flex flex-column ">
        <div className="flex">
          <i style={{ height: "16px" }} className="pi pi-slack"></i>
          <div className="project-setting-header ml-2">Remove Slack</div>
        </div>
        <div>
          <Button
            className="slack p-0  mt-2 ml-4 p-button-warning"
            aria-label="Slack"
            onClick={() => setDeleteDialog(!deleteDialog)}
          >
            <i className="pi pi-slack px-2"></i>
            <span className="px-2">Slack</span>
          </Button>
        </div>
      </div>}
      </div>
    </>
  );
}

import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../../utility/image";
import Button from "../../../component/common/button/button";
import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import InputIcon from "../../../component/common/inputIcon/inputIcon";
import {
  getPayslipData,
  payslipDashboard,
  exportPayslipData,
  setFrom,
  setSkip,
  setSearch,
  setTo,
  setLimit,
  generatePayslip,
} from "../../../store/features/HumanResource/payslip.store";
import { Calendar } from "primereact/calendar";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import {
  isAdminAccess,
  PaginationDropdown,
  superAdmin,
} from "../../../utility/utility";

export default function PaySlipChart() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const {
    payslipData,
    dashboardData,
    skip,
    limit,
    search,
    from,
    to,
    maxRecord,
  } = useSelector((store) => store.paySlip);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [date, setDate] = useState(new Date());
  const usersAdmin = isAdminAccess("Human Resource");
  const [actionItem, setActionItem] = useState({});
  const [loader, setloader] = useState(false);
  const payslip_page = (e) => {
  
    const datemonth = moment(e?.target.value || date).format("MMMM");
    const dateyear = Number(moment(e?.target.value || date).format("YYYY")) 
    // || Number(moment(date).format("YYYY"));
  
    console.log(e?.target.value, "e");
    const payload = {
      skip,
      limit,
      search,
      month: [datemonth ? datemonth : ""],
      year: [dateyear ? dateyear : ""],
    };
    dispatch(payslipDashboard(payload));
    dispatch(getPayslipData(payload));
  };

  useEffect(
    () => {
      const timeOut = setTimeout(() => {
        dispatch(setFrom(1));
        dispatch(setTo(10));
      }, 300);
      return () => {
        clearTimeout(timeOut);
      };
    },
    [search]
  );

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedMembers(value);
    setSelectAll(value.length === payslipData);
  };

  const generatePayslipFunc = (e) => {
    setloader(true);

    const fromdate = date
      ? moment(date).subtract(1, "month").date(26).format("DD-MMM-YYYY")
      : moment().subtract(1, "month").date(26).format("DD-MMM-YYYY");

    const todate = date
      ? moment(date).date(25).format("DD-MMM-YYYY")
      : moment().date(25).format("DD-MMM-YYYY");

    const payload = {
      month: date ? moment(date).format("MMMM") : moment().format("MMMM"),
      year: date
        ? Number(moment(date).format("YYYY"))
        : Number(moment().format("YYYY")),
      fromDate: fromdate,
      toDate: todate,
    };

    dispatch(generatePayslip(payload)).then((c) => {
      setloader(false);
      if (c.meta.requestStatus === "fulfilled") {
        getPayslipList();
        toast.current.show({
          severity: "success",
          summary: "PaySlip Generated Successfully",
          detail: "",
          life: 3000,
        });
      }
    });
  };

  const getPayslipList = () => {
    const payload = {
      skip,
      limit,
      search,
      month: date ? [moment(date).format("MMMM")] : [moment().format("MMMM")],
      year: date
        ? [Number(moment(date).format("YYYY"))]
        : [Number(moment().format("YYYY"))],
    };
    dispatch(getPayslipData(payload));
    dispatch(payslipDashboard(payload));
  };

  function checkboxIsSelected() {
    return selectedMembers !== null && selectedMembers.length > 0;
  }

  function exportPayslip() {
    const payload = {
      // empId:
      //   selectedMembers !== null || selectedMembers.length > 1
      //     ? selectedMembers.map((c) => c.memberId)
      //     : [actionItem.memberId],
      //     :null,
      empId: selectedMembers.map((c) => c.memberId),
      month: date ? [moment(date).format("MMMM")] : [moment().format("MMMM")],
      year: date
        ? [Number(moment(date).format("YYYY"))]
        : [Number(moment().format("YYYY"))],
    };
    setSelectedMembers(null);
    dispatch(exportPayslipData(payload)).then((c) => {
      if (c.meta.requestStatus === "fulfilled") {
        getPayslipList();
      }
    });
  }


  useEffect((e) => {
    payslip_page(e); 
  }, [skip, limit, search, to]);

  const dateFilter = (e) => {
    setDate(e?.target.value);
    payslip_page({ target: { value: e?.target.value } }); 

  };

  const items = [
    {
      label: "Payslip",
      icon: "pi pi-file-pdf",
      // command: () => {
      //   exportPayslip();
      // },
      command: () => {
        if (checkboxIsSelected()) {
          exportPayslip();
        } else {
          toast.current.show({
            severity: "info",
            summary: "Please select the checkbox.",
            detail: "",
            life: 3000,
          });
        }
      },
    },
  ];

  const Action = (item) => (
    <div>
      <Tooltip target=".action .p-speeddial-action" position="top" />

      <SpeedDial
        model={items}
        onClick={() => {
          setActionItem(item);
        }}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        className="action"
        buttonClassName="p-button-outlined action-btn-design "
      />
    </div>
  );

  const dateFormatTemplate = (e) => {
    if (e.DOJ === null) return "-";
    else return moment(e.DOJ).format("DD/MM/YYYY");
  };

  function formatNumber(number) {
    if (number <= 1000) {
      return <span className="payslip_values">{number}</span>;
    }
    // if ( number <= 99999) {
    //   return (
    //     <span className="payslip_values">
    //       {(number / 10000).toFixed(2)}
    //       <span className="hours">k</span>
    //     </span>
    //   );
    // }
    if (number >= 1000 && number < 100000) {
      return (
        <span className="payslip_values">
          {(number / 1000).toFixed(2)}
          <span className="hours">k</span>
        </span>
      );
    } else if (number >= 100000) {
      return (
        <span className="payslip_values">
          {(number / 100000).toFixed(1)}
          <span className="hours"> Lakhs</span>
        </span>
      );
    }
    return number.toString();
  }

  const handlePrevious = (e) => {
    if (skip > 1) {
      dispatch(setSkip(skip - 1));
      dispatch(setFrom(from - limit));
      dispatch(setTo(to - limit));
    }
  };

  const handleNext = (e) => {
    if (maxRecord >= to) {
      dispatch(setSkip(skip + 1));
      dispatch(setFrom(from + limit));
      dispatch(setTo(to + limit));
    }
  };

  //pagination
  const paginate = {
    RowsPerPageDropdown: () => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3" onClick={handlePrevious}>
              Previous
            </span>
          </button>
          <button type="button" className={options.className}>
            <span className="p-3" onClick={handleNext}>
              Next
            </span>
          </button>
        </>
      );
    },
  };
  const searchFn = (e) => {
    dispatch(setSearch(e.target.value));

    // payslip_page(e)
  };


  const formatCurrency = (value) => {
    return value.toLocaleString();
  };

  const formatAmount = (e, key) => {
    if (e[key] !== undefined && e[key] !== null) {
      return formatCurrency(e[key]);
    } else {
      return "-";
    }
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      {superAdmin() && usersAdmin ? (
        <>
          <div className="gap">
            <div>
              <div className=" payslip_flex">
                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Total Employees</div>
                    <img
                      src={Image.payslip_total_employee}
                      className="working_hours"
                    />
                  </div>
                  <div className="mt-1 ml-2 payslip_values ">
                    {dashboardData[0]?.employeeCount ?? 0}
                  </div>
                </div>

                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Net salary</div>
                    <img src={Image.salary} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.netPay ?? 0)}
                  </div>
                </div>

                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Deductions</div>
                    <img src={Image.deduction} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2 ">
                    {formatNumber(dashboardData[0]?.deduction ?? 0)}
                    {/* <span className="hours">Lakhs</span> */}
                  </div>
                </div>
              </div>
              <div className=" mt-3 payslip_flex">
                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Maximum Salary</div>
                    <img src={Image.payroll_cost} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.maxSalary ?? 0)}
                    {/* <span className="hours">Lakhs</span> */}
                  </div>
                </div>

                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Minimum Salary</div>
                    <img src={Image.payroll_cost} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.minSalary ?? 0)}
                    {/* <span className="hours">Lakhs</span> */}
                  </div>
                </div>
                <div className="col-3 dashboard_payslip">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Average Salary</div>
                    <img src={Image.average_salary} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.avgSalary ?? 0)}
                    {/* <span className="hours">Lakhs</span> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 payroll_detils">
              <div className="wallet_img">
                <img
                  src={Image.wallet}
                  width={36}
                  height={36}
                  className="wallet"
                />
                <img
                  src={Image.wallet_Ellipse}
                  width={73}
                  height={73}
                  className="ellipse"
                />
              </div>

              <div className="center">
                <h5>Payroll Date</h5>
                <h1 className="payroll_date">
                  {date
                    ? moment(date).endOf("month").format("DD MMM YYYY")
                    : moment().endOf("month").format("DD MMM YYYY")}
                </h1>
                <h5>Payroll Period:</h5>
                <h5>
                  ({" "}
                  {date
                    ? moment(date)
                        .subtract(1, "month")
                        .date(26)
                        .format("DD MMM YYYY")
                    : moment()
                        .subtract(1, "month")
                        .date(26)
                        .format("DD MMM YYYY")}{" "}
                  -{" "}
                  {date
                    ? moment(date).date(25).format("DD MMM YYYY")
                    : moment().date(25).format("DD MMM YYYY")}
                  )
                </h5>
                <Button
                  onClick={generatePayslipFunc}
                  label="Generate Payslip"
                  loading={loader}
                />
              </div>
            </div>

            {/* <div className="col-3 distribution">
              <div className=" ml-2 mt-2 salary_font">Salary Distribution</div>
              <div className="mt-4 dashboard_payslip-container">
                <Chart
                  type="pie"
                  data={dummyChartData}
                  options={lightOptions}
                />
              </div>
            </div> */}
          </div>
          <div className="mr-6 mt-2 ml-6 flex ">
            <div className="section_gap ">
              <div className="ml-2 payslip_head">Employee PaySlip</div>
              <div className="filter_section">
                <div className="col-7 ">
                  <InputIcon
                    placeholder="Search"
                    value={search}
                    onChange={(e) => searchFn(e)}
                  />
                </div>
                <div className="dropdown_input mr-4">
                  <Calendar
                    value={date}
                    onChange={dateFilter}
                    view="month"
                    dateFormat="mm/yy"
                    // placeholder="Select Month"
                  />
                </div>
                {/* <Button
                  className="create-btn"
                  label="Export"
                  onClick={exportPayslip}
                ></Button> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mr-6  ml-6 flex ">
            <div className="chart_filter ">
              {/* <div className="ml-2 payslip_head">
                  Payroll Slips
              </div> */}
              {/* <div className="filter_section">
                <div className="dropdown_input mr-4">
                  <Dropdown
                    options={filterOptions}
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.value);
                    }}
                  ></Dropdown>
                </div>
              </div> */}
            </div>
          </div>
          <div className="gap grid flex">
            <div className=" ml-5">
              <div className=" payslip_employee">
                <div className="col-3 chart">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Total Working Hours</div>

                    <img src={Image.working_hours} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.workHours ?? 0)}
                  </div>
                </div>

                <div className="col-3 chart">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Total Earnings </div>
                    <img src={Image.payroll_cost} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.earning ?? 0)}
                  </div>
                </div>

                <div className="col-3 chart">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Total Deductions </div>
                    <img src={Image.deduction} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2">
                    {formatNumber(dashboardData[0]?.deduction ?? 0)}
                  </div>
                </div>
                <div className="col-3 chart">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Total LOP </div>
                    <img src={Image.payroll_cost} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2 ">
                    {formatNumber(dashboardData[0]?.lopCount ?? 0)}
                  </div>
                </div>
                <div className="col-3 chart">
                  <div className=" ml-2 payslip_header_font2">
                    <div className="average">Net Pay in </div>
                    <img src={Image.netpay_in} className="working_hours" />
                  </div>
                  <div className="mt-1 ml-2 ">
                    {formatNumber(dashboardData[0]?.netPay ?? 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-6 mt-2 ml-5 flex ">
            <div className="section_gap ">
              <div className="ml-2 payslip_head">PaySlip</div>
              <div className="filter_section">
                <div className="dropdown_input mr-4">
                  <Calendar
                    value={date}
                    // onChange={(e) => setDate(e.value)}
                    onChange={dateFilter}
                    view="month"
                    dateFormat="mm/yy"
                    // placeholder="Select Month"
                  />
                </div>
                {/* <Button className="create-btn" label="Export"></Button> */}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="payslip-table-box ">
        <div className="asset-table-size">
          <DataTable
            className="asset-table-font"
            value={payslipData}
            responsiveLayout="scroll"
            selectAll={selectAll}
            selection={selectedMembers}
            onSelectionChange={onSelectionChange}
            dataKey="employeeId"
            paginator
            paginatorTemplate={paginate}
            rows={limit}
            paginatorClassName="justify-content-end custom-dropdown"
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column body={Action} header="Action" />
            <Column field="employee" header="Employee" />
            <Column field="employeeId" header="ID" />
            <Column body={dateFormatTemplate} header="DOJ" />
            <Column field="designation" header="Designation" />
            <Column field={(e) => formatAmount(e, "CTC")} header="CTC" />
            <Column field="workingDays" header="Working Days" />
            <Column field="lopDays" header="LOP Days" />
            <Column field="workedDays" header="Worked Days" />
            {/* <Column field="paidHours" header="Paid Hours" /> */}
            <Column
              field={(e) => formatAmount(e, "grossPay")}
              header="Gross Pay"
            />
            <Column
              field={(e) => formatAmount(e, "basicSalary")}
              header="Basic 40% (From Salary)"
            />
            <Column
              field={(e) => formatAmount(e, "HRA")}
              header="HRA 50% (From Basic)"
            />
            <Column
              field={(e) => formatAmount(e, "LTA")}
              header="LTA 10% (From Salary)"
            />
            <Column
              field={(e) => formatAmount(e, "specialAllowance")}
              header="Special Allowances"
            />
            <Column
              field={(e) => formatAmount(e, "basicEPF")}
              header="Basic EPF Salary"
            />
            <Column
              field={(e) => formatAmount(e, "employerPF")}
              header="Employer PF"
            />
            <Column
              field={(e) => formatAmount(e, "employeePF")}
              header="Employee PF"
            />
            <Column field={(e) => formatAmount(e, "lopAmount")} header="LOP" />
            <Column
              field={(e) => formatAmount(e, "deductions")}
              header="Deductions"
            />
            <Column field={(e) => formatAmount(e, "netPay")} header="Net Pay" />
            <Column
              field={(e) => formatAmount(e, "variablePay")}
              header="Variable Pay"
            />
            {/* <Column field="" header="August Variable Pay" /> */}
            <Column field={(e) => formatAmount(e, "toPay")} header="To Pay" />
            <Column field="AccountName" header="Account Name" />
            <Column field="AccountNumber" header="Account No" />
            <Column field="IFSC" header="IFSC Code" />
          </DataTable>
        </div>
      </div>
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import Input from "../common/inputtext/input-field";
import Button from "../common/button/button";
import constants from "../../constants";
import { postWithAuth } from "../../services/authentication";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getDesignation,
  createDesignation,
  resetDesignation,
} from "../../store/features/Users/designation";
import {
  createDepartment,
  getDepartment,
} from "../../store/features/Users/department";
import {
  createUser,
  getUsers,
  resetStatus,
} from "../../store/features/Users/User";
import validate from "../../factory-function/validate";
import { InputSwitch } from "primereact/inputswitch";

export default function CreateUser({ createDialog, setCreateDialog }) {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { skip, limit, search, status, error } = useSelector(
    (store) => store.users
  );

  const { designations } = useSelector((store) => store.designation);
  const { departments } = useSelector((store) => store.department);
  const { roles } = useSelector((store) => store.role);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    employeeId: "",
    probation: false 
  });
  const [submitDisable, setSubmitDisable] = useState(false);
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState({ role: "", roleId: "" });
  const [errorDetails, setErrorDetails] = useState({ email: "" });
  const [loader, setLoader] = useState(false);

  const rolesOption = roles.map((role) => {
    return { label: role?.role?.role_name, value: role._id };
  });

  const designationOptions = designations?.map((designation) => {
    return { label: designation?.designation, value: designation?._id };
  });
  const departmentOptions = departments?.map((department) => {
    return { label: department?.department, value: department?._id };
  });

  useEffect(() => {
    dispatch(getUsers({ skip, limit, search }));
  }, [skip, limit]);

  useEffect(() => {
    if (status == "success") {
      setCreateDialog(!createDialog);
      setUserDetails({ fullName: "", email: "", employeeId: "" });
      setRole({ role: "", roleId: "" });
      setDepartment("");
      setDesignation("");
      dispatch(resetStatus());
    }
    if (status == "error" && error != "") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "",
        life: 3000,
      });
      dispatch(resetStatus());
    }
  }, [status, error]);

  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(userDetails).every((val) => val !== "");
    setSubmitDisable(
      x && y && role.roleId !== "" && designation != "" && department != ""
    );
  }, [errorDetails, userDetails, role, designation, department]);

  const submitHandler = () => {
    setLoader(true);
    const payload = { ...userDetails, designation, department, ...role };
    dispatch(createUser(payload)).then((res) => {
      setLoader(false);
      if (!res.error) {
        setCreateDialog(!createDialog);
        dispatch(getUsers({ skip, limit, search }));
      } else {
        toast.current.show({
          severity: "error",
          summary: res.payload,
          life: 3000,
        });
      }
    });
  };

  const changeHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    if (e.target.name === "email")
      setErrorDetails({
        ...errorDetails,
        [e.target.name]: validate({ [e.target.name]: e.target.value }),
      });
  };

  const createDesignationHandler = (e) => {
    if (e.key == "Enter") {
      const index = designationOptions?.findIndex((designation) => {
        return designation.label === designation;
      });
      if (index === -1 && department !== "") {
        dispatch(createDesignation({ designation, department })).then(() => {
          dispatch(getDesignation(department));
        })
        setDesignation("");
      }
    }
  };
  const designationChangeHandler = (e) => {
    setDesignation(e.value);
  };

  const createDepartmentHandler = (e) => {
    if (e.key == "Enter") {
      const index = departmentOptions?.findIndex((department) => {
        return department.label === department;
      });
      if (index === -1) {
        setDepartment("");
        setDesignation("");
        dispatch(createDepartment(department)).then(() => {
          dispatch(getDepartment());
        })
      }
    }
  };

  const departmentChangeHandler = (e) => {
    setDepartment(e.value);
    const id = e.value;
    const index = departmentOptions.findIndex((department) => {
      return department.value === e.value;
    });
    if (index !== -1) {
      dispatch(getDepartment());
      dispatch(resetDesignation());
      setDesignation("");
      dispatch(getDesignation(id));
    }
  };

  const roleChangeHandler = (e) => {
    const index = rolesOption.findIndex((role) => {
      return role.value === e.value;
    });
    setRole({ roleId: e.value, role: rolesOption[index].label });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Add User"
        visible={createDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateDialog(!createDialog);
          setUserDetails({ fullName: "", email: "", employeeId: "" });
          setRole({ role: "", roleId: "" });
          setDepartment("");
          setDesignation("");
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Full Name"
                name="fullName"
                label="Full Name"
                type="text"
                onChange={changeHandler}
              />
            </div>
            <div className="col-6">
              <Input
                placeholder="Email"
                name="email"
                label="Email"
                type="text"
                onChange={changeHandler}
              />
              <span>{errorDetails?.email}</span>
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Input
                placeholder="Employee ID"
                name="employeeId"
                label="Employee ID"
                type="text"
                onChange={changeHandler}
              />
            </div>
            <div className="col-6">
              <Dropdown
                tooltip="Select or Type and Press Enter to Create New Department"
                tooltipOptions={{
                  position: "bottom",
                  className: "tooltip-font-size",
                }}
                editable={true}
                placeholder="Department"
                options={departmentOptions}
                value={department}
                onChange={departmentChangeHandler}
                onKeyDown={createDepartmentHandler}
              />
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <Dropdown
                placeholder="Role"
                value={role.roleId}
                options={rolesOption}
                onChange={roleChangeHandler}
              />
            </div>
            <div className="col-6">
              <Dropdown
                editable={true}
                tooltip="Select or Type and Press Enter to Create New Designation"
                tooltipOptions={{
                  position: "bottom",
                  className: "tooltip-font-size",
                }}
                placeholder="Designation"
                options={designationOptions}
                onKeyDown={createDesignationHandler}
                onChange={designationChangeHandler}
                value={designation}
                disabled={department === ""}
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div style={{display:'flex'}} >
                <p className="mr-2 announcement-time">
                  Probation
                </p>
                <InputSwitch
                  name="probation"
                  checked={userDetails.probation}
                  onChange={changeHandler}
                />
            </div>
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={!submitDisable}
              loading={loader}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

import React, { useRef, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Project from "./../../pages/Project/Project";

import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../../component/common/unauthorized/unauthorized";
import ProjectEmbedRoute from "../Project/projectEmbeddedRoute";
import {
  getProjectDetailsById,
  setProjectId,
} from "../../store/features/Projects/Project";

export default function ProjectLayout() {
  const location = useLocation();
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { projectId: projectIdFromStore } = useSelector(
    (store) => store.project
  );
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const hasAccess = async () => {
    return await memberDetails?.routes?.includes("Project");
  };
  useEffect(() => {
    if (projectId && projectId !== "") {
      dispatch(setProjectId(projectId));
      dispatch(getProjectDetailsById(projectId));
    }
  }, [projectId, projectIdFromStore, dispatch]);

  if (hasAccess) {
    return (
      <>
        {location.pathname === "/project" && <Project />}
        {location.pathname.startsWith("/project") &&
          location.pathname !== "/project" && <ProjectEmbedRoute />}
        <Outlet />
      </>
    );
  } else {
    return <Unauthorized  />;
  }
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  designations: [],
  status: "",
};

export const getDesignation = createAsyncThunk(
  "/designation",
  async (departmentId, { rejectWithValue }) => {
    try {
      const url =
        constants.API.DESIGNATION.FETCH + "?department=" + departmentId;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createDesignation = createAsyncThunk(
  "/create-designation",
  async ({ designation, department }, { rejectWithValue }) => {
    try {
      const url = constants.API.DESIGNATION.FETCH;
      const response = await api.post(url, {
        designation: designation,
        departmentId: department,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const designationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    resetDesignation: (state, action) => {
      state.designations = [];
    },
    setDesignation: (state, action) => {
      state.designations = action.payload;
    },
  },
  extraReducers: {
    [getDesignation.pending]: (state) => {
      state.isLoading = true;
    },
    [getDesignation.fulfilled]: (state, action) => {
      state.designations = action.payload.designations;
    },
    [getDesignation.rejected]: (state, action) => {
      state.status = "error";
    },
    [createDesignation.pending]: (state) => {
      state.isLoading = true;
    },
    [createDesignation.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createDesignation.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const { resetStatus, resetDesignation, setDesignation } =
  designationSlice.actions;

export default designationSlice.reducer;

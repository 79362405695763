import React, { useEffect, useState } from "react";
import Button from "../../component/common/button/button";
import ProjectMembersManage from "../../component/project/projectMember/projectMembersManage";
import ProjectMemberTable from "../../component/project/projectMember/projectMemberTable";
import { isAdminAccess } from "../../utility/utility";


export default function ProjectMembers() {
  const [visibleRight, setVisibleRight] = useState(false);
  const usersAdmin = isAdminAccess("Project");

  

  return (
    <>
      {visibleRight && (
        <ProjectMembersManage
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
        />
      )}
      <div className="flex justify-content-end mr-7">
        {usersAdmin && (
          <Button
            label="Manage"
            onClick={() => setVisibleRight(!visibleRight)}
          />
        )}
      </div>

      <div className="mt-5">
        <ProjectMemberTable />
      </div>
    </>
  );
}

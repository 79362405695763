import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { timesheetExport } from "../../store/features/Timesheet/timesheet";
import Image from "../../utility/image";
import Spinner from "../common/spinner/spinner";

export default function ExportTimesheet({ exportDialog, setExportDialog }) {
  const dispatch = useDispatch();
  const timesheet = useSelector((store) => store.timesheet);
  const [loading, setLoading] = useState(false);

  const downloadOption = [
    {
      id: 1,
      type: "Excel",
      img: Image.excelImg,
      path: "excel",
    },
    {
      id: 2,
      type: "PDF",
      img: Image.pdfImg,
      path: "pdf",
    },
  ];

  const handleDownload = (item) => {
    setLoading(true);
    let element = document.getElementById("download");
    element.style.cursor = "wait";

    dispatch(timesheetExport({ ...timesheet, extension: item.path })).then(
      (res) => {
        setLoading(false);
        setExportDialog(!exportDialog);
        if (!res?.error) {
          element.style.cursor = "";
          window.open(res.payload, "_blank", "noopener,noreferrer");
        }
      }
    );
  };

  return (
    <div>
      <Dialog
        header="Export"
        visible={exportDialog}
        style={{ width: "30vw" }}
        onHide={() => setExportDialog(!exportDialog)}
      >
        {loading ? (
          <div className="flex align-items-center justify-content-center w-full">
            <Spinner value={loading} />
          </div>
        ) : (
          <div className="flex align-items-center justify-content-evenly">
            {downloadOption.map((item) => {
              return (
                <div id="download" key={item.id} className="cursor-pointer">
                  <img src={item.img} onClick={() => handleDownload(item)} />
                </div>
              );
            })}
          </div>
        )}
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveStatus } from "../../store/features/DashBoard";
import { HEX_COLOUR_CODE } from "./../../constants";
import CircleProgressBar from "../common/progress-circle/CircleProgressBar";
import Image from "../../utility/image";
import EmptyScreen from "../common/empty/emptyScreen";

export default function LeaveStatus() {
  const dispatch = useDispatch();
  const { leaveStatus } = useSelector((store) => store.dashBoard);
  const [leaveType, setLeaveType] = useState([]);
  const [totalLeave, setTotalLeave] = useState([]);
  const [randomColour, setRandomColour] = useState([]);
  const [allLeaveTotal, setAllLeavesTotal] = useState();

  const [leaveChartData, setLeaveChartData] = useState({
    datasets: [
      {
        data: totalLeave,
        backgroundColor: randomColour,
      },
    ],
  });
  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });

  useEffect(() => {
    dispatch(getLeaveStatus());
  }, []);

  useEffect(() => {
    if (leaveStatus?.length > 0) {
      let allLeaves = [];
      let allLeaveType = [];
      let total = 0;
      leaveStatus.map((leave) => {
        if (leave?.count - leave?.leaveSelected > 0)
          allLeaves.push(leave?.count - leave?.leaveSelected);
        else allLeaves.push(0);

        allLeaveType.push(leave?.title);
        total = total + leave?.count - leave?.leaveSelected;
      });

      setTotalLeave(allLeaves);
      setLeaveType(allLeaveType);
      const ranColour = HEX_COLOUR_CODE?.slice(0, leaveStatus?.length);
      setRandomColour(ranColour);
      setAllLeavesTotal(total);
    }
  }, [leaveStatus]);

  useEffect(() => {
    setLeaveChartData({
      datasets: [
        {
          data: totalLeave,
          backgroundColor: randomColour,
        },
      ],
    });
  }, [totalLeave, randomColour]);

  const fn_percentage = ({ count, leaveSelected }) => {
    const total = count;
    const remaining = count - leaveSelected;
    const remainingPercentage = (remaining / total) * 100;
    return remainingPercentage ? remainingPercentage : 0;
  };


  return (
    <div className="dashboard-chart-container">
      <div className="announcement-time">Leave Status</div>
      <div className="flex  flex-column align-items-center justify-content-between">
        {leaveStatus && leaveStatus.length > 0 ? (
          <>
            <div className="flex justify-content-center align-items-center">
              <Chart
                type="doughnut"
                data={leaveChartData}
                options={lightOptions}
              />
            </div>

            <div
              className="flex align-items-center justify-content-evenly mt-3 column-gap-2 overflow-x-scroll overflow-y-hidden"
              style={{ width: "100%", height: "60px" }}
            >
              {leaveStatus &&
                leaveStatus.map((item, id) => {
                  // if (item.count - item.leaveSelected > 0) {
                    return (
                      <div key={id}>
                        <div
                          className="flex align-items-center column-gap-1"
                          style={{ width: "120px", height: "60px" }}
                        >
                          <div className="flex align-items-center justify-content-center column-gap-1">
                            <CircleProgressBar
                              progress={fn_percentage(item)}
                              imageUrl={Image.blueArrow}
                              ic={randomColour[id]}
                              oc={"#ffff"}
                            />
                          </div>
                          <div className="flex flex-column align-items-center justify-content-center">
                            <span>
                              {item.count - item.leaveSelected <= 0
                                ? 0
                                : item.count - item.leaveSelected}
                              &nbsp;
                              {item.title}
                            </span>
                            <p style={{ fontSize: "10px" }}>Remaining</p>
                          </div>
                        </div>
                      </div>
                    );
                  // }
                })}
            </div>
          </>
        ) : (
          <div>
            <EmptyScreen
              img={Image.emptyLeave}
              description="Currently no leave policies"
            />
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import SettingsEmbeddedRoute from "../Settings/settingsEmbeddedRoute";
import { useSelector } from "react-redux";
import Unauthorized from "../../component/common/unauthorized/unauthorized";

export default function SettingsLayout() {
  const { memberDetails } = useSelector((store) => store.memberDetails);

  const hasAccess = async () => {
    return await memberDetails?.routes?.includes("Settings");
  };

  if (hasAccess) {
    return (
      <div className="flex mx-5">
        <div className="col-2">
          <SettingsEmbeddedRoute />
        </div>
        <div className="col-10">
          <Outlet />
        </div>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
}

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import Input from "../common/inputtext/input-field";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import Button from "../common/button/button";
import { getAllUsers } from "../../store/features/Users/User";
import {
  getSlackChannel,
  getAllProjects,
  createProject,
} from "../../store/features/Projects/Project";
import {
  resetImageUrl,
  uploadImage,
  removeImage,
  resetRemoveImageUrlStatus,
} from "../../store/features/Image/image";
import ProfileUpload from "../common/file-upload/fileUpload";

export default function CreateProject({ createDialog, setCreateDialog }) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [userOptions, setUserOptions] = useState([]);
  const { allUsers } = useSelector((store) => store.users);
  const { imageUrl, removeImageUrlStatus } = useSelector(
    (store) => store.image
  );
  // const [slack, setSlack] = useState([]);
  const { slackChannel, skip, limit, search } = useSelector(
    (store) => store.project
  );
  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    startDate: "",
    endDate: "",
    projectLead: "",
    // slackChannel: "",
  });
  const [submitDisable, setSubmitDisable] = useState(true);

  const [projectImageUrl, setProjectImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getAllProjects({ skip, limit, search }));
  }, [skip, limit]);

  useEffect(() => {
    if (imageUrl !== "") {
      setProjectImageUrl(imageUrl);
      dispatch(resetImageUrl());
    }
  }, [imageUrl]);

  useEffect(() => {
    if (imageUrl == "" && removeImageUrlStatus == "success") {
      setProjectImageUrl("");
      dispatch(resetRemoveImageUrlStatus());
    }
  }, [removeImageUrlStatus]);

  useEffect(() => {
    if (allUsers) {
      let options = [];
      allUsers.forEach((user) => {
        options.push({ value: user._id, label: user.basic.fullName });
      });
      setUserOptions(options);
    }
  }, [allUsers]);

  // useEffect(() => {
  //   if (slackChannel) {
  //     let channeList = [];
  //     slackChannel.forEach((list) => {
  //       channeList.push(list.name);
  //     });
  //     channeList.unshift("none");
  //     setSlack(channeList);
  //   }
  // }, [slackChannel]);

  useEffect(() => {
    if (
      projectDetails.projectName !== "" &&
      projectDetails.projectLead !== ""
    ) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [projectDetails]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getSlackChannel());
  }, []);

  const changeHandler = (e) => {
    setProjectDetails({ ...projectDetails, [e.target.name]: e.target.value });
  };

  const uploadHandler = async (e) => {
    try {
      // setLoading(true);
      const fileData = new FormData();
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", async () => {
            fileData.append("file", files[0]);
            await dispatch(
              uploadImage({ folderName: "project", data: fileData })
            );
          });
        } else {
          // setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      //setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const removeProjectLogo = async () => {
    dispatch(removeImage({ folderName: "project", data: projectImageUrl }));
  };

  const submitHandler = async () => {

    try {
      setIsLoading(true)
      setCreateDialog(!createDialog);
      const submitedData = {
        ...projectDetails,
      }
      //   slackChannel:
      //     projectDetails?.slackChannel === "none"
      //       ? null
      //       : projectDetails?.slackChannel,
      // };
      dispatch(
        createProject({
          ...submitedData,
          companyLogo: projectImageUrl,
        })
      ).then(() => {
        setIsLoading(false)
        dispatch(getAllProjects({ skip, limit, search }));
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Create Project"
        visible={createDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateDialog(!createDialog);
          setProjectDetails({
            projectName: "",
            startDate: "",
            endDate: "",
            // slackChannel: "",
            projectLead: "",
          });
          setProjectImageUrl("");
        }}
      >
        <div className="label-font ml-2">Project Logo</div>
        <ProfileUpload
          profileImg={projectImageUrl}
          labelName="Logo"
          uploadHandler={uploadHandler}
          remove={removeProjectLogo}
        />
        <div className="flex">
          <div className="col-12 ml-1">
            <Input
              className="asset-input"
              placeholder="Project Name"
              name="projectName"
              label="Project Name"
              type="text"
              onChange={changeHandler}
              required={true}
            />
          </div>

          {/* <div className="col-6">
            <Dropdown
              options={slack}
              placeholder="Slack Channel"
              value={projectDetails.slackChannel}
              onChange={(e) =>
                setProjectDetails({ ...projectDetails, slackChannel: e.value })
              }
            />
          </div> */}
        </div>

        <div className="flex mt-2">
          <div className="col-6 ml-1">
            <Calendar
              dateFormat="dd/mm/yy"
              placeholder="Start Date"
              value={new Date(projectDetails.startDate)}
              readOnlyInput
              onChange={(e) =>
                setProjectDetails({ ...projectDetails, startDate: e.value })
              }
            ></Calendar>
          </div>
          <div className="col-6">
            <Calendar
              dateFormat="dd/mm/yy"
              placeholder="End Date"
              value={new Date(projectDetails.endDate)}
              readOnlyInput
              onChange={(e) =>
                setProjectDetails({ ...projectDetails, endDate: e.value })
              }
            ></Calendar>
          </div>
        </div>

        <div className="flex mt-2">
          <div className="col-6 m;-1">
            <Dropdown
              options={userOptions}
              placeholder="Project Lead"
              value={projectDetails.projectLead}
              onChange={(e) =>
                setProjectDetails({ ...projectDetails, projectLead: e.value })
              }
            ></Dropdown>
          </div>
          <div className="col-6">
            <Dropdown
              options={userOptions}
              placeholder="Select Client"
              value={projectDetails.clientId}
              onChange={(e) =>
                setProjectDetails({ ...projectDetails, clientId: e.value })
              }
            ></Dropdown>
          </div>
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button
            label="Submit"
            disabled={submitDisable}
            onClick={submitHandler}
            loading={isLoading}
          ></Button>
        </div>
      </Dialog>
    </div>
  );
}

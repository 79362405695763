import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import Button from "../../common/button/button";
import Input from "../../common/inputtext/input-field";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import {
  getProfileDetails,
  updateProfileDetails,
} from "../../../store/features/Users/User";
import { getRoles } from "../../../store/features/Users/role";
import department, {
  getDepartment,
} from "../../../store/features/Users/department";
import { getDesignation } from "../../../store/features/Users/designation";
import { Divider } from "primereact/divider";
import PhoneInput from "react-phone-input-2";
import { Calendar } from "primereact/calendar";
import constants from "../../../constants";
import { getNames } from "country-list";
import Alphabet from "../alphabet/alphabet";
import { fn_firstcharacter } from "../../../utility/utility";
import ProfileUpload from "../file-upload/fileUpload";
import { postWithAuth } from "../../../services/authentication";
import ProgressBar from "../progress-bar/progressBar";
import { getMemberDetails } from "../../../store/features/UserDetails/UserDetails";

export default function EditProfile({
  editProfileDialog,
  setEditProfileDialog,
}) {
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((store) => store.users);
  const { roles } = useSelector((store) => store.role);
  const { departments } = useSelector((store) => store.department);
  const { designations } = useSelector((store) => store.designation);
  const [updateItem, setUpdateItem] = useState();

  // profile upload
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [imageUploadDisable, setImageUploadDisable] = useState(false);

  useEffect(() => {
    const orgId = profileDetails?.work?.orgId;
    const departmentId = profileDetails?.departmentId;
    if (orgId) {
      dispatch(getRoles(orgId));
    }
    dispatch(getDepartment());
    getDesignation(departmentId);
    setUpdateItem({ ...profileDetails });
  }, [profileDetails]);

  const rolesOption = roles.map((role) => {
    return { label: role?.role?.role_name, value: role._id };
  });
  const departmentOptions = departments?.map((department) => {
    return { label: department?.department, value: department?._id };
  });
  const designationOptions = designations?.map((designation) => {
    return { label: designation?.designation, value: designation?._id };
  });

  const changeHandler = (e, object_name) => {
    setUpdateItem((prevState) => {
      const embeddedObject = {
        ...prevState[object_name],
        [e.target.name]: e.target.value,
      };
      return { ...prevState, [object_name]: embeddedObject };
    });
  };

  // image upload and delete
  const uploadHandler = async (e) => {
    try {
      setLoading(true);
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            uploadAssetProfile(files[0]);
          });
        } else {
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  function setProfilePic(url) {
    const embeddedObject = { ...updateItem.basic, profilePic: url };
    const updatedObject = { ...updateItem, basic: embeddedObject };
    setUpdateItem(updatedObject);
  }

  const uploadAssetProfile = async (data) => {
    const fileData = new FormData();
    fileData.append("file", data);
    const url = constants.API.CLOUD.UPLOAD + "/profilepic/upload";
    const response = await postWithAuth(url, fileData, token);
    if (response) {
      setImageUploadDisable(!imageUploadDisable);
      setProfilePic(response.data.URL);
      setLoading(false);
      return;
    }
  };

  const imageRemoveHandler = async () => {
    try {
      setLoading(true);
      if (
        updateItem?.basic?.profilePic !== "" ||
        updateItem?.basic !== undefined
      ) {
        const url = constants.API.CLOUD.UPLOAD + "/profilepic/delete";
        await postWithAuth(url, { url: updateItem.basic.profilePic }, token);
        setProfilePic("");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const updateHandler = () => {
    const data = {
      memberOrgId: profileDetails?._id,
      updatedValues: { ...updateItem },
    };
    dispatch(updateProfileDetails(data)).then((res) => {
      dispatch(getProfileDetails(profileDetails?._id));
      toast.current.show({
        severity: "success",
        detail: res.payload?.message,
        life: 1000,
      });
      dispatch(getMemberDetails(token)); // token
      setEditProfileDialog(!editProfileDialog);
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Edit Profile"
        visible={editProfileDialog}
        style={{ width: "80vw" }}
        onHide={() => {
          setEditProfileDialog(!editProfileDialog);
        }}
      >
        {/* <div className="flex align-items-center column-gap-3">
          {profileDetails?.basic?.profilePic ? (
            <img
              className="members-info-image"
              src={profileDetails?.basic?.profilePic}
            />
          ) : (
            <Alphabet
              width="100px"
              height="100px"
              fontSize="40px"
              letter={fn_firstcharacter(profileDetails?.basic?.fullName)}
            />
          )}

          <div className="flex flex-column row-gap-2">
            <h4>Complete Your Profile</h4>
            <div>
              <ProgressBar
                width={150}
                height={15}
                value={profileDetailsbar()}
                maxValue={100}
                color="#5a78ee"
              />
            </div>

            <div className="release-card-desc-font">
              Complete your profile to experience Squadzen fully!
            </div>
          </div>
        </div> */}
        {/* 
        <div className="flex justify-content-between mt-3">
          <div className="announcement-time ml-2">Upload Image</div>
        </div>

        <Divider /> */}
        <div className="grid mt-3">
          <div className="col-12 update-user-label-align">
            <ProfileUpload
              remove={imageRemoveHandler}
              uploadHandler={uploadHandler}
              labelName="Click Here to Upload"
              profileImg={updateItem?.basic?.profilePic}
            />
          </div>
        </div>

        {/* Personal Info */}

        <Divider />

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Full Name</div>
              <Input
                className="asset-input"
                name="fullName"
                type="text"
                value={updateItem?.basic?.fullName}
                onChange={(e) => changeHandler(e, "basic")}
                required={true}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Email</div>
              <Input
                className="asset-input"
                name="email"
                value={updateItem?.email}
                disable
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Phone Number</div>
              <PhoneInput
                country={"in"}
                value={updateItem?.personal?.phone}
                name="phone"
                onChange={(e) => {
                  const embeddedObject = {
                    ...updateItem.personal,
                    setActivityDate: e,
                    phone: e,
                  };
                  const updatedObject = {
                    ...updateItem,
                    personal: embeddedObject,
                  };

                  setUpdateItem(updatedObject);
                }}
              />
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Date Of Birth</div>
              <Calendar
                placeholder="Date Of Birth"
                dateFormat="dd/mm/yy"
                readOnlyInput
                value={new Date(updateItem?.personal?.dob)}
                name="dob"
                onChange={(e) => {
                  const embeddedObject = {
                    ...updateItem?.personal,
                    dob: e?.value,
                  };

                  const updatedObject = {
                    ...updateItem,
                    personal: embeddedObject,
                  };

                  setUpdateItem(updatedObject);
                }}
              ></Calendar>
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Gender</div>
              <Dropdown
                placeholder="Gender"
                options={constants.GENDER}
                name="gender"
                onChange={(e) => changeHandler(e, "personal")}
                value={updateItem?.personal?.gender}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Blood Group</div>
              <Dropdown
                options={constants.BLOOD_GROUP}
                value={updateItem?.personal?.bloodGroup}
                placeholder="Blood Group"
                name="bloodGroup"
                onChange={(e) => changeHandler(e, "personal")}
              />
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Role</div>

              <Dropdown
                placeholder="Role"
                options={rolesOption}
                value={updateItem?.work?.roleId}
                name="roleId"
                onChange={(e) => changeHandler(e, "work")}
                disabled
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Department</div>
              <Dropdown
                tooltip="Select or Type and Press Enter to Create New Department"
                tooltipOptions={{
                  position: "bottom",
                  className: "tooltip-font-size",
                }}
                placeholder="Department"
                editable={true}
                options={departmentOptions}
                value={updateItem?.departmentName}
                name="departmentName"
                disabled
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Nationality</div>

              <Dropdown
                placeholder="Nationality"
                options={getNames()}
                value={updateItem?.personal?.nationality}
                name="nationality"
                onChange={(e) => changeHandler(e, "personal")}
              />
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Employee Id</div>

              <Input
                placeholder="Employee Id"
                name="employeeId"
                value={updateItem?.work?.employeeId}
                onChange={(e) => changeHandler(e, "work")}
                disable
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Date Of Joining</div>
              <Calendar
                placeholder="Date of Joining"
                label="Date of Joining"
                name="dateOfJoining"
                dateFormat="dd/mm/yy"
                value={new Date(updateItem?.work?.dateOfJoining)}
                onChange={(e) => changeHandler(e, "work")}
                readOnlyInput
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font"> Designation</div>
              <Dropdown
                tooltip="Select or Type and Press Enter to Create New Designation"
                tooltipOptions={{
                  position: "bottom",
                  className: "tooltip-font-size",
                }}
                placeholder="Designation"
                editable={true}
                options={designationOptions}
                value={updateItem?.designationName}
                disabled
                name="designationName"
              />
            </div>
          </div>
        </div>

        {/* Referrel Info  */}

        <div className="flex justify-content-between mt-3">
          <div className="announcement-time ml-2">My Referrel</div>
        </div>
        <Divider />

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Reference Name</div>

              <Input
                name="referenceName1"
                value={updateItem?.contact?.referenceName1}
                onChange={(e) => changeHandler(e, "contact")}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Reference Contact Number</div>
              <PhoneInput
                label="Reference Contact Number"
                placeholder="Reference Contact Number"
                country={"in"}
                value={updateItem?.contact?.referenceContactNumber1}
                name="referenceContactNumber1"
                onChange={(e) => {
                  const embeddedObject = {
                    ...updateItem.contact,
                    referenceContactNumber1: e,
                  };
                  const updatedObject = {
                    ...updateItem,
                    contact: embeddedObject,
                  };
                  setUpdateItem(updatedObject);
                }}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font"> Personal Email</div>
              <Input
                name="personalEmail"
                value={updateItem?.contact?.personalEmail}
                onChange={(e) => changeHandler(e, "contact")}
              />
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Current Address</div>

              <Input
                name="currentAddress"
                value={updateItem?.contact?.currentAddress}
                onChange={(e) => changeHandler(e, "contact")}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font">Permanent Address</div>
              <Input
                name="permanentAddress"
                value={updateItem?.contact?.permanentAddress}
                onChange={(e) => changeHandler(e, "contact")}
              />
            </div>
          </div>

          <div className="col-4 flex-column">
            <div className="ml-4">
              <div className="label-font"> Emergency Contact Number</div>
              <PhoneInput
                label="Emergency Contact Number"
                placeholder="Emergency Contact Number"
                country={"in"}
                value={updateItem?.contact?.emergencyContactNumber}
                name="emergencyContactNumber"
                onChange={(e) => {
                  const embeddedObject = {
                    ...updateItem.contact,
                    emergencyContactNumber: e,
                  };
                  const updatedObject = {
                    ...updateItem,
                    contact: embeddedObject,
                  };
                  setUpdateItem(updatedObject);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-content-end mt-3">
          <Button
            className="create-btn"
            label="Update"
            onClick={updateHandler}
          />
        </div>
      </Dialog>
    </div>
  );
}

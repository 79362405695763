import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import Input from "../../common/inputtext/input-field";
import { Calendar } from "primereact/calendar";
import Button from "../../common/button/button";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  createRelease,
  getReleaseList,
} from "../../../store/features/Projects/Release";

export default function CreateRelease({
  createReleaseDialog,
  setCreateReleaseDialog,
}) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [releaseDetails, setReleaseDetails] = useState({
    name: "",
    startDate: "",
    endDate: "",
    description: "",
    projectId: "",
  });
  const { projectId } = useParams();

  const changeHandler = (e) => {
    setReleaseDetails({ ...releaseDetails, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    try {
      let data = { ...releaseDetails, projectId: projectId };
      await dispatch(createRelease(data));
      setCreateReleaseDialog(!createReleaseDialog);
      await dispatch(getReleaseList(projectId));
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Add Release"
        visible={createReleaseDialog}
        style={{ width: "40vw" }}
        onHide={() => {
          setCreateReleaseDialog(!createReleaseDialog);
          setReleaseDetails({
            name: "",
            startDate: "",
            endDate: "",
            description: "",
            projectId: "",
          });
        }}
      >
        <div className="flex flex-column row-gap-2">
          <div className="col-12">
            <Input
              className="asset-input"
              placeholder="Project Name"
              name="name"
              label="Project Name"
              type="text"
              onChange={changeHandler}
              required={true}
            />
          </div>

          <div className="flex">
            <div className="col-6">
              <Calendar
                dateFormat="dd/mm/yy"
                placeholder="Start Date"
                value={releaseDetails?.startDate}
                readOnlyInput
                onChange={(e) =>
                  setReleaseDetails({ ...releaseDetails, startDate: e.value })
                }
              />
            </div>
            <div className="col-6">
              <Calendar
                dateFormat="dd/mm/yy"
                placeholder="End Date"
                value={releaseDetails?.endDate}
                readOnlyInput
                onChange={(e) =>
                  setReleaseDetails({ ...releaseDetails, endDate: e.value })
                }
              />
            </div>
          </div>

          <div className="col-12">
            <Input
              className="asset-input"
              placeholder="Description"
              name="description"
              label="Description"
              type="text"
              onChange={changeHandler}
              required={true}
            />
          </div>

          <div className="flex justify-content-end">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={
                !releaseDetails.name ||
                !releaseDetails.startDate ||
                !releaseDetails.endDate
              }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

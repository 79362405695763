import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
    attendanceData:[],
    userList: [],
    search:"",
    skip: 1,
    limit: 10,
    from: 1,
    to: 10,
    maxRecord: 0,
  };

 
  export const getAttendanceData = createAsyncThunk(
    "get/attendanceData",
    async (payload, { rejectWithValue}) => {
      try {
        const { skip, limit, search } = payload;
        const data = {month:payload.month, year:payload.year, fromDate:payload.fromDate, toDate:payload.toDate }
        const url = constants.API.ATTENDANCE.GET +
        `?skip=${skip}&limit=${limit}&search=${search}`;
        const response = await api.post(url, data);
        return response.data;
      } catch (error) {
        rejectWithValue(error.response.data.message);
      }
    }
  );

  const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers:{
        setSkip: (state, action) => {
        state.skip = action.payload;
        },
        setLimit: (state, action) => {
        state.limit = action.payload;
        },
        setFrom: (state, action) => {
        state.from = action.payload;
        },
        setTo: (state, action) => {
        state.to = action.payload;
        },
        setMaxRecord: (state, action) => {
        state.maxRecord = action.payload;
        },
        setSearch: (state, action) => {
        state.search = action.payload;
        },
    },
    extraReducers:{
        [getAttendanceData.fulfilled]: (state, action) =>{
            state.attendanceData = action.payload.attendance;
            state.maxRecord = action.payload.requestsCount;
        }
    }
  });
  
export const {setSkip, setLimit, setTo,setSearch, setFrom, setMaxRecord } = 
  attendanceSlice.actions;

  export default attendanceSlice.reducer;
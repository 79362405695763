import React from "react";
import { useSelector } from "react-redux";
import { fn_firstcharacter } from "../../utility/utility";
import Alphabet from "../common/alphabet/alphabet";

import DashboardCalender from "./dashboardCalendar";

export default function DashboardProfile() {
  const { profileDetails } = useSelector((store) => store.users);

  return (
    <div className="dashboard-profile-container p-3 flex flex-column row-gap-2">
      <div className="announcement-time w-full text-left">My Profile</div>
      <div className="flex justify-content-center ">
        {profileDetails?.basic?.profilePic ? (
          <img
            className="members-info-image"
            src={profileDetails?.basic?.profilePic}
          />
        ) : (
          <Alphabet
            width="75px"
            height="75px"
            fontSize="30px"
            letter={fn_firstcharacter(profileDetails?.basic?.fullName)}
          />
        )}
      </div>

      <div className="announcement-time  flex justify-content-center ">
        {profileDetails?.designationName}
      </div>

      <div className="w-full">
        <DashboardCalender />
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import "./selectPlan.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../component/common/button/button";
import { Toast } from "primereact/toast";
import { Slider } from "primereact/slider";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import Image from "../../utility/image";
import { useDispatch } from "react-redux";
import {
  editActiveIndex,
  setSelectPlanPayload,
} from "../../store/features/Auth/signup";
import { Tooltip } from "primereact/tooltip";
import { useSelector } from "react-redux";
import { FreeTrial } from "../../component/common/free-trial/freeTrial";
import {
  planWithFiveMembers,
  selectPlanExpiryDate,
  selectPlanMode,
} from "../../utility/utility";
import { setActiveIndexExistingStep, upgradePlanApi } from "../../store/features/Auth/login";
import { InputNumber } from "primereact/inputnumber";

export default function SelectPlan({ isUpgradeUI, onHide, billingDetailsApi , isExisting }) {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const toast = useRef(null);
  const memberRef = useRef(null);
  const { memberOrgDetails } = useSelector((store) => store.orgSignUp);
  const { fullName, email, orgId } = memberOrgDetails;
  const [memberCount, setMemberCount] = useState(5);
  const [loader, setLoader] = useState(false);
  const [selectPlanOption, setSelectPlanOption] = useState({});
  const [selectedPlan, setselectedPlan] = useState("Annually");
  const [isEditing, setIsEditing] = useState(false)
  const [user, setuser] = useState("");
  const below5_user = planWithFiveMembers.plan;
  const currency_code = "₹";
  

  const { stripBillingDetails  , existingEmail} = useSelector((store) => store.user);
  const { stripePlanType, totalUsers, stripeSubscriptionId } =
    stripBillingDetails;

  useEffect(() => {
    if (isUpgradeUI) {
      setMemberCount(totalUsers);
      setselectedPlan(stripePlanType);
    }
  }, [isUpgradeUI]);

  useEffect(() => {
    return () => {
      setMemberCount(5);
      setselectedPlan("Annually");
    };
  }, []);


  useEffect(() => {
    if (memberCount <= 5) {
      setSelectPlanOption(() => {
        return planWithFiveMembers;
      });
    } else {
      setSelectPlanOption(() => {
        return selectPlanMode;
      });
    }
  }, [memberCount]);

  useEffect(() => {
    if (Object.keys(selectPlanOption).length && selectedPlan) {
      setuser((prev) => {
        prev = "";
        return selectPlanOption.plan[selectedPlan];
      });
    }
  }, [selectPlanOption, selectedPlan, memberCount]);

  const HandleTotalDollar = () => {
    if (user && selectedPlan === "Annually") {
      return memberCount * (user * 12);
    }
    return memberCount * user;
  };

  const handleContinue = () => {
    setLoader(!loader);
    const payload = {
      customerName: isExisting ? "" : fullName,
      email: isExisting ? existingEmail  : email,
      planType: selectedPlan,
      totalMembers: memberCount,
      totalAmount: HandleTotalDollar(),
      expiryDate: selectPlanExpiryDate(selectedPlan),
      orgId: isExisting ? null :  orgId,
    };
    if(isExisting) delete payload.orgId
    dispatch(setSelectPlanPayload(payload));
    setTimeout(() => {
      setLoader(!loader);
     if(existingEmail) dispatch(setActiveIndexExistingStep(2));
     else dispatch(editActiveIndex(3));
    }, 300);
  };

  const handleUpgrade = () => {
    setLoader(!loader);
    const payload = {
      planType: selectedPlan,
      totalMembers: memberCount,
    };
    dispatch(upgradePlanApi({ id: stripeSubscriptionId, payload })).then(
      (res) => {
        onHide(false);
        setLoader(!loader);
        if (!res.error) {
          billingDetailsApi();
        } else {
          toast.current.show({
            severity: "error",
            detail: res.payload,
          });
        }
      }
    );
  };

  const InputFocusOut = () => {
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const member = event.value === null ? 1 : parseInt(event.value)
    setMemberCount(member);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") setIsEditing(false);
  };

  const memberLogic = () => {
       if(totalUsers === undefined) return false;

       else if(totalUsers > memberCount ) {
        return true
       }
       else return false;
  }

  return (
    <div className="flex flex-column justify-content-center">
      <Toast ref={toast} />
      <Tooltip target=".member" mouseTrack mouseTrackLeft={10} />
      <div className="flex flex-column row-gap-3">
        {!isUpgradeUI && (
          <>
            {" "}
            <div className="flex flex-column row-gap-3">
              <div className="select-plan">Select Plan</div>
            </div>
            <div className="py-2">
              <FreeTrial
                title="Sign up for a 15 day free trial"
                description="Billing will commence after your 15 day trial."
              />
            </div>{" "}
          </>
        )}

        <div className="flex align-items-center column-gap-4 justify-content-around">
          {selectPlanOption?.mode?.map((item, id) => {
            return (
              <div
                className="flex align-items-center justify-content-between column-gap-4 subscription-plan p-2"
                key={id}
                style={
                  Boolean(item.plan === selectedPlan)
                    ? { border: "1px solid #FF006D" }
                    : { border: "1px solid #d1d5db" }
                }
              >
                <div className="flex align-items-center column-gap-2 ">
                  <RadioButton
                    checked={item.plan === selectedPlan}
                    onChange={() => setselectedPlan(() => item.plan)}
                    name={item.plan}
                  />
                  <div className="plan-type">{`${item.plan} plan`}</div>
                </div>
                <div className="flex align-items-center">
                  <div className="plan-amount">
                    {currency_code}
                    {item.subscription_amount}
                  </div>
                  <span>{"/user"}</span>
                </div>
              </div>
            );
          })}
        </div>
        {memberCount <= 5 && (
          <div className="flex align-items-center justify-content-center  column-gap-2">
            <div className="font-bold">Note : </div>
            <span>
              {currency_code}
              {below5_user[selectedPlan]} per user for Teams upto 5 users.
            </span>
          </div>
        )}

        {memberCount <= 100 && <div className="flex flex-column row-gap-2">
          <div className="flex align-items-center justify-content-between">
            <div>Members</div>
            <div className="flex  align-items-center">
              <div className="plan-amount">{`₹${user}`}</div>
              <p className="plan-type">{"/user"}</p>
            </div>
          </div>
          <div>
            <Slider
              value={memberCount}
              onChange={(e) => {
                setMemberCount(e.value);
              }}
            />
          </div>
        </div>}

        <div className="flex align-items-center justify-content-evenly w-12">
          <div className="flex flex-column row-gap-3">
            <div className="flex align-items-center column-gap-2">
              <img src={Image.memberLogo} alt="memberlogo" />
              <p className="plan-type member">user</p>
            </div>

            <div className="plan-amount text-center text-3xl font-bold member">
              {isEditing ? (
                <InputNumber
                  value={memberCount}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={InputFocusOut}
                />
              ) : (
                <div onClick={() => setIsEditing(true)} className="membercount">{memberCount}</div>
              )}
            </div>

            {/* <div className="plan-amount text-center text-3xl font-bold member">
              <div className="cursor-pointer">{memberCount}</div>
            </div> */}
          </div>
          <Divider layout="vertical" />
          <div className=" flex flex-column row-gap-3">
            <div className="flex align-items-center column-gap-2">
              <i
                className="pi pi-money-bill"
                style={{ color: "#FF006D", fontSize: "1rem" }}
              />
              <p className="plan-type">Total Price</p>
            </div>
            <div className="plan-amount text-center text-3xl font-bold">
              {currency_code}
              {HandleTotalDollar()}
            </div>
          </div>
        </div>

        <div className="flex flex-column row-gap-1 align-items-center justify-content-center">
          <p style={{ fontSize: "10px" }}>You will be billed {selectedPlan}</p>
          <p style={{ fontSize: "10px" }}>
            Once your subscription starts you cannot get a refund for any
            payment you've made.
          </p>
        </div>

        <div className="flex justify-content-end">
          <Button
            label={!isUpgradeUI ? "Continue" : "Upgrade Plan"}
            onClick={!isUpgradeUI ? handleContinue : handleUpgrade}
            loading={loader}
            disabled={Boolean(memberCount === 0) || totalUsers > memberCount}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  createMaintenanceHistory,
  deleteMaintenanceHistory,
  getMaintenanceHistory,
  updateMaintenanceHistory,
} from "../../../store/features/HumanResource/asset";
import EmptyScreen from "../../../component/common/empty/emptyScreen";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "../../../component/common/button/button";
import Alphabet from "../../../component/common/alphabet/alphabet";
import { fn_firstcharacter, isAdminAccess } from "../../../utility/utility";
import "./asset.scss";
import { SpeedDial } from "primereact/speeddial";
import moment from "moment";
import ReadMore from "../../../component/common/read-more/readMore";
import { InputTextarea } from "primereact/inputtextarea";
import Image from "../../../utility/image";

export const MaintenanceHistory = ({ visible, setVisible, hrAdmin }) => {
  const dispatch = useDispatch();
  const { getHistory } = useSelector((store) => store.assets);
  const [selectedItem, setSelectedItem] = useState({});
  const [maintenanceDialog, setMaintenanceDialog] = useState({
    isOpen: false,
    reason: "",
    solution: "",
    isEdit: false,
  });
  const usersAdmin = isAdminAccess("Human Resource");


  useEffect(() => {
    if (visible.assetItem?._id !== "")
    dispatch(getMaintenanceHistory({ assetItemId: visible.assetItem._id }));
  }, []);


  const handleCreateMaintenance = () => {
    setSelectedItem({});
    setMaintenanceDialog((prev) => {
      return { ...prev, isOpen: true, reason: "", solution: "", isEdit: false };
    });
  };

  const handleMoveMaintenance = () => {
    if (maintenanceDialog.isEdit) {
      const payload = {
        reason: maintenanceDialog.reason,
        solution: maintenanceDialog.solution,
        status:
          maintenanceDialog.solution?.length > 0 ? "Completed" : "InProgress",
      };
      dispatch(
        updateMaintenanceHistory({ payload: payload, id: selectedItem._id })
      ).then((res) => {
        if (!res.error) {
          dispatch(
            getMaintenanceHistory({ assetItemId: visible.assetItem._id })
          );
          setMaintenanceDialog((prev) => {
            return { ...prev, isOpen: false, isEdit: false };
          });
        }
      });
    } else {
      const payload = {
        assetItemId: visible.assetItem._id,
        reason: maintenanceDialog.reason,
        solution: maintenanceDialog.solution,
        status:
          maintenanceDialog.solution?.length > 0 ? "Completed" : "InProgress",
      };
      dispatch(createMaintenanceHistory(payload)).then((res) => {
        if (!res.error) {
          dispatch(
            getMaintenanceHistory({ assetItemId: visible.assetItem._id })
          );
          setMaintenanceDialog((prev) => {
            return { ...prev, isOpen: false, isEdit: false };
          });
        }
      });
    }
  };

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setMaintenanceDialog((prev) => {
          return {
            ...prev,
            isOpen: true,
            solution: selectedItem.solution,
            reason: selectedItem.reason,
            isEdit: true,
          };
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        dispatch(deleteMaintenanceHistory(selectedItem._id)).then((res) => {
          if (!res.error) {
            dispatch(
              getMaintenanceHistory({ assetItemId: visible.assetItem._id })
            );
          }
        });
      },
    },
  ];

  const Action = (item) => {
    return (
      <SpeedDial
        model={items}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        buttonClassName="p-button-outlined action-btn-design"
        onClick={() => setSelectedItem(item)}
      />
    );
  };

  return (
    <Dialog
      header="Maintenance History"
      visible={visible.isOpen}
      position="right"
      modal
      style={{ width: "60vw", height: "100vh" }}
      onHide={() =>
        setVisible((prev) => {
          return { ...prev, isOpen: !visible.isOpen, assetItem: {} };
        })
      }
      draggable={false}
      resizable={false}
    >
      <>
        <Dialog
          header="Move to Maintenance"
          visible={maintenanceDialog.isOpen}
          style={{ width: "40vw" }}
          onHide={() =>
            setMaintenanceDialog((prev) => {
              return { ...prev, isOpen: false, isEdit: false };
            })
          }
        >
          <div className="flex flex-column row-gap-4">
            <div className="flex flex-column row-gap-2">
              <label htmlFor="reason">Reason</label>
              <InputTextarea
                className="description-box"
                placeholder="Reason for Maintenance"
                name="Reason"
                id="reason"
                value={maintenanceDialog.reason}
                onChange={(e) =>
                  setMaintenanceDialog((prev) => {
                    return { ...prev, reason: e.target.value };
                  })
                }
              />
            </div>
            {hrAdmin && (
              <div className="flex flex-column row-gap-2">
                <label htmlFor="Solution">solution</label>
                <InputTextarea
                  className="description-box"
                  placeholder="Solution for Maintenance"
                  name="solution"
                  id="solution"
                  value={maintenanceDialog.solution}
                  onChange={(e) =>
                    setMaintenanceDialog((prev) => {
                      return { ...prev, solution: e.target.value };
                    })
                  }
                />
              </div>
            )}

            <div className="flex align-items-center justify-content-end">
              <Button
                className="primary"
                onClick={handleMoveMaintenance}
                label="submit"
                disabled={!maintenanceDialog.reason}
              />
            </div>
          </div>
        </Dialog>
        <div className="flex flex-column row-gap-3 p-3">
          <div className="flex column-gap-2 align-items-center justify-content-between">
            <div className="flex column-gap-4 align-items-center">
              <div className="image">
                {visible?.assetItem?.image ? (
                  <img
                    className="border-circle"
                    src={visible.assetItem.image}
                    alt="asset-img"
                    width={75}
                    height={75}
                  />
                ) : (
                  <Alphabet
                    letter={fn_firstcharacter(visible?.assetItem?.name)}
                    width="75px"
                    height="75px"
                    fontSize="2rem"
                  />
                )}
              </div>
              <div className="flex flex-column">
                <div className="mh_text">{visible?.assetItem?.name}</div>
                <div className="mh_label_name">
                  Asset ID :{" "}
                  <span className="mh_text">{visible.assetItem?.assetId}</span>
                </div>
                <div className="mh_label_name">
                  S/N :{" "}
                  <span className="mh_text">
                    {visible?.assetItem?.serialNumber}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center">
              {usersAdmin && <Button
                label="New Maintenance"
                onClick={handleCreateMaintenance}
              />
}
            </div>
          </div>
          {getHistory?.history?.length === 0 && (
            <EmptyScreen
              img={Image.emptyAsset}
              description="No History Available"
            />
          )}

          {getHistory?.history?.length > 0 && (
            <DataTable value={getHistory.history} responsiveLayout="scroll">
              <Column
                field={({ createdAt }) =>
                  moment(createdAt).format("DD MMM, YYYY")
                }
                header="Date"
              />
              <Column
                field={({ reason }) => (
                  <div style={{ width: "150px" }}>
                    <ReadMore text={reason} maxLength={30} />
                  </div>
                )}
                header="Reason"
              />
              <Column
                field={({ solution }) => (
                  <div style={{ width: "150px" }}>
                    <ReadMore text={solution} maxLength={30} />
                  </div>
                )}
                header="Solution"
              />
              <Column
                field={({ status }) => {
                  switch (status) {
                    case "Inprogress":
                      return (
                        <div
                          style={{
                            color: "#F27E11",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {status}
                        </div>
                      );
                    case "Completed":
                      return (
                        <div
                          style={{
                            color: "#28B425",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {status}
                        </div>
                      );
                    default:
                      return (
                        <div
                          style={{
                            color: "#F27E11",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {status}
                        </div>
                      );
                  }
                }}
                header="Action"
              />
              {hrAdmin && <Column body={Action} header="Action" />}
            </DataTable>
          )}
        </div>
      </>
    </Dialog>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSprintList,
  updateSprint,
} from "../../../store/features/Projects/Sprint";
import { DragDropContext } from "react-beautiful-dnd";
import { Tasklist } from "../drag-and-drop/Tasklist";

import Image from "../../../utility/image";
import EmptyScreen from "../../../component/common/empty/emptyScreen";
import { ActiveSprint } from "./activeSprint";
import { updateTask } from "../../../store/features/Projects/Task";
import { isEmptyArray } from "../../../utility/utility";

export const ProjectTask = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { sprintList } = useSelector((store) => store.sprint);
    const { updateStatus } = useSelector((store) => store.task);
  const [taskList, setTaskList] = useState({
    toDo: [],
    Inprogress: [],
    Review: [],
    Done: [],
  });
  const [projectTaskState, setprojectTaskState] = useState({
    activeCurrentSprint: {},
  });


  useEffect(() => {
    if (projectId) dispatch(getSprintList(projectId));
  }, [updateStatus === "success"]);

  useEffect(() => {
    if (!isEmptyArray(sprintList)) {
      setprojectTaskState((prev) => {
        const activeSprint = sprintList.find(
          (sprint) => sprint.status === "Active"
        );
        return { ...prev, activeCurrentSprint: activeSprint };
      });
      
      setTaskList((prev) => {
        const activeSprint = sprintList.find(
          (sprint) => sprint.status === "Active"
        );
        const data = activeSprint?.taskDetails;
        if (data?.length) {
          const toDo = data.filter((T) => T.status === "To Do");
          const Inprogress = data.filter((P) => P.status === "Inprogress");
          const Review = data.filter((R) => R.status === "Review");
          const Done = data.filter((D) => D.status === "Done");
          return { toDo, Inprogress, Review, Done };
        }
        return prev;
      });
    }
  }, [sprintList]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // if the draggable component is outside of the droppable area , this return
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    // example : A Task is draged in [To Do] and droped in [ToDO] , this will execute
    if (
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      let particularBoardName = source.droppableId;
      if (source.droppableId === "To Do") particularBoardName = "toDo";
      const findBoard = taskList[particularBoardName];
      const newItems = Array.from(findBoard);
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);
      setTaskList((prev) => {
        const data = { ...prev };
        data[particularBoardName] = newItems;
        return data;
      });
    }

    if (destination.droppableId !== source.droppableId) {
      let particularBoardName = source.droppableId;
      let droppable = destination.droppableId;
      if (source.droppableId === "To Do") particularBoardName = "toDo";
      if (destination.droppableId === "To Do") droppable = "toDo";
      const findBoard = taskList[particularBoardName];
      const filterTask = findBoard.find((_, index) => index === source.index);
      const item = { ...taskList };
      let sourceItem = item[particularBoardName];
      sourceItem.splice(source.index, 1);
      const target = item[droppable];
      target.splice(0, 0, filterTask);
      const payload = {
        ...filterTask,
        ["status"]: destination.droppableId,
        ["isBacklog"]: false,
      };
      dispatch(updateTask(payload)).then(() => {
        dispatch(getSprintList(projectId));
      });
    }
  };

  return (
    <>
      {(JSON.stringify(projectTaskState?.activeCurrentSprint) === "{}" || projectTaskState?.activeCurrentSprint === undefined)  ? (
        <div className="flex align-items-center justify-content-center w-full h-ful">
          <EmptyScreen description="No Active Sprints" img={Image.emptyBoard} />
        </div>
      ) : (
        <div className="flex flex-column row-gap-4">
          <ActiveSprint
            currentSprint={projectTaskState.activeCurrentSprint}
            projectId={projectId}
          />
          <div className="w-full">
            <DragDropContext onDragEnd={onDragEnd}>
              <Tasklist
                list={taskList}
                projectId={projectId}
                task={projectTaskState.activeCurrentSprint?.taskDetails}
              />
            </DragDropContext>
          </div>
        </div>
      )}
    </>
  );
};

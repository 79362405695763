import React from "react";

import PaySlipChart from "../../component/common/chart/payslipChart";
// import EmployeePaySlipChart from "../../component/common/chart/employePayslipChart"

export default function PaySlip(){
    return(
    <>
<div className="mt-4 flex flex-column row-gap-4">
    <><PaySlipChart /></> 
</div>
        </>
    )
}
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Button from "../../common/button/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import InputIcon from "../../common/inputIcon/inputIcon";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import bug from "../../../assets/bug.svg";
import story from "../../../assets/story.svg";
import taskfile from "../../../assets/task.svg";
import constants from "../../../constants";
import { getWithAuth } from "../../../services/authentication";
import {
  addTask,
  getTaskByRelease,
} from "../../../store/features/Projects/Release";
import { ChipBtn } from "../../common/chip/chip";
import { fn_color } from "../../../utility/utility";

export default function ReleaseAddTask({
  addReleaseTaskDialog,
  setAddReleaseTaskDialog,
  updateItem,
}) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { projectId } = useParams();
  const { allTasks } = useSelector((store) => store.release);

  const [createTask, setCreateTask] = useState({
    status: "",
    priority: "",
    sprint: "",
  });
  const [taskList, setTaskList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState([]);

  //pagination
  const [maxRecord, setMaxRecord] = useState();
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);

  //Approved reject checkbox
  const [taskApproval, setTaskApproval] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);
  //sprints
  const { sprintList } = useSelector((store) => store.sprint);
  const [sprints, setSprints] = useState([]);

  useEffect(() => {
    if (sprintList) {
      let options = [];
      sprintList.forEach((sprint) => {
        options.push({ value: sprint._id, label: sprint.sprintId });
      });
      setSprints(options);
    }
  }, [sprintList]);

  useEffect(() => {
    getReleaseData(projectId);
  }, []);

  useEffect(() => {
    setTaskList([...allTasks]);
  }, [allTasks]);

  useEffect(() => {
    getReleaseData(
      createTask.status,
      projectId,
      createTask.priority,
      createTask.sprint
    );
  }, [createTask.status, createTask.priority, createTask.sprint]);

  const statusDropdown = [
    { label: "All Status", value: "" },
    { label: "To Do", value: "To Do" },
    { label: "In Progress", value: "Inprogress" },
    { label: "Review", value: "Review" },
    { label: "Done", value: "Done" },
  ];

  const priorityDropdown = [
    { label: "All Priority", value: "" },
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const onSelectionChange = (event) => {
    const value = event.value;
    setTaskApproval(value);
    setSelectedMembers(value);
    setSelectAll(value.length === allTasks);
  };

  const taskImg = (props) => {
    let imagePath;
    if (props === "Bug") {
      imagePath = bug;
      return imagePath;
    } else if (props === "Task") {
      imagePath = taskfile;
      return imagePath;
    } else if (props === "Story") {
      imagePath = story;
      return imagePath;
    }
  };
  function taskType(task) {
    return (
      <div className="flex">
        <img
          src={taskImg(task.type)}
          alt={task.type}
          data-pr-tooltip={task.type}
        />
        <p className="ml-2">{task.type}</p>
      </div>
    );
  }
  function taskId(taskid) {
    return <div>{taskid.taskId}</div>;
  }
  function taskName(name) {
    const descriptionLength = name.title.toString().length;
    if (descriptionLength) {
      if (descriptionLength > 80) {
        return (
          <div className="asset-name-align">
            {show.findIndex((s) => s._id == name._id) !== -1
              ? name.title
              : name.title.slice(0, 80)}
            <div
              className="read-more-button hover"
              onClick={() => {
                let index = show.findIndex((s) => s._id == name._id);
                if (index !== -1) {
                  let nonShow = show.filter((s) => s._id !== name._id);
                  setShow(nonShow);
                } else {
                  setShow([...show, { _id: name._id, value: true }]);
                }
              }}
            >
              {show.findIndex((s) => s._id == name._id) !== -1
                ? "Read less"
                : "Read more"}
            </div>
          </div>
        );
      } else {
        return <div className="asset-name-align">{name.title}</div>;
      }
    }
  }

  const status = (status) => {
    return (
      <div
        style={{
          color: fn_color(status?.status),
          fontWeight: "600",
          fontSize: "14px",
        }}
      >
        {status?.status}
      </div>
    );
  };
  const getReleaseData = async (
    status,
    projectId,
    priority,
    sprint,
    search = ""
  ) => {
    let url = constants.API.RELEASE.FETCH_TASK;

    if (projectId) {
      url += `?projectId=${projectId}`;
    } else {
      // Handle the case where projectId is undefined
      return;
    }

    url += `&search=${search}&status=${status}&skip=${skip}&limit=${limit}&priority=${priority}&sprintId=${sprint}`;
    const data = await getWithAuth(url, token);
    setTaskList(data.data.tasks);
    setMaxRecord(data.data.requestsCount);
  };

  function handleChange(e) {
    setSearchValue(e.target.value);
    getReleaseData(
      createTask.status,
      projectId,
      createTask.priority,
      createTask.sprint,
      e.target.value
    );
  }
  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
              dispatch(setFrom(from - limit));
              dispatch(setTo(to - limit));
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              dispatch(setSkip(skip + 1));
              dispatch(setFrom(from + limit));
              dispatch(setTo(to + limit));
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  useEffect(() => {
    getReleaseData(
      createTask.status,
      projectId,
      createTask.priority,
      createTask.sprint
    );
  }, [skip, limit]);

  const submitHandler = () => {
    taskApproval.map((tasks) => {
      let data = { taskList: [tasks._id], _id: updateItem._id };
      dispatch(addTask(data));
      setAddReleaseTaskDialog(!addReleaseTaskDialog);
    });
    dispatch(
      getTaskByRelease({ projectId: projectId, releaseId: updateItem._id })
    );
  };
  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Add Task"
        visible={addReleaseTaskDialog}
        style={{ width: "70vw" }}
        onHide={() => {
          setAddReleaseTaskDialog(!addReleaseTaskDialog);
          setCreateTask({
            status: "",
            priority: "",
            sprint: "",
          });
        }}
      >
        <div className="mt-3 flex">
          <div className="ml-4">
            <InputIcon
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                handleChange(e);
              }}
            ></InputIcon>
          </div>

          <div className="ml-5 w-2">
            <Dropdown
              options={statusDropdown}
              placeholder="Status"
              value={createTask.status}
              onChange={(e) =>
                setCreateTask({ ...createTask, status: e.value })
              }
            ></Dropdown>
          </div>

          <div className="ml-5 w-2">
            <Dropdown
              options={priorityDropdown}
              placeholder="Priority"
              value={createTask.priority}
              onChange={(e) =>
                setCreateTask({ ...createTask, priority: e.value })
              }
            ></Dropdown>
          </div>

          <div className="ml-5 w-2">
            <Dropdown
              options={sprints}
              placeholder="Sprints"
              value={createTask.sprint}
              onChange={(e) =>
                setCreateTask({ ...createTask, sprint: e.value })
              }
            ></Dropdown>
          </div>
        </div>

        <div className="table-box mt-5">
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font timesheet-table-header-font"
              sortMode="multiple"
              value={taskList}
              responsiveLayout="scroll"
              onSelectionChange={onSelectionChange}
              selectAll={selectAll}
              selection={selectedMembers}
              paginator
              paginatorTemplate={paginate}
              rows={limit}
              paginatorClassName="justify-content-end custom-dropdown"
            >
              <Column
                selectionMode="multiple"
                selectionAriaLabel="name"
                headerStyle={{ width: "3em" }}
              ></Column>
              <Column field={taskType} header="Task Type"></Column>
              <Column body={taskId} header="Task Id"></Column>
              <Column field={taskName} header="Task Name"></Column>
              <Column body={status} header="Status"></Column>
              <Column
                body={(data) => <ChipBtn label={data?.priority} />}
                header="Priority"
              ></Column>
            </DataTable>
          </div>
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button label="Submit" onClick={() => submitHandler()}></Button>
        </div>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import Input from "./../inputtext/input-field";
import "./avatar.scss";
import {
  postWithAuth,
  putWithAuth,
  getWithAuth,
} from "../../../services/authentication";
import constants from "../../../constants";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import {
  approveRejectOverlay,
  fn_firstcharacter,
  isAdminAccess,
  maintenanceHistoryArray,
  moveToMaintennance,
  overlay,
} from "../../../utility/utility";
import { Tooltip } from "primereact/tooltip";
import Alphabet from "../alphabet/alphabet";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import Image from "../../../utility/image";
import { MaintenanceHistory } from "../../../pages/HR Managemnet/AssetCategory/maintenance-history";
import {
  createMaintenanceHistory,
  updateAssetStatus,
  moveToMaintenance,
} from "../../../store/features/HumanResource/asset";
import { useDispatch } from "react-redux";

export default function AvatarComponent({ data, state, setState, getApi }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const hrAdmin = isAdminAccess("Human Resource");
  const toast = useRef(null);
  const op = useRef(null);
  const approve_reject_overlay = useRef(null);
  const move_to_maintenance = useRef(null);
  const maintenance_history = useRef(null);
  const [requestDialog, setRequestDialog] = useState(false);
  const [requestForm, setRequestForm] = useState({});
  const [approvalForm, setApprovalForm] = useState(false);
  const [rejectionReasonDialog, setRejectionReasonDialog] = useState(false);
  const [reason, setReason] = useState("");
  const [allRecognition, setAllRecognition] = useState([]);
  const [maintenanceHistory, setMaintenanceHistory] = useState({
    isOpen: false,
    orgId: "",
    data: {},
  });
  const [maintenanceDialog, setMaintenanceDialog] = useState({
    isOpen: false,
    reason: "",
    solution: "",
  });
  const usersAdmin = isAdminAccess("Human Resource");

  useEffect(() => {
    setRequestForm(data);
  }, [data]);

  useEffect(() => {
    getAllRecognition();
  }, []);

  const submitHandler = async () => {
    try {
      const url = constants.API.ASSET.REQUEST_ASSET;
      const response = await putWithAuth(url, requestForm, token);
      setRequestDialog(!requestDialog);
      setState(!state);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const approvalHandler = () => {
    if (hrAdmin) {
      setApprovalForm(!approvalForm);
    }
  };

  const rejectHandler = () => {
    if (hrAdmin) {
      setRejectionReasonDialog(true);
      setApprovalForm(false);
    }
  };

  const approvalSubmitHandler = async (approve) => {
    try {
      const url = constants.API.ASSET.APPROVE_ASSET;
      if (approve === "Approve") {
        setRequestForm({ ...requestForm, isApproved: true });
        let data = { ...requestForm, isApproved: true };
        const response = await putWithAuth(url, data, token);
        setApprovalForm(!approvalForm);
        setState(!state);
      } else {
        let data = { ...requestForm, isApproved: false, reason: reason };
        const response = await putWithAuth(url, data, token);
        setRejectionReasonDialog(false);
        setState(!state);
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  async function getAllRecognition() {
    try {
      const url = constants.API.MEMBER.USER + `?filterOptions=1`;
      const response = await getWithAuth(url, token);
      let allRecognition = [];
      if (response?.data?.memberDetails) {
        allRecognition.push(response.data.memberDetails);
      }
      setAllRecognition(allRecognition);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response?.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  const handleOverley = (title) => {
    console.log("title",title)
    op.current.hide();
    if (title === "Assign") setRequestDialog(!requestDialog);
    else if (title === "Maintenance History") {
      setMaintenanceHistory((prev) => {
        return { ...prev, isOpen: !maintenanceHistory.isOpen, assetItem: data };
      });
    } else if (title === "Move to Maintenance")
      setMaintenanceDialog((prev) => {
        return { ...prev, isOpen: !maintenanceDialog.isOpen };
      });
      else if (title === "Print Asset Tag"){
        const printWindow = window.open('');
        printWindow.document.open();
        printWindow.document.write(`
          <html>
            <head>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  font-size:32px;
                  font-weight:600;
                }
                h1 {
                  color: #333;
                }
                ul {
                  margin-left: 30px;
                }
              </style>
            </head>
            <body>
           AssetId : ${data.assetId}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
      }
    else {
      alert(title);
    }
  };

  const handleApproveReject = (title) => {
    if (title === "Approve") {
      approvalHandler();
    } else if (title === "Reject") {
      rejectHandler();
    } else if (title === "Maintenance History") {
      setMaintenanceHistory((prev) => {
        return { ...prev, isOpen: !maintenanceHistory.isOpen, assetItem: data };
      });
    }
  };

  const handleMoveToMaintenance = (title) => {
    if (title === "Move to Maintenance") {
      move_to_maintenance.current.hide();
      setMaintenanceDialog((prev) => {
        return { ...prev, isOpen: !maintenanceDialog.isOpen };
      });
    } else if (title === "Maintenance History") {
      setMaintenanceHistory((prev) => {
        return { ...prev, isOpen: !maintenanceHistory.isOpen, assetItem: data };
      });
    }
  };

  const handleMoveMaintenance = () => {
    const { reason, solution } = maintenanceDialog;
    const payload = {
      assetItemId: data._id,
      reason: reason,
      solution: solution,
    };

    dispatch(createMaintenanceHistory(payload)).then((res) => {
      if (!res.error) {
        const payload = {
          data: { ...data, status: "Maintenance" },
          assetId: data._id,
        };
        dispatch(moveToMaintenance(payload)).then(() => {
          getApi();
        });
        setMaintenanceDialog((prev) => {
          return { ...prev, solution: "", reason: "", isOpen: false };
        });
      }
    });
  };

  const handleMaintenanceHistory = (title) => {
    maintenance_history.current.hide();
    if (title === "Maintenance History") {
      setMaintenanceHistory((prev) => {
        return { ...prev, isOpen: !maintenanceHistory.isOpen, assetItem: data };
      });
    }
  };

  return (
    <>
      {maintenanceHistory.isOpen && (
        <MaintenanceHistory
          visible={maintenanceHistory}
          setVisible={setMaintenanceHistory}
          hrAdmin={hrAdmin}
        />
      )}

      <OverlayPanel ref={maintenance_history} className="overlay_style">
        <div className="flex flex-column  row-gap-3">
          {maintenanceHistoryArray.map((item, i) => {
            return (
              <div className="flex column-gap-2 align-items-center" key={i}>
                <img src={item.icon} alt={item.name} width={24} height={24} />
                <div
                  className="overlay_txt cursor-pointer"
                  onClick={() => handleMaintenanceHistory(item.name)}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </OverlayPanel>

      <OverlayPanel ref={move_to_maintenance} className="overlay_style">
        <div className="flex flex-column  row-gap-3">
          {moveToMaintennance.map((item, i) => {
            return (
              <div className="flex column-gap-2 align-items-center" key={i}>
                <img src={item.icon} alt={item.name} width={24} height={24} />
                <div
                  className="overlay_txt cursor-pointer"
                  onClick={() => handleMoveToMaintenance(item.name)}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </OverlayPanel>

      <OverlayPanel ref={op} className="overlay_style">
        <div className="flex flex-column  row-gap-3">
          {overlay.map((item, i) => {
            return (
              <div className="flex column-gap-2 align-items-center" key={i}>
                <img src={item.icon} alt={item.name} width={24} height={24} />
                <div
                  className="overlay_txt cursor-pointer"
                  onClick={() => handleOverley(item.name)}
                >
                  {item.name}{" "}
                </div>
              </div>
            );
          })}
        </div>
      </OverlayPanel>

      <OverlayPanel ref={approve_reject_overlay} className="overlay_style">
        <div className="flex flex-column  row-gap-3">
          {approveRejectOverlay.map((item, i) => {
            if (hrAdmin) {
              return (
                <div className="flex column-gap-2 align-items-center" key={i}>
                  <img src={item.icon} alt={item.name} width={24} height={24} />
                  <div
                    className="overlay_txt cursor-pointer"
                    onClick={() => handleApproveReject(item.name)}
                  >
                    {item.name}{" "}
                  </div>
                </div>
              );
            } else if (item.name == "Maintenance History") {
              return (
                <div className="flex column-gap-2 align-items-center" key={i}>
                  <img src={item.icon} alt={item.name} width={24} height={24} />
                  <div
                    className="overlay_txt cursor-pointer"
                    onClick={() => handleApproveReject(item.name)}
                  >
                    {item.name}{" "}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </OverlayPanel>

      <Tooltip target=".assest" />
      <Toast ref={toast} />

      <Dialog
        header="Request Asset"
        visible={requestDialog}
        style={{ width: "50vw" }}
        onHide={() => setRequestDialog(!requestDialog)}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Asset Name"
                name="name"
                label="Asset Name"
                type="text"
                value={requestForm.name}
                disable={true}
              />
            </div>

            <div className="col-6">
              <Input
                placeholder="Asset Id"
                name="assetId"
                label="Asset Id"
                type="text"
                value={requestForm.assetId}
                disable={true}
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                onChange={(e) =>
                  setRequestForm({
                    ...requestForm,
                    requestedPeriodFrom: new Date(e.value),
                  })
                }
                minDate={new Date()}
              />
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                minDate={new Date()}
                onChange={(e) =>
                  setRequestForm({
                    ...requestForm,
                    requestedPeriodTo: new Date(e.value),
                  })
                }
              />
            </div>
            <div className="col-6"></div>
          </div>

          <div className="grid justify-content-end">
            <Button
              label="Submit"
              className="primary"
              onClick={submitHandler}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Approve or Reject"
        visible={approvalForm}
        style={{ width: "50vw" }}
        onHide={() => {
          setApprovalForm(!approvalForm);
        }}
      >
        <div className="flex flex-column row-gap-3 ">
          <div className="flex">
            <div className="col-6">
              <Input
                className="asset-input"
                placeholder="Asset Name"
                name="name"
                label="Asset Name"
                type="text"
                value={requestForm.name}
                disable={true}
              />
            </div>

            <div className="col-6">
              <Input
                placeholder="Asset Id"
                name="assetId"
                label="Asset Id"
                type="text"
                value={requestForm.assetId}
                disable={true}
              />
            </div>
          </div>

          <div className="flex">
            <div className="col-6">
              <Input
                placeholder="From"
                label="From"
                disable={true}
                value={moment(requestForm.from).format("DD/MM/YYYY")}
              />
            </div>
            <div className="col-6">
              <Input
                placeholder="To"
                label="To"
                disable={true}
                value={moment(requestForm.lastUpdate).format("DD/MM/YYYY")}
              />
            </div>
            <div className="col-6"></div>
          </div>

          <div className="flex">
            <div className="col-6">
              <Input
                placeholder="Name"
                label="Requestor Name"
                disable={true}
                value={requestForm?.memberDetails?.basic?.fullName}
              />
            </div>
            <div className="col-6">
              <Input
                placeholder="Role"
                label="Requestor Role"
                disable={true}
                value={requestForm?.memberDetails?.work?.role}
              />
            </div>
          </div>

          <div className="flex  justify-content-end">
            <div className="flex align-items-center column-gap-3">
              <Button
                label="Approve"
                className="primary"
                onClick={() => approvalSubmitHandler("Approve")}
              />
              {/* <Button
                label="Reject"
                className="primary"
                onClick={() => {
                  setRejectionReasonDialog(true);
                  setApprovalForm(false);
                }}
              /> */}
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Move to Maintenance"
        visible={maintenanceDialog.isOpen}
        style={{ width: "40vw" }}
        onHide={() =>
          setMaintenanceDialog((prev) => {
            return { ...prev, isOpen: !maintenanceDialog.isOpen };
          })
        }
      >
        <div className="flex flex-column row-gap-4">
          <div className="flex flex-column row-gap-2">
            <label htmlFor="reason">Reason</label>
            <InputTextarea
              className="description-box"
              placeholder="Reason for Maintenance"
              name="Reason"
              id="reason"
              value={maintenanceDialog.reason}
              onChange={(e) =>
                setMaintenanceDialog((prev) => {
                  return { ...prev, reason: e.target.value };
                })
              }
            />
          </div>
          {hrAdmin && (
            <div className="flex flex-column row-gap-2">
              <label htmlFor="Solution">solution</label>
              <InputTextarea
                className="description-box"
                placeholder="Solution for Maintenance"
                name="solution"
                id="solution"
                value={maintenanceDialog.solution}
                onChange={(e) =>
                  setMaintenanceDialog((prev) => {
                    return { ...prev, solution: e.target.value };
                  })
                }
              />
            </div>
          )}

          <div className="flex align-items-center justify-content-end">
            <Button
              className="primary"
              onClick={handleMoveMaintenance}
              label="submit"
              disabled={!maintenanceDialog.reason}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Reject"
        visible={rejectionReasonDialog}
        style={{ width: "40vw" }}
        onHide={() => setRejectionReasonDialog(!rejectionReasonDialog)}
      >
        <div className="flex flex-column row-gap-4">
          <InputTextarea
            className="description-box"
            placeholder="Rejection Reason"
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="flex align-items-center justify-content-end">
            <Button
              className="primary"
              onClick={() => approvalSubmitHandler("Reject")}
              label="Reject"
              disabled={!reason}
            />
          </div>
        </div>
      </Dialog>

      <div
        className="assest"
        data-pr-tooltip={data?.memberDetails?.basic?.fullName}
        data-pr-position="left"
      >
        {data.status === "UnAssigned" && (
          <div
            style={{ backgroundColor: "#ECECFC" }}
            className="avatar-container flex align-items-center justify-content-start column-gap-2 pl-1"
            onClick={(e) => op.current.toggle(e)}
          >
            <div
              className="plus cursor-pointer"
              style={{ backgroundColor: "#5143D4" }}
            >
              <i className="pi pi-plus" style={{ color: "#FFFFFF" }} />
            </div>

            <div className="AvatarFont" style={{ color: "#5143D4" }}>
              {data.status}
            </div>
          </div>
        )}

        {data.status === "Maintenance" && (
          <div
            style={{ backgroundColor: "#FFF6E6" }}
            className="avatar-container flex align-items-center justify-content-start column-gap-2 pl-1"
            onClick={(e) => maintenance_history.current.toggle(e)}
          >
            <div
              className="plus cursor-pointer"
              style={{ backgroundColor: "rgba(223, 158, 32, 1)" }}
            >
              <img
                src={Image.wrench}
                alt="maintenance"
                width={16}
                height={16}
              />
            </div>

            <div
              className="AvatarFont"
              style={{ color: "rgba(223, 158, 32, 1)" }}
            >
              {"Maintenance"}
            </div>
          </div>
        )}

        {data.status === "Occupied" && (
          <div
            style={{ backgroundColor: "#E1F0F3" }}
            className="avatar-container flex align-items-center justify-content-start column-gap-2 pl-1 "
            onClick={(e) => move_to_maintenance.current.toggle(e)}
          >
            {data?.memberDetails?.basic.profilePic ? (
              <img
                className="border-circle"
                src={data?.memberDetails?.basic?.profilePic}
                alt="name"
                width={33}
                height={33}
              />
            ) : (
              <Alphabet
                letter={fn_firstcharacter(data?.memberDetails?.basic?.fullName)}
              />
            )}

            <div className="occupied-font">{data.status}</div>
          </div>
        )}

        {data.status === "Requested" && (
          <div
            style={{ backgroundColor: "#FFF6E6" }}
            className="avatar-container flex align-items-center justify-content-start column-gap-2 p1-1"
            onClick={(e) => approve_reject_overlay.current.toggle(e)}
          >
            {data?.memberDetails?.basic.profilePic ? (
              <img
                src={data?.memberDetails?.basic?.profilePic}
                alt="name"
                width={33}
                height={33}
                className="border-circle"
              />
            ) : (
              <Alphabet
                letter={fn_firstcharacter(data?.memberDetails?.basic?.fullName)}
              />
            )}
            <div className="requested-font">{data.status}</div>
          </div>
        )}

        {data.status === "Retired" && (
          <div
            style={{ backgroundColor: "#ECECEE" }}
            className="avatar-container  flex align-items-center justify-content-start column-gap-2 pl-1"
          >
            <div
              className="plus cursor-pointer"
              style={{ backgroundColor: "#C4C4C4" }}
            >
              <img src={Image.danger} alt="retired" width={16} height={16} />
            </div>

            <div className="AvatarFont" style={{ color: "#C4C4C4" }}>
              {data.status}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import { getStartAndEndDate } from "../../../factory-function/getStartDateAndEndDate";
import api from "../../../axios/axios-interceptor";

const initialState = {
  timesheet: [],
  holidays: [],
  projectId: "",
  from: 1,
  to: 10,
  skip: 1,
  limit: 10,
  memberId: "",
  approvalStatus: "",
  isLoading: false,
  startDate: "",
  endDate: "",
  search: "",
  maxRecord: "",
  updateStatus: "",
  updateError: "",
  monthlyTimesheet: [],
  filterFrom: getStartAndEndDate("Current Month").startDate,
  filterTo: getStartAndEndDate("Current Month").endDate,
  filterStatus: false,
  filterDropDown: getStartAndEndDate("Current Month"),
  timesheetExportUrl: "",
  taskId: "",
  createStatus: "",
  createError: "",
  deleteStatus: "",
  deleteError: "",
};

export const getTimesheet = createAsyncThunk(
  "/timesheet",
  async (projectId, { getState, rejectWithValue }) => {
    try {
      const { timesheet } = getState();
      const project_id = projectId ? projectId : timesheet.projectId;
      const url =
        constants.API.TIMESHEET.CREATE +
        `?projectId=${project_id}&limit=${timesheet.limit}&skip=${timesheet.skip}&searchString=${timesheet.search}&memberId=${timesheet.memberId}&startDate=${timesheet.filterFrom}&endDate=${timesheet.filterTo}&taskId=${timesheet.taskId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getHolidays = createAsyncThunk(
  "/holiday",
  async ({ from, to }, { rejectWithValue }) => {
    try {
      const url =
        constants.API.HOLIDAY.CREATE + `?limit=10&skip=1&from=${from}&to=${to}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateTimesheet = createAsyncThunk(
  "/timesheet-update",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.TIMESHEET.CREATE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteTimesheet = createAsyncThunk(
  "/timesheetDelete",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.TIMESHEET.DELETE;
      const body = { activities: [data] };
      const response = await api.put(url, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getMonthlyTimesheet = createAsyncThunk(
  "/timesheet-monthly",
  async ({ from, to }, { rejectWithValue }) => {
    try {
      const url = constants.API.DASHBOARD.TIMESHEET + `?from=${from}&to=${to}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const timesheetExport = createAsyncThunk(
  "/timesheet-export",
  async (state, { rejectWithValue }) => {
    try {
      const url =
        constants.API.TIMESHEET.EXPORT +
        `?projectId=${state.projectId}&extension=${state.extension}&limit=${state.limit}&skip=${state.skip}&searchString=${state.search}&memberId=${state.memberId}&startDate=${state.filterFrom}&endDate=${state.filterTo}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const createTimeSheet = createAsyncThunk(
  "/timesheet-create",
  async (data, { rejectWithValue }) => {
    try {
      data.activityDate = new Date(data.activityDate)
        .toISOString()
        .replace(/T\d\d:\d\d:\d\d/, "T00:00:00");
      data.activityDate = new Date(data.activityDate).setDate(
        new Date(data.activityDate).getDate() + 1
      );
      const url = constants.API.TIMESHEET.CREATE;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    resetUpdateStatus: (state, action) => {
      state.updateError = "";
      state.updateStatus = "";
    },
    resetCreateStatus: (state, action) => {
      state.createError = "";
      state.createStatus = "";
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setFilterFrom: (state, action) => {
      state.filterFrom = action.payload;
    },
    setTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setFilterTo: (state, action) => {
      state.filterTo = action.payload;
    },
    setMemberId: (state, action) => {
      state.memberId = action.payload;
    },
    setFilterStatus: (state) => {
      state.filterStatus = true;
    },
    resetFilter: (state, action) => {
      state.memberId = "";
      state.filterFrom = state.filterDropDown.startDate;
      state.filterTo = state.filterDropDown.endDate;
      state.projectId = "";
      state.filterStatus = false;
    },
    resetTimesheetExportUrl: (state, action) => {
      state.timesheetExportUrl = "";
    },
    setFilterDropdown: (state, action) => {
      state.filterDropDown = action.payload;
      state.filterFrom = action.payload.startDate;
      state.filterTo = action.payload.endDate;
    },
    setInitialState: (state, action) => {
      state.memberId = "";
      // state.projectId=''
      state.taskId = "";
      state.filterStatus = false;
      state.filterFrom = getStartAndEndDate("Current Month").startDate;
      state.filterTo = getStartAndEndDate("Current Month").endDate;
      state.filterDropDown = getStartAndEndDate("Current Month");
      state.skip = 1;
      state.limit = 10;
    },
  },
  extraReducers: {
    [getTimesheet.pending]: (state) => {
      state.isLoading = true;
    },
    [getTimesheet.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.timesheet = action.payload.dailyActivities;
      state.maxRecord = action.payload.activitiesCount;
    },
    [getTimesheet.rejected]: (state, action) => {
      state.isLoading = false;
      state.status = "error";
    },

    [getHolidays.pending]: (state) => {},
    [getHolidays.fulfilled]: (state, action) => {
      state.holidays = action.payload.holidays;
    },
    [getHolidays.rejected]: (state, action) => {},

    [deleteTimesheet.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteTimesheet.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
    [updateTimesheet.pending]: (state) => {},
    [updateTimesheet.fulfilled]: (state, action) => {
      state.updateError = "";
      state.updateStatus = "success";
    },
    [updateTimesheet.rejected]: (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.payload;
    },
    [getMonthlyTimesheet.pending]: (state, action) => {},
    [getMonthlyTimesheet.fulfilled]: (state, action) => {
      state.monthlyTimesheet = action.payload.hoursSeries;
    },
    [getMonthlyTimesheet.rejected]: (state, action) => {},
    [timesheetExport.pending]: (state, action) => {},
    [timesheetExport.fulfilled]: (state, action) => {
      state.timesheetExportUrl = action.payload;
    },
    [timesheetExport.rejected]: (state, action) => {},
    [createTimeSheet.pending]: (state) => {},
    [createTimeSheet.fulfilled]: (state, action) => {
      state.createStatus = "success";
    },
    [createTimeSheet.rejected]: (state, action) => {
      state.createStatus = "error";
      state.createError = action.payload;
    },
  },
});

export const {
  setFilterStatus,
  resetTimesheetExportUrl,
  setInitialState,
  setTaskId,
  setFilterDropdown,
  resetStatus,
  resetCreateStatus,
  resetUpdateStatus,
  setMemberId,
  setProjectId,
  resetFilter,
  setFrom,
  setLimit,
  setMaxRecord,
  setSearch,
  setSkip,
  setTo,
  setFilterFrom,
  setFilterTo,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;

import React from "react";

const containerStyle = {
  background: "#F4F4F5",
  border: "1px solid #EAEAEA",
  borderRadius: "8px",
  padding:"8px"
};

const titleStyle = {
  color: "#161719",
  fontSize: "14px",
  fontWeight: 600,
};

const descStyle = {
  color: "#161719",
  fontSize: "14px",
};

export const FreeTrial = ({ title, description }) => {
  return (
    <div
      style={containerStyle}
      className="flex flex-column align-items-center row-gap-2 justify-content-center"
    >
      <p style={titleStyle}>{title}</p>
      <p style={descStyle}>{description}</p>
    </div>
  );
};

import React,{useState} from 'react'
import { InputText } from 'primereact/inputtext'

export default function SearchIconBar({value,onChange,placeholder,type}) {
 const [searchOpen, setSearchOpen] = useState(false);
  return (
    <div className="p-input-icon-left">
    <i
    className="pi pi-search"
      onClick={() => setSearchOpen(!searchOpen)}
      style={{ cursor: 'pointer', display:'inline-block' }}
    />
    {searchOpen && (
    <InputText className='search-bar' value={value} onChange={onChange} placeholder={placeholder} type={type} />
    )}
  </div>
  )
}


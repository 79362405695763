import React, { useEffect, useState, useRef } from "react";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";
import { fn_exe, getFileName } from "../../utility/utility";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setProjectId,
  getFileById,
  deleteDocs,
} from "../../store/features/Projects/File";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import Image from "../../utility/image";
import Button from "../../component/common/button/button";
import DeleteDialog from "../../component/common/confirm-delete-dialog/deleteDialog";
import FolderSidebar from "../../component/project/projectFiles/folderSidebar";

export default function ProjectFolderFiles() {
  const { projectId: projectParamsId } = useParams();
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [updateItem, setUpdateItem] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const allfilesStore = useSelector((store) => store.file);
  const { fileList } = useSelector((store) => store.file);
  //   side bar
  const [visibleRight, setVisibleRight] = useState(false);
  const [fileId, setFileId] = useState("");

  useEffect(() => {
    dispatch(setProjectId(projectParamsId));
  }, [projectParamsId]);

  useEffect(() => {
    dispatch(getFileById(folderId));
  }, []);

  const handleSubmit = (folderId) => {
    setVisibleRight(true);
    setFileId(folderId);
  };

  //Action button
  let items = [
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      await dispatch(
        deleteDocs({ id: folderId, urlpath: { url: updateItem } })
      ).then(() => {
        dispatch(getFileById(folderId));
      });
      setDeleteDialog(!deleteDialog);
      dispatch(getFileById(allfilesStore));
      toast.current.show({
        severity: "success",
        summary: "Success message",
        detail: "Deleted Successfully",
        life: 3000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}
      
      <div className="flex justify-content-end mr-5">
        <Button label="Upload" onClick={() => handleSubmit(folderId)}></Button>
      </div>

      {fileList?.length > 0 ? (
        <div className="flex column-gap-3 flex-wrap m-5">
          {fileList?.map((i, id) => {
            return (
              <div key={id} className="flex flex-column row-gap-3">
                <div className="flex column-gap-2">
                  <img src={fn_exe(i)} alt={id} width={100} height={100} />
                  <div className="folder-action-btn-align">
                    <SpeedDial
                      model={items}
                      radius={50}
                      direction="right"
                      showIcon="pi pi-ellipsis-v"
                      hideIcon="pi pi-times"
                      buttonClassName="p-button-outlined folderdocs-action-btn-design"
                      onClick={() => {
                        setUpdateItem(i);
                      }}
                    />
                  </div>
                </div>
                <a
                  className="release-card-desc-font"
                  style={{ textDecoration: "none" }}
                  href={i}
                  download
                >
                  {getFileName(i)}
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-content-center align-items-center">
          <EmptyScreen
            img={Image.EmptyImg}
            description="No file Available yet"
            header=""
            title=""
          />
        </div>
      )}

      {/* Folder sidebar */}
      <FolderSidebar
        visibleRight={visibleRight}
        setVisibleRight={setVisibleRight}
        fileId={fileId}
      />
    </>
  );
}

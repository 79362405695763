import React from "react";
import { Button } from "primereact/button";
import "./button.scss";

const PrimaryButton = ({
  label,
  icon,
  disabled,
  className,
  onClick,
  color,
  loading,
  visible
}) => {
  className = className ? className : "primary";
  visible = visible ? visible : "true"
  return (
    <Button
      label={label}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      className={className}
      visible={visible}
    />
  );
};

export default PrimaryButton;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  departments: [],
  status: "",
};

export const getDepartment = createAsyncThunk(
  "/department",
  async (_, {rejectWithValue}) => {
    try {
      const url = constants.API.DEPARTMENT.FETCH;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createDepartment = createAsyncThunk(
  "/create-department",
  async (department, { rejectWithValue }) => {
    try {
      const url = constants.API.DEPARTMENT.FETCH;
      const response = await api.post(url, { department });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    setDepartment: (state, action) => {
      state.departments = action.payload;
    },
  },
  extraReducers: {
    [getDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [getDepartment.fulfilled]: (state, action) => {
      state.departments = action.payload.departments;
    },
    [getDepartment.rejected]: (state, action) => {
      state.status = "error";
    },
    [createDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [createDepartment.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createDepartment.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const { resetStatus, setDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;

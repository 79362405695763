import React from "react";
import "./overViewDashBoard.scss";

export default function OverViewDashBoard({ item }) {
  return (
    <>
      <div
        style={{ background: item.colorCode }}
        className="flex flex-column p-3 justify-content-center align-items-start border-round overView__status"
      >
        <div className="flex column-gap-1">
          <div className="count__color">{item.count}</div>
          <i
            className={item.icon}
            style={{ color: "white", fontSize: "1rem" }}
          />
          <span className="count__color">{item.progress}</span>
        </div>
        <div className="progress__title">{item.name}</div>
      </div>
    </>
  );
}

import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Button from "../../common/button/button";
import Input from "../../common/inputtext/input-field";
import { useDispatch } from "react-redux";
import { createTask } from "../../../store/features/Projects/Task";
import { getSprintList } from "../../../store/features/Projects/Sprint";

export default function AddTaskForSprint({
  taskDialog,
  setTaskDialog,
  status,
  task,
  projectId,
}) {
  const dispatch = useDispatch();
  const [addTaskState, setAddTaskState] = useState({
    typeOptions: ["Bug", "Task", "Story"],
    title: "",
    type: "",
    loading: false,
    placeholder: "What is the task about..",
    label: "What is the task about..",
  });
  const { typeOptions, title, type, loading, placeholder, label } =
    addTaskState;
    

  const submitHandler = () => {
    setAddTaskState((prev) => {
      return { ...prev, loading: true };
    });
    const data = {
      status,
      title,
      type,
      project: task?.project,
      isBacklog: false,
      sprintId: task?.sprintId,
    };
    dispatch(createTask(data)).then((res) => {
      setAddTaskState((prev) => {
        return { ...prev, loading: false };
      });
      if (!res.error) {
        dispatch(getSprintList(projectId))
        setTaskDialog((prev) => {
          return { ...prev, taskDialog: !taskDialog };
        });
      }
    });
  };

  return (
    <Dialog
      header="Add Task"
      visible={taskDialog}
      style={{ width: "30vw" }}
      onHide={() => {
        setTaskDialog((prev) => {
          return { ...prev, taskDialog: !taskDialog };
        });
      }}
    >
      <div className="flex flex-column row-gap-4">
        <Dropdown
          placeholder="Task type"
          label="Task type"
          options={typeOptions}
          value={type}
          onChange={(e) => {
            setAddTaskState((prev) => {
              return { ...prev, type: e.target.value };
            });
          }}
        />
        <Input
          name="task-title"
          placeholder={placeholder}
          label={label}
          onChange={(e) => {
            setAddTaskState((prev) => {
              return { ...prev, title: e.target.value };
            });
          }}
        />
        <div className="flex align-items-center justify-content-end">
          <Button
            label="Submit"
            icon="pi pi-check"
            loading={loading}
            onClick={submitHandler}
            disabled={title === "" || type === ""}
          />
        </div>
      </div>
    </Dialog>
  );
}

import React from "react";
import { Dialog } from "primereact/dialog";

import SelectPlan from "../../../Authentication/selectPlan";

export const UpgradePlan = ({ visible, onHide, billingDetailsApi }) => {
  return (
    <Dialog
      header="Upgrade Plan"
      visible={visible}
      onHide={() => onHide(false)}
      style={{ width: "40vw", height: "450px" }}
    >
     <SelectPlan isUpgradeUI={true} billingDetailsApi={billingDetailsApi} onHide={onHide} />
    </Dialog>
  );
};

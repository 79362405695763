import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../component/common/inputtext/input-field";
import Checkbox from "../../component/common/checkbox/checkbox";
import Button from "../../component/common/button/button";
import { Password } from "primereact/password";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import {
  resetStatus,
  login,
  setIsLoading,
} from "./../../store/features/Auth/login";
import validate from "../../factory-function/validate";
import { setMemberDetailsAfterLogin } from "../../store/features/UserDetails/UserDetails";
import { GetRoutesInOrder } from "../../factory-function/getRoutesInOrder";

export default function Login() {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { status, error, isLoading, user } = useSelector((store) => store.user);
  const [checked, setchecked] = useState(false);
  const [loader, setloader] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [errorDetails, setErrorDetails] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(loginDetails).every((val) => val !== "");
    dispatch(setIsLoading(x && y));
  }, [errorDetails]);

  useEffect(() => {
    if (status === "error") {
      const errorStatus = error ? error : "User Not Found"
      toast.current.show({
        severity: "error",
        detail: errorStatus,
        life: 3000,
      });
    }
    if (status === "success") {
      let routes = user.memberDetails.modulePermission.map((mod) => {
        return mod.moduleName;
      });
      routes = GetRoutesInOrder(routes);
      const memberDetails = { ...user.memberDetails, routes: routes };
      dispatch(setMemberDetailsAfterLogin(memberDetails));
      Navigate("/");
    }
    dispatch(resetStatus());
  }, [status, error]);

  function handleChange(e) {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
    setErrorDetails({
      ...errorDetails,
      [e.target.name]: validate({ [e.target.name]: e.target.value }),
    });
  }

  const submitHandler = () => {
    setloader(true);
    dispatch(login(loginDetails)).then(() => setloader(false));
  };

  const handleKeyDown = (event) => {
    const validation = Object.values(loginDetails).every((val) => val !== "");
    // enter key 
    if (event.keyCode === 13 && validation) submitHandler();
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-4 ">
        <div className="flex flex-column row-gap-2">
          <p className="h1-font">Sign in to Squadzen</p>
          <div className="paragraph-font flex align-items-center column-gap-1">
            Don't have an account ?
            <span
              className="theme-font"
              onClick={() => Navigate("/auth/signup")}
            >
              Sign up
            </span>
          </div>
        </div>

        <div>
          <Input
            type="email"
            onChange={handleChange}
            label="Email"
            placeholder="Your Email"
            name="email"
          />
          <div className="error-text">{errorDetails.email}</div>
        </div>

        <div className="p-float-label">
          <Password
            inputId="password"
            name="password"
            onChange={handleChange}
            toggleMask
            feedback={false}
            onKeyDown={handleKeyDown}
          />
          <label htmlFor="password">Password</label>
        </div>
        {errorDetails.password && (
          <div className="p-float-label password-text error-text">
            {errorDetails.password}
          </div>
        )}

        <div className="flex align-items-center column-gap-2 justify-content-between">
         <div className="flex align-items-center column-gap-1">
         <Checkbox
            inputId="binary"
            checked={checked}
            name="rememberme"
            onChange={(e) => setchecked(e.checked)}
          />
          <label htmlFor="binary">Remember Me</label>
         </div>
         <p className="cursor-pointer" onClick={() => Navigate("/auth/existing-account")}>Already have account?</p>
        </div>

        <div className="flex justify-content-between align-items-center">
          <div
            className="forgot-font"
            onClick={() => Navigate("/auth/forgot-password")}
          >
            Forgot your password?
          </div>

          <div>
            <Button
              label="Continue"
              onClick={submitHandler}
              disabled={isLoading}
              loading={loader}
            />
          </div>
        </div>
      </div>
    </>
  );
}

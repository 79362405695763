import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";
const initialState = {
  users: [],
  profileDetails: {},
  status: "",
  search: "",
  skip: 1,
  limit: 10,
  userStatus: "Active",
  maxRecord: 0,
  isLoading: false,
  updateUserStatus: "",
  updateUserError: "",
  allUsers: [],
  deleteStatus: "",
  deleteError: "",
  from: 1,
  to: 10,
};

export const getUsers = createAsyncThunk("/user", async (data, {rejectWithValue}) => {
  try {
    const { skip, limit, search, userStatus } = data;
    const url =
      constants.API.MEMBER.LIST +
      `?search=${search}&skip=${skip}&limit=${limit}&status=${userStatus}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const createUser = createAsyncThunk(
  "/create-user",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.INVITE;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "/delete-user",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.DELETE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateUser = createAsyncThunk(
  "/update-user",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.EDIT;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "/all-users",
  async (_, {rejectWithValue}) => {
    try {
      const response = await api.get(constants.API.MEMBER.FETCH);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getProfileDetails = createAsyncThunk(
  "/profile-details",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.LIST + `/${id}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateProfileDetails = createAsyncThunk(
  "/update-profile",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.FETCH_DETAIL;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const activateUser = createAsyncThunk(
  "/activate-user",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.ACTIVATE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    resetUpdateStatus: (state, action) => {
      state.updateUserError = "";
      state.updateUserStatus = "";
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload.members;
      state.maxRecord = action.payload.requestsCount;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.status = "error";
    },
    [createUser.pending]: (state) => {},
    [createUser.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createUser.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [deleteUser.pending]: (state) => {},
    [deleteUser.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteUser.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
    [updateUser.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [updateUser.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.allUsers = action.payload.activeMembers;
    },
    [getAllUsers.rejected]: (state, action) => {},
    [getAllUsers.pending]: (state, action) => {},
    [getProfileDetails.fulfilled]: (state, action) => {
      state.profileDetails = action.payload.memberOrg;
    },
    [getProfileDetails.rejected]: (state, action) => {},
    [getProfileDetails.pending]: (state, action) => {},
    [updateProfileDetails.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [updateProfileDetails.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [updateProfileDetails.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
  },
});

export const {
  resetStatus,
  setSkip,
  setLimit,
  setMaxRecord,
  setSearch,
  resetUpdateStatus,
  setFrom,
  setTo,
  setUserStatus,
} = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTimesheet,
  deleteTimesheet,
  setInitialState,
  resetFilter,
  setProjectId,
} from "../../store/features/Timesheet/timesheet";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import DeleteDialog from "../common/confirm-delete-dialog/deleteDialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import UpdateTimesheet from "./updateTimesheet";
import { getSprintList } from "../../store/features/Projects/Sprint";

import {
  setFrom,
  setTo,
  setSkip,
  setLimit,
} from "./../../store/features/Timesheet/timesheet";
import Alphabet from "../common/alphabet/alphabet";
import {
  DateFormat,
  PaginationDropdown,
  WDateFormat,
  fn_firstcharacter,
} from "../../utility/utility";
import ReadMore from "../common/read-more/readMore";
import Image from "../../utility/image";

export default function TimesheetTable() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { skip, to, maxRecord, search, from, limit, timesheet } = useSelector(
    (store) => store.timesheet
  );

  const [deleteDialog, setDeleteDialog] = useState(false);

  const [updateTimesheetDialog, setUpdateTimesheetDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    dispatch(getTimesheet(""));
  }, [skip, to, maxRecord, from, limit, search]);

  const CommonHeader = ({ title, icon }) => {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={icon} height={16} />
        <div className="flex">&nbsp;{title}</div>
      </div>
    );
  };

  const projectsName = (projectName) => {
    return (
      <div className="flex align-items-center column-gap-2">
        {projectName.project?.projectDetails?.companyLogo ? (
          <img
            className="projects-img-size flex justify-content-center"
            src={projectName.project?.projectDetails?.companyLogo}
          />
        ) : (
          <Alphabet letter={fn_firstcharacter(projectName?.projectName)} />
        )}
        <div>{projectName?.projectName}</div>
      </div>
    );
  };

  const deleteHandler = () => {
    try {
      const { payload } = dispatch(deleteTimesheet(updateItem));
      if (payload && payload.status == 200) {
        setDeleteDialog(!deleteDialog);
        toast.current.show({
          severity: "success",
          summary: "Success message",
          detail: "Deleted Successfully",
          life: 3000,
        });
        dispatch(getTimesheet(""));
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const paginate = {
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            onClick={() => {
              dispatch(setSkip(skip - 1));
              dispatch(setFrom(from - limit));
              dispatch(setTo(to - limit));
            }}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3">Previous</span>
          </button>
          <button
            type="button"
            className={options.className}
            disabled={to >= maxRecord ? true : false}
            onClick={() => {
              dispatch(setSkip(skip + 1));
              dispatch(setFrom(from + limit));
              dispatch(setTo(to + limit));
            }}
          >
            <span className="p-3">Next</span>
          </button>
        </>
      );
    },
  };

  return (
    <>
      <Toast ref={toast} />
      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}

      {updateTimesheetDialog && (
        <UpdateTimesheet
          updateTimesheetDialog={updateTimesheetDialog}
          updateItem={updateItem}
          setUpdateItem={setUpdateItem}
          update={update}
          setUpdateTimesheetDialog={setUpdateTimesheetDialog}
        />
      )}

      <DataTable
        className="asset-table-font timesheet-table-header-font"
        sortMode="multiple"
        value={timesheet}
        responsiveLayout="scroll"
        paginator
        paginatorTemplate={paginate}
        rows={limit}
        paginatorClassName="justify-content-end custom-dropdown"
      >
        <Column
          field={projectsName}
          header={
            <CommonHeader title="Project Name" icon={Image.documentImage} />
          }
        />
        <Column
          body={(item) => (
            <div style={{ width: "250px", wordWrap: "break-word" }}>
              <ReadMore text={item.description} maxLength={70} />
            </div>
          )}
          header={
            <CommonHeader title="Description" icon={Image.documentImage} />
          }
        />
        <Column
          field={(item) => <>{DateFormat(item?.activityDate)}</>}
          header={<CommonHeader title="Date" icon={Image.dateIcon} />}
        />
        <Column
          field={({ duration }) => (
            <>{`${duration.hours} ${
              duration.minutes ? `:${duration.minutes}` : ""
            }Hrs`}</>
          )}
          header={<CommonHeader title="Log Time" icon={Image.timeIcon} />}
        />
        <Column
          body={(item) => <>{item.memberDetails.basic.fullName}</>}
          header={<CommonHeader title="Created" icon={Image.userIcon} />}
        />
      </DataTable>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import { postWithAuth } from "../../../services/authentication";
const initialState = {
  isLoading: false,
  status: "",
  error: "",
  memberOrgDetails: {},
};
export const orgSignUp = createAsyncThunk(
  "auth/orgSignUp",
  async ({ companyName, email, website, token }, thunkAPI) => {
    try {
      const response = await postWithAuth(
        constants.API.AUTHENTICATION.ORG_SIGNUP,
        { orgName: companyName, email: email, domain: website },
        token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
const orgSignUpSlice = createSlice({
  name: "orgSignUp",
  initialState,
  reducers: {
    resetorgSignUpStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    resetorgSignUpData: (state, action) => {
      state.error = "";
      state.status = "";
      state.isLoading = false;
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
    getMemberOrg: (state, { payload }) => {
      state.memberOrgDetails = payload;
    },
  },
  extraReducers: {
    [orgSignUp.pending]: (state) => {
      state.isLoading = true;
    },
    [orgSignUp.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [orgSignUp.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  resetorgSignUpStatus,
  resetorgSignUpData,
  setIsLoading,
  getMemberOrg,
} = orgSignUpSlice.actions;
export default orgSignUpSlice.reducer;

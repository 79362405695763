import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  email: "",
  isLoading: false,
  status: "",
  error: "",
};

export const changePassword = createAsyncThunk(
  "auth/change-password",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.CHANGE_PASSWORD, {
        email: data.email,
        password: window.btoa(data.password),
        confirmPassword: window.btoa(data.confirmPassword),
        verificationCode: data.verificationCode,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
  },
  extraReducers: {
    [changePassword.pending]: (state) => {
      state.isLoading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
    },
    [changePassword.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { resetStatus, setIsLoading } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;

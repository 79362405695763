import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import {
  uploadImage,
  resetImageUrl,
} from "../../../store/features/Image/image";
import { getFileById, uploadFile } from "../../../store/features/Projects/File";
import { Button } from "primereact/button";
import Alphabet from "../../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../../utility/utility";
import ProfileUpload from "../../common/file-upload/fileUpload";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import Image from "../../../utility/image";

export default function FolderSidebar({
  setVisibleRight,
  visibleRight,
  fileId,
}) {
  const { projectDetails } = useSelector((store) => store.project);
  const { allFiles } = useSelector((store) => store.file);
  const { folderId } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();

  //file upload url
  const [fileUrl, setFileUrl] = useState();
  const { imageUrl } = useSelector((store) => store.image);

  useEffect(() => {
    if (imageUrl !== "") {
      setFileUrl(imageUrl);
      dispatch(resetImageUrl());
    }
  }, [imageUrl]);


  const uploadHandler = async (e) => {
    try {
      const fileData = new FormData();
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", async () => {
            fileData.append("file", files[0]);
            dispatch(
              uploadImage({ folderName: "project", data: fileData })
            ).then((res)=>{
              if(res.payload.message ==="Profile picture upload success."){
                setFileUrl(res.payload.URL)
              }
            })
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const submitHandler = async () => {
    try {
      const file_array = allFiles?.filter((x) => x._id === fileId);
      const files = [...file_array[0]?.docs];
      files.push(fileUrl);
      let data = { _id: fileId, docs: files };
      await dispatch(uploadFile(data)).then((res)=>{
      dispatch(getFileById(folderId))
      })
      setVisibleRight(!visibleRight);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "something Went wrong!",
        detail: "",
        life: 3000,
      });
    }
  };
  return (
    <div>
      <Sidebar
        style={{ width: "35%" }}
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <Toast ref={toast} />
        <h3>Upload Files</h3>
        {[projectDetails].map((project, id) => {
          return (
            <div key={id} className="ml-3 mt-3">
              <div className="flex">
                <img src={Image.userIcon} alt="user" />
                <div className="sidebar-file-header-font ml-2 align-items-center">
                  Project Manager
                </div>
              </div>
              <div className="flex mt-3">
              <Alphabet  letter={fn_firstcharacter(project.leadInfo?.basic.fullName)} />
                <div className="ml-2 folder-name-font mt-1">
                  {project?.leadInfo?.basic?.fullName}
                </div>
              </div>
              <div className="flex mt-4">
                <img src={Image.dateIcon} alt="date" />
                <div className="sidebar-file-header-font ml-2">Create Date</div>
              </div>
              <div style={{ width: "8rem" }} className="endDate-btn mt-3 ml-3">
                {new Date(
                  project?.projectDetails?.createdAt
                ).toLocaleDateString("en-uk", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  weekday: "short",
                })}
              </div>
            </div>
          );
        })}

        <div className="mt-5 ml-3 flex">
          <img src={Image.documentImage} />
          <div className="sidebar-file-header-font ml-2">Source File</div>
        </div>

        <div className="mt-3 ml-3">
          <ProfileUpload labelName="" profileImg={fileUrl} uploadHandler={uploadHandler} remove={()=>setFileUrl("")} />
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button
            className="create-btn"
            label="Submit"
            onClick={submitHandler}
           />
        </div>
      </Sidebar>
    </div>
  );
}

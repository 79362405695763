import React, { useEffect, useRef } from "react";
import ScartchpadEditorDialog from "../../component/scratchpad/ScartchpadEditorDialog";
import ScratchpadCardContainer from "../../component/scratchpad/ScratchpadCardContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  createScratchpad,
  getScratchpad,
  resetStatus,
  setScratchpadCreate,
  setSelectedScratchpad,
} from "../../store/features/Scratchpad/scratchpad";
import { Toast } from "primereact/toast";
import Image from "../../utility/image";

export default function Scratchpad() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const scratchpad = useSelector((store) => store.scratchpad);
  const { error, status, createCount } = useSelector(
    (store) => store.scratchpad
  );

  useEffect(() => {
    dispatch(getScratchpad(scratchpad));
  }, []);

  useEffect(() => {
    if (status === "success") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "",
        life: 3000,
      });
      dispatch(getScratchpad(scratchpad));
    } else if (error !== "") {
      toast.current.show({
        severity: "error",
        summary: "Some Error Occurred",
        detail: "",
        life: 3000,
      });
    }
    dispatch(resetStatus());
  }, [status, error]);

  const CreateScratchpad = () => {
    if (createCount < 1) {
      dispatch(setScratchpadCreate(true));
      dispatch(createScratchpad({ note: "Temp" }));
      dispatch(setSelectedScratchpad({}));
    }
  };

  return (
    <div className="flex px-4 column-gap-2" style={{height: "500px"}}>
      <Toast ref={toast} />
      <div className="col-3">
        <div className="add-new-note cursor-pointer" onClick={CreateScratchpad}>
          <img src={Image.addBtn} alt="add_btn" height={20} width={20} />
          <p className="add_new__txt">Add New Note</p>
        </div>
        <ScratchpadCardContainer />
      </div>
      <div className="col-9">
        <ScartchpadEditorDialog />
      </div>
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  projectList: [],
  allprojects: [],
  getProjectData: [],
  slackChannel: [],
  skip: 1,
  from: 1,
  limit: 10,
  to: 10,
  search: "",
  maxRecord: "",
  error: "",
  status: "",
  projectId: "",
  assignedProjects: [],
  projectDetails: {},
  projectMemberDetails: [],
  projectStatus: "Active",
  isLoading: false,
  loading : false
};
export const getProjectList = createAsyncThunk(
  "/project-list",
  async (_,{rejectWithValue}) => {
    try {
      const response = await api.get(constants.API.PROJECT.ASSIGN_REASSIGN);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllProjects = createAsyncThunk(
  "/allproject-list",
  async (_, {rejectWithValue, getState }) => {
    try {
      const { project } = getState();
      const url =
        constants.API.PROJECT.PROJECT_ASSIGNED_LIST +
        `?limit=${project.limit}&skip=${project.skip}&search=${project.search}&status=${project.projectStatus}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSlackChannel = createAsyncThunk(
  "/slackchannel-list",
  async (_,{rejectWithValue}) => {
    try {
      const response = await api.get(constants.API.SLACK.CHANNEL_LIST);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createProject = createAsyncThunk(
  "/create-project",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.PROJECT.CREATE, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateProject = createAsyncThunk(
  "/update-project",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.put(constants.API.PROJECT.UPDATE, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "/delete-project",
  async (data, {rejectWithValue}) => {
    try {
      const payload = { projectIdList: [data] };
      const response = await api.put(constants.API.PROJECT.DELETE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const activateProject = createAsyncThunk(
  "/activate-project",
  async (data, {rejectWithValue}) => {
    try {
      const payload = { projectIdList: [data] };
      const response = await api.put(constants.API.PROJECT.ACTIVATE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getProjectDetailsById = createAsyncThunk(
  "/projectmember-list",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FETCH_BY_ID + "/" + id;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getUsersByProject = createAsyncThunk(
  "/projectmember",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.PROJECTASSIGNEDMEMBERS + id;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setMaxRecord: (state, action) => {
      state.maxRecord = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setProjectStatus: (state, action) => {
      state.projectStatus = action.payload;
    },
  },
  extraReducers: {
    [getProjectList.pending]: (state) => {
      // state.isLoading = true;
    },
    [getProjectList.fulfilled]: (state, action) => {
      state.assignedProjects = action.payload.assignedProjects;
      state.projectList = [
        ...action.payload.assignedProjects,
        ...action.payload.unAssignedProjects,
      ];
      // state.isLoading = false
    },
    [getProjectList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.loading = false;
    },
    [getAllProjects.pending]: (state) => {
      state.loading = true;
    },
    [getAllProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.allprojects = action.payload.projects?.map((project) => {
        if (!project.isKanban) {
          let list = [];
          project.sprints.map((element) => {
            if (element.status === "Completed") list.push(element);
          });
          if (list.length) {
            project["percentage"] = Math.round(
              (list.length / project.sprints.length) * 100
            );
          } else project.percentage = 0;
        } else {
          project.percentage = project.allTaskCount
            ? Math.round((project.doneTaskCount / project.allTaskCount) * 100)
            : 0;
        }

        project["members"] = [];
        project.assignment.map((element) => {
          let url = element?.assignedMemberDetails?.basic?.profilePic
            ? element?.assignedMemberDetails?.basic?.profilePic
            : null;
          project.members.push({
            name: element?.assignedMemberDetails?.basic?.fullName,
            url,
          });
        });
        return project;
      });

      state.maxRecord = action?.payload?.totalProjects;
    },
    [getAllProjects.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      // state.isLoading = false;
    },

    [getSlackChannel.pending]: (state) => {
      // state.isLoading = true;
    },
    [getSlackChannel.fulfilled]: (state, { payload }) => {
      if (payload.result?.channels)
        state.slackChannel = [...payload.result?.channels];
    },
    [getSlackChannel.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },

    [createProject.pending]: (state) => {},
    [createProject.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createProject.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [updateProject.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [updateProject.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [updateProject.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
    [deleteProject.pending]: (state) => {},
    [deleteProject.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteProject.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
    [getProjectDetailsById.fulfilled]: (state, action) => {
      let project = action.payload.project;
      if (project) {
        project["members"] = [];
        project.assignment.map((element) => {
          let url = element.assignedMemberDetails.basic.profilePic
            ? element.assignedMemberDetails.basic.profilePic
            : "";
          project.members.push({
            name: element.assignedMemberDetails.basic.fullName,
            url,
          });
        });
        state.projectDetails = project;
      }
    },
    [getUsersByProject.fulfilled]: (state, action) => {
      state.projectMemberDetails = action.payload;
    },
  },
});
export const {
  resetStatus,
  setSkip,
  setLimit,
  setFrom,
  setTo,
  setSearch,
  setMaxRecord,
  setProjectId,
  setProjectStatus,
} = projectSlice.actions;
export default projectSlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import "./employeeLeaveList.scss";
import leaveEmpty from "../../assets/leave-empty.svg";
import EmptyScreen from "../common/empty/emptyScreen";
import Spinner from "../common/spinner/spinner";
import constants from "../../constants";
import Alphabet from "../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../utility/utility";

export default function EmployeeLeaveList({ todayLeave, tomorrowLeave }) {
  const [spinner, setSpinner] = useState(false);

  return (
    <div className="w-11">
      <div className="grid flex justify-content-center">
        {spinner === true ? <Spinner value={spinner}></Spinner> : <></>}
      </div>
      {todayLeave && todayLeave.length > 0 && spinner === false ? (
        <div className="leave-scroll">
          <div>
            <div className="announcement-header-font flex justify-content-center">
              Who's Away
            </div>
            <div className="announcement-time mt-2">Today</div>
            {todayLeave.map((emp) => {
              return (
                <div className="flex column-gap-3 mt-2">
                  <div className="flex align-items-center">
                    {emp.userDetails.profilePic ? (
                      <img
                        className="img-size"
                        src={emp.userDetails.profilePic}
                        alt="profilepic"
                      />
                    ) : (
                      <Alphabet
                        letter={fn_firstcharacter(emp.userDetails.fullName)}
                        borderRadius="20%"
                      />
                    )}
                  </div>

                  <div className="flex flex-column">
                    <div className="announcement-time ">
                      {emp.userDetails.fullName}
                    </div>
                    <div className="leave-reason-font">{emp.reason}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      {spinner === false && todayLeave !== {} && todayLeave.length === 0 ? (
        <div className="leave-scroll no-leave-today">
          <div className="announcement-header-font flex justify-content-center">
            Who's Away
          </div>
          <EmptyScreen
            img={leaveEmpty}
            description={constants.EMPTY_SCREEN_TEXT[6].description}
          ></EmptyScreen>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

import React from "react";
import "./carousel.scss";
import { Carousel } from "primereact/carousel";
import Image from "../../../../utility/image";
import {
  DateFormat,
  fn_firstcharacter,
  responsiveOptions,
} from "../../../../utility/utility";
import MemberContainer from "../../../common/member-container/memberContainer";
import ProgressBar from "../../../common/progress-bar/progressBar";
import Alphabet from "../../../common/alphabet/alphabet";
import AvatarGroup from "../../../common/avatar-group/avatarGroup";

export default function CustomCarousel({ item, type }) {
  const ReleaseCardComponet = (item) => {
    return (
      <>
        <div className="release__card m-2 p-3 flex flex-column  row-gap-2 justify-content-center align-items-start">
          <div className="release__title">Release </div>
          <div className="release__desc">{item.name}</div>
          <div className="flex column-gap-2 justify-content-center align-items-center">
            <img src={Image.documentImage} alt={item.name} />
            <span>{item.taskDetails}</span>
          </div>
          <div>
            <div className="release__endate">{DateFormat(item?.endDate)}</div>
          </div>
        </div>
      </>
    );
  };

  const ProjectCardComponent = (item) => {
    return (
      <>
        <div className="p-3 m-2 flex flex-column row-gap-2 release__card ">
          <MemberContainer
            image={item?.projectDetails?.companyLogo}
            name={item?.projectDetails?.projectName}
            title={item?.projectDetails?.projectName}
            desc={
              item?.activeSprints?.sprintId ? item?.activeSprints?.sprintId : ""
            }
            padding="unset"
            titleFont="14px"
            descFont="12px"
          />
          <div
            className="flex justify-content-between align-items-center column-gap-4"
            style={{ height: "38px" }}
          >
            <div className="flex column-gap-2">
              <img src={Image?.documentImage} alt="file" />
              <span>{item?.sprints?.length ? item?.sprints?.length : 0}</span>
            </div>
            <AvatarGroup
              item={item.members}
              style={{ height: "25px", width: "25px" }}
            />
          </div>
          {/* ProgressBar */}
          <ProgressBar
            width={150}
            height={15}
            value={item.percentage}
            maxValue={100}
            color="#5a78ee"
          />
          {item?.leadInfo?.basic?.fullName ? (
            <div className="flex align-items-center justify-content-between">
              <div className="flex flex-column row-gap-2">
                <div className="flex align-items-center column-gap-1">
                  <img src={Image.userIcon} alt="user" />
                  <p style={{ fontSize: "10px" }}>Project Manager</p>
                </div>
              </div>
              <div className="flex align-items-center column-gap-1">
                {item?.leadInfo?.basic?.profilePic ? (
                  <img
                    width={20}
                    height={20}
                    style={{ borderRadius: "50%" }}
                    src={item?.leadInfo?.basic?.profilePic}
                    alt="team-lead"
                  />
                ) : (
                  <Alphabet
                    letter={fn_firstcharacter(item?.leadInfo?.basic?.fullName)}
                    width="20px"
                    height="20px"
                    fontSize="10px"
                  />
                )}
                <p style={{ color: "#252B30", fontSize: "10px" }}>
                  {item?.leadInfo?.basic?.fullName}
                </p>
              </div>
            </div>
          ) : <div style={{height:"20px"}}></div>}
        </div>
      </>
    );
  };

  const switchfn = (T) => {
    switch (T) {
      case "Release":
        return ReleaseCardComponet;
      case "Projects":
        return ProjectCardComponent;
      default:
        <p>Loading...</p>;
    }
  };

  return (
    <>
      <Carousel
        value={item}
        itemTemplate={switchfn(type)}
        numVisible={3}
        numScroll={3}
        responsiveOptions={responsiveOptions}
      />
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../store/features/Users/User";
import { Checkbox } from "primereact/checkbox";
import { useParams } from "react-router-dom";
import { postWithAuth, putWithAuth } from "../../../services/authentication";
import constants from "../../../constants";
import { Toast } from "primereact/toast";
import { getUsersByProject } from "../../../store/features/Projects/Project";
import Input from "../../common/inputtext/input-field";
import Alphabet from "../../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../../utility/utility";

export default function ProjectMembersManage({
  setVisibleRight,
  visibleRight,
}) {
  const { allUsers } = useSelector((store) => store.users);
  const { projectId } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { projectMemberDetails } = useSelector((store) => store.project);
  const [projectMembers, setProjectMembers] = useState([]);
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    dispatch(getAllUsers());
    setUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    let projectMembers = [];
    projectMemberDetails.forEach((members) => {
      projectMembers.push(members.memberDetails._id);
    });
    setProjectMembers(projectMembers);
  }, []);

  async function projectAssignHandler(e, id) {
    try {
      const postProject = { projectId, userId: id };
      const url = constants.API.PROJECT.ASSIGN_REASSIGN + id;
      if (e.checked === false) {
        const removeProjectUser = projectMembers.filter(
          (projectId) => projectId !== id
        );
        await putWithAuth(url, postProject, token);
        setProjectMembers(removeProjectUser);
      }
      if (e.checked === true) {
        setProjectMembers([...projectMembers, id]);
        await postWithAuth(url, postProject, token);
      }
      dispatch(getUsersByProject(projectId));
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.message,
        detail: "",
        life: 3000,
      });
    }
  }

  const searchFilterHandler = (value) => {
    if (value != "") {
      const regex = new RegExp(`${value}`, "i");
      const filterUser = allUsers.filter((user) =>
        regex.test(user.basic.fullName)
      );
      setUsers(filterUser);
    } else {
      setUsers(allUsers);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Sidebar
        style={{ width: "30%" }}
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h3>Add Members</h3>
        <div className="mt-4">
          <Input
            name="search"
            placeholder="Search"
            label="Search"
            onChange={(e) => searchFilterHandler(e.target.value)}
          />

          <div className="mt-3 members-list-table-border">
            {users.map((user, id) => {
              return (
                <div
                  key={id}
                  className="flex  align-items-center column-gap-3 p-3"
                >
                  <Checkbox
                    checked={projectMembers.includes(user._id)}
                    onChange={(e) => projectAssignHandler(e, user._id)}
                  />
                  {user?.basic?.profilePic ? (
                    <img
                      className="member-owner-image"
                      src={user?.basic?.profilePic}
                    />
                  ) : (
                    <Alphabet
                      borderRadius="20%"
                      letter={fn_firstcharacter(user.basic.fullName)}
                    />
                  )}
                  <p className="add-members-font">{user.basic.fullName}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Sidebar>
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import Button from "./../../component/common/button/button";
import { getWithAuth, postWithAuth } from "./../../services/authentication";
import constants from "./../../constants";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { isEmptyArray } from "../../utility/utility";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import Image from "../../utility/image";

export default function Email() {
  const [email, setEmail] = useState([]);
  const [emailDialog, setEmailDialog] = useState(false);
  const [CCBirthdayEmail, setCCBirthdayEmail] = useState([]);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getAllEmail();
    getOrgDetails();
  }, []);


  const getOrgDetails = async () => {
    try {
      let url = constants.API.MEMBER.GET_ORGANIZATIONS;
      url = url.replace("?", user.orgId);
      const response = await getWithAuth(url, token);
      let CCBirthdayEmail = response?.data?.CCBirthdayEmail;
      if (CCBirthdayEmail === undefined) {
        CCBirthdayEmail = [];
      }
      setCCBirthdayEmail(CCBirthdayEmail);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const getAllEmail = async () => {
    try {
      const url = constants.API.MEMBER.LIST;
      const response = await getWithAuth(url, token);
      const data = response.data;
      let emailArr = [];
      data?.members?.forEach((mem) => {
        emailArr.push(mem.email);
      });
      setEmail(emailArr);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const changeHandler = async (e, mail) => {
    try {
      if (e.checked === true) {
        let data = [...CCBirthdayEmail, mail];
        const url = constants.API.SETTINGS.BIRTHDAY_CCEMAIL;
        await postWithAuth(url, data, token);
        setCCBirthdayEmail([...CCBirthdayEmail, mail]);
      }
      if (e.checked === false) {
        let data = CCBirthdayEmail.filter((email) => {
          return email !== mail;
        });
        const url = constants.API.SETTINGS.BIRTHDAY_CCEMAIL;
        await postWithAuth(url, data, token);
        setCCBirthdayEmail(data);
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast />
      <div className="flex flex-column  row-gap-2">
        <div className="flex align-items-center justify-content-between  w-full">
          <div className="announcement-header-font">Birthday CC Mail</div>
          <div>
            <Button
              className="create-btn"
              label="Add  Email"
              onClick={() => setEmailDialog(!emailDialog)}
            />
          </div>
        </div>
        {!isEmptyArray(CCBirthdayEmail) ? (
          CCBirthdayEmail.map((email, id) => {
            return (
              <div
                className="flex column-gap-2 p-2 align-items-start"
                key={id}
              >
                <img src={Image.userIcon} alt="member" width={20} height={20} />
                <p>{email}</p>
              </div>
            );
          })
        ) : (
          <EmptyScreen img={Image.EmptyImg} description="No Email yet " />
        )}
      </div>

      <Dialog
        header="Add Email"
        visible={emailDialog}
        style={{ width: "40vw" }}
        onHide={() => {
          setEmailDialog(!emailDialog);
        }}
      >
        <>
          {email.map((mail, id) => {
            return (
              <div key={id} className="pt-2">
                <Checkbox
                  inputId={mail}
                  value={mail}
                  onChange={(e) => changeHandler(e, mail)}
                  checked={CCBirthdayEmail.includes(mail)}
                />
                &nbsp;&nbsp;
                <label
                  htmlFor={mail}
                  className="p-checkbox-label module-font mr-6"
                >
                  {mail}
                </label>
              </div>
            );
          })}
        </>
      </Dialog>
    </>
  );
}

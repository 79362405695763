import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputIcon from "../../component/common/inputIcon/inputIcon";
import CreateRelease from "../../component/project/projectRelease/createRelease";
import ReleaseCard from "../../component/project/projectRelease/releaseCard";
import addPlus from "../../assets/add-plus.svg";
import ReleaseTaskTable from "../../component/project/projectRelease/releaseTaskTable";
import {
  setSearch,
  getReleaseList,
} from "../../store/features/Projects/Release";
import { useParams } from "react-router-dom";

export default function ProjectRelease() {
  const dispatch = useDispatch();
  const [createReleaseDialog, setCreateReleaseDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectId } = useParams();
  
  useEffect(() => {
    dispatch(getReleaseList(projectId))
  }, [searchValue]);
 
  return (
    <div>
      <div className="mt-3">
        <div className="ml-5">
          <InputIcon
            placeholder="Search"
            onChange={(e) => {
              dispatch(setSearch(e.target.value));
              setSearchValue(e.target.value);
            }}
          ></InputIcon>
        </div>
      </div>

      <div className="flex mt-3 ml-5 release-scroll">
        <div
          className="create-release-btn"
          onClick={() => {
            setCreateReleaseDialog(!createReleaseDialog);
          }}
        >
          <div className="flex justify-content-center mt-7">
            <img src={addPlus} />
            <p className="create-release-font mt-1 ml-2">Create Next Release</p>
          </div>
        </div>

        <div className="ml-3">
          <ReleaseCard />
        </div>
      </div>

      {/* Release Task Table */}
      <div className="mt-3">
        <ReleaseTaskTable />
      </div>
      <CreateRelease
        createReleaseDialog={createReleaseDialog}
        setCreateReleaseDialog={setCreateReleaseDialog}
      />
    </div>
  );
}

import React from 'react'
import BacklogSprints from '../../component/project/projectBacklog/backlogSprints'
import ProjectBacklogTable from '../../component/project/projectBacklog/backlogTable'

export default function ProjectBacklog() {
  return (
    <div className='flex flex-column row-gap-3 align-items-center p-4 min-h-screen'>
      <BacklogSprints />
      <ProjectBacklogTable />
    </div>
  )
}

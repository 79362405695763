import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import Input from "../../common/inputtext/input-field";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import bigFolder from "../../../assets/big-folder.svg";
import Button from "../../common/button/button";
import {
  createFile,
  getFilesList,
  setProjectId,
} from "../../../store/features/Projects/File";

export default function CreateFolder({
  createFileDialog,
  setCreateFileDialog,
}) {
  const [createFolder, setCreateFolder] = useState({
    folderName: "",
    docs: [],
  });
  const { projectId: projectParamsId } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { memberDetails } = useSelector((store) => store.memberDetails);

  useEffect(() => {
    dispatch(setProjectId(projectParamsId));
  }, [projectParamsId]);

  const changeHandler = (e) => {
    setCreateFolder({ ...createFolder, [e.target.name]: e.target.value });
  };

  const submitHandler = () => {
    const data = {
      ...createFolder,
      projectId: projectId,
      createdBy: memberDetails.memberOrgId,
      updatedBy: memberDetails.memberOrgId,
      orgId: memberDetails.orgId,
    };
    dispatch(createFile(data)).then((res) => {
      setCreateFileDialog(!createFileDialog);
      if (!res.error)
        dispatch(getFilesList({ projectId: projectParamsId, search: "" }));
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Create Folder"
        visible={createFileDialog}
        style={{ width: "30vw" }}
        onHide={() => {
          setCreateFileDialog(!createFileDialog);
        }}
      >
        <div>
          <img className="mt-3" src={bigFolder} style={{ height: "190px" }} />
          <div className="create-folder-input">
            <Input
              className="asset-input"
              placeholder="Folder Name"
              name="folderName"
              label="Folder Name"
              type="text"
              onChange={changeHandler}
              required={true}
              value={createFolder.folderName}
            />
          </div>
          <div className="grid justify-content-end mt-6 mr-2">
            <Button
              label="Submit"
              onClick={submitHandler}
              disabled={createFolder.folderName === ""}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

import React, { lazy } from "react";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import PayrollDialog from "./payrollDialog";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getPayrollUserList,
  setFrom,
  setSearch,
  setSkip,
  setTo,
  setLimit,
} from "../../store/features/HumanResource/payslip.store";
import { useSelector } from "react-redux";
import { PaginationDropdown } from "../../utility/utility";
import { Dropdown } from "primereact/dropdown";
import InputIcon from "../../component/common/inputIcon/inputIcon";

export default function PayRoll() {
  const dispatch = useDispatch();
  const [createDialog, setCreateDialog] = useState(false);

  const { userList, skip, search, limit, from, to, maxRecord } = useSelector(
    (state) => state.paySlip
  );
  const [rowData, setRowData] = useState({});

  const items = [
    {
      label: "Payroll Setting",
      icon: "pi pi-user-plus",
      command: () => {
        setCreateDialog(true);
      },
    },
  ];
  const payroll_page = () => {
    const payload = {
      skip,
      limit,
      search,
    };
    dispatch(getPayrollUserList(payload));
  };

  useEffect(() => {
    payroll_page();
  }, [skip, limit, to, search]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      payroll_page();
      dispatch(setFrom(1));
      dispatch(setTo(10));
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [search]);

  const searchFn = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const ActionMenu = (data) => (
    <div>
      <Tooltip target=".action .p-speeddial-action" position="top" />

      <SpeedDial
        model={items}
        onClick={() => setRowData(data)}
        radius={50}
        direction="right"
        showIcon="pi pi-ellipsis-v"
        hideIcon="pi pi-times"
        className="action"
        buttonClassName="p-button-outlined action-btn-design "
      />
    </div>
  );

  const handlePrevious = (e) => {
    if (skip > 1) {
      dispatch(setSkip(skip - 1));
      dispatch(setFrom(from - limit));
      dispatch(setTo(to - limit));
    }
  };

  const handleNext = () => {
    if (maxRecord >= to) {
      dispatch(setSkip(skip + 1));
      dispatch(setFrom(from + limit));
      dispatch(setTo(to + limit));
    }
  };

  //pagination
  const paginate = {
    RowsPerPageDropdown: () => {
      const dropdownOptions = PaginationDropdown;
      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={limit}
            options={dropdownOptions}
            onChange={(e) => {
              dispatch(setLimit(e.value));
              dispatch(setSkip(1));
              dispatch(setFrom(1));
              dispatch(setTo(e.value));
            }}
          />
        </>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <>
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {/* {from} - {maxRecord >= to ? to : maxRecord} of {maxRecord} */}
            {from} - {to} of {maxRecord}
          </span>
          <button
            type="button"
            className={options.className}
            disabled={from === 1 ? true : false}
          >
            <span className="p-3" onClick={handlePrevious}>
              Previous
            </span>
          </button>
          <button type="button" className={options.className}>
            <span className="p-3" onClick={handleNext}>
              Next
            </span>
          </button>
        </>
      );
    },
  };
  const formatCurrency = (value) => {
    return value.toLocaleString(); 
  }
  const formatAmount = (e, field) => {
    const keys = field.split('.');

    for (const key of keys){
      if (e && e[key] !== undefined && e[key] !== null) {
        e = e[key]
      } else {
        return '-';
      }
    }
    return formatCurrency(e);
  };

  return (
    <>
      <div className="payroll_search mr-4">
        <InputIcon
          placeholder="Search"
          value={search}
          onChange={(e) => searchFn(e)}
        />
      </div>

      <div className="m-4 flex flex-column row-gap-4">
        <DataTable
          className="asset-table-font"
          value={userList}
          responsiveLayout="scroll"
          paginator
          paginatorTemplate={paginate}
          rows={limit}
          lazy
          paginatorClassName="justify-content-end custom-dropdown"
        >
          <Column field="basic.fullName" header="Name" />
          <Column field="work.employeeId" header="Employee ID" />
          <Column field="work.role" header="Role" />
          <Column field={(e) => formatAmount(e, "salary.CTC")} header="Basic Salary"/>
          <Column field={(e) => formatAmount(e, "salary.basicEPF")} header="EPF" />
          <Column field={(e) => formatAmount(e, "salary.variablePay")} header="Varible Pay" />
          <Column field="salary.accountName" header="Account Name" />
          <Column field="salary.accountNumber" header="Account Number" />
          <Column field="salary.ifscCode" header="IFSC Code" />
          <Column body={ActionMenu} header="Action" />
        </DataTable>
      </div>
      <PayrollDialog
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        rowData={rowData}
        payroll_page={payroll_page}
      />
    </>
  );
}

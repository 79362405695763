import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  taskItem: [],
  taskList: [],
  error: "",
  status: "",
  updateStatus: "",
  updateError: "",
  createTaskStatus: "",
  disableSortable: false,
  activeTask: {},
  subTasks: [],
  bugs: [],
  updatedSubTask: [],
  updatedBugTask: [],
  updatedAttachement: [],
  updatedLinks: [],
  updatedComments: [],
  selectedTask: {},
  sprintBacklogUpdate: false,
};

export const getTaskList = createAsyncThunk(
  "/getTaskList",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const url = constants.API.TASK.FETCH + "?projectId=" + projectId + "&skip=0";
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTaskListBySprint = createAsyncThunk(
  "/task-list",
  async ({ projectId, _id, name }, { rejectWithValue }) => {
    try {
      const url = constants.API.TASK.FETCH_TASK_BY_SPRINT
      const apiRoute = name === "Backlog" ? `${url}task?projectId=${projectId}&skip=0` : `${url}${_id}/task?projectId=${projectId}`
      const response = await api.get(apiRoute);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateTask = createAsyncThunk(
  "/update-task",
  async (data, { rejectWithValue }) => {
    try {
      if (data.assignee === null || data.assignee === undefined)  delete data.assignee;
      const url = constants.API.TASK.FETCH;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createTask = createAsyncThunk(
  "/task-create",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.TASK.FETCH;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

//sub task
export const createSubTask = createAsyncThunk(
  "/subtask-create",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SUB_TASK.CREATE;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getSubTask = createAsyncThunk(
  "/subtask-list",
  async (taskId, { rejectWithValue }) => {
    try {
      const url = constants.API.SUB_TASK.CREATE + "?taskId=" + taskId;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateSubTask = createAsyncThunk(
  "/subtask-update",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SUB_TASK.CREATE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteSubTask = createAsyncThunk(
  "/subtask-delete",
  async (subTaskId, { rejectWithValue }) => {
    try {
      const url = constants.API.SUB_TASK.CREATE + "?subTaskId=" + subTaskId;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

//sub task
export const createBugs = createAsyncThunk(
  "/bugs-create",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.BUG_TASK.CREATE;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getBugs = createAsyncThunk(
  "/bugs-list",
  async (taskId, { rejectWithValue }) => {
    try {
      const url = constants.API.BUG_TASK.CREATE + "?taskId=" + taskId;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateBugs = createAsyncThunk(
  "/bugs-update",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.BUG_TASK.CREATE;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteBugs = createAsyncThunk(
  "/bugs-delete",
  async (bugTaskId, { rejectWithValue }) => {
    try {
      const url = constants.API.BUG_TASK.CREATE + "?bugTaskId=" + bugTaskId;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    setDisableSortable: (state, action) => {
      state.disableSortable = action.payload;
    },
    setActiveTask: (state, action) => {
      state.activeTask = action.payload;
    },
    setActiveTask: (state, action) => {
      state.activeTask = action.payload;
    },
    resetCreateTaskStatus: (state, action) => {
      state.createTaskStatus = "";
    },
    //
    setUpdatedSubTask: (state, action) => {
      state.updatedSubTask = action.payload;
    },
    setUpdatedBugTask: (state, action) => {
      state.updatedBugTask = action.payload;
    },
    setUpdatedAttachment: (state, action) => {
      state.updatedAttachement = action.payload;
    },
    setUpdatedLink: (state, action) => {
      state.updatedLinks = action.payload;
    },
    setUpdatedComment: (state, action) => {
      state.updatedComments = action.payload;
    },
    resetUpdateStatus: (state, action) => {
      state.updateError = "";
      state.updateStatus = "";
    },
    setSprintBacklogUpdate: (state, action) => {
      state.sprintBacklogUpdate = action.payload;
    },
    setSelectedTask: (state, action) => {
      const { sprintId, _id } = action.payload;
      if (!(sprintId in state.selectedTask)) {
        let obj = { ...state.selectedTask };
        obj[sprintId] = { task: [_id] };
        state.selectedTask = { ...obj };
      } else {
        let obj = { ...state.selectedTask };
        if (obj[sprintId].task.includes(_id)) {
          obj[sprintId].task = obj[sprintId].task.filter((t) => t !== _id);
        } else {
          obj[sprintId].task = [...obj[sprintId].task, _id];
        }
        state.selectedTask = { ...obj };
      }
    },
    resetSelectedTask: (state, action) => {
      state.selectedTask = {};
    },
  },
  extraReducers: {
    // getTaskList Task item list
    [getTaskList.fulfilled]: (state, action) => {
      state.taskItem = action.payload;
    },
    [getTaskListBySprint.pending]: (state) => {
      state.isLoading = true;
    },
    [getTaskListBySprint.fulfilled]: (state, action) => {
      if (action?.payload?.backlogDetails) {
        state.taskList = action.payload.backlogDetails;
      } else {
        state.taskList = action.payload;
      }
    },
    [getTaskListBySprint.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [updateTask.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTask.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      state.isLoading = false;
    },
    [updateTask.rejected]: (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.payload.message;
      state.isLoading = false;
    },
    [createTask.pending]: (state) => {
      state.isLoading = true;
    },
    [createTask.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.createTaskStatus = "success";
    },
    [createTask.rejected]: (state, action) => {
      state.createTaskStatus = "error";
      state.isLoading = false;
    },
    [getSubTask.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubTask.fulfilled]: (state, action) => {
      state.subTasks = action.payload;
    },
    [getSubTask.rejected]: (state, action) => {},
    [getBugs.pending]: (state) => {
      state.isLoading = true;
    },
    [getBugs.fulfilled]: (state, action) => {
      state.bugs = action.payload;
    },
    [getBugs.rejected]: (state, action) => {},
    [createSubTask.fulfilled]: (state, action) => {
      let data = [...state.subTasks];
      data.push(action.payload.subTask);
      state.subTasks = data;
    },
    [createBugs.fulfilled]: (state, action) => {
      let data = [...state.bugs];
      data.push(action.payload.bugTask);
      state.bugs = data;
    },
    [deleteSubTask.fulfilled]: (state, action) => {
      let data = state.subTasks.filter((task) => {
        return task._id != action.meta.arg;
      });
      state.subTasks = data;
    },
    [deleteBugs.fulfilled]: (state, action) => {
      let data = state.bugs.filter((bugs) => {
        return bugs._id != action.meta.arg;
      });
      state.bugs = data;
    },
    [updateSubTask.fulfilled]: (state, action) => {
      let id = state.subTasks.findIndex((task) => {
        return task._id == action.payload.updatedTask._id;
      });
      state.subTasks[id] = {
        ...action.payload.updatedTask,
        assigneeDetails: action.payload.assigneeDetails,
      };
    },
    [updateBugs.fulfilled]: (state, action) => {
      let id = state.bugs.findIndex((bug) => {
        return bug._id == action.payload.updatedTask._id;
      });

      state.bugs[id] = {
        ...action.payload.updatedTask,
        assigneeDetails: action.payload.assigneeDetails,
      };
    },
  },
});

export const {
  resetStatus,
  setSelectedTask,
  resetSelectedTask,
  resetUpdateStatus,
  setSprintBacklogUpdate,
  setDisableSortable,
  setActiveTask,
  resetCreateTaskStatus,
  setUpdatedAttachment,
  setUpdatedBugTask,
  setUpdatedComment,
  setUpdatedLink,
  setUpdatedSubTask,
} = taskSlice.actions;

export default taskSlice.reducer;

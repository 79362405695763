import React from 'react'
import { InputText } from 'primereact/inputtext'

export default function InputIcon({value,onChange,placeholder,type,icon}) {
  return (
    <span className="p-input-icon-left">
    <i className="pi pi-search" />
    <InputText className='search-bar' value={value} onChange={onChange} placeholder={placeholder} type={type} />
</span>
  )
}

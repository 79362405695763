import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  email: "",
  isLoading: false,
  status: "",
  error: "",
  verificationCode: "",
  successMessage:'',
};

export const forgetPassword = createAsyncThunk(
  "auth/forget-Password",
  async (email, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.FORGOT_PASSWORD, {
        email: email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
    resetSuccessMessage:(state,action)=>{
      state.successMessage='';
    }
  },
  extraReducers: {
    [forgetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [forgetPassword.fulfilled]: (state, action) => {
      state.status = "success";
      state.successMessage=action.payload.user.message;
      state.email = action.meta.arg;
      state.verificationCode = state.isLoading = false;
    },
    [forgetPassword.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { resetStatus, setIsLoading,resetSuccessMessage } = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  allFiles: [],
  error: "",
  status: "",
  folderName: "",
  folderId: "",
  fileList: [],
  projectId: "",
  search: "",
};

//Releases
export const getFilesList = createAsyncThunk(
  "/files-list",
  async ({ projectId, search }, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FOLDER_LIST + `?projectId=${projectId}&search=${search}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getFileById = createAsyncThunk(
  "/file-list",
  async (id, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FOLDER_LIST_BY_ID + `?id=${id}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createFile = createAsyncThunk(
  "/create-file",
  async (data, {rejectWithValue}) => {
    try {
      const url =
        constants.API.PROJECT.FOLDER_CREATE + `?projectId=${data.projectId}`;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "/delete-file",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FOLDER_DELETE + "?id=" + data;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "/upload-file",
  async (data, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FILE_UPLOAD + "?id=" + data._id;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteDocs = createAsyncThunk(
  "/delete-docsfile",
  async ({ id, urlpath }, {rejectWithValue}) => {
    try {
      const url = constants.API.PROJECT.FOLDER_FILE_DOCSURL_DELETE + "?id=" + id;
      const response = await api.post(url, urlpath);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
    setFolderName: (state, action) => {
      state.folderName = action.payload;
    },
    setFolderId: (state, action) => {
      state.folderId = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
  },
  extraReducers: {
    //Releases
    [getFilesList.pending]: (state) => {
      state.isLoading = true;
    },
    [getFilesList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allFiles = [...action.payload.files];
    },
    [getFilesList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [getFileById.pending]: (state) => {
      state.isLoading = true;
    },
    [getFileById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.fileList = action.payload?.files[0]?.docs;
    },
    [getFileById.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [createFile.pending]: (state) => {},
    [createFile.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createFile.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [deleteFile.pending]: (state) => {},
    [deleteFile.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteFile.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
    [deleteDocs.pending]: (state) => {},
    [deleteDocs.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteDocs.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },

    [uploadFile.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [uploadFile.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [uploadFile.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
  },
});
export const {
  resetStatus,
  setFolderName,
  setFolderId,
  setSearch,
  setProjectId,
} = filesSlice.actions;
export default filesSlice.reducer;

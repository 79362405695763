import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/common/button/button";
import { otpVerify, resetStatus } from "../../store/features/Auth/otpVerify";
import { setIsLoading } from "../../store/features/Auth/forgetPassword";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgetPassword,
  resetSuccessMessage,
} from "../../store/features/Auth/forgetPassword";
import { resetStatus as resetForgotPasswordStatus } from "../../store/features/Auth/forgetPassword";

const styleSheet = {
  countDown: {
    color: "rgba(220, 58, 97, 1)",
    fontWeight: "400",
  },
  notReceived: {
    color: "rgba(0, 0, 0, 1)",
  },
  resend: {
    color: "rgba(105, 105, 105, 1)",
  },
};

export default function ForgotPasswordOTP() {
  const location = useLocation();
  const Navigate = useNavigate();
  const toast = useRef(null);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(59);
  const { email, isLoading, successMessage } = useSelector(
    (store) => store.forgetPassword
  );
  const { status, error } = useSelector((store) => store.otpVerify);

  const queryParams = new URLSearchParams(location.search);
  const queryEmail = queryParams.get("email");

  useEffect(() => {
    if (status === "error") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "",
        life: 3000,
      });
      dispatch(resetSuccessMessage());
    }
    if (status === "success") {
      Navigate("/auth/change-password");
    }
    dispatch(resetStatus());
  }, [error, status]);

  useEffect(() => {
    if (successMessage !== "") {
      toast.current.show({
        severity: "success",
        summary: successMessage,
        detail: "",
        life: 3000,
      });
    }
    dispatch(resetSuccessMessage());
    setSeconds(60);
  }, [successMessage]);

  useEffect(() => {
    if (otp.length < 6) {
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsLoading(false));
    }
  }, [otp]);
  const dispatch = useDispatch();
  function changeHandler(otpNumber) {
    setOtp(otpNumber);
  }

  function resetOtpHandler() {
    if (seconds === 0) {
      dispatch(forgetPassword(email));
      dispatch(resetForgotPasswordStatus());
    }
  }


  setTimeout(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    }
  }, 1000);

  function submitHandler() {
    const otpDetails = {
      email: queryEmail,
      verificationCode: parseInt(otp),
    };
    dispatch(otpVerify(otpDetails));
    dispatch(resetForgotPasswordStatus());
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-3 justify-content-center h-screen">
        <div className="h1-font">Verify Your Email Account</div>
        <div className="paragraph-font">
          Please enter the verification code we sent to your email
        </div>

        <OtpInput
          value={otp}
          onChange={changeHandler}
          numInputs={6}
          separator={<p style={{ padding: "10px" }} />}
          isInputNum={true}
          shouldAutoFocus={true}
          containerStyle={"otpContainer"}
          inputStyle={"otpInputs"}
          focusStyle={"otpInputsFocus"}
        />

        <div className="paragraph-font flex justify-content-between ">
          <div style={styleSheet.countDown} className="text-sm">
            {seconds / 10 > 1 ? (
              <span>00:{seconds}</span>
            ) : (
              <span>00:0{seconds}</span>
            )}
          </div>
          <div className="flex align-items-center column-gap-1">
            <p className="text-sm" style={styleSheet.notReceived}>
              Didn't receive code ?
            </p>
            <span
               className="text-sm cursor-pointer"
              style={seconds ? styleSheet.resend : styleSheet.countDown}
              onClick={() => resetOtpHandler()}
            >
              Resend
            </span>
          </div>
        </div>

        <div className="flex justify-content-end">
          <Button
            onClick={submitHandler}
            label="Submit"
            disabled={!isLoading}
          />
        </div>
      </div>
    </>
  );
}

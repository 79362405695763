import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  sprintList: [],
  allSprintList: [],
  sprintTaskCount: [],
  error: "",
  status: "",
};

export const getSprintList = createAsyncThunk(
  "/sprint-list",
  async (projectId, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.FETCH + "?projectId=" + projectId;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllSprintList = createAsyncThunk(
  "/allsprint-list",
  async (projectId, { rejectWithValue }) => {
    try {
      const url = constants.API.PROJECT.SPRINT_LIST + "?projectId=" + projectId;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSprintTaskCount = createAsyncThunk(
  "/sprint-taskcount",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.PROJECT.SPRINT_TASK_COUNT + "?sprintId=" + data;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createSprint = createAsyncThunk(
  "/create-sprint",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.FETCH;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateSprint = createAsyncThunk(
  "/update-sprint",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.FETCH;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteSprint = createAsyncThunk(
  "/delete-sprint",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.FETCH + "?sprintId=" + data;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const startSprint = createAsyncThunk(
  "modify/start-sprint",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.START;
      const response = await api.put(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const completeSprint = createAsyncThunk(
  "modify/complete-sprint",
  async (data, { rejectWithValue }) => {
    try {
      const url = constants.API.SPRINT.COMPLETE + "?sprintId=" + data;
      const response = await api.put(url, {});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const sprintSlice = createSlice({
  name: "sprint",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: {
    [getSprintList.pending]: (state) => {
      state.isLoading = true;
    },
    [getSprintList.fulfilled]: (state, action) => {
      state.sprintList = action.payload;
    },
    [getSprintList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [getAllSprintList.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllSprintList.fulfilled]: (state, action) => {
      state.allSprintList = action.payload;
    },
    [getAllSprintList.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [getSprintTaskCount.pending]: (state) => {
      state.isLoading = true;
    },
    [getSprintTaskCount.fulfilled]: (state, action) => {
      state.sprintTaskCount = action.payload;
    },
    [getSprintTaskCount.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [createSprint.pending]: (state) => {},
    [createSprint.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createSprint.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    [updateSprint.pending]: (state) => {
      state.updateUserStatus = "pending";
    },
    [updateSprint.fulfilled]: (state, action) => {
      state.updateUserStatus = "success";
    },
    [updateSprint.rejected]: (state, action) => {
      state.updateUserStatus = "error";
      state.updateUserError = action.payload;
    },
    [deleteSprint.pending]: (state) => {},
    [deleteSprint.fulfilled]: (state, action) => {
      state.deleteStatus = "success";
    },
    [deleteSprint.rejected]: (state, action) => {
      state.deleteStatus = "error";
      state.deleteError = action.payload;
    },
  },
});

export const { resetStatus } = sprintSlice.actions;

export default sprintSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";


const initialState = {
  getAllPage: [],
  subPageArray: [],
  subPageList: {},
  orgId: "",
  memberId: "",
  wikiExportUrl: "",
  useDetails: {},
};

export const wikiCreateSpaceApi = createAsyncThunk(
  "/createSpace",
  async (payload, {rejectWithValue}) => {
    try {
      const url = constants.API.WIKI.CREATE_PAGE;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllPageApi = createAsyncThunk(
  "/allPage/wiki",
  async ({ orgId, memberId }, {rejectWithValue}) => {
    try {
      const url =
        constants.API.WIKI.PARENT_PAGE + `?orgId=${orgId}&memberId=${memberId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSubpage = createAsyncThunk(
  "/getSubpage/wiki",
  async ({ orgId, selectedWikiPage, memberId }, {rejectWithValue}) => {
    try {
      const url = `${constants.API.WIKI.SUB}?orgId=${orgId}&pageId=${selectedWikiPage}&memberId=${memberId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSubpageById = createAsyncThunk(
  "/getSubpageById/wiki",
  async ({ orgId, contentId }, {rejectWithValue}) => {
    try {
      const url = `${constants.API.WIKI.PAGE}/${contentId}?orgId=${orgId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const exportWikiDocs = createAsyncThunk(
  "/exportWikiDocs",
  async ({ orgId, pageId, extension }, {rejectWithValue}) => {
    try {
      const url = `${constants.API.WIKI.EXPORT}?orgId=${orgId}&pageId=${pageId}&extension=${extension}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removePage = createAsyncThunk(
  "/removePage/wiki",
  async ({ orgId, pageId }, {rejectWithValue}) => {
    try {
      const url = `${constants.API.WIKI.CREATE_PAGE}?orgId=${orgId}&pageId=${pageId}`;
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editWikipage = createAsyncThunk(
  "/editWiki",
  async (payload, {rejectWithValue}) => {
    try {
      const url = constants.API.WIKI.CREATE_PAGE;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const wikiSlice = createSlice({
  name: "wiki",
  initialState,
  reducers: {
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setMemberId: (state, action) => {
      state.memberId = action.payload;
    },
    resetWikiExportUrl: (state) => {
      state.wikiExportUrl = "";
    },
    setUserDetails: (state, { payload }) => {
      state.useDetails = payload;
    },
    setLoader:(state,action) => {
      state.initialLoader = action.payload
    }
  },

  extraReducers: {
    [getAllPageApi.fulfilled]: (state, { payload }) => {
      state.getAllPage = payload.pages;
    },
    [getSubpage.fulfilled]: (state, action) => {
      state.subPageArray = action.payload.pages;
    },
    [getSubpageById.fulfilled]: (state, { payload }) => {
      state.subPageList = payload;
    },
    [exportWikiDocs.fulfilled]: (state, { payload }) => {
      state.wikiExportUrl = payload;
    },
  },
});

export const { setOrgId, resetWikiExportUrl, setMemberId, setUserDetails , setLoader } =
  wikiSlice.actions;

export default wikiSlice.reducer;

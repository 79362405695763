import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import { Toast } from "primereact/toast";
import { SpeedDial } from "primereact/speeddial";
import {
  getTaskByRelease,
  deleteTask,
  getReleaseList,
} from "../../../store/features/Projects/Release";
import { ChipBtn } from "../../common/chip/chip";
import { fn_color, fn_firstcharacter } from "../../../utility/utility";
import Alphabet from "../../common/alphabet/alphabet";
import Image from "../../../utility/image";

export default function ReleaseTaskTable() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { allReleaseTasks, selectedRelease } = useSelector(
    (store) => store.release
  );
  const { allUsers } = useSelector((store) => store.users);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});

  useEffect(() => {
    if (projectId && selectedRelease && selectedRelease._id) {
      dispatch(
        getTaskByRelease({ projectId: projectId, releaseId: selectedRelease._id })
      );
    }
  }, [projectId || '', selectedRelease, dispatch]);
  
  

  function priorityHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.documentImage} style={{ height: "16px" }} />
        <div className="flex">&nbsp;Priority</div>
      </div>
    );
  }

  function taskHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.documentImage} style={{ height: "16px" }} />
        <div className="flex">&nbsp;Tasks</div>
      </div>
    );
  }

  function memberHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.userIcon} style={{ height: "16px" }} />
        <div className="flex">&nbsp;Members</div>
      </div>
    );
  }

  function progressHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.chartIcon} style={{ height: "16px" }} />
        <div className="flex">&nbsp;Progress</div>
      </div>
    );
  }
  function dateHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.dateIcon} style={{ height: "16px" }} />
        <div className="flex">&nbsp;Date</div>
      </div>
    );
  }

  function task(task) {
    return <div>{task.title}</div>;
  }
  
  function members(member) {
    return (
      <div>
        {allUsers?.map((name) => {
          if (name?._id == member?.assignee) {
            return <img
              className="projects-img-size flex justify-content-center"
              src={name?.basic?.profilePic}
            ></img> ? (
              <Alphabet letter={fn_firstcharacter(name?.basic?.fullName)}/>
            ) : (
              <></>
            );
          }
        })}
      </div>
    );
  }
  const progress = (progress) => {
    return (
      <div
        style={{
          color: fn_color(progress?.status),
          fontWeight: "600",
          fontSize: "14px",
        }}
      >
        {progress?.status}
      </div>
    );
  };

  function startDate(date) {
    return new Date(date.createdAt).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
      weekday: "short",
    });
  }

  let items = [
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const Action = (data) => {
    return (
      <div>
        <SpeedDial
          model={items}
          radius={50}
          direction="left"
          showIcon="pi pi-ellipsis-v"
          hideIcon="pi pi-times"
          buttonClassName="p-button-outlined action-btn-design"
          onClick={() => {
            setUpdateItem(data);
          }}
        />
      </div>
    );
  };

  const deleteHandler = async () => {
    try {
      let data = updateItem._id;
      dispatch(deleteTask(data)).then((res) => {
        if (!res.error) {
          dispatch(getReleaseList(projectId));
          dispatch(
            getTaskByRelease({
              projectId: projectId,
              releaseId: updateItem.releaseId,
            })
          );
        }
      });
      setDeleteDialog(!deleteDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <DeleteDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        onClick={deleteHandler}
      ></DeleteDialog>
      <div className="table-box mt-4">
        <div className="asset-table-size">
          <DataTable
            className="asset-table-font"
            sortMode="multiple"
            value={allReleaseTasks}
            responsiveLayout="scroll"
          >
            <Column
              body={(data) => <ChipBtn label={data?.priority} />}
              header={priorityHeader}
            ></Column>
            <Column field={task} header={taskHeader}></Column>
            <Column field={members} header={memberHeader}></Column>
            <Column field={progress} header={progressHeader}></Column>
            <Column body={startDate} header={dateHeader}></Column>
            <Column  body={Action} headerClassName="release-tableaction" header="Action"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}

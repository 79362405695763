import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjectDetailsById } from "../../store/features/Projects/Project";
import {
  fn_firstcharacter,
  projectLink,
  PROJECT_MODULE,
} from "../../utility/utility";
import Alphabet from "../../component/common/alphabet/alphabet";

const ProjectEmbedRoute = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState("Overview");
  const { projectId } = useSelector((store) => store.project);
  const { projectDetails } = useSelector((store) => store.project);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetailsById(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    const projectActiveTabPath = location.pathname.split("/");
    if (PROJECT_MODULE.tab.includes(projectActiveTabPath[3]))
      setActive(projectActiveTabPath[3]);
    else setActive(PROJECT_MODULE.tab[0]);
  }, []);

  const clickHandler = ({ target }) => {
    setActive(target.innerHTML);
    projectLink(projectId, target.innerHTML, Navigate);
  };

  return (
    <div className="flex column-gap-4  mx-4">
      <div
        className="flex justify-content-center align-items-center column-gap-2"
        style={{ height: "50px" }}
      >
        {projectDetails?.projectDetails?.companyLogo ? (
          <img
            className="project-overview-header-round"
            src={projectDetails?.projectDetails?.companyLogo}
          />
        ) : (
          <Alphabet
            letter={fn_firstcharacter(
              projectDetails?.projectDetails?.projectName
            )}
          />
        )}
        <div className="text-900 font-semibold text-2xl">
          {projectDetails?.projectDetails?.projectName}
        </div>
      </div>
      <div className="flex p-3  column-gap-4" id="project">
        {PROJECT_MODULE.project_tab.map((item, id) => {
          return (
            <div
              key={id}
              onClick={clickHandler}
              style={{ cursor: "pointer" }}
              className={
                active === item.tab_name
                  ? "project__tab__active pb-3"
                  : "project__tab"
              }
            >
              {item.tab_name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectEmbedRoute;

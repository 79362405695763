import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../component/common/button/button";
import InputIcon from "../../component/common/inputIcon/inputIcon";
import ProjectListTable from "../../component/project/projectListTable";
import {
  getAllProjects,
  setSearch,
  getProjectList,
  setProjectStatus,
  setSkip,
  setProjectId,
} from "../../store/features/Projects/Project";
import CreateProject from "../../component/project/createProject";
import { InputSwitch } from "primereact/inputswitch";
import { isAdminAccess, isEmpty } from "../../utility/utility";
import Spinner from "../../component/common/spinner/spinner";
import { useParams } from "react-router-dom";

export default function Project() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [createDialog, setCreateDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectStatus, search, allprojects, loading , to , from } = useSelector(
    (store) => store.project
  );
  const [checked, setChecked] = useState(true);
  const projectAdmin = isAdminAccess("Project");

  useEffect(() => {
    dispatch(getProjectList());
  }, []);

  useEffect(() => {
    dispatch(getAllProjects());
  }, [projectStatus,to,from]);



  useEffect(() => {
    if (projectId) dispatch(setProjectId(projectId));
  }, [projectId]);

  useEffect(() => {
    dispatch(setProjectStatus("Active"));
  }, []);



  function changeProjectStatus() {
    setChecked(!checked);
    if (!checked) dispatch(setProjectStatus("Active"));
    else dispatch(setProjectStatus("Deactive"));
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(setSkip(1));
      dispatch(getAllProjects());
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [search]);

  const searchFn = (e) => {
    dispatch(setSearch(e.target.value));
    setSearchValue(e.target.value);
  };

  return (
    <>
      {createDialog && (
        <CreateProject
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}
        />
      )}

      <div className="flex mx-4 align-items-center justify-content-between">
        <div className="col-9">
          <InputIcon
            placeholder="Search"
            onChange={(e) => searchFn(e)}
            value={searchValue}
          />
        </div>
        <>
          {projectAdmin && (
            <div className="flex column-gap-2  align-items-center">
              <p className="announcement-time">
                {checked ? "Inactive" : "Active"}
              </p>
              <InputSwitch
                onChange={() => changeProjectStatus()}
                checked={checked}
              />
              <Button
                className="create-btn"
                label="Create"
                onClick={() => {
                  setCreateDialog(!createDialog);
                }}
              />
            </div>
          )}
        </>
      </div>

      {loading ? (
        <div
          className="flex align-items-center justify-content-center  "
          style={{ height: "300px" }}
        >
          <Spinner value={loading} />
        </div>
      ) : (
        <ProjectListTable />
      )}
    </>
  );
}

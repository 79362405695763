import React, { useRef, useState } from "react";
import { Password } from "primereact/password";
import Button from "../../component/common/button/button";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { useNavigate, useParams } from "react-router-dom";
import { userSignupApi } from "../../store/features/Auth/login";
import validate from "../../factory-function/validate";

export default function UserSignup() {
  const toast = useRef(null);
  const { orgId, userId } = useParams();
  const [loader, setLoader] = useState(false);
  const [userSignup, setUserSignup] = useState({
    password: "",
    confirmPassword: "",
    orgId,
    userId,
    errorPasswordMessage : ""
  });

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const handleSubmit = () => {
    setLoader(true);
    dispatch(userSignupApi(userSignup)).then((res) => {
      setLoader(false);
      if (!res.error) {
        const email = encodeURIComponent(res.payload?.email);
        Navigate(
          `/auth/verify-otp?email=${email}&orgId=${res.payload?.orgId}`
        );
      } else {
        toast.current.show({
          severity: "error",
          summary: res?.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <div className="h-screen flex align-items-center">
      <Toast ref={toast} />
      <div className="overall-conatiner w-full flex flex-column row-gap-4">
        <div className="flex flex-column row-gap-2">
          <div className="h1-font">User Sign Up</div>
          <div className="paragraph-font">
            Fill the form to set your password
          </div>
        </div>

        <div className="">
          <Password
            placeholder="Password"
            name="password"
            onChange={(e) => {
              setUserSignup((prev) => {
                return { ...prev, password: e.target.value , errorPasswordMessage : validate({"password" : e.target.value}) };
              });
            }}
            label="Password"
            toggleMask
          />
          <span className="p-float-label password-text error-text">{userSignup.errorPasswordMessage}</span>
        </div>
        <div className="">
          <Password
            placeholder="Confirm Password"
            name="confirmPassword"
            onChange={(e) => {
              setUserSignup((prev) => {
                return { ...prev, confirmPassword: e.target.value };
              });
            }}
            label="Confirm Password"
            toggleMask
            feedback={false}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />
          <span className="p-float-label password-text error-text"></span>
        </div>

        <div className=" flex justify-content-end">
          <Button
            onClick={handleSubmit}
            label="Submit"
            loading={loader}
            disabled={
              userSignup.password === "" ||
              userSignup.confirmPassword !== userSignup.password
            }
          />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState,useRef } from 'react';
import { Dialog } from 'primereact/dialog'
import Dropdown from './../../common/dropdown/dropdown'
import React from 'react'
import Button from './../../common/button/button';
import { useSelector } from 'react-redux';
import constants from '../../../constants';
import { putWithAuth } from '../../../services/authentication';
import { getSprintList } from '../../../store/features/Projects/Sprint';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import UpdateTaskDialog from '../tasks/updateTaskDialog';

export default function TaskMoveDialog({taskMoveDialog,setTaskMoveDialog,taskList,update,setUpdate,sprint}) {
     
    const toast=useRef(null);
    const { sprintList } = useSelector(store => store.sprint);
    const {selectedTask}=useSelector(store=>store.task);
    const [sprintDropdown,setSprintDropdown]=useState([]);
    const [updateTaskDialog,setUpdateTaskDialog]=useState(false);
    const dispatch=useDispatch();
    const {projectId}=useParams();



    const taskMoveHandler=async()=>{
        try{
         //get selected task
           let taskListId;
           taskList=taskList ==undefined ? selectedTask[sprint]?.task : taskList;
           if(taskList[0]._id){
            taskListId=taskList.map(task=>task._id);
           }
           else{
            taskListId=taskList
           }
           const URL=constants.API.PROJECT_SETTINGS.UPDATE_TASK;
           const token=localStorage.getItem("token");
           if(sprintId=="Backlog"){
            await putWithAuth(URL,{sprint:sprintId,taskList:taskListId,isBacklog:true},token);
           }
           else{
            await putWithAuth(URL,{sprint:sprintId,taskList:taskListId,isBacklog:false},token);
           }
           setTaskMoveDialog(!taskMoveDialog);
           dispatch(getSprintList(projectId));
           if(update !==undefined){
            setUpdate(!update);
           }
        }
        catch(err){
            toast.current.show({
                severity: "error",
                summary: err.message,
                detail: "",
                life: 3000,
              });
        }
    }

    useEffect(()=>{
        let dropDown=[];
         if(sprintList.length > 0){
           dropDown=sprintList.map(sprint=>{return {label:sprint.title,value:sprint._id}});
           dropDown.push({label:"Backlog",value:"Backlog"});
           setSprintDropdown(dropDown);
         }
         else{
            setSprintDropdown([])
         }
    },[sprintList])
    const [sprintId,setSprintId]=useState("");
  return (
    <div>
        <Toast ref={toast}></Toast>
        <Dialog
          header="Move To Sprint"
          visible={taskMoveDialog}
          style={{ width: "35vw" }}
          onHide={() => { setTaskMoveDialog(!taskMoveDialog); }}
        >
            <div>
                <Dropdown
                options={sprintDropdown}
                placeholder="Sprint"
                style={{ width: "50vw" }}
                value={sprintId}
                onChange={(e) =>
                  setSprintId(e.value)
                }
              />
            </div>
            <div className='flex justify-content-end ml-3 mt-5'>
                <Button label="Move" onClick={taskMoveHandler} taskLisk={taskList}></Button>
            </div>
        </Dialog>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import SelectPlan from "../selectPlan";
import { ExistingCustomer } from "./existingUser";
import Login from "../login";
import CheckoutForm from "../payment/checkoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import Steps from "../../../component/common/steps/steps";
import { loadStripe } from "@stripe/stripe-js";
// import { setActiveIndexExistingStep } from "../../../store/features/Auth/login";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KET);

export const ExistingAccountSteps = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { getActiveIndexExistingStep } = useSelector((store) => store.user);

  useEffect(() => {
    setActiveIndex(getActiveIndexExistingStep);
  }, [getActiveIndexExistingStep]);

  

  const stepSwitch = () => {
    switch (activeIndex) {
      case 0:
        return <ExistingCustomer />;
      case 1:
        return <SelectPlan isExisting />;
      case 2:
        return (
          <Elements stripe={stripePromise}>
            <CheckoutForm  isExisting />
          </Elements>
        );

      default:
        return <Login />;
    }
  };

  return (
    <div className="flex flex-column row-gap-4  justify-content-start">
      <Steps
        model={["Existing", "Select Plan", "Payment"]}
        activeIndex={activeIndex}
      />
      {stepSwitch()}
    </div>
  );
};

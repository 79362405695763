import React from "react";
import "./CircleProgressBar.scss";

const CircleProgressBar = ({ progress, imageUrl , oc , ic }) => {
  const radius = 21;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className="circle-progress-container">
      <svg className="circle-progress" width="50" height="50">
        <circle
          className="circle-progress-background"
          cx="25"
          cy="25"
          r={25}
          fill={oc}
        />
        <circle
          className="circle-progress-bar"
          stroke={ic}
          cx="25"
          cy="25"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        
        <circle
          cx="25"
          cy="25"
          r="12"
          fill="#FAFBFF"
          className="circle-progress-inner"
        />
        <image
          className="circle-progress-image"
          href={imageUrl}
          x="5"
          y="5"
          width="40"
          height="40"
        />
      </svg>
    </div>
  );
};

export default CircleProgressBar;

import React, { useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  addScratchpadImage,
  updateScratchpad,
  resetImageUploadStatus,
  removeScratchpadImage,
  setRemoveImageStatus,
} from "../../store/features/Scratchpad/scratchpad";
import { Toast } from "primereact/toast";
import ProfileUpload from "../common/file-upload/fileUpload";

export default function AddScratchpadImage({
  addImageDialog,
  setAddImageDialog,
}) {
  const dispatch = useDispatch();
  const { selectedScratchpad, imageURL, addImageStatus, removeImageStatus } =
    useSelector((store) => store.scratchpad);
  const toast = useRef(null);

  useEffect(() => {
    if (addImageStatus == "success" && imageURL != "") {
      const img = selectedScratchpad.note + `<img src="${imageURL}"/>`;
      let data = { ...selectedScratchpad, note: img };
      data.id = data._id;
      data.image = imageURL;
      dispatch(updateScratchpad(data));
      setAddImageDialog(!addImageDialog);
      dispatch(resetImageUploadStatus());
    }
  }, [imageURL, addImageStatus]);

  useEffect(() => {
    if (removeImageStatus == "success") {
      let data = { ...selectedScratchpad };
      data.id = data._id;
      data.note = data.note.replace(
        /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
        ""
      );
      data.image = "";
      dispatch(updateScratchpad(data));
      setAddImageDialog(false);
      dispatch(setRemoveImageStatus());
    }
  }, [removeImageStatus]);

  const uploadHandler = async (e) => {
    try {
      // setLoading(true);
      const fileData = new FormData();
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", async () => {
            fileData.append("file", files[0]);
            await dispatch(
              addScratchpadImage({ id: selectedScratchpad._id, data: fileData })
            );
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };
  async function imageRemoveHandler(e) {
    dispatch(removeScratchpadImage(selectedScratchpad._id));
  }

  return (
    <div>
      <Toast ref={toast}></Toast>
      <Dialog
        header="Add & Remove Image"
        visible={addImageDialog}
        style={{ width: "40vw" }}
        onHide={() => setAddImageDialog(!addImageDialog)}
      >
        <ProfileUpload
          remove={(e) => imageRemoveHandler(e)}
          uploadHandler={uploadHandler}
          profileImg={selectedScratchpad.image}
          labelName="Upload Image"
        />
      </Dialog>
    </div>
  );
}

export default function formatDate(dateValue){
    const date=new Date(dateValue);
    let dateStr=`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    return dateStr;
}

 export function getDates(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(parseInt(new Date(dt).getDate()));
    }
    return arr;
};
import React from "react";
import Image from "../../../utility/image";
import "./createWiki.scss";

export default function CreateWiki({isCheck}) {
  const handleCreateSpace = () => {
    isCheck(true)
  };

  return (
    <>
      <div
        className="flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div className="flex flex-column row-gap-2 justify-content-center align-items-center">
          <div>
            <img className="" src={Image.emptyWiki} alt="Empty Wiki" />
          </div>
          <h2>Create a Wiki</h2>
          <div className="flex flex-column row-gap-1 justify-content-center align-items-center">
            <p>Keep your Important Notes (or) Informations </p>
            <p>here and you can share at anytime.</p>
          </div>
          <div>
            <span className="cursor-pointer" onClick={handleCreateSpace} style={{color:"#6366F1",fontWeight:"bold"}}>+ Create Space</span>
          </div>
        </div>
      </div>
    </>
  );
}

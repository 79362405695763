import React, { useEffect, useState } from "react";
import Button from "../../component/common/button/button";
import CreateUser from "../../component/users/createUser";
import UserTable from "../../component/users/userTable";
import { useSelector, useDispatch } from "react-redux";
import InputIcon from "../../component/common/inputIcon/inputIcon";
import { getRoles } from "../../store/features/Users/role";
import {
  getUsers,
  setFrom,
  setSearch,
  setSkip,
  setUserStatus,
  setTo,
} from "../../store/features/Users/User";
import { getDepartment } from "../../store/features/Users/department";
import { InputSwitch } from "primereact/inputswitch";
import { isAdminAccess } from "../../utility/utility";

export default function Users() {
  const dispatch = useDispatch();
  const usersAdmin = isAdminAccess("Users");
  const { memberDetails } = useSelector((store) => store.memberDetails);
  const { skip, search, limit, from, to, userStatus, maxRecord } = useSelector(
    (store) => store.users
  );
  const [createDialog, setCreateDialog] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    dispatch(getDepartment());
    return () => {
      dispatch(setUserStatus("Active"));
      dispatch(setSkip(1));
      dispatch(setTo(10));
    };
  }, []);

  useEffect(() => {
    dispatch(getUsers({ skip, limit, search, userStatus: userStatus }));
  }, [skip, limit, to, userStatus]);

  const handleProjectToggle = (checked) => {
    setChecked(!checked);
    const status = checked ? "InActive" : "Active";
    dispatch(setUserStatus(status));
    dispatch(getUsers({ skip: 1, limit, search, userStatus: status }));
    dispatch(setSkip(1));
    dispatch(setFrom(1));
    dispatch(setTo(10));
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getUsers({ skip: 1, limit, search, userStatus: userStatus }));
      dispatch(setFrom(1));
      dispatch(setTo(10));
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [search]);

  const searchFn = (e) => {
    dispatch(setSearch(e.target.value));
  };

  return (
    <>
      {createDialog && (
        <CreateUser
          createDialog={createDialog}
          setCreateDialog={setCreateDialog}
        />
      )}
      <div className="flex">
        <div className="col-9 ml-3">
          <InputIcon
            placeholder="Search"
            value={search}
            onChange={(e) => searchFn(e)}
          />
        </div>
        <div className="mt-3">
          {usersAdmin && (
            <div className="flex ml-7">
              <div className="flex align-content-center mr-2 mt-1">
                <p className="mr-2 announcement-time">
                  {checked ? "Inactive" : "Active"}
                </p>
                <InputSwitch
                  name="name"
                  checked={checked}
                  onChange={() => handleProjectToggle(checked)}
                />
              </div>
              <Button
                label="Add"
                onClick={() => {
                  setCreateDialog(!createDialog);
                  dispatch(getRoles(memberDetails?.orgId));
                }}
              />
            </div>
          )}
        </div>
      </div>
      <UserTable />
    </>
  );
}

import extractContent from "../factory-function/extractTextFromHTML";
import moment from "moment";
import txt from "../assets/filetypeicon/txt-icon.svg";
import jpg from "../assets/filetypeicon/jpg-icon.svg";
import docx from "../assets/filetypeicon/doc-icon.svg";
import pdf from "../assets/filetypeicon/pdf-icon.svg";
import ppt from "../assets/filetypeicon/ppt-icon.svg";
import xlsx from "../assets/filetypeicon/xls-icon.svg";
import zip from "../assets/filetypeicon/zip-icon.svg";
import Image from "./image";

const FILE_SYSTEM = { txt, jpg, docx, pdf, ppt, xlsx, zip };
export default FILE_SYSTEM;

export const getHeader = () => {
  const token = localStorage.getItem("token");
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return header;
};

export const isEmpty = (value) => {
  return value !== "" && value !== null && value !== undefined;
};

export const responsiveOptions = [
  {
    breakpoint: "1024px",
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: "600px",
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: "480px",
    numVisible: 1,
    numScroll: 1,
  },
];

export const getFileExtension = (url) => {
  return `.${url?.split(".")?.pop()}`;
};

export const getFileName = (urlName) => {
  const name = `${urlName?.split("/").pop().split("_").shift()}`;
  return name;
};

export const fn_exe = (url) => {
  switch (getFileExtension(url)) {
    case ".txt":
      return FILE_SYSTEM.txt;
    case ".jpeg":
      return FILE_SYSTEM.jpg;
    case ".png":
      return FILE_SYSTEM.jpg;
    case ".PNG":
      return FILE_SYSTEM.jpg;
    case ".jpg":
      return FILE_SYSTEM.jpg;
    case ".pdf":
      return FILE_SYSTEM.pdf;
    case ".docx":
      return FILE_SYSTEM.docx;
    case ".xlsx":
      return FILE_SYSTEM.xlsx;
    case ".zip":
      return FILE_SYSTEM.zip;
    case ".ppt":
      return FILE_SYSTEM.ppt;
    default:
      break;
  }
};

// Example : 25 Jan 2023
export const DateFormat = (data) => {
  if (data !== (null || undefined)) {
    return new Date(data).toLocaleDateString("en-uk", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
  return "invalid format";
};

// 30 Jan 2023, 5:58 pm
export const DateTimeFormat = (data) => {
  return new Date(data).toLocaleTimeString("en-uk", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
export const WDateFormat = (data) => {
  return new Date(data).toLocaleDateString("en-uk", {
    month: "short",
    day: "numeric",
    year: "numeric",
    weekday: "short",
  });
};
export const Fn_DD_MM = (data) => {
  const date = new Date(data).getDate();
  const month = new Date(data).getMonth();
  return `${date} ${month}`;
};

export const Fn_scratchpad_note = (n, mode = true) => {
  const _note = extractContent(n, mode);
  if (_note?.length > 80) return _note.substring(0, 80) + "....";
  return _note;
};

export const Fn_notification = (n, mode = true) => {
  const _note = extractContent(n, mode);
  if (_note?.length > 30) return _note.substring(0, 30) + "....";
  return _note;
};

export const first_Chart = (e) => {
  return e?.charAt(0).toUpperCase();
};

// 19 April 2021, 20:39 PM

export const dataFormat_long = (e) => {
  const data = moment(e).format("DD MMMM YYYY, h:mm a");
  return data;
};

export const dataFormat_short = (e) => {
  const data = moment(e).format("DD MMM").toUpperCase();
  return data;
};

export const fn_firstcharacter = (e) => {
  return e?.charAt(0)?.toUpperCase();
};

export const replaceDotMethod = (data, maxLength) => {
  return data?.length > maxLength ? data?.slice(0, maxLength) + "..." : data;
};
export const fn_color = (c) => {
  switch (c) {
    case "Inprogress":
      return "#F27E11";
    case "To Do":
      return "#969696";
    case "Review":
      return "#9428FF";
    case "Done":
      return "#28B425";
    default:
      return "#fffff";
  }
};

export const taskType_placeholder = (p) => {
  switch (p) {
    case "Task":
      return "What is task about";
    case "Bug":
      return "What is bug about";
    case "Story":
      return "What is story about";
    default:
      return "What is task about";
  }
};

export const navLink = (target, Navigate) => {
  switch (target) {
    case "Dashboard":
      Navigate("/");
      break;
    case "Human Resources":
      Navigate("/hr");
      break;
    case "Timesheet":
      Navigate("/timesheet");
      break;
    case "Settings":
      Navigate("/settings/role");
      break;
    case "Project":
      Navigate("/project");
      break;
    case "Projects":
      Navigate("/project");
      break;
    case "Wiki":
      Navigate("/wiki");
      break;
    case "Scratchpad":
      Navigate("/scratchpad");
      break;
    case "Users":
      Navigate("/users");
      break;
    default:
      Navigate("/");
      break;
  }
};

export const HrNavLink = (target, Navigate) => {
  switch (target.tab_name) {
    case "All":
      Navigate(target.path);
      break;
    case "Assets":
      Navigate(target.path);
      break;
    case "Leave Request":
      Navigate(target.path);
      break;
    case "Holiday Calendar":
      Navigate(target.path);
      break;
    case "Recognition":
      Navigate(target.path);
      break;
    case "Announcement":
      Navigate(target.path);
      break;
    case "PaySlip":
      Navigate(target.path);
      break;
    case "PayRoll":
      Navigate(target.path);
      break;
    case "Attendance":
      Navigate(target.path);
      break;
    default:
      Navigate("/hr");
      break;
  }
};

export const projectLink = (projectId, target, Navigate) => {
  switch (target.toLowerCase()) {
    case "overview":
      Navigate(`/project/${projectId}`);
      break;
    case "tasks":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "release":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "members":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "timesheet":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "backlog":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "files":
      Navigate(`/project/${projectId}/${target}`);
      break;
    case "settings":
      Navigate(`/project/${projectId}/settings`);
      break;
    default:
      Navigate(`/login`);
      break;
  }
};

export const WIKI_CHECKBOX_SPACE_ARRAY = [
  {
    id: 1,
    label: "Public",
    ischecked: false,
  },
  {
    id: 2,
    label: "Private",
    ischecked: true,
  },
];

export const PageOption = [
  { id: 1, label: "Add" },
  { id: 2, label: "Delete" },
  { id: 3, label: "Privacy Settings" },
];

export const SubOption = [
  { id: 1, label: "Delete Page" },
  { id: 2, label: "Export" },
  { id: 3, label: "Change Parent Page" },
];

export const HR_MODULE = {
  hr_tab: [
    {
      id: 1,
      tab_name: "All",
      path: "/hr",
    },
    {
      id: 2,
      tab_name: "Announcement",
      path: "/hr/announcement",
    },
    {
      id: 3,
      tab_name: "Assets",
      path: "/hr/assets",
    },
    {
      id: 4,
      tab_name: "Leave Request",
      path: "/hr/leave-request",
    },
    {
      id: 5,
      tab_name: "Holiday Calendar",
      path: "/hr/holiday-calendar",
    },
    {
      id: 5,
      tab_name: "Recognition",
      path: "/hr/recognition",
    },
    {
      id: 6,
      tab_name: "PaySlip",
      path: "/hr/payslip",
    },
    {
      id: 7,
      tab_name: "PayRoll",
      path: "/hr/payroll",
    },
    {
      id: 8,
      tab_name: "Attendance",
      path: "/hr/attendance",
    },
  ],
};

export const HR_MODULE_USER = {
  hr_tab: [
    {
      id: 1,
      tab_name: "All",
      path: "/hr",
    },
    {
      id: 2,
      tab_name: "Announcement",
      path: "/hr/announcement",
    },
    {
      id: 3,
      tab_name: "Assets",
      path: "/hr/assets",
    },
    {
      id: 4,
      tab_name: "Leave Request",
      path: "/hr/leave-request",
    },
    {
      id: 5,
      tab_name: "Holiday Calendar",
      path: "/hr/holiday-calendar",
    },
    {
      id: 5,
      tab_name: "Recognition",
      path: "/hr/recognition",
    },
    {
      id: 6,
      tab_name: "PaySlip",
      path: "/hr/payslip",
    },
    {
      id: 7,
      tab_name: "Attendance",
      path: "/hr/attendance",
    },
  ],
};

export const PROJECT_MODULE = {
  tab: [
    "Overview",
    "Tasks",
    "Release",
    "Members",
    "Backlog",
    "Timesheet",
    "Files",
    "Settings",
  ],
  project_tab: [
    {
      id: 1,
      tab_name: "Overview",
    },
    {
      id: 2,
      tab_name: "Tasks",
    },
    {
      id: 3,
      tab_name: "Release",
    },
    {
      id: 4,
      tab_name: "Members",
    },
    {
      id: 5,
      tab_name: "Backlog",
    },
    {
      id: 5,
      tab_name: "Timesheet",
    },
    {
      id: 5,
      tab_name: "Files",
    },
    {
      id: 5,
      tab_name: "Settings",
    },
  ],
};

export const isEmptyArray = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};
export const isEmptyObject = (obj) => {
  if (typeof obj === "object" && Object.keys(obj).length === 0) return true;
  return false;
};

export const getCurrentDaysMonth = () => {
  const now = new Date();
  const numDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  const daysArray = Array.from({ length: numDays }, (_, i) => i + 1);
  return daysArray;
};

export const dashboard__coordinates = {
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: "#495057",
      },
      grid: {
        display: true,
        borderDash: [5, 5],
        borderDashOffset: 2,
        borderWidth: 2,
      },
    },
  },
};

export const dashboard__dataSet = {
  labels: [0, 0, 0, 0, 0, 0, 0],
  datasets: [
    {
      label: "0 Hrs 0 Mins",
      data: [0, 0, 0, 0, 0, 0, 0],
      fill: true,
      borderColor: "#FF006D",
      borderWidth: 2,
      tension: 0.4,
      backgroundColor: "rgba(255, 0, 109, 0.1)",
      pointRadius: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#FFFFFF",
      pointHoverBorderColor: "#FF006D",
      pointHoverBorderWidth: 2,
      pointHitRadius: 10,
    },
  ],
};

// annual
// 1-5 = 50 inr  , 1$
// 6 - all = 100 inr , 2$
// montly
// 1-5 = 75 , 1 inr , 0.5$
// 6 - all = 150 inr, 3$

export const selectPlanMode = {
  mode: [
    {
      id: 1,
      plan: "Monthly",
      subscription_amount: 150,
    },
    {
      id: 2,
      plan: "Annually",
      subscription_amount: 100,
    },
  ],
  plan: {
    Monthly: 150,
    Annually: 100,
  },
};

export const planWithFiveMembers = {
  mode: [
    {
      id: 1,
      plan: "Monthly",
      subscription_amount: 75,
    },
    {
      id: 2,
      plan: "Annually",
      subscription_amount: 50,
    },
  ],
  plan: {
    Monthly: 75,
    Annually: 50,
  },
};

export const weekDropdown = [
  { label: "1 Week", value: "1 Week" },
  { label: "2 Weeks", value: "2 Weeks" },
  { label: "3 Weeks", value: "3 Weeks" },
  { label: "4 Weeks", value: "4 Weeks" },
  { label: "Custom", value: "Custom" },
];

export const getZeroHM = () => {
  let date = new Date();
  date.setHours(8);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const PaginationDropdown = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
];

export const statusDropdown = [
  { label: "UnAssigned", value: "UnAssigned" },
  { label: "Retired", value: "Retired" },
];
export const editDropDown = [
  { label: "UnAssigned", value: "UnAssigned" },
  { label: "Retired", value: "Retired" },
  { label: "Occupied", value: "Occupied" },
  { label: "Requested", value: "Requested" },
];

export const overlay = [
  {
    icon: Image.assign_user,
    name: "Assign",
  },
  {
    icon: Image.maintanance,
    name: "Move to Maintenance",
  },
  {
    icon: Image.maintanance_history,
    name: "Maintenance History",
  },
  // {
  //   icon: Image.delete_asset,
  //   name: "Delete Asset",
  // },
  { icon: Image.print, 
    name: "Print Asset Tag",
  },
];

export const moveToMaintennance = [
  {
    icon: Image.maintanance,
    name: "Move to Maintenance",
  },
  {
    icon: Image.maintanance_history,
    name: "Maintenance History",
  },
];

export const maintenanceHistoryArray = [
  {
    icon: Image.maintanance_history,
    name: "Maintenance History",
  },
];

export const approveRejectOverlay = [
  {
    icon: Image.approve,
    name: "Approve",
  },
  {
    icon: Image.reject,
    name: "Reject",
  },
  {
    icon: Image.maintanance_history,
    name: "Maintenance History",
  },  
];

export const SettingTab = ["Roles & Permission", "Email", "Billing"];

export const isAdminAccess = (moduleName) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const moduleAccess = userData.modulePermission?.filter(
    (modules) => modules.moduleName === moduleName
  );
  if (!isEmptyArray(moduleAccess) && moduleAccess[0].permission === "Admin")
    return true;
  return false;
};
export const superAdmin = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  if (userData.role === "SuperAdmin")
    return true;
  return false;
};

export const selectPlanExpiryDate = (selectedPlan) => {
  let result;
  const today = new Date();
  if (selectedPlan === "Monthly") {
    const nextMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const nextMonthString = dateFormatter.format(nextMonth);
    result = new Date(nextMonthString).toISOString();
  } else {
    const nextYear = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    );

    function formatDate(date) {
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate().toString();
      const year = date.getFullYear().toString();
      return day + " " + month + " " + year;
    }
    result = new Date(formatDate(nextYear)).toISOString();
  }

  return result;
};

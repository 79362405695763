import React, { useEffect, useState, useRef } from "react";
import InputText from "../../common/inputtext/input-field";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import Button from "../../common/button/button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setDisableSortable,
  updateTask,
  resetUpdateStatus,
} from "../../../store/features/Projects/Task";
import { getUsersByProject } from "../../../store/features/Projects/Project";
import TaskComments from "./taskComments";
import TaskAddLink from "./taskAddLink";
import TaskFileUpload from "./TaskFileUpload";
import { getSprintList } from "../../../store/features/Projects/Sprint";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { isEmptyObject } from "../../../utility/utility";

export default function UpdateTaskDialog({
  taskDialog,
  setUpdateTaskDialog,
  getBacklogData,
}) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { projectId } = useParams();
  const { projectMemberDetails } = useSelector((store) => store.project);
  const { activeTask, updateStatus, updateError } = useSelector(
    (store) => store.task
  );

  const { sprintList } = useSelector((store) => store.sprint);
  const [updateValue, setUpdateValue] = useState({});
  const [assigneeOptions, setAssigneeOptions] = useState([]);

  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);

  useEffect(() => {
    if (!isEmptyObject(activeTask)) {
      if (Object.hasOwn(activeTask, "assignee") && activeTask.assignee !== null)
        setUpdateValue(() => {
          return {
            ...activeTask,
            assigneeDetails: activeTask.assignee,
            assignee: activeTask.assignee._id,
          };
        });
      else setUpdateValue(activeTask);
    }
  }, [activeTask, sprintList]);

  useEffect(() => {
    if (updateStatus == "error" && updateError != "") {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: updateError,
        life: 3000,
      });
    }
    dispatch(resetUpdateStatus());
  }, [updateStatus]);

  useEffect(() => {
    if (!isEmptyObject(projectMemberDetails)) {
      const assignee = projectMemberDetails.map((user) => {
        return {
          label: user?.memberDetails?.basic?.fullName,
          value: user?.userId,
        };
      });
      setAssigneeOptions(assignee);
    }
  }, [projectMemberDetails]);

  const submitHandler = () => {
    const payload = { ...updateValue };
    if (payload.assignee === null || payload.assignee === undefined)
      delete payload.assignee;
    dispatch(updateTask(payload)).then((res) => {
      if (!res.error) {
        if (getBacklogData) getBacklogData();
      }
    });

    //update dialog is also used in other component.
    if (setUpdateTaskDialog === undefined) dispatch(setDisableSortable(false));
    else if (setUpdateTaskDialog.toString() == "task/setSprintBacklogUpdate")
      dispatch(setUpdateTaskDialog(false));
    else setUpdateTaskDialog(false);
  };

  const handleChange = (title, value) => {
    setUpdateValue((prev) => {
      return { ...prev, [`${title}`]: value };
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Edit Task"
        visible={taskDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          if (setUpdateTaskDialog === undefined) {
            dispatch(setDisableSortable(false));
          } else {
            if (
              setUpdateTaskDialog.toString() === "task/setSprintBacklogUpdate"
            )
              dispatch(setUpdateTaskDialog(false));
            else setUpdateTaskDialog(false);
          }
          dispatch(resetUpdateStatus());
          dispatch(getSprintList(projectId));
        }}
      >
        <div className="flex justify-content-around">
          <div>
            <div className="grid">
              <div className=" col-6">
                <span className="label-font">Name</span>
                <InputText
                  placeholder="Name"
                  value={updateValue.title}
                  onChange={({ target }) => handleChange("title", target.value)}
                />
              </div>
              <div className="col-6">
                <span className="label-font">Story Points</span>
                <InputText
                  placeholder="Story Points"
                  value={updateValue?.storyPoint ? updateValue?.storyPoint : ""}
                  onChange={({ target }) =>
                    handleChange("storyPoint", target.value)
                  }
                />
              </div>
            </div>

            <div className="grid">
              <div className="col-6">
                <span className="label-font">Type</span>
                <Dropdown
                  options={["Bug", "Task", "Story"]}
                  placeholder="Type"
                  value={updateValue.type}
                  onChange={(e) => handleChange("type", e.value)}
                />
              </div>
              <div className="col-6">
                <span className="label-font">Priority</span>
                <Dropdown
                  options={["Low", "Medium", "High"]}
                  placeholder="Priority"
                  value={updateValue.priority}
                  onChange={(e) => handleChange("priority", e.value)}
                />
              </div>
            </div>

            <div className="grid">
              <div className="col-6">
                <span className="label-font">Start-Date</span>
                <Calendar
                  placeholder="Start Date"
                  value={new Date(updateValue.startDate)}
                  onChange={(e) => handleChange("startDate", new Date(e.value))}
                />
              </div>

              <div className="col-6">
                <span className="label-font">End-Date</span>
                <Calendar
                  placeholder="End Date"
                  value={new Date(updateValue.endDate)}
                  onChange={(e) => handleChange("endDate", new Date(e.value))}
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="label-font">Description</div>
              <Editor
                className="editor-border"
                style={{ height: "180px" }}
                value={updateValue.description}
                onTextChange={(e) => handleChange("description", e.htmlValue)}
              />
            </div>

            <div className="grid  mt-2">
              <span className="label-font">Assignee</span>
              <Dropdown
                options={assigneeOptions}
                value={updateValue.assignee}
                onChange={(e) => handleChange("assignee", e.value)}
              />
            </div>

            <div className="mt-3">
              <span className="label-font">Attachment from Computer</span>
              <TaskFileUpload
                setUpdateValue={setUpdateValue}
                attachments={updateValue.attachments}
              />
            </div>

            <div className="mt-3">
              <TaskAddLink
                updateItem={updateValue}
                setUpdateValue={setUpdateValue}
                links={updateValue.links}
              />
            </div>

            <div className="mt-3">
              <h4>Comments</h4>
              <TaskComments
                setUpdateValue={setUpdateValue}
                comments={updateValue.comments}
              />
            </div>

            <div className="flex justify-content-end ml-3 mt-4">
              <Button
                label="Submit"
                onClick={submitHandler}
                disabled={updateValue.title === ""}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import blue from "../../assets/blue-round.svg";
import pink from "../../assets/pink-round.svg";
import celebration from "../../assets/celebration.svg";
import { useSelector, useDispatch } from "react-redux";
import getFirstAndLastDayOfMonth from "../../factory-function/getFirstAndLastDayOfMonth";
import {
  getMonthlyTimesheet,
  getHolidays,
} from "../../store/features/Timesheet/timesheet";
import { getDates } from "../../factory-function/formatDate";
import "./dashboardCalendar.scss";

export default function DashboardCalender() {
  const dispatch = useDispatch();
  const [viewDate, setViewDate] = useState(new Date());
  const [holidayDate, setholidayDate] = useState([]);
  const { holidays, monthlyTimesheet } = useSelector(
    (store) => store.timesheet
  );
  const [highlightDate, setHighlightDate] = useState([]);
  const [nonFilledTimesheetDate, setNonFilledTimesheetDates] = useState([]);

  useEffect(() => {
    const fromAndToDate = getFirstAndLastDayOfMonth(viewDate);
    dispatch(getMonthlyTimesheet(fromAndToDate));
    const date = getFirstAndLastDayOfMonth(viewDate);
    dispatch(getHolidays(date));
  }, [viewDate]);

  useEffect(() => {
    if (monthlyTimesheet) {
      const currentDate = new Date();
      let dates = [];
      let nonTimesheetDate = [];
      const currentMonth =
        viewDate.getFullYear() === currentDate.getFullYear() &&
        viewDate.getMonth() === currentDate.getMonth()
          ? true
          : false;
      monthlyTimesheet.forEach((timesheet, i) => {
        let timesheetDate = new Date(
          viewDate.getFullYear(),
          viewDate.getMonth(),
          i + 1
        );
        if (timesheet.totalHrs !== 0 || timesheet.totalMins !== 0) {
          dates.push(timesheetDate);
        } else {
          if (timesheetDate.getDay() !== 6 && timesheetDate.getDay() !== 0) {
            if (currentMonth) {
              if (i + 1 < currentDate.getDate()) {
                nonTimesheetDate.push(i + 1 + "");
              }
            } else {
              nonTimesheetDate.push(i + 1 + "");
            }
          }
        }
      });
      if (nonTimesheetDate.length > 0) {
        nonTimesheetDate[nonTimesheetDate.length - 1] = `${
          nonTimesheetDate[nonTimesheetDate.length - 1]
        }th ${viewDate.toLocaleDateString("en-us", {
          month: "long",
        })} ${viewDate.getFullYear()}`;
      }
      setHighlightDate(dates);
      setNonFilledTimesheetDates(nonTimesheetDate);
    }
  }, [monthlyTimesheet]);

  useEffect(() => {
    if (holidays.length > 0) {
      let dateArr = [];
      holidays.forEach((holiday) => {
        if (holiday.from !== holiday.to) {
          let val = getDates(holiday.from, holiday.to);
          dateArr.push(...val);
        } else {
          dateArr.push(parseInt(new Date(holiday.from).getDate()));
        }
      });
      setholidayDate(dateArr);
    }
  }, [holidays]);

  const dateTemplate = (date) => {
    if (holidayDate.includes(date.day)) {
      return (
        <div className="holiday-highlight-calendar dashboard-holiday-round">
          {date.day}
        </div>
      );
    }

    return date.day;
  };

  return (
    <>
      <div className="dashboard-profile-calendar flex flex-column row-gap-2 align-items-center justify-content-center p-2">
        <div className="flex align-items-center justify-content-center column-gap-2">
          <img src={celebration} alt="celebration" />
          <p>Upcoming Holidays</p>
        </div>

        {holidays.length > 0 ? (
          <div className="holidays-list">
            {holidays.map((data, id) => {
              const fromDate = new Date(data.from);
              const toDate = new Date(data.to);
              const currentDate = new Date();

              if (toDate > currentDate) {
                return (
                  <li key={id}>
                    {fromDate.toDateString()} - {data.title}
                  </li>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <p>No holidays this month</p>
        )}
      </div>

      <div className="dashboard-calendar-wrap">
        <Calendar
          inline
          value={highlightDate}
          viewDate={viewDate}
          onViewDateChange={(e) => setViewDate(e.value)}
          selectionMode="multiple"
          disabledDays={[0, 6]}
          maxDate={new Date()}
          dateTemplate={dateTemplate}
        />
      </div>

      <div className="status-label-font flex  align-items-center justify-content-center column-gap-4  py-4">
        <div className="flex align-items-center column-gap-2">
          <img src={blue} alt="blue" />
          &nbsp; Due date
        </div>

        <div className="flex align-items-center column-gap-2">
          <img src={pink} alt="pink" />
          &nbsp; Timesheet Updated
        </div>
      </div>
    </>
  );
}

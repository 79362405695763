import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";

const initialState = {
  email: {},
  isLoading: true,
  status: "",
  error: "",
  resendOtpStatus: "",
  resendOtpError: "",
  getActiveIndex: 0,
  getSignUpdetails: {},
  selectplanPayload: {},
};

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (name, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.SIGNUP, {
        email: name.email,
        password: name.password,
        fullName: name.fullName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const signUpResendOtp = createAsyncThunk(
  "auth/signup-resendotp",
  async (email, {rejectWithValue}) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.SIGNUP_RESEND, {
        email: email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);


export const selectPlanApi = createAsyncThunk(
  "auth/SelectPlanApi",
  async (payload, rejectWithValue) => {
    try {
      const response = await api.post(constants.API.AUTHENTICATION.PLAN_TYPE, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    resetSignUpStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    resetSignUpData: (state, action) => {
      state.error = "";
      state.status = "";
      state.isLoading = false;
      state.email = {};
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
    resetOtpStatus: (state, action) => {
      state.resendOtpError = "";
      state.resendOtpStatus = "";
    },
    editActiveIndex: (state, action) => {
      state.getActiveIndex = action.payload;
    },
    setSignUpDetails: (state, { payload }) => {
      state.getSignUpdetails = payload;
    },
    setSelectPlanPayload: (state, action) => {
      state.selectplanPayload = action.payload;
    },
  },
  extraReducers: {
    [signUp.pending]: (state) => {
      state.isLoading = true;
    },
    [signUp.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.email = action.payload?.user?.email;
    },
    [signUp.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = true;
      state.error = action.payload;
    },
    [signUpResendOtp.pending]: (state) => {
      state.isLoading = true;
    },
    [signUpResendOtp.fulfilled]: (state, action) => {
      state.resendOtpStatus = "OTP sent successfully";
    },
    [signUpResendOtp.rejected]: (state, action) => {
      state.resendOtpError = action.payload;
    },
  },
});

export const {
  resetSignUpStatus,
  resetSignUpData,
  setIsLoading,
  resetOtpStatus,
  editActiveIndex,
  setSignUpDetails,
  setSelectPlanPayload,
} = signUpSlice.actions;

export default signUpSlice.reducer;

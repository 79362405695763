import React, { useState, useEffect, useRef } from "react";
import UpdateTaskDialog from "../tasks/updateTaskDialog";
import CreateSprint from "../tasks/createSprint";
import Button from "../../common/button/button";
import { getSprintList } from "../../../store/features/Projects/Sprint";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TaskCard from "./taskCard";
import TaskMoveDialog from "../../project/projectBacklog/taskMoveDialog";
import { deleteSprint } from "./../../../store/features/Projects/Sprint";
import EditSprint from "../../project/tasks/editSprint";
import DeleteDialog from "../../common/confirm-delete-dialog/deleteDialog";
import { SpeedDial } from "primereact/speeddial";
import ModifySprintModal from "./modify-sprint/modifySprintModal";
import { ChipLowBtn } from "../../common/chip/chip";
import Image from "../../../utility/image";
import { Tooltip } from "primereact/tooltip";
import "./backlogSprint.scss";
import TaskCreateInput from "../taskCreateInput";
import { Badge } from "primereact/badge";

function CollapsibleDiv({ sprint, hasActiveSprint }) {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [collapsed, setCollapsed] = useState(true);
  const [editSprintDialog, setEditSprintDialog] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [sprintStart, setSprintStart] = useState(false);
  const { selectedTask } = useSelector((store) => store.task);
  const [update, setUpdate] = useState([]);
  const [taskMoveDialog, setTaskMoveDialog] = useState(false);
  const [isExpandInput, setisExpandInput] = useState(false);
  const {
    resetSelectedTask,
  } = require("../../../store/features/Projects/Task");

  //useEffect to reset after move functionality
  useEffect(() => {
    setTaskMoveDialog(false);
    dispatch(resetSelectedTask());
    dispatch(getSprintList(projectId));
  }, [update]);

  const items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        setEditSprintDialog(!editSprintDialog);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setDeleteDialog(!deleteDialog);
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      let data = updateItem._id;
      await dispatch(deleteSprint(data));
      dispatch(getSprintList(projectId));
      setDeleteDialog(!deleteDialog);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const handleSprintStart = () => {
    setSprintStart(!sprintStart);
  };

  const fnchip = (s) => {
    return s === "Active" ? "Active" : "Completed";
  };

  const handleAddTask = () => {
    setCollapsed(false);
    setisExpandInput(!isExpandInput);
  };

  return (
    <>
      <Tooltip target="._Sprint" />
      {editSprintDialog && (
        <EditSprint
          editSprintDialog={editSprintDialog}
          setEditSprintDialog={setEditSprintDialog}
          selectedItem={updateItem}
          setSelectedItem={setUpdateItem}
        />
      )}

      {deleteDialog && (
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          onClick={deleteHandler}
        />
      )}

      {sprintStart && (
        <ModifySprintModal
          item={sprint}
          visible={sprintStart}
          onHide={setSprintStart}
        />
      )}

      {taskMoveDialog && (
        <TaskMoveDialog
          taskMoveDialog={taskMoveDialog}
          setTaskMoveDialog={setTaskMoveDialog}
          sprint={sprint._id}
          update={update}
          setUpdate={setUpdate}
        />
      )}

      <div className="accodion flex p-2 align-items-center justify-content-between bg-white border-round-sm">
        <div className="flex align-items-center column-gap-2">
          <img
            src={collapsed ? Image.B_leftArrow : Image.B_downArrow}
            height={30}
            width={30}
            onClick={() => setCollapsed(!collapsed)}
          />
          <div className="flex align-items-center column-gap-2">
            <p className="text-sm">{sprint.title}</p>
            <Badge value={sprint?.taskDetails?.length} severity="danger" />
          </div>
          {sprint.status === "Active" && (
            <div className="flex align-items-center">
              <ChipLowBtn
                label={fnchip(sprint?.status)}
                className="chip__high__btn chip"
              />
            </div>
          )}

          {sprint.status === "Completed" && (
            <div className="flex align-items-center">
              <ChipLowBtn
                label={fnchip(sprint?.status)}
                className="chip__high__btn chip"
              />
            </div>
          )}
        </div>

        <div className="flex align-items-center column-gap-4">
          {sprint.status !== "Completed" && (
            <>
              <i
                className="pi pi-plus _Sprint cursor-pointer"
                style={{ fontSize: "1rem" }}
                data-pr-tooltip="Add Task here"
                onClick={handleAddTask}
              />

              <Button
                label={<i className="pi pi-power-off" />}
                onClick={handleSprintStart}
                disabled={hasActiveSprint || sprint?.taskDetails?.length === 0}
              />
            </>
          )}

          <SpeedDial
            model={items}
            radius={50}
            direction="right"
            showIcon="pi pi-ellipsis-v"
            hideIcon="pi pi-times"
            buttonClassName="p-button-outlined action-btn-design"
            onClick={() => {
              setUpdateItem(sprint);
            }}
          />
        </div>
      </div>

      <div style={{ display: collapsed && "none" }}>
        {/* Sprint Move To Task */}
        <div className="flex flex-column p-3">
          {selectedTask[sprint._id] &&
            selectedTask[sprint._id]?.task?.length > 0 && (
              <div className="flex justify-content-end align-items-center p-2">
                <div
                  className="flex  column-gap-2 align-items-center backlog__movetask__btn"
                  onClick={() => setTaskMoveDialog(!taskMoveDialog)}
                >
                  <span>{"Move To"}</span>
                  <i className="pi pi-angle-down sprint__move__to" />
                </div>
              </div>
            )}
          {/* sprint.taskDetails.length */}
          {isExpandInput && (
            <TaskCreateInput sprintID={sprint?._id} onHide={setisExpandInput} />
          )}
          <div className="backlog-div flex flex-column row-gap-2 ">
            {sprint.taskDetails && sprint.taskDetails.length > 0 ? (
              <>
                {sprint.taskDetails.map((task) => (
                  <TaskCard task={task} sprint={sprint} />
                ))}
              </>
            ) : (
              <span className="text-base">{"No Tasks Found"}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function BacklogSprints() {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [mainCollapsible, setMainCollapsible] = useState(true);
  const [addSprintDialog, setAddSprintDialog] = useState(false);
  const [sprintBacklogUpdate, setSprintBacklogUpdate] = useState(false);
  const [allSprint, setAllSprint] = useState([]);
  const [hasActiveSprint, setHasActiveSprint] = useState(false);
  const { sprintList } = useSelector((store) => store.sprint);
  const { updateStatus } = useSelector((store) => store.task);

  useEffect(() => {
    if(projectId) dispatch(getSprintList(projectId));
  }, [updateStatus === "success"]);

  useEffect(() => {
    let sprints = [...sprintList];
    const activeSprintIndex = sprintList.findIndex(
      (sprint) => sprint.status === "Active"
    );

    if (activeSprintIndex !== -1) {
      setHasActiveSprint(true);
      let temp = sprints[0];
      sprints[0] = sprints[activeSprintIndex];
      sprints[activeSprintIndex] = temp;
    }

    let upcommingSprints = sprints.filter(
      (sprint) => sprint.status === "Completed"
    );

    sprints = sprints.filter((sprint) => sprint.status !== "Completed");
    sprints.push(...upcommingSprints);
    setAllSprint(sprints);
  }, [sprintList]);

  return (
    <div className="Backlog w-full">
      {/* CreateSprint Dialog */}

      {addSprintDialog && (
        <CreateSprint
          addSprintDialog={addSprintDialog}
          setAddSprintDialog={setAddSprintDialog}
        />
      )}

      {/* Edit sprint dialog */}
      {sprintBacklogUpdate && (
        <UpdateTaskDialog
          taskDialog={sprintBacklogUpdate}
          setUpdateTaskDialog={setSprintBacklogUpdate}
        />
      )}

      {/* Upcomming Sprints */}
      {
        <div className="p-2 sprint-container  flex align-items-center justify-content-between">
          <div className="flex align-items-center column-gap-2">
            <img
              className="cursor-pointer"
              src={mainCollapsible ? Image.B_leftArrow : Image.B_downArrow}
              width={30}
              height={30}
              onClick={() => setMainCollapsible(!mainCollapsible)}
            />
            <div className="sprint-header-font ">Sprints</div>
          </div>
          <Button
            label="Create"
            onClick={() => setAddSprintDialog(!addSprintDialog)}
          />
        </div>
      }

      <div
        className="collapsible-div sprintOverley"
        style={{ display: mainCollapsible && "none" }}
      >
        {allSprint &&
          allSprint.map((sprint, id) => {
            return (
              <CollapsibleDiv
                sprint={sprint}
                key={id}
                hasActiveSprint={hasActiveSprint}
              />
            );
          })}
        {allSprint.length === 0 && <p className="text-center">No Sprint Available </p>}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import date from "../../assets/white-date.svg";
import "./timesheetCalendar.scss";
import { useSelector, useDispatch } from "react-redux";
import getFirstAndLastDayOfMonth from "../../factory-function/getFirstAndLastDayOfMonth";
import {
  getMonthlyTimesheet,
  getHolidays,
} from "../../store/features/Timesheet/timesheet";
import { getDates } from "../../factory-function/formatDate";
import Image from "../../utility/image";
import { Divider } from "primereact/divider";

export default function Calender() {
  const dispatch = useDispatch();
  const [viewDate, setViewDate] = useState(new Date());
  const [holidayDate, setholidayDate] = useState([]);
  const { holidays, monthlyTimesheet } = useSelector(
    (store) => store.timesheet
  );
  const [highlightDate, setHighlightDate] = useState([]);
  const [nonFilledTimesheetDate, setNonFilledTimesheetDates] = useState([]);

  useEffect(() => {
    const fromAndToDate = getFirstAndLastDayOfMonth(viewDate);
    dispatch(getMonthlyTimesheet(fromAndToDate));
    const date = getFirstAndLastDayOfMonth(viewDate);
    dispatch(getHolidays(date));
  }, [viewDate]);

  useEffect(() => {
    if (monthlyTimesheet) {
      const currentDate = new Date();
      let dates = [];
      let nonTimesheetDate = [];
      const currentMonth =
        viewDate.getFullYear() === currentDate.getFullYear() &&
        viewDate.getMonth() === currentDate.getMonth()
          ? true
          : false;
      monthlyTimesheet.forEach((timesheet, i) => {
        let timesheetDate = new Date(
          viewDate.getFullYear(),
          viewDate.getMonth(),
          i + 1
        );

        if (timesheet.totalHrs !== 0 || timesheet.totalMins !== 0)
          dates.push(timesheetDate);
        else {
          if (timesheetDate.getDay() !== 6 && timesheetDate.getDay() !== 0) {
            if (currentMonth) {
              if (i + 1 < currentDate.getDate())
                nonTimesheetDate.push(i + 1 + "");
            } else nonTimesheetDate.push(i + 1 + "");
          }
        }
      });

      if (nonTimesheetDate.length > 0) {
        nonTimesheetDate[nonTimesheetDate.length - 1] = `${
          nonTimesheetDate[nonTimesheetDate.length - 1]
        } ${viewDate.toLocaleDateString("en-us", {
          month: "long",
        })} ${viewDate.getFullYear()}`;
      }
      setHighlightDate(dates);
      setNonFilledTimesheetDates(nonTimesheetDate);
    }
  }, [monthlyTimesheet]);

  useEffect(() => {
    if (holidays.length > 0) {
      let dateArr = [];
      holidays.forEach((holiday) => {
        if (holiday.from !== holiday.to) {
          let val = getDates(holiday.from, holiday.to);
          dateArr.push(...val);
        } else {
          const data = {
            month: parseInt(new Date(holiday.from).getMonth() + 1),
            date: parseInt(new Date(holiday.from).getDate()),
          };
          dateArr.push(data);
        }
      });
      setholidayDate(dateArr);
    }
  }, [holidays]);

  const dateTemplate = (date) => {
    let isHoliday = false;
  
    // Check if the date is a holiday
    holidayDate.forEach((holiday) => {
      if (holiday.month === date.month + 1 && holiday.date === date.day) {
        isHoliday = true;
      }
    });
  
    if (isHoliday) {
      return (
        <div className="holiday-highlight-calendar">
          {date.day}
        </div>
      );
    } else {
      return date.day;
    }
  };

  const calenderNote = [
    {
      title: "Due date",
      icon: Image.blueDot,
    },
    {
      title: "Timesheet Updated",
      icon: Image.pinkDot,
    },
  ];

  return (
    <div className="flex flex-column row-gap-4 align-items-center mb-4">
      <div className="calendar-wrap w-full">
        <Calendar
          inline
          value={highlightDate}
          viewDate={viewDate}
          onViewDateChange={(e) => setViewDate(e.value)}
          selectionMode="multiple"
          disabledDays={[0, 6]}
          maxDate={new Date()}
          dateTemplate={dateTemplate}
        />
      </div>

      <div className="flex align-items-center justify-content-center column-gap-3">
        {calenderNote.map((c, id) => {
          return (
            <div className="flex align-items-center column-gap-2" key={id}>
              <img src={c.icon} alt={c.title} />
              <p className="calendar_txt">{c.title}</p>
            </div>
          );
        })}
      </div>

      <div className="status-not-updated flex flex-column row-gap-2 align-items-center p-3">
        <p>You have not updated your status on</p>
        <div className="">
          {nonFilledTimesheetDate.map((time, id, last) => {
            const lastItem = last.length === id + 1 ? "" : ",";
            return (
              <span className="status-not-updated-dates" key={id}>
                {time}
                {lastItem}
              </span>
            );
          })}
        </div>
      </div>
      <Divider />

      <div className="special-holiday flex flex-column row-gap-2 align-items-center  justify-content-center p-2">
        <div className="flex column-gap-2">
          <img src={date} alt="date" />
          <p>Special holidays</p>
        </div>
        {holidays.length > 0 ? (
          <div className="holidays-list">
            {holidays.map((data, id) => {
              return (
                <li key={id}>
                  {new Date(data.from).toDateString()} - {data.title}{" "}
                </li>
              );
            })}
          </div>
        ) : (
          <p>No holidays this month</p>
        )}
      </div>
    </div>
  );
}

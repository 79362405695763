import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Home from "../../pages/HR Managemnet/home";
import HREmbedRoute from "../Human Resource/hrEmbeddedRoute";
import { useSelector } from "react-redux";
import Unauthorized from "../../component/common/unauthorized/unauthorized";

export default function HRLayout() {
  const location = useLocation();
  const {memberDetails}=useSelector((store) => store.memberDetails);
  // const hasAccess=memberDetails?.routes?.includes("Human Resource");
  const hasAccess = async () => {
    return await memberDetails?.routes?.includes("Human Resource")
  }
  if(hasAccess){
  return (
    <div>
       <HREmbedRoute></HREmbedRoute>
      {location.pathname === "/hr" ? <Home></Home> : ""}
      <Outlet></Outlet>
    </div> 
  );
  }
  else{
    return <Unauthorized></Unauthorized>
  }
}

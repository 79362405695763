import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputIcon from "../../component/common/inputIcon/inputIcon";
import TimesheetTable from "../../component/timesheet/timesheetTable";
import { Button } from "primereact/button";
import Calender from "../../component/timesheet/calender";
import {
  getTimesheet,
  setInitialState,
  setProjectId,
  setSearch,
} from "../../store/features/Timesheet/timesheet";
import exportImage from "./../../assets/export.svg";
import emptyImage from "./../../assets/leave-empty.svg";
import ExportTimesheet from "../../component/timesheet/exportTimesheet";
import TimesheetFilter from "../../component/timesheet/timesheetFilter";
import DropdownFilter from "../../component/timesheet/dropdownFilter";
import Spinner from "./../../component/common/spinner/spinner";
import constants from "../../constants";
import EmptyScreen from "../../component/common/empty/emptyScreen";
import { isAdminAccess, isEmptyArray } from "../../utility/utility";

export default function Timesheet() {
  const dispatch = useDispatch();

  const { isLoading, timesheet, projectId } = useSelector(
    (store) => store.timesheet
  );

  const [searchValue, setSearchValue] = useState("");
  const [exportDialog, setExportDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const hrAdmin = isAdminAccess("Human Resource");
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    dispatch(setSearch(e.target.value));
  };
  useEffect(() => {
    dispatch(setInitialState());
    dispatch(setProjectId(""));
    dispatch(getTimesheet(""));
  }, [searchValue, projectId]);

  return (
    <>
      {exportDialog && (
        <ExportTimesheet
          exportDialog={exportDialog}
          setExportDialog={setExportDialog}
        />
      )}
      {filterDialog && (
        <TimesheetFilter
          setFilterDialog={setFilterDialog}
          filterDialog={filterDialog}
          hrAdmin={hrAdmin}
        />
      )}
      <div className="flex flex-column mx-5 row-gap-4">
        <div className="flex w-full align-items-center justify-content-between">
          <div className="search-bar">
            <InputIcon placeholder="Search" onChange={handleSearch} />
          </div>
          <div className="flex align-items-center column-gap-2">
            <DropdownFilter />
            <Button
              className="filter-button"
              label="Filter"
              icon="pi pi-filter"
              onClick={() => setFilterDialog(!filterDialog)}
            />
            {hrAdmin && (
              <img
                src={exportImage}
                width={20}
                height={20}
                className="hover"
                onClick={() => setExportDialog(!exportDialog)}
              />
            )}
          </div>
        </div>

        <div className="flex column-gap-4">
          <div style={{ width: "65%" }}>
            {isLoading && (
              <div className="flex justify-content-center align-items-center">
                <Spinner
                  className="flex justify-content-center"
                  value={isLoading}
                />
              </div>
            )}

            {!isEmptyArray(timesheet) ? (
              <TimesheetTable />
            ) : (
              <EmptyScreen
                img={emptyImage}
                description={constants.EMPTY_SCREEN_TEXT[7].description}
              />
            )}
          </div>

          <div className="calender__container" style={{ width: "35%" }}>
            <Calender />
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { InputText } from "primereact/inputtext";
function InputField({ value, onChange, placeholder, label, name,disable,type,required,onKeyDown }) {
  if(disable==undefined){
    disable=false;
  }
  if(type===undefined){
    type="text"
  }
  if(required===undefined){
    required=false
  }
  return (
    <div>
      <span className="p-float-label">
        <InputText
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          disabled={disable}
          type={type}
          required={required}
          onKeyDown={onKeyDown}
        />
        <label htmlFor="inputtext">{label}</label>
      </span>
    </div>
  );
}
export default InputField;

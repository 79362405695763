import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import "./taskCreateInput.scss";
import Image from "../../utility/image";
import { createTask } from "../../store/features/Projects/Task";
import { useSelector, useDispatch } from "react-redux";
import { taskType_placeholder } from "../../utility/utility";
import { getSprintList } from "../../store/features/Projects/Sprint";
import { useParams } from "react-router-dom";

export default function TaskCreateInput({ getBacklogData, sprintID, onHide }) {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const { projectDetails } = useSelector((store) => store.project);
  const [isOpen, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [selectType, setSelectType] = useState("Task");
  const [inputValue, setInputValue] = useState("");

  const taskDropdown = [
    { id: 1, image: Image.taskImage, name: "Task" },
    { id: 2, image: Image.bugImage, name: "Bug" },
    { id: 3, image: Image.storyImage, name: "Story" },
  ];

  const toggleDropdown = () => setOpen(!isOpen);

  const handlePlaceholder = (id) => {
    const placeholder = taskDropdown.find((item) => item.id === parseInt(id));
    return taskType_placeholder(placeholder?.name);
  };

  const handleItemClick = (id, selectedType) => {
    selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    setSelectType(selectedType);
  };

  const handleTaskApi = (e) => {
    if (e.key === "Enter" || e.code === "Enter") {
      const payload = {
        title: e.target.value,
        type: selectType,
        isBacklog: true,
        status: "To Do",
        project: {
          id: projectDetails._id,
          name: projectDetails?.projectDetails.projectName,
        },
      };
      if (sprintID) {
        payload["isBacklog"] = false;
        payload["sprintId"] = sprintID;
      }
      dispatch(createTask(payload)).then((res) => {
        if (!res.error) {
          setInputValue("");
          if (getBacklogData) getBacklogData();
          if (sprintID && onHide) {
            dispatch(getSprintList(projectId));
            onHide(false);
          }
        }
      });
    }
  };

  return (
    <>
      <div className="task-layout">
        <div className="flex align-items-center column-gap-2 p-2">
          <div className="flex align-items-center column-gap-2">
            {selectedItem ? (
              <>
                <img
                  width={25}
                  height={25}
                  src={
                    taskDropdown.find((item) => item.id == selectedItem)?.image
                  }
                  alt="backlog"
                />
                <span className="tasklayout-text">
                  {taskDropdown.find((item) => item.id == selectedItem)?.name}
                </span>
              </>
            ) : (
              <>
                <img
                  width={25}
                  height={25}
                  src={taskDropdown[0]?.image}
                  alt="task-layout"
                />
                <span className="tasklayout-text">{taskDropdown[0]?.name}</span>
              </>
            )}

            <i
              className={`pi pi-angle-down down-arrowicon  ${isOpen && "open"}`}
              onClick={toggleDropdown}
            />
          </div>

          <InputText
            placeholder={handlePlaceholder(selectedItem)}
            onKeyDown={handleTaskApi}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />
        </div>

        <div
          className={`backlog-values ${isOpen && "open"}`}
          onClick={toggleDropdown}
        >
          <div className="all-item">
            {taskDropdown.map((item, id) => (
              <div
                key={id}
                className="backlog-item"
                onClick={(e) => handleItemClick(e.target.id, item.name)}
                id={item.id}
              >
                <img src={item.image} alt="task-type" /> {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

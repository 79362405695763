import React from "react";
import { Chip } from "primereact/chip";
import "./chipBtn.scss";
export default function ChipButton({ label, image }) {
  return <Chip label={label} image={image} />;
}

export function PriorityChip({ label }) {
  if (label === "Low") return <ChipLowBtn label={label} />;
  else if (label === "High") return <ChipHighBtn label={label} />;
  else if (label === "Medium") return <ChipMediumBtn label={label} />;
  return <ChipButton label={label} />;
}
export function ChipLowBtn({ label }) {
  return (
    <>
      <div className="chip__low__btn chip">
        <span>{label}</span>
      </div>
    </>
  );
}

export function ChipHighBtn({ label }) {
  return (
    <>
      <div className="chip__high__btn chip">
        <span>{label}</span>
      </div>
    </>
  );
}

export function ChipMediumBtn({ label }) {
  return (
    <>
      <div className="chip__medium__btn chip">
        <span>{label}</span>
      </div>
    </>
  );
}

export const ChipBtn = ({ label }) => {
  switch (label.toLowerCase()) {
    case "low":
      return (
        <>
          <div className="chip-low flex justify-content-center align-items-center">
            Low
          </div>
        </>
      );
    case "medium":
      return (
        <>
          <div className="chip-medium flex justify-content-center align-items-center">
            Med
          </div>
        </>
      );
    case "high":
      return (
        <>
          <div className="chip-high flex justify-content-center align-items-center">
            High
          </div>
        </>
      );
    default:
      <div style={{ color: "red" }}>error occured</div>;
      break;
  }
};

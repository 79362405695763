import React, { useEffect, useState } from "react";
import Steps from "../../../component/common/steps/steps.js";
import "./signUpStep.scss";
import SignUp from "../signup";
import SelectPlan from "../selectPlan.js";
import OrganizationSignup from "../organization-signup.js";
import Login from "../login.js";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../payment/checkoutForm.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KET);

const SignUpSteps = () => {
  const { getActiveIndex } = useSelector((store) => store.signUp);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(getActiveIndex);
  }, [getActiveIndex]);

  const stepSwitch = () => {
    switch (activeIndex) {  
      case 0:
        return <SignUp />;
      case 1:
        return <OrganizationSignup />;
      case 2:
        return <SelectPlan isUpgradeUI={false} />;
      case 3:
        return (
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        );

      default:
        return <Login />;
    }
  };

  return (
    <>
      <div className="flex flex-column row-gap-4 ">
        <Steps
          model={["Sign Up", "Set up", "Select Plan", "Payment"]}
          activeIndex={activeIndex}
        />
        {stepSwitch()}
      </div>
    </>
  );
};

export default SignUpSteps;

import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingSprintApi } from "../../store/features/DashBoard";
import Image from "../../utility/image";
import EmptyScreen from "../common/empty/emptyScreen";
import MemberContainer from "../common/member-container/memberContainer";

export default function UpcomingSprints() {
  const { getUpcomingSprint } = useSelector((store) => store.dashBoard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUpcomingSprintApi());
    return () => {};
  }, []);

  return (
    <>
      <div className="dashboard-profile-container bg-white overflow-y-scroll">
        {getUpcomingSprint && getUpcomingSprint.length > 0 ? (
          <div className="flex flex-column row-gap-2 p-3">
            <div className="announcement-time">Upcoming Sprints/Tasks</div>
            <div>
              {getUpcomingSprint.some((item) =>
                moment(item.sprint.startDate).isAfter(new Date())
              ) ? (
                getUpcomingSprint
                  .filter((item) =>
                    moment(item.sprint.startDate).isAfter(new Date())
                  )
                  .map((item, id) => (
                    <MemberContainer
                      key={id}
                      image={item.project.projectDetails.companyLogo}
                      name={item.project.projectDetails.projectName}
                      title={item.sprint.title}
                      desc={moment(item.sprint.startDate).format("DD MMM YYYY")}
                    />
                  ))
              ) : (
                <EmptyScreen
                  img={Image.emptySprint}
                  description="No Upcoming Sprints"
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ marginTop: "4rem" }}>
            <> </>
          </div>
        )}
      </div>
    </>
  );
}

import { Route, Routes } from "react-router-dom";
import Login from "./../pages/Authentication/login";
import ForgotPassword from "./../pages/Authentication/forgot-password";
import VerifyOtp from "./../pages/Authentication/verify-otp";
import OrganizationSignup from "./../pages/Authentication/organization-signup";
import ChangePassword from "./../pages/Authentication/change-password";
import UnprotectedRoutes from "./upProtectedRoute";
import AuthLayout from "./Layouts/AuthLayout";
import ForgotPasswordOTP from "./../pages/Authentication/forgot-password-otp";
import SelectPlan from "../pages/Authentication/selectPlan";
import Payment from "../pages/Authentication/payment";
import UserSignup from "../pages/Authentication/userSignup";
import SignUpSteps from "../pages/Authentication/sign-up-steps/signUpStep";
import { ExistingAccountSteps } from "../pages/Authentication/existing-user/existingAccountSteps";
import { Terms } from "../pages/Authentication/policy/terms";
import { PrivacyPolicy } from "../pages/Authentication/policy/privacy-policy";



export function AuthRoute() {
  return (
    <>
      <Routes>
        <Route element={<UnprotectedRoutes />}>
          <Route path="/auth" element={<AuthLayout />}  exact>
          <Route  path="terms"  element={<Terms />}  exact />
          <Route  path="privacy-policy"  element={<PrivacyPolicy />}  />
            <Route path="existing-account" element={<ExistingAccountSteps />} exact />
            <Route path="login" element={<Login />} exact />
            <Route path="signup" element={<SignUpSteps />} exact />
            <Route path="forgot-password" element={<ForgotPassword />} exact />
            <Route path="verify-otp" element={<VerifyOtp />} exact />
            <Route path="org-signup" element={<OrganizationSignup />} exact />
            <Route path="change-password" element={<ChangePassword />} exact />
            <Route path="otp-verify" element={<ForgotPasswordOTP />} exact />
            <Route path="select-plan" element={<SelectPlan />} exact />
            <Route path="payment" element={<Payment />} exact />
            <Route path="userSignup/:orgId/:userId" element={<UserSignup />} exact />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

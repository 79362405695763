import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
// import { FileUpload } from "primereact/fileupload";
import Input from "../common/inputtext/input-field";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import Button from "../common/button/button";
import { getAllUsers } from "../../store/features/Users/User";
import {
  getSlackChannel,
  getAllProjects,
  updateProject,
} from "../../store/features/Projects/Project";
import {
  resetImageUrl,
  uploadImage,
  removeImage,
  resetRemoveImageUrlStatus,
} from "../../store/features/Image/image";
import ProfileUpload from "../common/file-upload/fileUpload";

export default function UpdateProject({
  updateProjectDialog,
  setUpdateProjectDialog,
  selectedItem,
  setSelectedItem,
}) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [userOptions, setUserOptions] = useState([]);
  const { allUsers } = useSelector((store) => store.users);
  // const [slack, setSlack] = useState([]);
  const { slackChannel, skip, limit, search } = useSelector(
    (store) => store.project
  );

  //image add and remove
  const { imageUrl, removeImageUrlStatus } = useSelector(
    (store) => store.image
  );
  const [projectImageUrl, setProjectImageUrl] = useState("");
  const [updateItem, setUpdateItem] = useState({});

  useEffect(() => {
    if (imageUrl !== "") {
      setProjectImageUrl(imageUrl);
      dispatch(resetImageUrl());
    }
  }, [imageUrl]);

  useEffect(() => {
    if (imageUrl == "" && removeImageUrlStatus == "success") {
      dispatch(resetImageUrl());
      setProjectImageUrl("");
      dispatch(resetRemoveImageUrlStatus());
    }
  }, [removeImageUrlStatus]);

  useEffect(() => {
    if (JSON.stringify(selectedItem) != "{}") {
      setUpdateItem({ ...selectedItem });
      setProjectImageUrl(selectedItem.projectDetails.companyLogo);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (allUsers) {
      let options = [];
      allUsers.forEach((user) => {
        options.push({ value: user._id, label: user.basic.fullName });
      });
      setUserOptions(options);
    }
  }, [allUsers]);

  // useEffect(() => {
  //   if (slackChannel) {
  //     let channeList = [];
  //     slackChannel.forEach((list) => {
  //       channeList.push(list.name);
  //     });
  //     channeList.unshift("none");
  //     setSlack(channeList);
  //   }
  // }, [slackChannel]);

  useEffect(() => {
    dispatch(getAllUsers());
    //  dispatch(getSlackChannel());
  }, []);

  const changeHandler = (e, object_name) => {
    let embeddedObject = {
      ...updateItem[object_name],
      [e.target.name]: e.target.value,
    };
    const updatedObject = { ...updateItem, [object_name]: embeddedObject };
    setUpdateItem(updatedObject);
  };

  const uploadHandler = async (e) => {
    try {
      // setLoading(true);
      const fileData = new FormData();
      let files = e.files;
      if (files[0] !== undefined) {
        if (files[0].size < 1024000) {
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", async () => {
            fileData.append("file", files[0]);
            dispatch(uploadImage({ folderName: "project", data: fileData }));
          });
        } else {
          // setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "File Size too large",
            detail: "",
            life: 3000,
          });
        }
      }
    } catch (err) {
      //setLoading(false);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  };

  const removeProjectLogo = async () => {
    dispatch(removeImage({ folderName: "project", data: projectImageUrl }));
  };

  const submitHandler = async () => {
    const data = {
      ...updateItem,
      projectDetails: {
        ...updateItem.projectDetails,
        companyLogo: projectImageUrl,
      },
    };
    const payload = {
      ...data,
      // slackChannel:
      //   data.projectDetails.slackChannel === "none"
      //     ? null
      //     : data.projectDetails.slackChannel,
    };
    dispatch(updateProject({ updatedDetails: payload })).then((res) => {
      if (res.payload?.message === "Updated successfully") {
        setUpdateProjectDialog(!updateProjectDialog);
        dispatch(getAllProjects({ skip, limit, search }));
      } else {
        toast.current.show({
          severity: "error",
          summary: "Please check if you have entered valid fields",
          detail: "",
          life: 3000,
        });
      }
    });
  };

  const handlePreFillValue = (val) => {
    let date;
    if (val) date = new Date(val);
    else date = null;
    return date;
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Update Project"
        visible={true}
        style={{ width: "50vw" }}
        onHide={() => {
          setUpdateProjectDialog(!updateProjectDialog);
        }}
      >
        {/* <div className="ml-2 label-font">Project Logo</div> */}
        <ProfileUpload
          profileImg={projectImageUrl}
          labelName="Profile Logo"
          uploadHandler={uploadHandler}
          remove={removeProjectLogo}
        />
        <div className="flex">
          <div className="col-12 ml-1">
            {/* <small style={{color:"red"}}>{"*"}</small> */}
            <Input
              className="asset-input"
              placeholder="Project Name"
              name="projectName"
              label="Project Name"
              type="text"
              value={updateItem?.projectDetails?.projectName}
              onChange={(e) => changeHandler(e, "projectDetails")}
              required={true}
            />
          </div>

          {/* <div className="col-6">
            <Dropdown
              options={slack}
              name="slackChannel"
              placeholder="Slack Channel"
              value={updateItem?.projectDetails?.slackChannel}
              onChange={(e) => changeHandler(e, "projectDetails")}
            />
          </div> */}
        </div>

        <div className="flex mt-2">
          <div className="col-6 ml-1">
            <Calendar
              dateFormat="dd/mm/yy"
              name="startDate"
              placeholder="Start Date"
              value={handlePreFillValue(updateItem?.projectDetails?.startDate)}
              readOnlyInput
              onChange={(e) => changeHandler(e, "projectDetails")}
            />
          </div>
          <div className="col-6">
            <Calendar
              dateFormat="dd/mm/yy"
              name="endDate"
              placeholder="End Date"
              value={handlePreFillValue(updateItem?.projectDetails?.endDate)}
              readOnlyInput
              onChange={(e) => changeHandler(e, "projectDetails")}
            />
          </div>
        </div>

        <div className="flex mt-2">
          <div className="col-6 ">
            {/* <small style={{color:"red"}}>{"*"}</small> */}
            <Dropdown
              options={userOptions}
              name="projectLead"
              placeholder="Project Lead"
              value={updateItem?.projectDetails?.projectLead}
              onChange={(e) => changeHandler(e, "projectDetails")}
            />
          </div>
          <div className="col-6">
            <Dropdown
              options={userOptions}
              name="clientId"
              placeholder="Select Client"
              value={updateItem?.projectDetails?.clientId}
              onChange={(e) => changeHandler(e, "projectDetails")}
            />
          </div>
        </div>

        <div className="grid justify-content-end mt-4 mr-2">
          <Button
            label="Submit"
            onClick={submitHandler}
            disabled={
              !updateItem?.projectDetails?.projectName ||
              !updateItem?.projectDetails?.projectLead
            }
          />
        </div>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import "./recognition.scss";
import constants from "../../constants";
import { getWithAuth } from "../../services/authentication";
import { Toast } from "primereact/toast";
import { Carousel } from "primereact/carousel";
import EmptyScreen from "../common/empty/emptyScreen";
import EmptyRecognition from "./../../assets/empty-recognition.svg";
import Alphabet from "../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../utility/utility";
import Image from "../../utility/image";

export default function Recognition() {
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const [allRecognition, setAllRecognition] = useState([]);

  const responsiveOptions = [
    {
      numVisible: 1,
      numScroll: 1,
    },
  ];

  useEffect(() => {
    getAllRecognition();
  }, []);

  async function getAllRecognition() {
    try {
      const url = constants.API.RECOGNITION.CREATE + `?filterOptions=1`;
      const response = await getWithAuth(url, token);
      let allRecognition = [];
      response.data.recognitions.forEach((recognition) => {
        recognition.recognitions.forEach((rec) => allRecognition.push(rec));
      });
      setAllRecognition(allRecognition);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  const allReg = (recognition) => {
    return (
      <div>
        <div className="all-recognition-box sm-2 md-2 lg-2">
          <Toast ref={toast} />
          <img
            className="orange-balloon"
            src={Image.orangeBalloon}
            alt="orangeballoon"
          />
          {recognition.profilePic ? (
            <img
              className="all-tab-recognition-image"
              src={recognition?.profilePic}
              alt="Employee-image"
            />
          ) : (
            <div className="flex justify-content-center align-items-center ">
              <Alphabet
                fontSize="2rem"
                letter={fn_firstcharacter(recognition.empName)}
                width="100px"
                height="100px"
                borderRadius="20%"
              />
            </div>
          )}
          <div className="flex justify-content-center announcement-time mt-2">
            <img
              className="blue-balloon"
              src={Image.blueBalloon}
              alt="blueballoon"
            />
          </div>
          <div className="flex justify-content-center announcement-time mt-2">
            <img
              className="red-balloon"
              src={Image.redBalloon}
              alt="redballoon"
            />
          </div>
          <div className="flex justify-content-center announcement-time mt-2">
            {recognition.empName}
          </div>
          <div className="flex justify-content-center announcement-time mt-2">
            {recognition.recognition}
          </div>
          <div className="flex justify-content-between">
            <img className="mt-2 leaf" src={Image.leftLeaf} alt="leftLeaf" />
            <img className="mt-2 leaf" src={Image.rightLeaf} alt="leafright" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {allRecognition.length > 0 ? (
        <Carousel
          value={allRecognition}
          numVisible={1}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          circular
          autoplayInterval={3000}
          itemTemplate={allReg}
        />
      ) : (
        <div className="all-recognition-box mt-2 empty-all-recognition-box">
          <br />
          <div className="announcement-header-font flex justify-content-center">
            Recognitions
          </div>
          <EmptyScreen
            img={EmptyRecognition}
            description="No Recognitions for this month"
          />
        </div>
      )}
    </div>
  );
}
<div></div>;

import React from "react";
import "./errorToast.scss";

export const ErrorToast = ({ title, description }) => {
  return (
    <div className="flex error__container w-full column-gap-2 p-2">
      <div className="flex align-items-center">
        <i
          className="pi pi-exclamation-circle"
          style={{ color: "#FF006D", fontSize: "1rem" }}
        />
      </div>
      <div className="flex flex-column row-gap-2">
        <p className="error__title">{title}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

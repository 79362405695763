import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Image from "../../utility/image";
import { getActiveTasks } from "../../store/features/DashBoard";
import { useDispatch, useSelector } from "react-redux";
import Alphabet from "../common/alphabet/alphabet";
import { fn_color, fn_firstcharacter, isAdminAccess } from "../../utility/utility";
import { Dropdown } from "primereact/dropdown";
import EmptyScreen from "../common/empty/emptyScreen";
import EmptyTasks from "../../assets/task-empty-state.svg";

export default function ProjectSprintsTable() {
  const dispatch = useDispatch();
  const { activeTasks } = useSelector((store) => store.dashBoard);
  const [show, setShow] = useState([]);
  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(5);
  const usersAdmin = isAdminAccess("Project")


  const onCustomPage2 = (event) => {
    setFirst2(event.first);
    setRows2(event.rows);
  };

  useEffect(() => {
    if (usersAdmin) dispatch(getActiveTasks());
  }, [usersAdmin]);

  function userNameHeader() {
    return (
      <div className="flex flex-nowrap timesheet-table-header-font">
        <img src={Image.userIcon} style={{ height: "16px" }} alt="user" />
        <div className="flex"> &nbsp;User Name</div>
      </div>
    );
  }
  const userName = (name) => {
    return (
      <div className="flex align-items-center">
        {name?.assignee?.basic?.profilePic ? (
          <img
            className="projects-img-size flex"
            src={name?.assignee?.basic?.profilePic}
          ></img>
        ) : (
          name?.assignee?.basic?.fullName && (
            <Alphabet
              letter={fn_firstcharacter(name?.assignee?.basic?.fullName)}
            />
          )
        )}
        <div className="ml-2 dataTable__Text">
          {name?.assignee?.basic?.fullName ?? "N/A"}
        </div>
      </div>
    );
  };

  const taskId = (taskId) => {
    return <div>{taskId.taskId}</div>;
  };

  const status = (status) => {
    return (
      <div
        style={{
          color: fn_color(status?.status),
          fontWeight: "600",
          fontSize: "13px",
        }}
      >
        {status?.status}
      </div>
    );
  };

  function taskName(task) {
    const descriptionLength = task.title.toString().length;
    if (descriptionLength) {
      if (descriptionLength > 30) {
        return (
          <div className="asset-name-align">
            {show.findIndex((s) => s._id == task._id) !== -1
              ? task.title
              : task.title.slice(0, 80)}
            <div
              className="read-more-button hover"
              onClick={() => {
                let index = show.findIndex((s) => s._id == task._id);
                if (index !== -1) {
                  let nonShow = show.filter((s) => s._id !== task._id);
                  setShow(nonShow);
                } else {
                  setShow([...show, { _id: task._id, value: true }]);
                }
              }}
            >
              {show.findIndex((s) => s._id == task._id) !== -1
                ? "Read less"
                : "Read more"}
            </div>
          </div>
        );
      } else {
        return <div className="asset-name-align">{task.title}</div>;
      }
    }
  }

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
      {usersAdmin  ? (
        <div className="table-box" style={{ minHeight: "80vh" }}>
          <div className="asset-table-size">
            <DataTable
              className="asset-table-font"
              sortMode="multiple"
              value={activeTasks}
              responsiveLayout="scroll"
              paginator
              paginatorTemplate={template2}
              first={first2}
              rows={rows2}
              onPage={onCustomPage2}
              paginatorClassName="justify-content-end custom-dropdown"
            >
              <Column body={userName} header={userNameHeader} />
              <Column field={taskName} header="Task Name" />
              <Column body={taskId} header="Task Id" />
              <Column body={status} header="Status" />
            </DataTable>
          </div>
        </div>
      ) : (
        <div
          className="table-box flex align-items-center justify-content-center"
          style={{ minHeight: "80vh" }}
        >
          <EmptyScreen img={EmptyTasks} title="No Ongoing Tasks" />
        </div>
      )}
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";
const initialState = {
  user: {},
  isLoading: false,
  status: "",
  error: "",
  token: "",
  loader: false,
  userDetails: {},
  stripBillingDetails: {},
  transactionHistory:[],
  getActiveIndexExistingStep : 0,
  existingEmail : ""

};

export const login = createAsyncThunk("auth/login", async (name, {rejectWithValue}) => {
  try {
    const passwordEncrypt = window.btoa(name.password);
    const response = await api.post(constants.API.AUTHENTICATION.SIGNIN,{
      email: name.email,
      password: passwordEncrypt,
    })
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});




export const userSignupApi = createAsyncThunk(
  "auth/reset/userSignupApi/",
  async (payload, {rejectWithValue}) => {
    try {
      const url = constants.API.AUTHENTICATION.USER_VERIFY;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



export const cancelSubcription = createAsyncThunk(
  "cancel/subcription",
  async (payload, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.CANCEL_SUBCRIPTION;
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



export const billingDetails = createAsyncThunk(
  "get/billingDetails",
  async (orgId, {rejectWithValue}) => {
    try {
      const url = constants.API.MEMBER.BILLING_DETAILS + `?orgId=${orgId}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



export const upgradePlanApi = createAsyncThunk(
  "auth/upgradePlanApi",
  async ({ id, payload }, {rejectWithValue}) => {
    try {
      const url =
        constants.API.MEMBER.UPGRADE_SUBCRIPTION + `?subscriptionId=${id}`;
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



export const getTransactionHistory = createAsyncThunk("get/history", async (subscriptionId,{rejectWithValue}) => {
  try {
    const url =  constants.API.MEMBER.TRANSACTION_HISTORY + `?subscriptionId=${subscriptionId}`;
    const response = await api.get(url)
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data.message);
  }
})

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.error = "";
      state.status = "";
    },
    setIsLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setActiveIndexExistingStep : (state, action) => {
      state.getActiveIndexExistingStep = action.payload
    },
    setExistingAccountEmail : (state, action) => {
      state.existingEmail = action.payload
    }
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true;
      state.loader = true;
    },
    [login.fulfilled]: (state, action) => {
      localStorage.setItem("token", action.payload.user.token);
      localStorage.setItem(
        "user",
        JSON.stringify(action.payload.user.memberDetails)
      );
      state.status = "success";
      state.isLoading = false;
      state.user = action.payload.user;
      state.loader = false;
    },
    [login.rejected]: (state, action) => {
      state.status = "error";
      state.isLoading = false;
      state.error = action.payload;
      state.loader = true;
    },
    [billingDetails.fulfilled]: (state, action) => {
      state.stripBillingDetails = action.payload;
      state.loader = false;
    },
    [billingDetails.rejected]: (state, action) => {
      state.stripBillingDetails = {};
    },
    [getTransactionHistory.fulfilled]:(state,action) => {
      state.transactionHistory  = action.payload

    }
  },
});

export const { resetStatus, setIsLoading, setUserDetails , setActiveIndexExistingStep ,setExistingAccountEmail} = loginSlice.actions;

export default loginSlice.reducer;

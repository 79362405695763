export default function validate(validation, confirmPassword) {
  if (validation["email"]) {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!regex.test(validation.email)) {
      return "Must be valid e-mail";
    } else {
      return "";
    }
  } else if (validation["fullName"]) {
    if (validation.fullName?.length < 4) {
      return "Name must be more than 4 character";
    } else {
      return "";
    }
  } else if (validation["name"]) {
    if (validation?.name?.length < 4) {
      return "Name must be more than 4 character";
    } else {
      return "";
    }
  } else if (validation["password"]) {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!regex.test(validation.password)) {
      return "Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    } else {
      return "";
    }
  } else if (validation["confirmPassword"]) {
    if (validation.confirmPassword !== confirmPassword) {
      return "password and confirm password must be same";
    } else {
      return "";
    }
  } else if (validation["terms"]) {
    if (validation.terms !== true) {
      return "checkbox must be checked";
    } else {
      return "";
    }
  } else if (validation["companyName"]) {
    if (validation.companyName.length < 4) {
      return "company name must be greated than 4 characters";
    } else {
      return "";
    }
  } else if (validation["website"]) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!regex.test(validation.website)) {
      return "Enter correct website format";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

import React, { useEffect, useState } from "react";
import "./modifySprintModal.scss";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import PrimaryButton from "../../../common/button/button";
import { useDispatch } from "react-redux";
import { getSprintList, startSprint } from "../../../../store/features/Projects/Sprint";
import { useParams } from "react-router-dom";

export default function ModifySprintModal({ item, visible, onHide }) {
  const [updateItem, setUpdateItem] = useState({});
  const dispatch = useDispatch();
  const {projectId}=useParams();

  const weekDropdown = [
    { label: "1 Week", value: "1 Week" },
    { label: "2 Weeks", value: "2 Weeks" },
    { label: "3 Weeks", value: "3 Weeks" },
    { label: "4 Weeks", value: "4 Weeks" },
    { label: "Custom", value: "Custom" },
  ];

  useEffect(() => {
    if (JSON.stringify(item) !== "{}") {
      setUpdateItem({ ...item });
    }
  }, [item]);

  const inputHandler = (e, field) => {
    const data = { ...updateItem };
    data[field] = e;
    setUpdateItem(data);
  };

  const startHandler = () => {
    // api call here
    onHide(!visible);
    dispatch(startSprint(updateItem)).then(() => {
      onHide(!visible);
      dispatch(getSprintList(projectId));
    })
  };
  return (
    <>
      <Dialog
        header="Would you like to modify the date of your sprint?"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => onHide(!visible)}
      >
        <div className="flex flex-column">
          <div className="col">
            <div className="label-font">Duration</div>
            <Dropdown
              options={weekDropdown}
              placeholder="Duration"
              value={updateItem?.duration}
              onChange={(e) => inputHandler(e.target.value, "duration")}
            ></Dropdown>
          </div>
          <div className="flex col-12">
            <div className="col-6">
            <div className="label-font">Start Date</div>
              <Calendar
                placeholder="Start Date"
                dateFormat="dd/mm/yy"
                value={new Date(updateItem?.startDate)}
                readOnlyInput
                onChange={(e) => inputHandler(e.target.value, "startDate")}
              />
            </div>
            <div className="col-6">
            <div className="label-font">End Date</div>
              <Calendar
                placeholder="End Date"
                dateFormat="dd/mm/yy"
                value={new Date(updateItem?.endDate)}
                readOnlyInput
                onChange={(e) => inputHandler(e.target.value, "endDate")}
              />
            </div>
          </div>
          <div className="flex justify-content-end column-gap-3">
            <PrimaryButton label="Cancel" onClick={() => onHide(!visible)} />
            <PrimaryButton label="Start" onClick={() => startHandler()} />
          </div>
        </div>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import constants from "../../constants";
import { Toast } from "primereact/toast";
import { getWithAuth } from "../../services/authentication";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Button from "../common/button/button";

export default function AssetFilterDialog({
  setFilterDialog,
  filterDialog,
  filterValue,
  setFilterValue,
  update,
  setUpdate,
  setFrom,
  setSkip
}) {
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const [employeeDropdown, setEmployeeDropdown] = useState([]);
  const statusDropdown = [
    { label: "UnAssigned", value: "UnAssigned" },
    { label: "Occupied", value: "Occupied" },
    { label: "Requested", value: "Requested" },
    { label: "Retired", value: "Retired" },
  ];
  useEffect(() => {
    getEmployeeList();
  }, []);
  async function getEmployeeList() {
    const url = constants.API.MEMBER.LIST;
    const response = await getWithAuth(url, token);
    const empData = [];
    response.data.members.forEach((member) => {
      empData.push({ label: member.basic.fullName, value: member._id });
    });
    setEmployeeDropdown(empData);
  }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header=" Asset Filter"
        visible={filterDialog}
        style={{ width: "30vw" }}
        position="right"
        onHide={() => {
          setFilterDialog(!filterDialog);
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="Requested From"
                dateFormat="dd/mm/yy"
                value={new Date(filterValue.from)}
                maxDate={filterValue.to}
                readOnlyInput
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    from: e.value,
                  })
                }
             />
            </div>
            <div className="col-6">
              <Calendar
                placeholder="Requested To"
                dateFormat="dd/mm/yy"
                value={new Date(filterValue.to)}
                minDate={filterValue.from}
                readOnlyInput
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    to: e.value,
                  })
                }
             />
            </div>
          </div>

          <div className="flex">
            <div className="col-6">
              <Dropdown
                options={statusDropdown}
                placeholder="Status"
                required={true}
                value={filterValue.status}
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    status: e.value,
                  })
                }
             />
            </div>
            <div className="col-6">
              <Dropdown
                options={employeeDropdown}
                placeholder="Employee Name"
                required={true}
                value={filterValue.memberId}
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    memberId: e.value,
                  })
                }
             />
            </div>
          </div>
        </div>

        <div className="grid justify-content-around mt-3">
          <Button
            label="Filter"
            onClick={() => {
              setSkip(1);
              setFrom(1);
              setFilterDialog(!filterDialog);
              setUpdate(!update);
            }}
          />
          <Button
            label="Reset"
            onClick={() => {
              setSkip(1);
              setFrom(1);
              setFilterDialog(!filterDialog);
              setFilterValue({});
              setUpdate(!update);
            }}
         />
        </div>
      </Dialog>
    </>
  );
}

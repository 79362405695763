import { Dialog } from "primereact/dialog";
import React from "react";
import Button from "../../common/button/button";

export default function CompleteSprintModel({
  completeSprintDialog,
  setCompleteSprintDialog,
  hasTasks,
  sprintCompleteHandler,
}) {
  let header;
  if (hasTasks) {
    header =
      "Sprint has tasks which are not in Done , Are you sure you want to end the sprint ? (Remaining task will be moved to backlog)";
  } else {
    header = "Are you sure you want to end the sprint";
  }

  return (
    <Dialog
      header="Complete Sprint"
      visible={completeSprintDialog}
      style={{ width: "30vw" }}
      onHide={() => setCompleteSprintDialog(!setCompleteSprintDialog)}
    >
      <h4>{header}</h4>
      <div className="flex flex-row-reverse mt-4">
        <Button
          label="Confirm"
          onClick={() => sprintCompleteHandler()}
        />
        <div
          className="announcement-time mt-2 mr-3"
          onClick={() => setCompleteSprintDialog(!completeSprintDialog)}
        >
          Cancel
        </div>
      </div>
    </Dialog>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../component/common/button/button";
import Input from "../../component/common/inputtext/input-field";
import validate from "../../factory-function/validate";
import {
  getMemberOrg,
  orgSignUp,
  resetorgSignUpStatus,
  setIsLoading,
} from "../../store/features/Auth/orgSignUp";
import { Toast } from "primereact/toast";
import { editActiveIndex } from "../../store/features/Auth/signup";
import Image from "../../utility/image";
import {
  removeImage,
  resetImageUrl,
  uploadImage,
} from "../../store/features/Image/image";

const stylesheet = {
  logInStyle: { cursor: "pointer", color: "#5A78EE", fontSize: "16px" },
  supportStyle: { color: "#666874", fontSize: "10px" },
};

export default function OrganizationSignup() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const logoRef = useRef(null);
  const { status, error, isLoading } = useSelector((store) => store.orgSignUp);
  const { email } = useSelector((store) => store.signUp);
  const { token } = useSelector((store) => store.otpVerify);
  const [loader, setloader] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    website: "",
  });
  const [companyLogo, setCompanyLogo] = useState("")
  const [errorDetails, setErrorDetails] = useState({
    companyName: "",
    website: "",
  });


  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(companyDetails).every((val) => val !== "");
    dispatch(setIsLoading(x && y));
  }, [errorDetails]);

  function changeHandler(e) {
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
    setErrorDetails({
      ...errorDetails,
      [e.target.name]: validate({ [e.target.name]: e.target.value }),
    });
  }

  const submitHandler = () => {
    setloader(true);
    const organisationDetails = {
      ...companyDetails,
      token: token,
      email: email,
    };

    dispatch(orgSignUp(organisationDetails)).then((res) => {
      setloader(false);
      if (!res.error) {
        dispatch(getMemberOrg(res.payload.signup.memberDetails));
        dispatch(editActiveIndex(2));
      }
    });
  };
  useEffect(() => {
    if (status === "error") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "Message Content",
        life: 3000,
      });
    }
    if (status === "success") {
      toast.current.show({
        severity: "success",
        summary: "USer added successfully",
        detail: "Message Content",
        life: 3000,
      });
    }
    dispatch(resetorgSignUpStatus());
  }, [status, error]);

  const urlHandler = (url) => {
    setCompanyLogo(url);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const handleUploadLogo = (file) => {
    convertFileToBase64(file)
      .then((base64String) => {
        urlHandler(base64String);
      })
      .catch(() => {
        urlHandler("");
      });
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file.size < 1024000) handleUploadLogo(file);
    else {
      toast.current.show({
        severity: "error",
        summary: "File Size too large",
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column row-gap-4">
        <div className="flex flex-column row-gap-2 ">
          <div className="h1-font">Setup your Application</div>
        </div>

        <div>
          <Input
            placeholder="Enter company Name"
            onChange={changeHandler}
            name="companyName"
            label="Company Name"
          />
          <span className="error-text">{errorDetails.companyName}</span>
        </div>

        <div>
          <Input
            placeholder="Enter website"
            onChange={changeHandler}
            name="website"
            label="Website"
          />
          <span className="error-text">{errorDetails.website}</span>
        </div>

        <div className="flex flex-column row-gap-2">
          <label>Logo</label>

          {!companyLogo ? (
            <div className="flex column-gap-2">
              <div className="flex ">
                <input
                  type="file"
                  id="company-logo"
                  style={{ display: "none" }}
                  ref={logoRef}
                  onChange={handleImageUpload}
                />
                <img
                  src={Image.companyLogo}
                  alt="companyLogo"
                  width={50}
                  height={50}
                  className="cursor-pointer"
                  onClick={() => logoRef.current.click()}
                />
              </div>
              <div className="flex flex-column">
                <p className="text-xs font-normal">
                  Upload your Organization logo{" "}
                </p>
                <p style={stylesheet.supportStyle}>Supports: jpg, png </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-column row-gap-2 align-items-center justify-content-center">
              <img
                className="border-circle"
                src={companyLogo}
                alt="companylogo"
                width={100}
                height={100}
              />
              <Button label="Remove Image" onClick={() => urlHandler("")} />
            </div>
          )}
        </div>

        <div className="flex justify-content-end">
          <Button
            onClick={submitHandler}
            disabled={isLoading}
            label="Submit"
            loading={loader}
          />
        </div>
      </div>
    </>
  );
}

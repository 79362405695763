import React, { useEffect, useState, useRef } from "react";
import constants from "../../constants";
import { Toast } from "primereact/toast";
import { getWithAuth } from "../../services/authentication";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Button from "../common/button/button";

export default function FilterDialog({
  setFilterDialog,
  filterDialog,
  filterValue,
  setFilterValue,
  update,
  setUpdate,
}) {
  const token = localStorage.getItem("token");
  const toast = useRef(null);
  const [leavePolicyDropDown, setLeavePolicyDropdown] = useState([]);
  const leaveStatusDropdown = [
    { label: "Approved", value: "Approved" },
    { label: "Declined", value: "Declined" },
    { label: "Pending", value: "Pending" },
  ];
  useEffect(() => {
    getAllPolicy();
  }, []);
  async function getAllPolicy() {
    try {
      const LEAVEPOLICYURL = constants.API.LEAVE_REQUEST.CREATE_LEAVE_POLICY;
      let leavePolicyResponse = await getWithAuth(LEAVEPOLICYURL, token);
      let data = leavePolicyResponse.data.leavePolicys;
      let leavePolicyDropdown = [];
      data.forEach((leavePolicy) => {
        leavePolicyDropdown.push({
          value: leavePolicy._id,
          label: leavePolicy.title,
        });
      });
      setLeavePolicyDropdown(leavePolicyDropdown);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Leave Filter"
        visible={filterDialog}
        style={{ width: "30vw" }}
        position="right"
        onHide={() => {
          setFilterDialog(!filterDialog);
        }}
      >
        <div>
          <div className="grid mt-3">
            <div className="col-6">
              <Calendar
                placeholder="From"
                dateFormat="dd/mm/yy"
                value={new Date(filterValue.from)}
                maxDate={filterValue.to}
                readOnlyInput
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    from: e.value,
                  })
                }
              />
            </div>
            <div className="col-6">
              <Calendar
                placeholder="To"
                dateFormat="dd/mm/yy"
                value={new Date(filterValue.to)}
                minDate={filterValue.from}
                readOnlyInput
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    to: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-6">
              <Dropdown
                options={leavePolicyDropDown}
                placeholder="Leave Type"
                required={true}
                value={filterValue.leavePolicyId}
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    leavePolicyId: e.value,
                  })
                }
              />
            </div>
            <div className="col-6">
              <Dropdown
                options={leaveStatusDropdown}
                placeholder="Leave Status"
                required={true}
                value={filterValue.status}
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    status: e.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <br />
        <div className="grid justify-content-around">
          <Button
            label="Filter"
            onClick={() => {
              setFilterDialog(!filterDialog);
              setUpdate(!update);
            }}
          />
          <Button
            label="Reset"
            onClick={() => {
              setFilterDialog(!filterDialog);
              setFilterValue({});
              setUpdate(!update);
            }}
          />
        </div>
      </Dialog>
    </>
  );
}

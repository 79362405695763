import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import validate from "./../../factory-function/validate";
import Input from "../../component/common/inputtext/input-field";
import { Password } from "primereact/password";
import Button from "../../component/common/button/button";
import Checkbox from "../../component/common/checkbox/checkbox";
import { Toast } from "primereact/toast";
import {
  signUp,
  resetSignUpStatus,
  setIsLoading,
  editActiveIndex,
  setSignUpDetails,
} from "../../store/features/Auth/signup";
import VerifyOtp from "./verify-otp";

export default function Signup() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { status, error, isLoading } = useSelector((store) => store.signUp);
  const [checked, setchecked] = useState(false);
  const [isOtpRender, setIsOtpRender] = useState(false);
  const [loader, setloader] = useState(false);

  const [signupDetails, setSignupDetails] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });
  const [errorDetails, setErrorDetails] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: "",
  });

  useEffect(() => {
    if (status === "error") {
      toast.current.show({
        severity: "error",
        summary: error,
        detail: "",
        life: 3000,
      });
    }
    if (status === "success") {
      // Navigate("/auth/verify-otp");
    }
    dispatch(resetSignUpStatus());
  }, [status]);

  useEffect(() => {
    let x = Object.values(errorDetails).every((val) => val === "");
    let y = Object.values(signupDetails).every((val) => val !== "");
    dispatch(setIsLoading(x && y));
  }, [errorDetails]);

  function handleOnChange(e) {
    setSignupDetails({ ...signupDetails, [e.target.name]: e.target.value });
    if (e.target.name === "confirmPassword") {
      setErrorDetails({
        ...errorDetails,
        [e.target.name]: validate(
          { [e.target.name]: e.target.value },
          signupDetails.password
        ),
      });
    } else {
      setErrorDetails({
        ...errorDetails,
        [e.target.name]: validate({ [e.target.name]: e.target.value }),
      });
    }
  }

  function submitHandler() {
    setloader(true);
    dispatch(signUp(signupDetails)).then((res) => {
      setloader(false);
      if (!res.error) {
        dispatch(
          setSignUpDetails({
            fullName: signupDetails.fullName,
            email: signupDetails.email,
          })
        );
        setIsOtpRender(!isOtpRender);
      }
    });
  }

  const stylesheet = {
    logInStyle: { cursor: "pointer", color: "#5A78EE", fontSize: "16px" },
  };

  return (
    <>
      <Toast ref={toast} />
      {!isOtpRender ? (
        <div className="flex flex-column row-gap-4">
          <div className="flex flex-column row-gap-2">
            <div className="h1-font">{"Sign up to Squadzen"}</div>

            <div className="paragraph-font flex align-items-center column-gap-1">
              <p>Already have an account?</p>
              <span
                style={stylesheet.logInStyle}
                onClick={() => Navigate("/auth/login")}
              >
                Log in
              </span>
            </div>
          </div>

          <div>
            <Input
              placeholder="Username"
              name="fullName"
              label="Your Name"
              type="text"
              onChange={handleOnChange}
              value={signupDetails.fullName}
            />
            <span className="error-text">{errorDetails.fullName}</span>
          </div>
          <div>
            <Input
              placeholder="Your Email"
              name="email"
              label="Your Email"
              type="text"
              onChange={handleOnChange}
              value={signupDetails.email}
            />
            <span className="error-text">{errorDetails.email}</span>
          </div>

          <div>
            <Password
              placeholder="Create password"
              name="password"
              label="Your Password"
              type="text"
              onChange={handleOnChange}
              value={signupDetails.password}
              feedback={signupDetails.password.length >= 1 ? true : false}
              toggleMask
            />

            {errorDetails.password && (
              <div className="p-float-label password-text error-text">
                {errorDetails.password}
              </div>
            )}
          </div>

          <div>
            <Password
              placeholder="Confirm password"
              name="confirmPassword"
              label="Confirm Password"
              type="text"
              onChange={handleOnChange}
              value={signupDetails.confirmPassword}
              toggleMask
              feedback={false}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />
            {errorDetails.confirmPassword && (
              <div className="p-float-label password-text error-text">
                {errorDetails.confirmPassword}
              </div>
            )}
          </div>

          <div className="flex align-items-center column-gap-2">
            <Checkbox
              checked={checked}
              name="terms"
              onChange={(e) => {
                setchecked(e.checked);
              }}
            />
            <label htmlFor="terms" >
              I agree to{" "}
              <Link className="theme-font no-underline hover:underline" target="_blank" to="/auth/terms">
                Terms of use
              </Link>
              {"  and  "}  
              <Link
                className="theme-font no-underline hover:underline"
                target="_blank"
                to="/auth/privacy-policy"
              >
                Privacy policy
              </Link>
            </label>
            <span></span>
          </div>

          <div className="flex justify-content-end ">
            <Button
              onClick={submitHandler}
              label="Continue"
              disabled={!checked || isLoading}
              loading={loader}
            />
          </div>
        </div>
      ) : (
        <VerifyOtp />
      )}
    </>
  );
}

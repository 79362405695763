import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import constants from "../../../constants";
import api from "../../../axios/axios-interceptor";


const initialState = {
  skip: 1,
  from: 1,
  limit: 10,
  to: 10,
};

export const UpdateTaskStatus = createAsyncThunk(
  "update/moveTaskStatus",
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.put(constants.API.PROJECT_SETTINGS.UPDATE_TASK, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const RemoveSlackChannel = createAsyncThunk("remove/slackChannel",
async (id, {rejectWithValue}) => {
    try {
        const url = constants.API.SLACK.REMOVE_SLACK + `/${id}`
        const response = await api.put(url,{})
        return response.data
    }
    catch (error) {
        return rejectWithValue(error.response.data.message);
      }
}
)


const projectSettingSlice = createSlice({
  name: "projectSetting",
  initialState,
  reducer: {},
  extraReducers: {},
});

export default projectSettingSlice.reducer;